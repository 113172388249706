import React from 'react';
import { Button, Calendar, Col, Form, Input, Row, Select, Card } from 'antd';
import TimelineTable from './components/timelineTable';

const MonthlyView = () => {
    const onPanelChange = (value, mode) => {
        console.log(value.format('YYYY-MM-DD'), mode);
    };
    return (<>
        <Row gutter={16}>
            <Col span={8} className='shadowCard'>
                <Calendar key='boxCalender' fullscreen={false} onPanelChange={onPanelChange} />
            </Col>
            <Col span={8} className='shadowCard'>
                <Card>
                    <Form layout='vertical'>
                        <Form.Item label="Office" name="office">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Provider" name="provider">
                            <Select />
                        </Form.Item>
                        <Form.Item label="Timeline" name="timeline">
                            <Select />
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
            <Col span={6} className='shadowCard'>
                <Button type="primary" >
                    Open Modal with async logic
                </Button>
            </Col>
        </Row>
        <Calendar key='fullCalender' onPanelChange={onPanelChange} />
        <Row>
            <Col span={24}>
            <TimelineTable />
            </Col>
        </Row>
    </>
    );
};
export default MonthlyView;