import axios from "axios";
import { API } from "../../config";

const InsuranceList = async () => {
  const response = await axios.get(API+'/insurance');
  return response.data;
};

const getPayer = async () => {
    const response = await axios.get(API+'/insurance/payer');
    return response.data; 
}
const createInsurance = async (payload) => {
    console.log(payload);
    const response = await axios.post(API+'/insurance', payload);
    return response.data;
  };
  
  const updateInsurance = async (payload) => {
      console.log(payload);
      const response = await axios.put(API+'/insurance/update', payload);
      return response.data;
  }
  
  const getInsurancebyId = async (id) => {
      const payload = {
          params: {
            id
          }
        }
        console.log(payload);
      const response = await axios.get(API+'/insurance/id', payload);
      return response.data;
    };

  const getAllPayer = async() => {
    const resp = await axios.get(API+'/payer/payers');
    return resp.data;
  }


const InsuranceAPI = {
    InsuranceList,
    createInsurance,
    updateInsurance,
    getInsurancebyId,
    getPayer,
    getAllPayer,
}

export default InsuranceAPI;