import axios from "axios";
import { API } from "../../../config";

const payerList = async () => {
  const response = await axios.get(API+'/payer');
  return response.data;
};

const patientList = async () => {
  try {
    const resp = await axios.get(API+'/patient');
    return resp.data;
  } catch (err) {
    console.log(err);
  }
}

const stateList = async () => {
  try {
    const resp = await axios.get(API+'/states');
    return resp.data;
  } catch (err) {
    console.log(err);
  }
}

const getPatient = async (id) => {
  let payload = {
    params: {
      id
    }
}
  try {
    const resp = await axios.get(API+'/patient/query', payload);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
}

const getPatientyQuery = async (id, key) => {
  let payload = {
    params: {
      patientId: id
    }
}
console.log(payload)
  try {
    const resp = await axios.get(API+'/patient/query', payload);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
}

const cityList = async (state_code) => {
  const payload = {
    params: {
      state_code
    }
  }
  try {
    const resp = await axios.get(API +'/states/city', payload);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
}

const cptcode = async () =>{
  try {
    const resp = await axios.get(API+'/code/cpt');
    return resp.data;
  } catch (err) {
    console.log(err);
  }
}

const getcptcode = async (search) => {
  const payload = {
      params: {
          search
      }
  }
  const response = await axios.get(API+'/code/cpt/search', payload);
  return response.data;
};


const providerList = async () => {
  try {
    const resp = await axios.get(API+'/provider');
    return resp.data;
  } catch (err) {
    console.log(err);
  }
}

const createPatientVisit = async (payload) => {

  try {
    const resp = await axios.post(API+'/patientvisit', payload);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
}

const searchPatient  = async (payload) => {
  try {
    const resp = await axios.post(API+'/patientvisit', payload);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
}


const getPatientVisitbyId = async (id) => {
  const payload = {
    params: {
        id
    }
}
  try {
    const resp = await axios.get(API+'/patientvisit/id', payload);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
}

const getPatientVisit = async () => {
  try {
    const resp = await axios.get(API+'/patientvisit');
    return resp.data;
  } catch (err) {
    console.log(err);
  }
}

const getPatientVisitquery = async (date) => {
  const payload = {
    params: {
        date
    }
}
  try {
    const resp = await axios.get(API+'/patientvisit/query', payload);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
}


const getProvider = async (id) => {
  const payload = {
    params: {
      id,
    }
  }
  try {
    const resp = await axios.get(API+'/provider/query', payload);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
}

const getIcdCodeAll = async () => {
  try {
    const resp = await axios.get(API+'/code/icdcode');
    return resp.data;
  } catch (err) {
    console.log(err);
  }
}

const patientApi = {
  payerList,
  patientList,
  stateList,
  cityList,
  cptcode,
  providerList,
  getPatient,
  getProvider,
  createPatientVisit,
  getPatientVisit,
  getcptcode,
  getPatientVisitquery,
  getPatientVisitbyId,
  getPatientyQuery,
  searchPatient,
  getIcdCodeAll,
}

export default patientApi