import { BarsOutlined, UserOutlined, EnvironmentOutlined, FieldTimeOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { Card, Table, Tag, Tooltip, Button, Space, TimePicker } from "antd"
import { useCallback, useEffect, useState } from "react";
import { generateCalendar } from "./generateCalendar";
import { useDispatch } from "react-redux";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import appointmentAPI from "../appointment.hook";
import Swal from "sweetalert2";

const format = 'HH:mm';
const TimelineTable = ({ appointmentsList, editApp }) => {
    const navigate = useNavigate();
    const [searchParams, setSearchBarParams] = useSearchParams();
    const [appmnt, setAppmnt] = useState([]);
    const [appointmentData, setAppointmentData] = useState([]);
    const getData = async () => {
        const appointmentData = await generateCalendar();
        setAppointmentData(appointmentData);
    }
    const createVisit = async (r) => {
        let params = {
            appId: r.data.id,
            isCreated: true,
            tab: 'addNewvisit',
        };
        // await appointmentAPI.updateCreateVisit(params);
        let path = {
            pathname: '/patient',
            search: createSearchParams(params).toString()
        }
        navigate(path);
    };

    const getAppointments = (h, p) => {
        const list = appointmentsList?.filter((g, i) => {
            const timemin = h?.min ? `${h.time}:${h.min}${h.timeAt}` : `${h.time}${h.timeAt}`;
            if (g.appointmenVisitTime === timemin) {
                h.data = {
                    ...h.data,
                    appointmenPatientPhone: g.appointmenPatientPhone,
                    providerId: g.providerId,
                    appointmenVisitDuration: g.appointmenVisitDuration,
                    appointmentPatientautoElgili: g.appointmentPatientautoElgili,
                    appointmentPatientdob: g.appointmentPatientdob,
                    appointmentPatientfirstName: g.appointmentPatientfirstName,
                    appointmetofficeRadio: g.appointmetofficeRadio,
                    checkIn: g.appointmentCheckIn,
                    checkout: g.appointmentCheckOut,
                    id: g._id
                }
                h.id = g._id + i;
                return h;
            }
        });
        return list[0];
    };

    useEffect(() => {
        // console.log('toi', appointmentsList, appointmentData);
        getData();
        const timeLinedata = () => {
            for (var i = 0; i < appointmentData.length; i++) {
                for (var j = 0; j < appointmentsList?.length; j++) {
                    const timemin = appointmentData[i]?.min ? `${appointmentData[i].time}:${appointmentData[i].min}${appointmentData[i].timeAt}` : `${appointmentData[i].time}${appointmentData[i].timeAt}`;
                    // console.log(timemin, j, appointmentsList[j].appointmenVisitTime);
                    if (timemin === appointmentsList[j].appointmenVisitTime) {
                        // console.log(appointmentData[i]);
                        appointmentData[i].data = {
                            ...appointmentData[i].data,
                            appointmenPatientPhone: appointmentsList[j].appointmenPatientPhone,
                            providerId: appointmentsList[j].providerId,
                            appointmenVisitDuration: appointmentsList[j].appointmenVisitDuration,
                            appointmentPatientautoElgili: appointmentsList[j].appointmentPatientautoElgili,
                            appointmentPatientdob: appointmentsList[j].appointmentPatientdob,
                            appointmentPatientfirstName: appointmentsList[j].appointmentPatientfirstName,
                            appointmetofficeRadio: appointmentsList[j].appointmetofficeRadio,
                            appointmentCheckIn: appointmentsList[j].appointmentCheckIn,
                            appointmentCheckOut: appointmentsList[j].appointmentCheckOut,
                            id: appointmentsList[j]._id,
                            isCreated: appointmentsList[j].isCreated,
                            appointmetReason: appointmentsList[j].appointmetReason,
                            status: appointmentsList[j].status,
                        }
                        appointmentData[i].id = appointmentsList[j]._id;
                    } else {
                        appointmentData[i].id = `${i}-${j}`;
                    }

                }
            }
            setAppmnt(appointmentData);
            // console.log(appointmentData);
            // const appData = appointmentData?.map((d, i) => {
            //     return getAppointments(d, i);
            // });
            // console.log(appData)
            // setAppmnt(appData);
        }
        timeLinedata();
    }, [appointmentsList]);

    const getInforApp = (r) => {
        return (
            <Card
                title="Patient Information"
                bordered={false}
                style={{
                    width: 230,
                }}
                size="small"
            >
                <div className="tip">

                    <div>
                        <span className="tipLabel icon"><UserOutlined /> {r?.data?.appointmentPatientfirstName}</span>
                        {/* <span className="tipValue"></span> */}
                    </div>
                    <div style={{ marginTop: '8px' }}><span className="tipLabel">Dob :</span> <span className="tipValue"> {r?.data?.appointmentPatientdob}</span></div>
                    <div style={{ marginTop: '8px' }}><span className="tipLabel">Phone :</span> <span className="tipValue">  {r?.data?.appointmenPatientPhone}</span></div>
                    <div style={{ marginTop: '8px' }}><span className="tipLabel icon"><FieldTimeOutlined />Reason</span> <span className="tipValue">  {r?.data?.reasonVisit}</span></div>
                    <div style={{ marginTop: '8px' }}><span className="tipLabel">check In :</span> <span className="tipValue" style={{ fontSize: '12px' }}>  {r?.data?.appointmentCheckIn} - {r?.data?.appointmentCheckOut}</span></div>
                    <div style={{ marginTop: '8px' }}><span className="tipLabel">Provider :</span> <span className="tipValue">  {r?.data?.appointmenPatientProvider}</span></div>
                    <div style={{ marginTop: '8px' }}><span className="tipLabel status">Status :</span> <span className="tipValue">  {r?.data?.status}</span></div>
                    <div style={{ marginTop: '8px' }}><span className="tipLabel icon"><EnvironmentOutlined /> In person</span></div>
                </div>

            </Card>
        )
    }

    const columns = [
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
            width: '8%',
            render: (text, record) => (
                record?.min === 0 ?
                    <><Space size="small" />
                        <span className="hourlyRowhightly">  {record?.time} : {record?.min} {record?.timeAt}</span></> :
                    <span className="mintsRowhightly"> : {record?.min} {record?.timeAt}</span>)
        },
        {
            title: 'Lenght',
            dataIndex: ["data", "appointmenVisitDuration"],
            key: 'appointmenVisitDuration',
            render: (text) => (
                text ? <span> {text} mins</span> : ''
            )
        },
        {
            title: 'Patient Name',
            dataIndex: ["data", "appointmentPatientfirstName"],
            key: 'appointmentPatientfirstName',
            render: (text, record) => (<Tooltip color="#fff" title={getInforApp(record)}> {text}</Tooltip>)
        },
        {
            title: 'DoB',
            dataIndex: ["data", "appointmentPatientdob"],
            key: 'appointmentPatientdob'
        },
        {
            title: 'Phone',
            dataIndex: ["data", "appointmenPatientPhone"],
            key: 'appointmenPatientPhone'
        },
        {
            title: 'Provider',
            dataIndex: ["data", "providerId"],
            key: 'appointmenPatientProvider'
        },
        {
            title: 'Reasion Visit',
            dataIndex: ["data", "appointmetReason"],
            key: 'appointmetReason'
        },
        {
            title: 'Automated Eligibility',
            dataIndex: ["data", "appointmentPatientautoElgili"],
            key: 'appointmentPatientautoElgili'
        },
        {
            title: 'Check In',
            dataIndex: ["data", "appointmentCheckIn"],
            key: 'appointmentCheckIn',
            width: '8%',
            // render: (txt, r) => <TimePicker size="small" value={txt ? dayjs(txt, format) : ''} use12Hours format="h:mm a" />
        },
        {
            title: 'Check Out',
            dataIndex: ["data", "appointmentCheckOut"],
            key: 'appointmentCheckOut',
            width: '8%',
        },
        {
            title: 'Create Visit',
            dataIndex: ["data", "isCreated"],
            key: 'isCreated',
            render: (text, record) => {
                // console.log(text, record);
                return text ? <Tag color="blue" className="tagCreate">Created</Tag> :  (record?.data?.appointmentPatientfirstName)? <Tag color="green" className="tagCreate" onClick={() => createVisit(record)}>Create Visit</Tag>: null
            }
        },
        {
            title: 'Action',
            dataIndex: ["data", "id"],
            key: 'id',
            render: (text, record) => <>
                <Space>
                    <Tag color="green" onClick={() => editApp(record)}>
                        <EditOutlined />  Edit
                    </Tag>
                    {/* <Tag color="green" onClick={() => editApp(record)}>
                        <SaveOutlined /> 
                    </Tag> */}
                </Space>

            </>
        }
    ];

    return (
        <Table
            dataSource={appmnt}
            rowClassName={(record) => (record?.min === 0 ? "hourRow" : "")}
            rowKey={(record) => record?.id}
            columns={columns}
            pagination={{ pageSize: 36 }}
            size="small"
        />
    );
}

export default TimelineTable;