import { Input, Typography, Select, DatePicker, Form } from "antd";
import { SearchOutlined, DeleteOutlined } from "@ant-design/icons";
const { Title } = Typography;

const data = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'];

const dataIcd10 = []

for (var i = 0; i < 12; i++) {
    let dt =
    {
        name: data[i],
        iv: `(${i + 1})`,
        fromDate: 'fromDate',
        toDate: 'toDate',
        pos: 'pos',
        cpt: 'cpt',
        modifier: [{ 'v': '' }, { 'v': '' }, { 'v': '' }, { 'v': '' }],
        icd10Pointer: 'icd10Pointer',
        lineCharge: 'lineCharge',
        dayorunit: 'dayorunit',
        insPayment: 'insPayment',
        patientPayment: 'patientPayment',
        adj: 'adj',
        balance: 'balance',
    }
    dataIcd10.push(dt);
}
const { Option } = Select

const ICDCode10 = ({ cptList, calcTotal, popIcd, del }) => {
    // const dummy = [{ _id: "65aade125f698b86583217ff", code: "0003T", descp: "Cervicography" },
    // { _id: "65aade125f698b8658321809", code: "0024T", descp: "Transcath cardiac reduction" },
    // { _id: "65aade125f698b8658321811", code: "0033T", descp: "Endovasc taa repr incl subcl" },
    // { _id: "65aade125f698b8658321813", code: "0035T", descp: "Insert endovasc prosth, taa" },
    // { _id: "65aade125f698b8658321815", code: "0037T", descp: "Artery transpose/endovas taa" },
    // { _id: "65aade125f698b8658321818", code: "0040T", descp: "Rad s/i, endovasc taa prosth" },
    // { _id: "65aade125f698b865832181d", code: "0045T", descp: "Whole body photography" },
    // { _id: "65aade125f698b865832181e", code: "0046T", descp: "Cath lavage, mammary ducts" },
    // { _id: "65aade125f698b8658321824", code: "0052T", descp: "Replace component heart syst" },
    // { _id: "65aade125f698b8658321826", code: "0054T", descp: "Bone surgery using computer" },
    // { _id: "65aade125f698b865832182b", code: "0060T", descp: "Electrical impedance scan" },
    // { _id: "65aade125f698b865832182c", code: "0061T", descp: "Destruction of tumor, breast" },
    // { _id: "65aade125f698b865832182e", code: "0063T", descp: "Rep intradisc annulus;1lev" }]

    // const getcptOptionCode = () => {
    //     const cptOpt = dummy?.map((d) => <Option key={d.code} name={d.descp} value={d.code}>{d.code}</Option>);
    //     return cptOpt;
    // }

    return (
        <>

            <Title level={5}>ICD - 10 </Title>
            <div className="icd9Wrapper">
                <div style={{ display: 'block' }}>
                    <Select style={{ width: '250px', margin: '10px 25px' }} >
                        <Select.Option value="ICD - system defined">ICD system define</Select.Option>
                    </Select>
                </div>
                <div key={`icd10-header`} className={`icd10-header-row`}>
                    <span className="icdCode10-header squ">
                        No
                    </span>
                    <span className="icdCode10-header date">
                        From date
                    </span>
                    <span className="icdCode10-header date">
                        To date
                    </span>
                    <span className="icdCode10-header sel">
                        POS
                    </span>
                    <span className="icdCode10-header sel">
                        CPT
                    </span>
                    <span className="icdCode10-header modifierWrapper">
                        Modifier
                        <span style={{ display: 'block' }}>
                            <span className="modifer">
                                A
                            </span>
                            <span className="modifer">
                                B
                            </span>
                            <span className="modifer">
                                C
                            </span>
                            <span className="modifer">
                                D
                            </span>
                        </span>

                    </span>
                    <span className="icdCode10-header nPut">
                        ICD-10 Pointer
                    </span>
                    <span className="icdCode10-header nPut">
                        Line Charge
                    </span>
                    <span className="icdCode10-header nPut">
                        Days or Units
                    </span>
                    <span className="icdCode10-header nPut">
                        Ins. Payment
                    </span>
                    <span className="icdCode10-header nPut">
                        Patient Payment
                    </span>
                    <span className="icdCode10-header nPut">
                        Adj
                    </span>
                    <span className="icdCode10-header nPut">
                        Balance
                    </span>
                    <span className="icdCode10-header squ">
                        Clear
                    </span>
                </div>
                {dataIcd10.map((d, i) => {
                    return (
                        <div key={`icd10-${i}`} className={`icd10-row`}>
                            <span className="icdCode10-squ">
                                {i + 1}
                            </span>
                            <span className="icdCode10-input date">
                                <Form.Item name={['icdcode', i, 'fromDate']} >
                                    <DatePicker format={'MM/DD/YYYY'} size="small" style={{ height: '28px' }} />
                                </Form.Item>
                            </span>
                            <span className="icdCode10-input date">
                                <Form.Item name={['icdcode', i, 'toDate']} >
                                    <DatePicker format={'MM/DD/YYYY'} size="small" style={{ height: '28px' }} />
                                </Form.Item>
                            </span>
                            <span className="icdCode10-input sel">
                                <Form.Item name={['icdcode', i, 'pos']}>
                                    <Input size="small" />
                                    {/* <Select
                                        size="small"
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {getcptOptionCode()}
                                    </Select> */}
                                </Form.Item>
                                {/* <span> <SearchOutlined onClick={() => popIcd('icdcode', i, 'pos')} /> </span> */}
                            </span>
                            <span className="icdCode10-input sel">
                                <Form.Item name={['icdcode', i, 'cpt']}>
                                    <Input size="small" />
                                    {/* <Select
                                        size="small"
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {getcptOptionCode()}
                                    </Select> */}
                                </Form.Item>
                            </span>
                            <span className="icdCode10-input modifierWrapper">
                                {d.modifier.map((m, j) => (

                                    <span key={`icd10-${i}-${j}`} className="icdCode10-input modifer">
                                        <Form.Item name={['icdcode', i, 'modified', j]}>
                                            <Input size="small" />
                                        </Form.Item>
                                    </span>
                                )
                                )}

                            </span>
                            <span className="icdCode10-input">
                                <Form.Item name={['icdcode', i, 'icd10Pointer']}>
                                    <Input size="small" />
                                </Form.Item>
                            </span>
                            <span className="icdCode10-input">
                                <Form.Item name={['icdcode', i, 'lineCharge']}>
                                    <Input size="small" onBlur={(e, h) => calcTotal(e, i, d, h)} />
                                </Form.Item>
                            </span>
                            <span className="icdCode10-input">
                                <Form.Item name={['icdcode', i, 'dayorunit']}>
                                    <Input size="small" />
                                </Form.Item>
                            </span>
                            <span className="icdCode10-input">
                                <Form.Item name={['icdcode', i, 'insPayment']}>
                                    <Input size="small" disabled />
                                </Form.Item>
                            </span>
                            <span className="icdCode10-input">
                                <Form.Item name={['icdcode', i, 'patientPayment']}>
                                    <Input size="small" disabled />
                                </Form.Item>
                            </span>
                            <span className="icdCode10-input">
                                <Form.Item name={['icdcode', i, 'adj']}>
                                    <Input size="small" disabled />
                                </Form.Item>
                            </span>
                            <span className="icdCode10-input">
                                <Form.Item name={['icdcode', i, 'balance']}>
                                    <Input size="small" disabled />
                                </Form.Item>
                            </span>
                            <span className="icdCode10-input del" onClick={() => del(i)}>
                                <DeleteOutlined />
                            </span>
                        </div>
                    )
                })}
            </div>

        </>
    )
}

export default ICDCode10;