import { useEffect, useState } from "react";
import IcdcodeAPI from "../../utilities/datalist/icdcode.api";
import { Col, Row, Table, Button, Input, Tag, Typography } from "antd";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";

const {Title } = Typography
const IcdCode = () => {
    const [dataList, setDataList] = useState([]);
    const [fulldataList, setFulldataList] = useState([]);


    const saveValue = (r) => {
        console.log(r);
    }

    const columns = [
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code'
        },
        {
            title: 'Description',
            dataIndex: 'desp',
            key: 'desp',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            // render: (txt, r) => (
            //     <Row gutter={12}>
            //         <Col span={12}>
            //             <Tag color="green" onClick={() => saveValue(r)}>
            //                 <SaveOutlined /> Save
            //             </Tag>
            //         </Col>
            //     </Row>
            // )
        }
    ]

    useEffect(() => {
        const getCodes = async () => {
            const resp = await IcdcodeAPI.getIcdcode();
            setFulldataList(resp);
            setDataList(resp);
        };
        getCodes();
    }, []);

    const filterData = (e, type) => {

        const fData = fulldataList.filter((d) => {
            if (type === 'desp') {
                const dtype = d[type].toLowerCase();
                if (dtype.includes(e.target.value.toLowerCase())) {
                    return d;
                }
            }
            if (type === 'code') {
                const dtype = d[type].toString().toLowerCase();
                if (dtype.includes(e.target.value.toString().toLowerCase())) {
                    return d;
                }
            }
        })
        setDataList(fData);
    }
    return (
        <Row gutter={[16, 16]}>

        <Col span={6}>
            <Title level={5}>Code</Title>
            <Input type="text" onChange={(e) => filterData(e, 'code')} />
        </Col>
        <Col span={6}>
            <Title level={5}>Description</Title>
            <Input type="text" onChange={(e) => filterData(e, 'desp')} />
        </Col>
            <Col span={24}>
                <Table
                    rowKey="_id"
                    dataSource={dataList}
                    columns={columns} />
            </Col>
        </Row>
    )
}

export default IcdCode;