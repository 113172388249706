import { Button, Col, Divider, Form, Input, Row, Select, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import ProviderAPI from "./provider.api";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { headerName } from "../../layout/headerName.hook";
const { Title } = Typography;

const Providerlist = () => {
    const navigate = useNavigate();
    const [dataList, setDataList] = useState([]);
    const [dataListfull, setDataListfull] = useState([]);

    const dispatch = useDispatch();
    dispatch(headerName(`Provider List`));

    const editProvider = (v, r) => {
        console.log('editBpProvider');
        let params = { id: r._id, mode: 'edit' };
        let path = {
            pathname: '/provider',
            search: createSearchParams(params).toString()
        }
        navigate(path);
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'providerId',
            key: 'providerId',
            render: (txt, r) => (<p className="tdClick" onClick={() => editProvider(txt, r)}>{txt}</p>)
        },
        {
            title: 'Last Name',
            dataIndex: 'providerLastName',
            key: 'providerLastName',
            render: (txt) => (<p style={{cursor:'pointer'}} onClick={() => editProvider(txt)}>{txt}</p>)
        },
        {
            title: 'First Name',
            dataIndex: 'providerFirstName',
            key: 'providerFirstName',
            render: (txt) => (<p style={{cursor:'pointer'}} onClick={() => editProvider(txt)}>{txt}</p>)
        },
        {
            title: 'State',
            dataIndex: 'providerState',
            key: 'providerState'
        }, {
            title: 'City',
            dataIndex: 'providerCity',
            key: 'providerCity'
        },
        {
            title: 'Zip code',
            dataIndex: 'providerZip',
            key: 'providerZip'
        },
        {
            title: 'Phone',
            dataIndex: 'providerPhonenumber',
            key: 'providerPhonenumber'
        },
        {
            title: 'NPI',
            dataIndex: 'providerNPI',
            key: 'providerNPI'
        }
    ]

    const handlefilter = (val) => {
        let arry = [];
        for (var i = 0; i < dataListfull.length; i++) {
            // console.log(dataListfull[i][val['searchby']], val.searchValue)
            if (dataListfull[i][val['searchby']]?.toLowerCase().includes(val?.searchValue?.toLowerCase())) {
                arry.push(dataListfull[i]);
            }
        }
        setDataList(arry);
    }

    useEffect(() => {
        const getbplist = async () => {
            const resp = await ProviderAPI.providerList();
            setDataList(resp);
            setDataListfull(resp)
        }
        getbplist();
    }, [])
    return (
        <>
            <div className="billingproviderlistwrapper">
                <Row>
                <Col span={24}>
                        <Title level={4} >
                            Provider List</Title>
                            <Divider/>
                    </Col>
                </Row>
                <Form layout="vertical" onFinish={handlefilter}>
                    <Row gutter={16}>
                        <Col span={6}>

                            <Form.Item name="searchby" label="Filter By" >
                                <Select
                                    style={{ width: '100%' }}
                                    size="small" >
                                    <Select.Option value="providerLastName"> Last name</Select.Option>
                                    <Select.Option value="providerFirstName">First Name</Select.Option>
                                    <Select.Option value="provideState">State</Select.Option>
                                    <Select.Option value="providerCity">City</Select.Option>
                                    <Select.Option value="providerZip">Zip</Select.Option>
                                    <Select.Option value="providerPhonenumber">Phone</Select.Option>
                                    <Select.Option value="providerNPI">NPI</Select.Option></Select>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="searchwith" style={{ marginTop: '22px' }} >
                                <Select size="small" name="searchwith" style={{ width: '100%' }} >
                                    <Select.Option value="billingProvideStart">Start with</Select.Option>
                                    <Select.Option value="billingProvideEnd">Ends with</Select.Option>
                                    <Select.Option value="billingProvideContian">Contains</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="searchValue" label="Search value" >
                                <Input size="small" name="searchValue" />
                            </Form.Item>
                        </Col>
                        <Col span={6} justify="end" style={{ marginTop: '20px', color: '#fff' }} >
                            <Button htmlType="submit" className="btnPrimary">
                                Filter
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className="providerTable">
                <Row gutter={8}>
                    <Col span={24}>
                        <Table
                            rowKey="_id"
                            dataSource={dataList}
                            columns={columns} />
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Providerlist;