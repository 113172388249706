import { useEffect, useState } from "react"
import { errorHandler } from "../../utilities/helpers"
import { UserServiceAPI } from "./user.service.api";
import { Col, Row, Space, Table, Tag } from "antd";
import { EditOutlined } from "@ant-design/icons";



const UserList = () => {
    const [dataList, setDataList] = useState([]);
    const [useLists, setUseLists] = useState([]);

    const editUser = (v, r) => {
        console.log(v, r);
    }

    const getAllUser = async () => {
        try {
            let resp = await UserServiceAPI.userList();
            console.log(resp);
            setDataList(resp);
            setUseLists(resp);
            return resp;
        } catch (err) {
            errorHandler(err);
        }
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'userId',
            key: 'userId',
            render: (txt, r) => (<p className="tdClick" onClick={() => editUser(txt, r)}>{txt}</p>)
        },
        {
            title: 'Name',
            dataIndex: 'username',
            key: 'username',
            render: (txt) => (<p style={{cursor:'pointer'}} onClick={() => editUser(txt)}>{txt}</p>)
        },
        
        {
            title: 'SSN',
            dataIndex: 'user_SSN',
            key: 'user_SSN'
        },
        {
            title: 'Role',
            dataIndex: 'userRole',
            key: 'userRole',
            // render: (txt) => (<p style={{cursor:'pointer'}} onClick={() => editUser(txt)}>{txt}</p>)
        },
        {
            
            title: 'Profile Name',
            dataIndex: 'profileName',
            key: 'profileName'
        },
        {
            title: 'Facility',
            dataIndex: 'facility',
            key: 'facility'
        }, 
        {
            title: 'mail',
            dataIndex: 'email',
            key: 'email'
        }, {
            title: 'State',
            dataIndex: 'state',
            key: 'state'
        },
        {
            title: 'Zip code',
            dataIndex: 'zip',
            key: 'zip'
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone'
        },
        {
            title: "Action",
            render: () => (
                <Space>
                    <Tag color='green'>
                        Generate Password
                    </Tag>
                    <Tag color='green'>
                       <>
                       <EditOutlined/> Edit
                       </> 
                    </Tag>
                </Space>
            )
        }

    ]

    useEffect(() => {
        getAllUser();
    }, []);

    return (
        <div className="providerTable">
                <Row gutter={8}>
                    <Col span={24}>
                        <Table
                            rowKey="_id"
                            dataSource={dataList}
                            columns={columns} />
                    </Col>
                </Row>
            </div>
    )
}

export default UserList;