import React, { useEffect, useState } from 'react';
import './style.scss';
import dayjs from 'dayjs';
import dayLocaleData from 'dayjs/plugin/localeData';
import { Col, Row, Select, DatePicker } from 'antd';
import TimelineTable from './components/timelineTable';
import appointmentAPI from './appointment.hook';
import patientApi from '../patient/hook/patient.api';
import { getUserInfo } from '../../store/hook/user.hook';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

dayjs.extend(dayLocaleData);

const Option = Select;
const DailyViewAppointment = ({ payerList, patients, editApp }) => {
    const [appointmentsList, setAppointmentsList] = useState([]);
    const [querydate, setQuerydate] = useState(dayjs().format('MM/DD/YYYY'));
    const [queryProvd, setQueryProvd] = useState('');
    const [providerList, setProviderList] = useState([]);

    const [isUserInfo, setIsUserInfo] = useState();
  const navigate = useNavigate();
  const userInfo = useSelector(getUserInfo);

  useEffect(() => {
    setIsUserInfo(userInfo);
  }, [isUserInfo, userInfo]);

  const isAuthenticated = userInfo?.userId ? true : false;
  if (!isAuthenticated) navigate('/login');
    

    const handleProvd = (v) => {
        setQueryProvd(v);
        getAppiontments(querydate, v);
    }

    useEffect(() => {
        const getProviders = async () => {
            const providerLists = await patientApi.providerList();
            if (providerLists.length) {
                if(userInfo.role === 'provider'){
                    setQueryProvd(userInfo.providerId);
                } else{
                    setQueryProvd(providerLists[0].providerId);
                }
                
                setProviderList(providerLists);
            }
        }
        getProviders();
    }, []);

    const getAppiontments = async (date, provd) => {
        let query ={
            date,
            providerId: provd
        }
        console.log(query);
        const appsList = await appointmentAPI.getDateAppointment(query, 'day');
        setAppointmentsList(appsList);
    }

    const onPanelChange = (v) => {
        setQuerydate(v?.format('MM/DD/YYYY'));
        getAppiontments(v?.format('MM/DD/YYYY'), queryProvd)
    }
    useEffect(() => {
        getAppiontments(querydate, userInfo.providerId);
    }, [querydate]);

    return (
        <>

            <Row gutter={[16, 24]} style={{ marginBottom: '20px' }}>
                {/* <Col span={8} className='shadowCard'>
                    <Calendar fullscreen={false} onChange={onPanelChange} />
                </Col>
                <Col span={8} className='shadowCard'>
                    <Card>
                        <Form layout='vertical'>
                            <Form.Item label="Office" name="office">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Provider" name="provider">
                                <Select />
                            </Form.Item>
                            <Form.Item label="Timeline" name="timeline">
                                <Select />
                            </Form.Item>
                        </Form>
                    </Card>
                </Col> */}
                {/* <Col span={6} className='shadowCard'>
                    <Button type="primary" >
                        Open Modal with async logic
                    </Button>
                </Col> */}
                <Col span={3} > Appoinment Date</Col>
                <Col span={3} >
                    <DatePicker size='small' onChange={ onPanelChange} format={'MM/DD/YYYY'} />
                </Col>

                <Col span={3} > Provider </Col>
                <Col span={6}>
                    <Select size='small' style={{ width: '200px' }} onChange={(e) => handleProvd(e)} value={queryProvd} name="provider" >
                        {providerList?.map((p) =>
                            <Option value={p.providerId} key={p.providerId} >{p.providerFirstName}</Option>
                        )}
                        {/* <Option value="all" key="all" >All</Option> */}
                    </Select>
                </Col>

            </Row>
            <Row>
                <Col span={24}>
                    <TimelineTable appointmentsList={appointmentsList} editApp={editApp} />
                </Col>
            </Row>

        </>
    )

}

export default DailyViewAppointment;