import { Button, Col, Divider, Form, Input, Row, Select, Typography } from "antd"
// import './style.scss'
import moment from "moment";
import ReferringProviderAPI from "./referringprovider.api";
import patientApi from "../patient/hook/patient.api";
import { useEffect, useState } from "react";
import { headerName } from "../../layout/headerName.hook";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import InsuranceAPI from "../insurance/insurance.api";
import TaxonomycodeAPI from "../../utilities/datalist/taxonomycode.api";
import Swal from "sweetalert2";
const { Title } = Typography;

const NewReferringProvider = () => {
    const [form] = Form.useForm();
    const [cities, setCities] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [referringProviderdocid, setReferringProviderdocid] = useState();
    const [referringProviderData, setReferringProviderData] = useState();
    const [searchParams] = useSearchParams();
    const [mode, setMode] = useState(false);
    const [taxonomyList, setTaxonomyList] = useState([]);

    const dispatch = useDispatch();
    dispatch(headerName(`Referring Provider`));

    const { Option } = Select;

    const onFinish = async (v) => {
        console.log(v);
        const payload = {
            ...v,
            referringProviderId: new Date().getTime(),
            createBy: 'admin',
            createTime: new Date().getTime(),
            createDate: moment().format('MM/DD/YYYY'),
            facilityId: '',
        }
        console.log(payload);
        const resp = await ReferringProviderAPI.createReferringProvider(payload);
        console.log(resp);
        Swal.fire({
            title: 'Successfully!',
            text: 'Referring Provider created Successfully!',
            icon: 'success',
            confirmButtonText: 'Okay'
        });
    }

    const onState = async (v) => {
        const cityList = await patientApi.cityList(v);
        setCities(cityList);
    }

    useEffect(() => {
        const getStateList = async () => {
            const stateLists = await patientApi.stateList();
            setStateList(stateLists);
        }
        const getTaxnomo = async () => {
            const resp = await TaxonomycodeAPI.getTaxonomycode();
            setTaxonomyList(resp);
        }
        getTaxnomo();
        getStateList();
    }, []);

    useEffect(() => {
        const pId = searchParams.get('id');
        const pmode = searchParams.get('mode');
        setReferringProviderdocid(pId);
        setMode(pmode === 'edit');
        console.log(pId, pmode);
        if (pId) {
            const getbProvider = async (id) => {
                const resp = await ReferringProviderAPI.getReferringProviderId(id);
                console.log(resp);
                setReferringProviderData(resp[0]);
                form.setFieldsValue(resp[0]);
            }
            getbProvider(pId)
        }

    }, [form, searchParams]);

    return (
        <>
            <div className="providerFormWrapper">
            <Row>
                <Col span={24}>
                        <Title level={4} >
                            Reffering Provider</Title>
                            <Divider/>
                    </Col>
                </Row>
                <Form layout="vertical"
                    initialValues={{
                        ...referringProviderData
                    }}
                    className="providerForm"
                    onFinish={onFinish}
                    form={form} >
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="referringProvideLastname" label="Referring Provider Last Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a last name!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="referringProvidefirstname" label="First Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a first name!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="referringProvideMiddlename" label="MI Name">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="referringProvideAddress" label="Address"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a Address!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="referringProvideAdress2" label="Addres line2">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="referringProvideState" label="state"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a state!',
                                },
                            ]}>
                                <Select showSearch size="small"
                                    optionFilterProp="children"
                                    onSelect={onState}
                                    filterOption={(input, option) =>
                                        option?.props?.children?.toString().toLowerCase().indexOf(input?.toString().toLowerCase()) >= 0
                                        || option?.props?.value?.toString().toLowerCase().indexOf(input?.toString().toLowerCase()) >= 0
                                    } >
                                    {stateList?.map((s) => <Option key={s.id} value={s.state_code}>{s.state_code}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="referringProvideCity" label="City"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a city!',
                                },
                            ]}>
                                <Select showSearch size="small"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                    {cities?.map((s) => <Option key={s.id} value={s.name}>{s.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="referringProvideZip" label="Zip Code" 
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a zip code!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="referringProvidePhonenumber" label="Phone"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a phone!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name="referringProvideFaxnumber" label="Fax">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="referringProviderEmail" label="Email">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="referringProvideTaxId" label="Tax ID"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a tax Id!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="referringProvideTaxIdtype" label="Specialty" 
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a Specialty!',
                                },
                            ]}>
                                <Select showSearch size="small"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.props?.children?.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                                        || option?.props?.value?.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                                    } >
                                    {taxonomyList?.map((s) => <Option key={s.Code} value={s.Code}>{s.Code}-{s.DisplayName}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        </Row>
                        <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="referringProviderContact" label="Contact Person">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="referringProviderUPIN" label="UPIN" rules={[
                                {
                                    required: true,
                                    message: 'Please enter a PIN!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="referringProviderIndividualNPI" label="Individual NPI" 
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a NPI!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="referringProvideSpecialty" label="Specialty EIN/SSN" rules={[
                                {
                                    required: true,
                                    message: 'Please select a ein/ssn!',
                                },
                            ]}>
                                <Select size="small" >
                                    <Select.Option value="EIN">EIN</Select.Option>
                                    <Select.Option value="SSN">SSN</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={6} justify="end" style={{ marginTop: '30px' }}>
                        <Col span={4}>
                            <Button htmlType="submit" className="btnPrimary">Submit</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}

export default NewReferringProvider;