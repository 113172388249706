import axios from "axios";
import { API } from "../../config";

const getCptcode = async () => {
  const response = await axios.get(API+'/code/cptcode');
  return response.data;
};

const getCptcodeId = async (id) => {
  const payload = {
    params: {
      id
    }
  }
  console.log(payload);
  const response = await axios.get(API+'/code/cptcode/id', payload);
  return response.data;
};

const createCptcode = async (payload) => {
  // console.log(payload);
  const response = await axios.post(API+'/code/cptcode', payload);
  return response.data;
};

const updateCptcode = async (payload) => {
  console.log(payload);
  const response = await axios.put(API+'/code/cptcode/update', payload);
  return response.data;
}


const CptcodeAPI = {
    getCptcode,
    createCptcode,
    updateCptcode,
    getCptcodeId,
  }
  
  export default CptcodeAPI;