import { Button, Col, Divider, Form, Input, Row, Select, Tag, Typography } from "antd";
import patientApi from "../patient/hook/patient.api";
import { useEffect, useRef, useState } from "react";
import { CheckOutlined, DashOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import ProviderAPI from "../provider/provider.api";
import ProviderModel from "../../components/providerModel";
import { UserServiceAPI } from "./user.service.api";
import FacilityModal from "../../components/facility.modal";
import facilityAPI from "../facility/facility.api";
import Swal from "sweetalert2";

const { Title } = Typography;
const { Option } = Select;
const UserCreation = () => {
    const [form] = Form.useForm();
    const formRef = useRef(null);
    const [cities, setCities] = useState();
    const [stateList, setStateList] = useState();
    const [userdocid, setUserdocid] = useState();
    const [mode, setMode] = useState();
    const [isprovdModal, setIsprovdModal] = useState(false);
    const [userAdmin, setUserAdmin] = useState(false);
    const [userStaff, setUserStaff] = useState(true);
    const [modalTitle, setModalTitle] = useState('');
    const [providerList, setProviderList] = useState([]);
    const [popProvd, setPopProvd] = useState('');
    const [userSelect, setUserSelect] = useState('');
    const [validateColor, setValidateColor] = useState('red');
    const [pop, setPop] = useState('');
    const [isSpAdmin, setIsSpAdmin] = useState(true);

    const [fList, setFList] = useState([]);
    const [isfModal, setIsfModal] = useState(false);

    const onPullf = (key, value, type) => {
        setIsfModal(false);
        if (key) {
            let formFields = {
                facility: key?.facilityName,
                facilityId: key?.facility,
            }
            form.setFieldsValue({ ...formFields });
        }
    }
    const onFinish = async(e) => {
        try{
            await UserServiceAPI.createUser(e);
            Swal.fire({
                title: 'Success!',
                text: 'User created Successfully!',
                icon: 'success',
                confirmButtonText: 'Okay',
                confirmButtonColor: '#005154'
            });
        } catch (err){
            Swal.fire({
                title: 'Error!',
                text: err?.response?.data?.message || 'Something went wrong!. Please check with administrator',
                icon: 'error',
                confirmButtonText: 'Okay',
                confirmButtonColor: '#005154'
            });
        }

    }

    const searchFacility = () => {
        setIsfModal(true);
    }

    const handlefCancel = () => {
        setIsfModal(false);
    }

    const userRolchg = (e) => {
        console.log(e);
        setPop(e);
        if (e === 'provider') {
            setIsprovdModal(true);
            setModalTitle('Provider');
            setPopProvd('provider')
        }
    }

    const handleprovdCancel = () => {
        setIsprovdModal(false);
    }

    const handleprovdok = () => {
        setIsprovdModal(false);
    }

    const onPullprovider = async (key, value, type) => {
        console.log(key, value, type);
        setIsprovdModal(false);
        setUserSelect({
            id: key?.providerId,
            name: `${key?.providerLastName}, ${key?.providerFirstName}`
        });
        // let selected = {'userRoleId': `${key?.providerLastName}, ${key?.providerLastName}`};
        // console.log(selected)

    }

    useEffect(() => {
        form.setFieldsValue({
            userRoleID: userSelect?.name || '',
            profileId: userSelect?.id,
            profileName: userSelect?.name
        });
    }, [userSelect])

    const onState = async (v) => {
        const cityList = await patientApi.cityList(v);
        setCities(cityList);
    }

    const getProvider = async () => {
        const resp = await ProviderAPI.providerList();
        console.log(resp);
        setProviderList(resp);
    }

    const validDateUserId = async () => {
        const userId = form.getFieldValue('userId');
        console.log(userId);
        console.log(userId);
        let resp = await UserServiceAPI.getbyUserId(userId);
        console.log(resp);
    }

    const getAllfacilities = async () => {
        let resp = await facilityAPI.facilityList();
        setFList(resp);
        console.log(resp);
    }

    useEffect(() => {
        const getStateList = async () => {
            const stateLists = await patientApi.stateList();
            setStateList(stateLists);
        }
        getStateList();
        getProvider();
        getAllfacilities();
    }, []);

    return (
        <>
            <div className="providerFormWrapper">
                <Row>
                    <Col span={24}>
                        <Title level={4} >
                            User Management</Title>
                        <Divider />
                    </Col>
                </Row>
                <Form layout="vertical"
                    initialValues={{}}
                    className="providerForm"
                    onFinish={onFinish}
                    form={form}>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="userdocId" label="User Identinity">
                                <Input disabled size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="user_SSN" label="SSN Number" rules={[
                                {
                                    required: true,
                                    message: 'Please enter a User SSN!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="userId" label="User Id"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter a User Id!',
                                    },
                                ]}
                            >
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={1}>
                            <Tag color={validateColor} onClick={() => validDateUserId()} style={{ 'marginTop': '26px' }}>
                                <CheckOutlined color={validateColor} />
                            </Tag>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="username" label="User Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter a user name!',
                                    },
                                ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="email" label="E-Mail" rules={[
                                {
                                    required: true,
                                    message: 'Please Select a user role!',
                                },
                            ]} >
                                <Input size="small" />

                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="userRole" label="User Role" rules={[
                                {
                                    required: true,
                                    message: 'Please Select a user role!',
                                },
                            ]}>
                                <Select size="small" onChange={(e) => userRolchg(e)}>
                                    {userStaff && (
                                        <>
                                            <Option value="staff">Staff</Option>
                                            <Option value="administrated">Administrated</Option>
                                            <Option value="provider">Provider</Option>
                                        </>
                                    )}
                                    {userAdmin && (
                                        <>
                                            <Option value="userAdmin">User Admin</Option>
                                            <Option value="admin">Admin</Option>
                                        </>
                                    )}

                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item name="userRoleID"
                                rules={[
                                    {
                                        required: (pop === "provider"),
                                        message: 'Please Select a user role!',
                                    },
                                ]}>
                                <Input size="small" disabled style={{ marginTop: '25px' }} />
                            </Form.Item>

                            <Form.Item name="profileId" hidden >
                                <Input size="small" />
                            </Form.Item>

                            <Form.Item name="profileName" hidden>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        {isSpAdmin && (
                            <>
                                <Col span={4}>
                                    <Form.Item name="facility" label="Facility"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Select a Facility role',
                                            }
                                        ]}>
                                        <Input size="small" />
                                    </Form.Item>
                                    <Form.Item name="facilityId" hidden>
                                        <Input size="small" />
                                    </Form.Item>
                                </Col>

                                <Col span={1}>
                                    <SearchOutlined onClick={() => searchFacility()} className="searchBtn" style={{ marginTop: '22px' }} />
                                </Col>
                            </>
                        )}
                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="address" label="Address"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter a Address!',
                                    },
                                ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="addressline2" label="Address line 2">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="state" label="State"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter a state!',
                                    },
                                ]}>
                                <Select showSearch size="small"
                                    optionFilterProp="children"
                                    onSelect={onState}
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                    {stateList?.map((s) => <Option key={s.id} value={s.state_code}>{s.state_code}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="city" label="City"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter a city!',
                                    },
                                ]}>
                                <Select showSearch size="small"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                    {cities?.map((s) => <Option key={s.id} value={s.name}>{s.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="zip" label="Zip code"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter a zip code!',
                                    },
                                ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="phone" label="Phone"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter a phone!',
                                    },
                                ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="fax" label="Fax">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={6} justify="end" style={{ marginTop: '30px' }}>
                        <Col span={4}>
                            <Button htmlType="submit" className="btnPrimary"> {mode ? 'Update' : 'Submit'} </Button>
                        </Col>
                    </Row>

                </Form>
            </div>

            {isprovdModal && (
                <ProviderModel
                    modalTitle={modalTitle}
                    isprovdModal={isprovdModal}
                    handleprovd={userRolchg}
                    handleprovdCancel={handleprovdCancel}
                    onPullprovider={onPullprovider}
                    providerList={providerList}
                    popProvd={popProvd}
                />

            )}

            {isfModal && (
                <FacilityModal
                    isfModal={isfModal}
                    handlefCancel={handlefCancel}
                    fList={fList}
                    modalTitle={modalTitle}
                    onPullf={onPullf}
                />
            )}
        </>
    )

}

export default UserCreation;