import { Input, Typography, Select, Form } from "antd";
import { SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import { useEffect } from "react";
const { Title } = Typography;
const { Option } = Select;



const ICDCode9 = ({ descClear, cptList, form, dataIcd9, searchicd, onSel, onSearch, icdCodeList }) => {

    const geticdOptionCode = () => {
        const icdCodeLists = icdCodeList?.map((d) => <Option key={d.code} name={d.desp} value={d.code}>{d.code}</Option>);
        return icdCodeLists;
    }

    const geticdOptionDesp = () => {
        const icdCodeListds = icdCodeList?.map((d) => <Option key={d.code} value={d.code}>{d.desp}</Option>);
        return icdCodeListds;
    }


    return (
        <>
            <Title level={5}> Diagnosis Code - ICD Code10 </Title>
            <div className="icd9Wrapper">
                <div style={{ display: 'block' }}>
                    <Select size="small" style={{ width: '250px', margin: '10px 25px' }} >
                        <Select.Option value='icd-9'>icd9</Select.Option>
                        <Select.Option value='icd-9 system desfine'>icd9- system define</Select.Option>
                    </Select>
                </div>

                {dataIcd9?.map((d, i) => {
                    return (
                        <div key={`icd9-${i}`} className={`icd9-row`}>
                            <div className="icd9Code identity" >
                                <span>
                                    {d.name} {d.iv}
                                </span>
                            </div>
                            <div className="icd9Code inputCode">
                                <Form.Item name={['icd9', i, 'code']} rules={[
                                {
                                    required: i === 0,
                                    message: 'Please select a diagnosis!',
                                },
                            ]}>
                                    {/* <Select
                                    size="small" 
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.props?.children?.toString().indexOf(input?.toString().toLowerCase()) >= 0
                                        || option?.props?.value?.toString().indexOf(input?.toString().toLowerCase()) >= 0
                                    }
                                    onChange={(e, h) => onSel(e, i, d, h)}
                                    onSearch={(e) => onSearch(e, i, d)}
                                >
                                    {geticdOptionCode()}
                                </Select> */}
                                    {/* onBlur={(e) => onblur(e, i, d, h) } */}
                                    <Input size="small" onBlur={(e) => onSel(e, i, d) }/>
                                </Form.Item>
                            </div>
                            <div className="icd9Code inputDesc">
                                <Form.Item name={['icd9', i, 'desp']}>
                                    {/* <Select
                                    size="small" 
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.props?.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                        || option?.props?.value?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                    }
                                >
                                    {geticdOptionDesp()}
                                </Select> */}
                                    <Input size="small" />
                                </Form.Item>
                            </div>
                            <div onClick={() => searchicd(i)} className="icd9Code del">
                                <SearchOutlined />
                            </div>
                            <div onClick={() => descClear(i)} className="icd9Code del">
                                <DeleteOutlined />
                            </div>
                        </div>
                    )
                })}
            </div></>
    )
}

export default ICDCode9;