export const InsuranceData = ["1: Medicare",
"2: Medicaid",
"5: Commercial",
"81: Self-Pay",
"95: Workers Comp",
"99: Personal Injury",
"96: Auto",
"7: Managed Care",
"Other Source of Payment Codes",
"11: Medicare (Managed Care)",
"111: Medicare HMO",
"112: Medicare PPO",
"113: Medicare POS",
"119: Medicare Managed Care Other",
"12: Medicare (Non-managed Care)",
"121: Medicare FFS",
"122: Medicare Drug Benefit",
"123: Medicare Medical Savings Account (MSA)",
"129: Medicare Non-managed Care Other",
"19: Medicare Other",
"21: Medicaid (Managed Care)",
"211: Medicaid HMO",
"212: Medicaid PPO",
"213: Medicaid PCCM (Primary Care Case Management)",
"219: Medicaid Managed Care Other",
"22: Medicaid (Non-managed Care Plan)",
"23: Medicaid/SCHIP",
"24: Medicaid Applicant",
"25: Medicaid - Out of State",
"29: Medicaid Other",
"3: OTHER GOVERNMENT (Federal/State/Local)(excluding Department of Corrections)",
"31: Department of Defense",
"311: TRICARE (CHAMPUS)",
"3111: TRICARE Prime?HMO",
"3112: TRICARE Extra?PPO",
"3113: TRICARE Standard - Fee For Service",
"3114: TRICARE For Life--Medicare Supplement",
"3115: TRICARE Reserve Select",
"3116: Uniformed Services Family Health Plan (USFHP) -- HMO",
"3119: Department of Defense - (other)",
"312: Military Treatment Facility",
"3121: Enrolled Prime?HMO",
"3122: Non-enrolled Space Available",
"3123: TRICARE For Life (TFL)",
"313: Dental --Stand Alone",
"32: Department of Veterans Affairs",
"321: Veteran care--Care provided to Veterans",
"3211: Direct Care--Care provided in VA facilities",
"3212: Indirect Care--Care provided outside VA facilities",
"32121: Fee Basis",
"32122: Foreign Fee/Foreign Medical Program(FMP)",
"32123: Contract Nursing Home/Community Nursing Home",
"32124: State Veterans Home",
"32125: Sharing Agreements",
"32126: Other Federal Agency",
"322: Non-veteran care",
"3221: Civilian Health and Medical Program for the VA (CHAMPVA)",
"3222: Spina Bifida Health Care Program (SB)",
"3223: Children of Women Vietnam Veterans (CWVV)",
"3229: Other non-veteran care",
"33: Indian Health Service or Tribe",
"331: Indian Health Service - Regular",
"332: Indian Health Service - Contract",
"333: Indian Health Service - Managed Care",
"334: Indian Tribe - Sponsored Coverage",
"34: HRSA Program",
"340: Title V (MCH Block Grant)",
"342: Migrant Health Program",
"343: Ryan White Act",
"349: Other",
"35: Black Lung",
"36: State Government",
"361: State SCHIP program (codes for individual states)",
"362: Specific state programs (list/ local code)",
"369: State, not otherwise specified (other state)",
"37: Local Government",
"371: Local - Managed care",
"3711: HMO",
"3712: PPO",
"3713: POS",
"372: FFS/Indemnity",
"379: Local, not otherwise specified (other local, county)",
"38: Other Government (Federal, State, Local not specified)",
"381: Federal, State, Local not specified managed care",
"3811: Federal, State, Local not specified - HMO",
"3812: Federal, State, Local not specified - PPO",
"3813: Federal, State, Local not specified - POS",
"3819: Federal, State, Local not specified - not specified managed care",
"382: Federal, State, Local not specified - FFS",
"389: Federal, State, Local not specified - Other",
"39: Other Federal",
"4: DEPARTMENTS OF CORRECTIONS",
"41: Corrections Federal",
"42: Corrections State",
"43: Corrections Local",
"44: Corrections Unknown Level",
"51: Managed Care (Private)",
"511: Commercial Managed Care - HMO",
"512: Commercial Managed Care - PPO",
"513: Commercial Managed Care - POS",
"514: Exclusive Provider Organization",
"515: Gatekeeper PPO (GPPO)",
"519: Managed Care, Other (non HMO)",
"52: Private Health Insurance - Indemnity",
"521: Commercial Indemnity",
"522: Self-insured (ERISA) Administrative Services Only (ASO) plan",
"523: Medicare supplemental policy (as second payer)",
"529: Private health insurance?other commercial Indemnity",
"53: Managed Care (private) or private health insurance (indemnity), not otherwise specified",
"54: Organized Delivery System",
"55: Small Employer Purchasing Group",
"59: Other Private Insurance",
"6: BLUE CROSS/BLUE SHIELD",
"61: BC Managed Care",
"611: BC Managed Care - HMO",
"612: BC Managed Care - PPO",
"613: BC Managed Care - POS",
"619: BC Managed Care - Other",
"62: BC Indemnity",
"63: BC (Indemnity or Managed Care) - Out of State",
"64: BC (Indemnity or Managed Care) - Unspecified",
"69: BC (Indemnity or Managed Care) - Other",
"71: HMO",
"72: PPO",
"73: POS",
"179: Other Managed Care, Unknown if public or private",
"8: NO PAYMENT from an Organization/Agency/Program/Private Payer Listed",
"82: No Charge",
"821: Charity",
"822: Professional Courtesy",
"823: Research/Clinical Trial",
"83: Refusal to Pay/Bad Debt",
"84: Hill Burton Free Care",
"85: Research/Donor",
"89: No Payment, Other",
"9: MISCELLANEOUS/OTHER",
"91: Foreign National",
"92: Other (Non-government)",
"93: Disability Insurance",
"94: Long-term Care Insurance",
"951: Worker\'s Comp HMO",
"953: Worker\'s Comp Fee-for-Service",
"954: Worker\'s Comp Other Managed Care",
"959: Worker\'s Comp, Other unspecified",
"98: Other specified (includes Hospice - Unspecified plan)",
"99: No Typology Code available for payment source",
"9999: Unavailable / Unknown"]