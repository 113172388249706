import { SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, DatePicker, Divider, Form, Input, Modal, Row, Select } from "antd"
import patientApi from "./hook/patient.api";
import { useState } from "react";
const {Option} = Select;
const AddNewPatient = (props) => {
    const { open, handleOk, confirmLoading, handleCancel, stateList, providerList } = props;
    const [cities, setCities] = useState([]);
    const value = true;
    const onState = async (v) => {
        console.log(v);
        const cityList = await patientApi.cityList(v);
        console.log(cityList);
        setCities(cityList);
    }
    return (
        <>
            <Modal
                title="Add New Patient"
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                width={1000}
                footer={null}
                className="patientModalWrapper"
            >
                <Form layout="vertical"
                    initialValues={{}}
                    className="appointmentForm"
                    onFinish={handleOk}
                >

                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="patientLastName" label="Last Name">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="patientFirstName" label="First Name">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="patientMiddleName" label="Middle Name">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="patientDob" label="Date of Birth">
                                <DatePicker format="MM/DD/YYYY" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="patientSex" label="Sex">
                                <Select>
                                    <Select.Option value="Male">Male</Select.Option>
                                    <Select.Option value="Female">Female</Select.Option>
                                    <Select.Option value="Other">Other</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Divider orientation="left">Contact Information</Divider>
                        <Col span={8}>
                            <Form.Item name="patientAddress" label="Address">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="patientAddressline2" label="Address line2">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="patientAddressState" label="State">
                            <Select showSearch
                                    optionFilterProp="children"
                                    onSelect={onState}
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                {stateList?.map((s) => <Option key={s.state_code} value={s.state_code}>{s.state_code}</Option> )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="patientAddressCity" label="City">
                            <Select showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                {cities?.map((s) => <Option key={s.key} value={s.name}>{s.name}</Option> )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="patientAddressZipCode" label="Zip code">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="patientAddressPhoneHome.st" label="Home phone">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={2} style={{ marginTop: '30px' }}>
                            <Form.Item name="patientAddressPhoneHome.ct" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4} style={{ marginTop: '30px' }}>
                            <Form.Item name="patientAddressPhoneHome.number" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="patientAddressPhoneWork.st" label="Work phone">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={2} style={{ marginTop: '30px' }}>
                            <Form.Item name="patientAddressPhoneWork.ct" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4} style={{ marginTop: '30px' }}>
                            <Form.Item name="patientAddressPhoneWork.number" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="patientAddressPhoneCell.st" label="Cell phone">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={2} style={{ marginTop: '30px' }}>
                            <Form.Item name="patientAddressPhoneCell.ct" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4} style={{ marginTop: '30px' }}>
                            <Form.Item name="patientAddressPhoneCell.number" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8} >
                            <Form.Item name="patientAddressmail" label="E-mail">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Divider orientation="left">Insurance Information</Divider>
                        <Col span={6}>
                            <Form.Item name="insuranceCo" label="Insurance Co">
                                <Input suffix={<SearchOutlined />} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="insuranceName" label="Insurance Name">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="insuranceHealthPlanElg" label="Health Plan Eligibilty">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="insuranceEligibiltyAct"
                                label="Health Plan Eligibilty"
                                valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="insurancePrimaryInsured" label="Primary Insured">
                                <Input suffix={<SearchOutlined />} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="insuranceInsuredRelat" label="Insured Relationship">
                                <Select>
                                    <Select.Option selected value="Self"> Self</Select.Option>
                                    <Select.Option value="Spouse"> Spouse</Select.Option>
                                    <Select.Option value="Child"> Child</Select.Option>
                                    <Select.Option value="Other"> Other Relationship</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="insuranceLastName" label="Last name">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="insuranceFirstName" label="First name">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="insuranceMiddleName" label="Middle name">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="insuranceSubscriberID" label="Subscriber ID">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="insuranceGroupNo" label="Group No">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="insurancePlanName" label="Plan name">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="insuranceDeductible" label="Deductible">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="insuranceCopay" label="Co-Pay">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="insuranceAuthorization" label="Authorization #">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="insuranceVisitAuthorized" label="# of Visits Authorized">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="insuranceEffStartDate" label="Effective Start Date">
                                <DatePicker />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="insuranceEffToDate" label="Effective To Date">
                                <DatePicker />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16} justify='end'>
                        <Divider />
                        <Col span={8}>
                            <Button type="primary" htmlType="submit" className="btnPrimary" >Add Patient</Button>
                            <Button type="default" htmlType="submit" className="btnDefault" onClick={() => handleCancel()}>Close</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}

export default AddNewPatient;