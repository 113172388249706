import { DashOutlined, SearchOutlined } from "@ant-design/icons";
import { Row, Col, Form, Typography, Input, Divider, DatePicker, Select, Button } from "antd";
import { useEffect, useState } from "react";
const { Title } = Typography;

const UB04Form = () => {
    const [form] = Form.useForm();
    const [ubTable, setUbTable] = useState([])
    const labelCol4 = {
        span: 4
    }
    const labelCol6 = {
        span: 6
    }
    const labelCol8 = {
        span: 8
    }
    const labelCol12 = {
        span: 12
    }
    const onFinish = (v) => {
        console.log(v);
    }
    const column = {
        HCPCS: '',
        SVCQual: '',
        Srvunit: '',
        desp: '',
        ncMeas: '',
        ndCharge: '',
        ndcCharge: '',
        ndunit: '',
        nonCovidCharge: '',
        revCd: '',
        serviceFrom: '',
        serviceTo: '',
        tatoalCharge: '',
    }

    const getTable = () => {
        let tableArry = [];
        for (var i = 0; i < 22; i++) {
            tableArry.push(column);
        }
        setUbTable(tableArry);
    }

    useEffect(() => {
        getTable();
    }, []);
    return (
        <div className="ub04formWrapper">
            <Row justify="center">
                <Col span={24}>
                    <Title level={5} className="patientViewheader" >UB04 Form - [Create Claim]</Title>
                </Col>
                <Col span={24}>
                    <Form layout="horizontal"
                        initialValues={{}}
                        className="appointmentForm"
                        onFinish={onFinish}
                        form={form} >
                        <Row gutter={16} justify='end'>
                            <Col span={10}>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item name="ubPayerName" labelCol={labelCol6} label="Payer Name">
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name="ubPayerAddress" labelCol={labelCol8} label="Address/ PayerID">
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Row gutter={2}>
                                            <Col span={12}>
                                                <Form.Item name="ubPayerCity" labelCol={labelCol12} label="City, State, Zip">
                                                    <Input size="small" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={4}>
                                                <Form.Item name="ubPayerState" >
                                                    <Input size="small" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name="ubPayerZip" >
                                                    <Input size="small" />
                                                </Form.Item>
                                            </Col>

                                        </Row>
                                    </Col>

                                </Row>
                            </Col>
                            <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />

                        </Row>
                        <Row gutter={4}>
                            <Col span={8} className="dashRight">
                                <Row>
                                    <Col span={20}>
                                        <span className="ubformtext">
                                            1. Billing Group
                                        </span>
                                        <Form.Item name="billGroup">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2} style={{ textAlign: 'center' }}>
                                        <DashOutlined className="searchdotbtn" />
                                    </Col>
                                </Row>
                                <Row gutter={2}>
                                    <Col span={12}>
                                        <span className="ubformtext">
                                            Billing Provide (Last, First, MI)
                                        </span>
                                        <Form.Item name="billProvideLast">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className="mT22">
                                        <Form.Item name="billProvideFirst">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4} className="mT22">
                                        <Form.Item name="billProvideMI">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <span className="ubformtext">
                                            Address
                                        </span>
                                        <Form.Item name="billGroupAddress">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={2}>
                                    <Col span={12}>
                                        <span className="ubformtext">
                                            City, State, Zip
                                        </span>
                                        <Form.Item name="billGroupCity">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} className="mT22">
                                        <Form.Item name="billGroupState">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} className="mT22">
                                        <Form.Item name="billGroupZip">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={20}>
                                        <span className="ubformtext">
                                            Phone Number
                                        </span>
                                        <Form.Item name="billGroupPhone">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={20}>
                                        <span className="ubformtext">
                                            Billing Prov.PIN#
                                        </span>
                                        <Form.Item name="billGroupProviderPIN">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={8} className="dashRight">
                                <Row>
                                    <Col span={20}>
                                        <span className="ubformtext">
                                            2. Pay-To-Provider
                                        </span>
                                        <Form.Item name="PaytoPrvd">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2} style={{ textAlign: 'center' }}>
                                        <DashOutlined className="searchdotbtn" />
                                    </Col>
                                </Row>
                                <Row gutter={2}>
                                    <Col span={12}>
                                        <span className="ubformtext">
                                            Pay-To Provide (Last, First, MI)
                                        </span>
                                        <Form.Item name="PaytoPrvdLast">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className="mT22">
                                        <Form.Item name="PaytoPrvdFirst">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4} className="mT22">
                                        <Form.Item name="PaytoPrvdMI">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <span className="ubformtext">
                                            Address
                                        </span>
                                        <Form.Item name="PaytoPrvdAddress">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={2}>
                                    <Col span={12}>
                                        <span className="ubformtext">
                                            City, State, Zip
                                        </span>
                                        <Form.Item name="PaytoPrvdCity">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} className="mT22">
                                        <Form.Item name="PaytoPrvdState">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} className="mT22">
                                        <Form.Item name="PaytoPrvdZip">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={20}>
                                        <span className="ubformtext">
                                            Phone Number
                                        </span>
                                        <Form.Item name="PaytoPrvdPhone">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={20}>
                                        <span className="ubformtext">
                                            Pay-To-TaxIDN#
                                        </span>
                                        <Form.Item name="PaytoPrvdTaxId">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={8}>
                                <Row gutter={4} >
                                    <Col span={12} className="dashRight">
                                        <span className="ubformtext">
                                            3a. Pat.Cntl #
                                        </span>
                                        <Form.Item name="patCntl">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <span className="ubformtext">
                                            4. Type of bill
                                        </span>
                                        <Form.Item name="typeofBill">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />

                                </Row>
                                <Row>
                                    <Col span={12} className="dashRight">
                                        <span className="ubformtext" >
                                            b. Med.Rec.#
                                        </span>
                                        <Form.Item name="medRec">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <span className="ubformtext">
                                            5. Fed. Tax No.
                                        </span>
                                        <Form.Item name="FebTaxNo">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />

                                </Row>
                                <Row>
                                    <Col span={20} className="dashRight">
                                        <span className="ubformtext" >
                                            6. Statement Covers Period
                                        </span>
                                    </Col>
                                    <Col span={20} className="dashRight">
                                        <Row gutter={2}>
                                            <Col span={12} className="ubformtext" >
                                                <span>
                                                    From
                                                </span>
                                                <Form.Item name="statCoverPridFrm">
                                                    <DatePicker format='YYYy-MM-DD' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12} className="ubformtext" >
                                                <span>
                                                    Through
                                                </span>
                                                <Form.Item name="statCoverPridTo">
                                                    <DatePicker format='YYYy-MM-DD' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={4}>
                                        <span className="ubformtext" >
                                            7
                                        </span>
                                    </Col>
                                </Row>
                            </Col>
                            <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />

                        </Row>
                        <Row gutter={8}>
                            <Col span={8} className="dashRight">
                                <Row>
                                    <Col span={20}>
                                        <span className="ubformtext">
                                            8a. Patient Name
                                        </span>
                                        <Form.Item name="billGroup">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2} style={{ textAlign: 'center' }}>
                                        <DashOutlined className="searchdotbtn" />
                                    </Col>
                                </Row>
                                <Row gutter={2}>
                                    <Col span={12}>
                                        <span className="ubformtext">
                                            Patient Name (Last, First, MI)
                                        </span>
                                        <Form.Item name="patientLast">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className="mT22">
                                        <Form.Item name="billProvideFirst">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4} className="mT22">
                                        <Form.Item name="billProvideMI">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={16}>
                                <Row>
                                    <Col span={4}>
                                        <span className="ubformtext">
                                            9. Patient Address
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <span className="ubformtext">
                                            a. Street
                                        </span>
                                        <Form.Item name="patientAddress">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={6}>
                                        <span className="ubformtext">
                                            b. City
                                        </span>
                                        <Form.Item name="patientCity">
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <span className="ubformtext">
                                            c. State
                                        </span>
                                        <Form.Item name="patientState">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <span className="ubformtext">
                                            d. Zip
                                        </span>
                                        <Form.Item name="patientZip">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <span className="ubformtext">
                                            e. Country
                                        </span>
                                        <Form.Item name="patientCountry">
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                </Row>

                            </Col>
                            <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />

                        </Row>
                        <Row gutter={2}>
                            <Row gutter={2}>
                                <Col span={2}>
                                    <span className="ubformtext">
                                        10. Birthday
                                    </span>
                                    <Form.Item name="patientDoB">
                                        <DatePicker size="small" format="MM/DD/YYYY" />
                                    </Form.Item>
                                </Col>
                                <Col span={2} className="dashRight">
                                    <span className="ubformtext">
                                        11. Sex
                                    </span>
                                    <Form.Item name="patientSex">
                                        <Select size="small">
                                            <Select.Option value="Male">Male</Select.Option>
                                            <Select.Option value="Female">Female</Select.Option>
                                            <Select.Option value="Other">Other</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6} className="dashRight">
                                    <span className="ubformtext txtCenter" >
                                        Admission
                                    </span>
                                    <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />

                                    <Row gutter={2}>
                                        <Col span={11}>
                                            <span className="ubformtext">
                                                12. Date
                                            </span>
                                            <Form.Item name="patientAdmisDate">
                                                <DatePicker size="small" format="MM/DD/YYYY" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <span className="ubformtext">
                                                13. HR
                                            </span>
                                            <Form.Item name="patientHR">
                                                <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>
                                            <span className="ubformtext">
                                                14. Type
                                            </span>
                                            <Form.Item name="patientType">
                                                <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <span className="ubformtext">
                                                15. Src
                                            </span>
                                            <Form.Item name="patientSrc">
                                                <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={1}>
                                    <span className="ubformtext">
                                        16. DHR
                                    </span>
                                    <Form.Item name="patientDHR">
                                        <Input size="small" />
                                    </Form.Item>
                                </Col>
                                <Col span={1} className="dashRight">
                                    <span className="ubformtext">
                                        17. Sat
                                    </span>
                                    <Form.Item name="patientSat">
                                        <Input size="small" />
                                    </Form.Item>
                                </Col>
                                <Col span={9} className="dashRight">
                                    <span className="ubformtext txtCenter">
                                        Condition Codes
                                    </span>
                                    <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />

                                    <Row gutter={2}>
                                        <div className="displayMinInput">
                                            <div className="miniInput">
                                                <span className="ubformtext">
                                                    18
                                                </span>
                                                <Form.Item name="patient18">
                                                    <Input size="small" />
                                                </Form.Item>
                                            </div>
                                            <div className="miniInput">
                                                <span className="ubformtext">
                                                    19
                                                </span>
                                                <Form.Item name="patient19">
                                                    <Input size="small" />
                                                </Form.Item>
                                            </div>
                                            <div className="miniInput">
                                                <span className="ubformtext">
                                                    20
                                                </span>
                                                <Form.Item name="patient20">
                                                    <Input size="small" />
                                                </Form.Item>
                                            </div>
                                            <div className="miniInput">
                                                <span className="ubformtext">
                                                    21
                                                </span>
                                                <Form.Item name="patient21">
                                                    <Input size="small" />
                                                </Form.Item>
                                            </div>
                                            <div className="miniInput">
                                                <span className="ubformtext">
                                                    22
                                                </span>
                                                <Form.Item name="patient22">
                                                    <Input size="small" />
                                                </Form.Item>
                                            </div>
                                            <div className="miniInput">
                                                <span className="ubformtext">
                                                    23
                                                </span>
                                                <Form.Item name="patient23">
                                                    <Input size="small" />
                                                </Form.Item>
                                            </div>
                                            <div className="miniInput">
                                                <span className="ubformtext">
                                                    24
                                                </span>
                                                <Form.Item name="patient24">
                                                    <Input size="small" />
                                                </Form.Item>
                                            </div>
                                            <div className="miniInput">
                                                <span className="ubformtext">
                                                    25
                                                </span>
                                                <Form.Item name="patient25">
                                                    <Input size="small" />
                                                </Form.Item>
                                            </div>
                                            <div className="miniInput">
                                                <span className="ubformtext">
                                                    26
                                                </span>
                                                <Form.Item name="patient26">
                                                    <Input size="small" />
                                                </Form.Item>
                                            </div>
                                            <div className="miniInput">
                                                <span className="ubformtext">
                                                    27
                                                </span>
                                                <Form.Item name="patient27">
                                                    <Input size="small" />
                                                </Form.Item>
                                            </div>
                                            <div className="miniInput">
                                                <span className="ubformtext">
                                                    28
                                                </span>
                                                <Form.Item name="patient28">
                                                    <Input size="small" />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </Row>
                                </Col>
                                <Col span={2} className="dashRight">
                                    <span className="ubformtext">
                                        29. Accident State
                                    </span>
                                    <Form.Item name="patientAccdientSt">
                                        {/* <DatePicker className="xs" size="small" format="MM/DD/YYYY" /> */}
                                        <Input size="small" />
                                    </Form.Item>
                                </Col>
                                <Col span={1}>
                                    <span className="ubformtext">
                                        30.
                                    </span>
                                </Col>
                                <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />

                            </Row>

                        </Row>
                        <Row gutter={4}>
                            <Col span={3} className="dashRight">
                                <span className="ubformtext">
                                    31. Occurence
                                </span>
                                <Row gutter={2}>
                                    <Col span={8} className="dashRight">
                                        <span className="ubformtext">
                                            Code
                                        </span>
                                        <Form.Item name="patient31OccCode">
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={16}>
                                        <span className="ubformtext">
                                            Date
                                        </span>
                                        <Form.Item name="patient31OccDate">
                                            <DatePicker className="xs" size="small" format="MM/DD/YYYY" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={3} className="dashRight">
                                <span className="ubformtext">
                                    32. Occurence
                                </span>
                                <Row gutter={2}>
                                    <Col span={8} className="dashRight">
                                        <span className="ubformtext">
                                            Code
                                        </span>
                                        <Form.Item name="patient32OccCode">
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={16}>
                                        <span className="ubformtext">
                                            Date
                                        </span>
                                        <Form.Item name="patient32OccDate">
                                            <DatePicker className="xs" size="small" format="MM/DD/YYYY" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={3} className="dashRight">
                                <span className="ubformtext">
                                    33. Occurence
                                </span>
                                <Row gutter={2}>
                                    <Col span={8} className="dashRight">
                                        <span className="ubformtext">
                                            Code
                                        </span>
                                        <Form.Item name="patient33OccCode">
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={16}>
                                        <span className="ubformtext">
                                            Date
                                        </span>
                                        <Form.Item name="patient33OccDate">
                                            <DatePicker className="xs" size="small" format="MM/DD/YYYY" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={3} className="dashRight">
                                <span className="ubformtext">
                                    34. Occurence
                                </span>
                                <Row gutter={2}>
                                    <Col span={8} className="dashRight">
                                        <span className="ubformtext">
                                            Code
                                        </span>
                                        <Form.Item name="patient34OccCode">
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={16}>
                                        <span className="ubformtext">
                                            Date
                                        </span>
                                        <Form.Item name="patient34OccDate">
                                            <DatePicker className="xs" size="small" format="MM/DD/YYYY" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={6} className="dashRight">
                                <span className="ubformtext">
                                    35. Occurence
                                </span>
                                <Row gutter={2}>
                                    <Col span={4} className="dashRight">
                                        <span className="ubformtext">
                                            Code
                                        </span>
                                        <Form.Item name="patient35OccCode">
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <span className="ubformtext">
                                            From
                                        </span>
                                        <Form.Item name="patient35OccDateFrom">
                                            <DatePicker className="xs" size="small" format="MM/DD/YYYY" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <span className="ubformtext">
                                            Through
                                        </span>
                                        <Form.Item name="patient35OccDateFrom">
                                            <DatePicker className="xs" size="small" format="MM/DD/YYYY" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                            </Col>
                            <Col span={6}>
                                <span className="ubformtext">
                                    36. Occurence
                                </span>
                                <Row gutter={2}>
                                    <Col span={4} className="dashRight">
                                        <span className="ubformtext">
                                            Code
                                        </span>
                                        <Form.Item name="patient36OccCode">
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <span className="ubformtext">
                                            From
                                        </span>
                                        <Form.Item name="patient36OccDateFrom">
                                            <DatePicker className="xs" size="small" format="MM/DD/YYYY" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <span className="ubformtext">
                                            Through
                                        </span>
                                        <Form.Item name="patient36OccDateFrom">
                                            <DatePicker className="xs" size="small" format="MM/DD/YYYY" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                            </Col>
                            <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />
                        </Row>
                        <Row gutter={4}>
                            <Col span={2} className="dashRight">
                                <span className="ubformtext">
                                    37. Reason For Delay
                                </span>
                                <Form.Item name="reasonForDelay">
                                    <Input size="small" />
                                </Form.Item>
                            </Col>
                            <Col span={6} className="dashRight">
                                <span className="ubformtext">
                                    38. Subscriber Information
                                </span>
                                <Row gutter={4}>
                                    <Col span={12}>
                                        <span className="ubformtext">
                                            Last, First, MI
                                        </span>
                                        <Form.Item name="subscribeLast">
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className="mT22">
                                        <Form.Item name="subscribeFirst">
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4} className="mT22">
                                        <Form.Item name="subscribeMI">
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={2} style={{ marginTop: '-6px' }}>
                                    <Col span={24}>
                                        <span className="ubformtext">
                                            Address
                                        </span>
                                        <Form.Item name="subscribeAddress">
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <span className="ubformtext" style={{ marginTop: '-6px' }}>
                                    City, State, Zip
                                </span>

                                <Row gutter={2}>
                                    <Col span={12}>
                                        <Form.Item name="subscribeCity"  >
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item name="subscribeState" >
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="subscribeZip" >
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>

                                </Row>
                            </Col>
                            <Col span={1} className="dashRight">
                                <div className="ubformtext">a</div>
                                <div className="ubformtext">b</div>
                                <div className="ubformtext">c</div>
                                <div className="ubformtext">d</div>
                            </Col>
                            <Col span={5} className="dashRight">

                                <span className="ubformtext txtCenter">39. Value Codes</span>
                                <Row gutter={2}>
                                    <Col span={12} className="dashRight">
                                        <span className="ubformtext">Code</span>
                                        <Form.Item name="valueCode1">
                                            <Input size="small" />
                                        </Form.Item>
                                        <Form.Item name="valueCode2">
                                            <Input size="small" />
                                        </Form.Item>
                                        <Form.Item name="valueCode3">
                                            <Input size="small" />
                                        </Form.Item>
                                        <Form.Item name="valueCode4">
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <span className="ubformtext">Date</span>
                                        <Form.Item name="valueDate1">
                                            <DatePicker className="xs" size="small" format="MM/DD/YYYY" />
                                        </Form.Item>
                                        <Form.Item name="valueDate2">
                                            <DatePicker className="xs" size="small" format="MM/DD/YYYY" />
                                        </Form.Item>
                                        <Form.Item name="valueDate3">
                                            <DatePicker className="xs" size="small" format="MM/DD/YYYY" />
                                        </Form.Item>
                                        <Form.Item name="valueDate4">
                                            <DatePicker className="xs" size="small" format="MM/DD/YYYY" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                            </Col>
                            <Col span={5} className="dashRight">

                                <span className="ubformtext txtCenter">40. Value Codes</span>
                                <Row gutter={2}>
                                    <Col span={12} className="dashRight">
                                        <span className="ubformtext">Code</span>
                                        <Form.Item name="valueCode21">
                                            <Input size="small" />
                                        </Form.Item>
                                        <Form.Item name="valueCode22">
                                            <Input size="small" />
                                        </Form.Item>
                                        <Form.Item name="valueCode23">
                                            <Input size="small" />
                                        </Form.Item>
                                        <Form.Item name="valueCode24">
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <span className="ubformtext">Date</span>
                                        <Form.Item name="valueDate21">
                                            <DatePicker className="xs" size="small" format="MM/DD/YYYY" />
                                        </Form.Item>
                                        <Form.Item name="valueDate22">
                                            <DatePicker className="xs" size="small" format="MM/DD/YYYY" />
                                        </Form.Item>
                                        <Form.Item name="valueDate23">
                                            <DatePicker className="xs" size="small" format="MM/DD/YYYY" />
                                        </Form.Item>
                                        <Form.Item name="valueDate24">
                                            <DatePicker className="xs" size="small" format="MM/DD/YYYY" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                            </Col>
                            <Col span={5} >

                                <span className="ubformtext txtCenter">41. Value Codes</span>
                                <Row gutter={2}>
                                    <Col span={12} className="dashRight">
                                        <span className="ubformtext">Code</span>
                                        <Form.Item name="valueCode31">
                                            <Input size="small" />
                                        </Form.Item>
                                        <Form.Item name="valueCode32">
                                            <Input size="small" />
                                        </Form.Item>
                                        <Form.Item name="valueCode33">
                                            <Input size="small" />
                                        </Form.Item>
                                        <Form.Item name="valueCode34">
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <span className="ubformtext">Date</span>
                                        <Form.Item name="valueDate31">
                                            <DatePicker className="xs" size="small" format="MM/DD/YYYY" />
                                        </Form.Item>
                                        <Form.Item name="valueDate32">
                                            <DatePicker className="xs" size="small" format="MM/DD/YYYY" />
                                        </Form.Item>
                                        <Form.Item name="valueDate33">
                                            <DatePicker className="xs" size="small" format="MM/DD/YYYY" />
                                        </Form.Item>
                                        <Form.Item name="valueDate34">
                                            <DatePicker className="xs" size="small" format="MM/DD/YYYY" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                            </Col>
                            <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />

                        </Row>
                        <Row className="ubTableHeader">
                            <Col span={1}> </Col>
                            <Col span={2}>
                                <span className="ubformtext">
                                    42. Rev.Cd.
                                </span>
                            </Col>

                            <Col span={3}>
                                <span className="ubformtext">
                                    43. Description
                                </span>
                            </Col>
                            <Col span={2}>
                                <span className="ubformtext">
                                    SVCQual
                                </span>
                            </Col>
                            <Col span={2}>
                                <span className="ubformtext">
                                    44. HCPCS/Rate/ HCPCS Code
                                </span>
                            </Col>
                            <Col span={4}>
                                <span className="ubformtext">
                                    45. Service Date
                                </span>
                                <Row>
                                    <Col span={12}>
                                        <span className="ubformtext">
                                            From
                                        </span>
                                    </Col>
                                    <Col span={12}>
                                        <span className="ubformtext">
                                            To
                                        </span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={1}>
                                <span className="ubformtext">
                                    46. Srv.Units
                                </span>
                            </Col>
                            <Col span={2}>
                                <span className="ubformtext">
                                    47. Total Charge
                                </span>
                            </Col>
                            <Col span={2}>
                                <span className="ubformtext">
                                    48. Non-Covid Charge
                                </span>
                            </Col>
                            <Col span={5}>
                                <span className="ubformtext">
                                    49. NDCCode/Charge/Unit/Meas
                                </span>
                            </Col>
                            <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />
                        </Row>
                        {ubTable?.map((d, i) => {
                            return (
                                <Row gutter={4}>

                                    <Col span={1}>
                                        {i + 1}
                                    </Col>
                                    <Col span={2}>
                                        <Form.Item name={['table', i, 'revCd']}>
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={3}>
                                        <Form.Item name={['table', i, 'desp']}>
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <Form.Item name={['table', i, 'SVCQual']}>
                                            <Select size="small" >
                                                <Select.Option value="SVCQual">SVCQual</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <Form.Item name={['table', i, 'HCPCS']}>
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Row>
                                            <Col span={12}>
                                                <Form.Item name={['table', i, 'serviceFrom']}>
                                                    <DatePicker size="small" format='YYYY-MM-DD' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name={['table', i, 'serviceTo']}>
                                                    <DatePicker size="small" format='YYYY-MM-DD' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={1}>
                                        <Form.Item name={['table', i, 'Srvunit']}>
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <Form.Item name={['table', i, 'tatoalCharge']}>
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <Form.Item name={['table', i, 'nonCovidCharge']}>
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <Form.Item name={['table', i, 'ndcCharge']}>
                                            <Input prefix={<SearchOutlined />} size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}>
                                        <Form.Item name={['table', i, 'ndCharge']}>
                                            <Input size="small" />
                                        </Form.Item>

                                    </Col>
                                    <Col span={1}>
                                        <Form.Item name={['table', i, 'ndunit']}>
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}>
                                        <Form.Item name={['table', i, 'ncMeas']}>
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            );
                        })}
                        <Row>
                            <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />
                            <Col span={6} className="dashRight ubformtext">
                                <div className="mediContainer">
                                    <span>
                                        50. Payer
                                    </span>
                                </div>
                            </Col>
                            <Col span={2} className="dashRight ubformtext">
                                <div className="mediContainer">
                                    <span>
                                        Payer ID
                                    </span>
                                </div>
                            </Col>
                            <Col span={3} className="dashRight ubformtext">
                                <div className="mediContainer">
                                    <span>
                                        51. Health Plan
                                    </span>
                                </div>
                            </Col>
                            <Col span={1} className="dashRight ubformtext">
                                <div className="mediContainer">
                                    <span>
                                        52. Rel Info
                                    </span>
                                </div>
                            </Col>
                            <Col span={1} className="dashRight ubformtext">
                                <div className="mediContainer">
                                    <span>
                                        53. ASG Ben
                                    </span>
                                </div>
                            </Col>
                            <Col span={3} className="dashRight ubformtext">
                                <div className="mediContainer">
                                    <span>
                                        54. Prior Payments
                                    </span>
                                </div>
                            </Col>
                            <Col span={3} className="dashRight ubformtext">
                                <div className="mediContainer">
                                    <span>
                                        55. Est. Amount Due
                                    </span>
                                </div>
                            </Col>
                            <Col span={3} className=" ubformtext">
                                <div className="mediContainer">
                                    <span>
                                        56. NPI(Billing Prov.)
                                    </span>
                                </div>
                                {/* <Col span={3} className="dashRight ubformtext"> */}
                                    <Form.Item name={['payer', 'NPIBilling']}>
                                        <Input size="small" />
                                    </Form.Item>
                                {/* </Col> */}
                            </Col>
                            <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />
                        </Row>
                        <Row>
                            <Col span={6} className="dashRight ubformtext">
                                <Row gutter={2}>
                                    <Col span={10}>
                                        <Form.Item name={['payer', 'insuranceFirstname']}>
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item name={['payer', 'insurancemiddlename']}>
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item name={['payer', 'insuranceLastname']}>
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>

                                </Row>

                            </Col>
                            <Col span={2} className="dashRight ubformtext">
                                <Form.Item name={['payer', 'payIda']}>
                                    <Input size="small" />
                                </Form.Item>
                            </Col>
                            <Col span={3} className="dashRight ubformtext">
                                <Form.Item name={['payer', 'healthPlana']}>
                                    <Input size="small" />
                                </Form.Item>
                            </Col>
                            <Col span={1} className="dashRight ubformtext">
                                <Form.Item name={['payer', 'relInfoa']}>
                                    <Input size="small" />
                                </Form.Item>
                            </Col>
                            <Col span={1} className="dashRight ubformtext">
                                <Form.Item name={['payer', 'asgBena']}>
                                    <Input size="small" />
                                </Form.Item>
                            </Col>
                            <Col span={3} className="dashRight ubformtext">
                                <Form.Item name={['payer', 'priorPaymenta']}>
                                    <Input size="small" />
                                </Form.Item>
                            </Col>
                            <Col span={3} className="dashRight ubformtext">
                                <Form.Item name={['payer', 'estAmountDue']}>
                                    <Input size="small" />
                                </Form.Item>
                            </Col>
                            <Col span={3} className=" ubformtext">
                                <div className="mediContainer">
                                    <span>
                                        56. NPI(Billing Prov.)
                                    </span>
                                </div>
                                <Form.Item name={['payer', 'npiBilling']}>
                                    <Input size="small" />
                                </Form.Item>
                            </Col>
                            <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />
                        </Row>
                        <Row gutter={8} >
                            <Col span={12} className="mt30">
                                <Button type="primary" className="primaryBtn" htmlType="submit">
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form >
                </Col >
            </Row >
        </div >
    )
};

export default UB04Form;