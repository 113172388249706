import axios from "axios";
import { API } from "../../config";

const getReferringProvider = async () => {
  const response = await axios.get(API+'/referring');
  return response.data;
};

const getReferringProviderId = async (id) => {
  const payload = {
    params: {
      id
    }
  }
  console.log(payload);
  const response = await axios.get(API+'/referring/id', payload);
  return response.data;
};

const createReferringProvider = async (payload) => {
  console.log(payload);
  const response = await axios.post(API+'/referring', payload);
  return response.data;
};

const updateReferringProvider = async (payload) => {
  console.log(payload);
  const response = await axios.put(API+'/referring/update', payload);
  return response.data;
}


const ReferringProviderAPI = {
    getReferringProvider,
    createReferringProvider,
    updateReferringProvider,
    getReferringProviderId,
  }
  
  export default ReferringProviderAPI;