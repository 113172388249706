import axios from "axios";
import { API } from "../../../config";

const getClaim = async () => {
  const response = await axios.get(API+'/claim');
  return response.data;
};

const createClaim = async (payload) => {
  // console.log(payload);
  const response = await axios.post(API+'/claim', payload);
  return response.data;
};

const getClaimbyId = async (id) => {
  const payload = {
    params: {
      id
    }
  }
  try {
    const resp = await axios.get(API+'/claim/id', payload);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
}


const getpatientvisitforclaim = async (id) => {
  const payload = {
    params: {
      id
    }
  }
  try {
    const resp = await axios.get(API+'/claim/merge/id', payload);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
}

const claimAPI = {
  getClaim,
  createClaim,
  getpatientvisitforclaim,
  getClaimbyId
}

export default claimAPI;