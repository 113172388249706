import { Row, Col, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";

const PatientProfile = () => {
    return (
        <div className="patientPrpfile-wapper">
            <div className="patientBase">
                <Row gutter={16}>
                    <Col span={3}>
                        <Avatar shape="square" size={64} icon={<UserOutlined />} />
                    </Col>
                </Row>
            </div>
        </div>
    )

}

export default PatientProfile;