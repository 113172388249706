import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Table } from "antd";
import { useEffect, useState } from "react";


const InsuranceListModal = ({
    ispayerModal,
    handlepayerCancel,
    payerList,
    modalTitle,
    onPullpayer,
    pullMorePayer }) => {
    const [filterpatnt, setFilterpatnt] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const rowSelection = (r) => {
        console.log(r);
        onPullpayer(r);
    }

    const searchMore = (key, val) => {
        let Obj = {
            [key]: val,
        }
        setInputValue(Obj)
    }

    const searchPatient = (e, type) => {
        console.log(e.target.value, type);
        const filterdata = payerList?.filter((d) => d[type].toLowerCase().includes(e.target.value));
        console.log(filterdata);
        setFilterpatnt(filterdata);
        searchMore(type, e.target.value)
    }

    const columns = [
        {
            title: 'Payer ID',
            dataIndex: 'PayerID',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Name',
            dataIndex: 'PayerName',
        },
        {
            title: 'State',
            dataIndex: 'State',
        },
        {
            title: 'Reports',
            dataIndex: 'Reports',
        },
        {
            title: 'Reports',
            dataIndex: 'Reports',
        },
        {
            title: 'Secondary',
            dataIndex: 'Secondary',
        },
        {
            title: 'Enrollment',
            dataIndex: 'Enrollment',
        },
        {
            title: 'PayerType',
            dataIndex: 'PayerType',
        }
    ];

    useEffect(() => { 
        setFilterpatnt(filterpatnt);
    }, []);


    return (
        <Modal  title={modalTitle} open={ispayerModal} width={950} onOk={handlepayerCancel} onCancel={handlepayerCancel}>
            <div style={{ marginTop: '30px', display: 'inline-block', marginBottom: '20px', width: '200px' }}>
                <Input placeholder='Last name' suffix={<SearchOutlined />} name="PayerName" onChange={(e) => searchPatient(e, 'PayerName')} />
            </div>
            <div style={{ display: 'inline-block', margin: '30px 0px 20px 10px', width: '200px' }}>
                <Input placeholder='First name' suffix={<SearchOutlined />} name="PayerID" onChange={(e) => searchPatient(e, 'PayerID')} />
            </div>
            {/* <div style={{ display: 'inline-block', margin: '30px 0px 20px 10px', width: '200px' }}>
                <Input placeholder='Patient ID' suffix={<SearchOutlined />} name="patientId" onChange={(e) => searchPatient(e, 'patientId')} />
            </div> */}
            <div style={{ display: 'inline-block', margin: '30px 0px 20px 10px', width: '200px' }}>
                <Button type="primary" onClick={() => pullMorePayer(inputValue)} > Search more </Button>
            </div>

            <Table
                rowKey={(record) =>record._id}
                onRow={(record) => ({
                    onClick : () => rowSelection(record),
                })}
                columns={columns}
                dataSource={filterpatnt.length > 0 ? filterpatnt : payerList}
                pagination={{
                    pageSize: 8
                }}
            />

        </Modal>
    )

}

export default InsuranceListModal;