import axios from "axios";
import { API } from "../../config";

const getTaxonomycode = async () => {
  const response = await axios.get(API+'/taxonomycode');
  return response.data;
};

const getTaxonomycodeId = async (id) => {
  const payload = {
    params: {
      id
    }
  }
  console.log(payload);
  const response = await axios.get(API+'/taxonomycode/id', payload);
  return response.data;
};

const createTaxonomycode = async (payload) => {
  // console.log(payload);
  const response = await axios.post(API+'/taxonomycode', payload);
  return response.data;
};

const updateTaxonomycode = async (payload) => {
  console.log(payload);
  const response = await axios.put(API+'/taxonomycode/update', payload);
  return response.data;
}


const TaxonomycodeAPI = {
    getTaxonomycode,
    createTaxonomycode,
    updateTaxonomycode,
    getTaxonomycodeId,
  }
  
  export default TaxonomycodeAPI;