import { Button, Col, Divider, Form, Input, Row, Select, Typography } from "antd"
import './style.scss'
import moment from "moment";
import BillingProviderAPI from "./billingprovider.api";
import patientApi from "../patient/hook/patient.api";
import { useEffect, useState } from "react";
import { headerName } from "../../layout/headerName.hook";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import InsuranceAPI from "../insurance/insurance.api";
const { Title } = Typography;

const NewBillingProvider = () => {
    const [form] = Form.useForm();
    const [cities, setCities] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [billProviderdocid, setBillProviderdocid] = useState();
    const [billingProviderData, setBillingProviderData] = useState();
    const [searchParams] = useSearchParams();
    const [mode, setMode] = useState(false);

    const dispatch = useDispatch();
    dispatch(headerName(`Billing Provider`));

    const { Option } = Select;

    const onFinish = async (v) => {
        console.log(v);
        const payload = {
            ...v,
            billingProviderId: new Date().getTime(),
            createBy: 'admin',
            createTime: new Date().getTime(),
            createDate: moment().format('MM/DD/YYYY'),
            facilityId: '',
        }
        console.log(payload);
        const resp = await BillingProviderAPI.createBillingProvider(payload);
        console.log(resp);
    }

    const onState = async (v) => {
        const cityList = await patientApi.cityList(v);
        setCities(cityList);
    }

    useEffect(() => {
        const getStateList = async () => {
            const stateLists = await patientApi.stateList();
            setStateList(stateLists);
        }
        getStateList();
    }, []);

    useEffect(() => {
        const pId = searchParams.get('id');
        const pmode = searchParams.get('mode');
        setBillProviderdocid(pId)
        setMode(pmode === 'edit');
        if (pId) {
            const getbProvider = async (id) => {
                const resp = await BillingProviderAPI.getBillingProviderId(id);
                console.log(resp);
                setBillingProviderData(resp[0]);
                form.setFieldsValue(resp[0]);
            }
            getbProvider(pId)
        }

    }, [form, searchParams]);

    return (
        <>
            <div className="providerFormWrapper">
            <Row>
                <Col span={24}>
                        <Title level={4} >
                            Billing Provider</Title>
                            <Divider/>
                    </Col>
                </Row>
                <Form layout="vertical"
                    initialValues={{
                        ...billingProviderData
                    }}
                    className="providerForm"
                    onFinish={onFinish}
                    form={form} >
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="billingProvideLastname" label="Billing Provider Last Name" rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a last name!',
                                        },
                                    ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="billingProvidefirstname" label="Billing Provider First Name" rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a first name!',
                                        },
                                    ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="billingProvideMiddlename" label="Billing Provider MI Name">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="billingProvideClaimName" label="Print on Claim name" rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a name!',
                                        },
                                    ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="billingProvideAddress" label="Address" rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a Address!',
                                        },
                                    ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="billingProvideAdress2" label="Addres line2">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="billingProvideState" label="state" rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a state!',
                                        },
                                    ]}>
                                <Select showSearch size="small"
                                    optionFilterProp="children"
                                    onSelect={onState}
                                    filterOption={(input, option) =>
                                        option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                    {stateList?.map((s) => <Option key={s.id} value={s.state_code}>{s.state_code}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="billingProvideCity" label="City"rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a city!',
                                        },
                                    ]}>
                                <Select showSearch size="small"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                    {cities?.map((s) => <Option key={s.id} value={s.name}>{s.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="billingProvideZip" label="Zip Code" rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a zipcode!',
                                        },
                                    ]}>
                                <Input size="small" />        
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="billingProvidePhonenumber" label="Phone" rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a phone!',
                                        },
                                    ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        {/* <Col span={2} style={{ marginTop: '30px' }}>
                            <Form.Item name="billingProvidePhonestate">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={4} style={{ marginTop: '30px' }}>
                            <Form.Item name="billingProvidePhonenumber" >
                                <Input size="small" />
                            </Form.Item>
                        </Col> */}

                        <Col span={6}>
                            <Form.Item name="billingProvideFaxnumber" label="Fax">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        {/* <Col span={2} style={{ marginTop: '30px' }}>
                            <Form.Item name="billingProvideFaxstate">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={4} style={{ marginTop: '30px' }}>
                            <Form.Item name="billingProvideFaxnumber" >
                                <Input size="small" />
                            </Form.Item>
                        </Col> */}
                        <Col span={5}>
                            <Form.Item name="billingProvideTaxId" label="Tax ID" rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a  tax Id!',
                                        },
                                    ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="billingProvideTaxIdtype" label="Tax ID Type">
                                <Select size="small" >
                                    <Select.Option value="EIN">EIN</Select.Option>
                                    <Select.Option value="SSN">SSN</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="billingProvideGroupNo" label="Group no">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="billingProvideOrganizationalNPI" label="Organizational NPI"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a organizational NPI!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="billingProvideSpecialty" label="Specialty">
                                <Select size="small" >
                                    <Select.Option value="EIN">EIN</Select.Option>
                                    <Select.Option value="SSN">SSN</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={6} justify="end" style={{ marginTop: '30px' }}>
                        <Col span={4}>
                            <Button htmlType="submit" className="btnPrimary">Submit</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}

export default NewBillingProvider;