import { Checkbox, Col, DatePicker, Divider, Form, Input, Row, Select, Typography } from "antd";
import './../style.scss'
import { useEffect, useState } from "react";
import patientApi from "../../patient/hook/patient.api";
import { useDispatch } from "react-redux";
import { headerName } from "../../../layout/headerName.hook";

const { Title } = Typography
const { Option } = Select;
const { RangePicker } = DatePicker;

const PatientData = () => {

    const [stateList, setStateList] = useState();
    const [cities, setCities] = useState([]);
    const [empCities, setEmpCities] = useState([]);
    const [emrgCities, setEmrgCities] = useState([]);
    const [kinCities, setKinCities] = useState([]);
    const [patientExmptcheck, setPatientExmptcheck] = useState();
    const [patientHealthcheck, setPatientHealthcheck] = useState();

    const onState = async (v) => {
        const cityList = await patientApi.cityList(v);
        setCities(cityList);
    }
    const onempState = async (v) => {
        const cityList = await patientApi.cityList(v);
        setEmpCities(cityList);
    }
    const onemrgState = async (v) => {
        const cityList = await patientApi.cityList(v);
        setEmrgCities(cityList);
    }
    const onkinState = async (v) => {
        const cityList = await patientApi.cityList(v);
        setKinCities(cityList);
    }
    
    useEffect(() => {
        const getStateList = async () => {
            const stateLists = await patientApi.stateList();
            setStateList(stateLists);
        }
        getStateList();
    }, []);

    return(
        <>
            <span className="titleStyle">
                        Patient Demography
                    </span>
                    <Row gutter={8}>

                        <Col span={6}>
                            <Form.Item name="patientId" label="Patient ID">
                                <Input size="small" disabled />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="patientPrimaryCareProvd" label="Primary Care Provider">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={6}>
                            <Form.Item name="patientReferringProvd" label="Referring Provider">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={4}>
                            <Form.Item name="patientLastName" label="Last name" 
                            rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a patient last name!',
                                        },
                                    ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="patientFirstName" label="First name" rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a patient first name!',
                                        },
                                    ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="patientsurffix" label="surffix">
                                <Select showSearch size="small">
                                    <Select.Option value="Jr">Jr</Select.Option>
                                    <Select.Option value="Sr">sr</Select.Option>
                                    <Select.Option value="I">I</Select.Option>
                                    <Select.Option value="II">II</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="patientMiddleName" label="MI">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="patientDob" label="DoB" rules={[
                                        {
                                            required: true,
                                            message: 'Please select a patient DoB!',
                                        },
                                    ]}>
                                <DatePicker size="small" format={'MM/DD/YYYY'} />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="patientSex" label="Sex" rules={[
                                        {
                                            required: true,
                                            message: 'Please select a patient gender!',
                                        },
                                    ]}>
                            <Select size="small" >
                                    <Select.Option value="Male">Male</Select.Option>
                                    <Select.Option value="Female">Female</Select.Option>
                                    <Select.Option value="Other">Other</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="patientSSN" label="SSN">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={8}>
                        <Col span={4}>
                            <Form.Item name="patientMaritalStatus" label="Marital Status" rules={[
                                        {
                                            required: true,
                                            message: 'Please select a patient marital status!',
                                        },
                                    ]}>
                                <Select size="small" >
                                    <Select.Option value="Married">Married</Select.Option>
                                    <Select.Option value="Other">Other</Select.Option>
                                    <Select.Option value="Single">Single</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="patientEmpStatus" label="Employement Status">
                                <Select size="small" >
                                    <Select.Option value="Employee">Employee</Select.Option>
                                    <Select.Option value="Full Time Employee">Full Time Employee</Select.Option>
                                    <Select.Option value="Part Time Employee">Part Time Employee</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="patientProfessionaltitle" label="Professional Title">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="patientPrefferedLang" label="Preffered Language">
                                <Select size="small">
                                    <Select.Option value="English">English</Select.Option>
                                    <Select.Option value="French">French</Select.Option>
                                    <Select.Option value="Hindi">Hindi</Select.Option>
                                    <Select.Option value="Tamil">Tamil</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="patientReligion" label="Regligion">
                                <Select showSearch size="small">
                                    <Select.Option value="Christian">Christian</Select.Option>
                                    <Select.Option value="Muslim">Muslim</Select.Option>
                                    <Select.Option value="Hindu">Hindu</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="patientsexualOrint" label="Sexual Orientation">
                                <Select showSearch size="small">
                                    <Select.Option value="Lesbian, Gay, Homosexual">Lesbian, Gay, Homosexual</Select.Option>
                                    <Select.Option value="Bisexual">Bisexual</Select.Option>
                                    <Select.Option value="Something Else">Something Else</Select.Option>
                                    <Select.Option value="Don't know">Don't know</Select.Option>
                                    <Select.Option value="Choose not disclose">Choose not disclose</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="patientGenderindfy" label="Gender Identity">
                                <Select showSearch size="small">
                                    <Select.Option value="Identifies as Male">Identifies as Male</Select.Option>
                                    <Select.Option value="Identifies as Female">Identifies as Female</Select.Option>
                                    <Select.Option value="Female to Male / Transfender Male" >Female to Male / Transfender Male</Select.Option>
                                    <Select.Option value="Male to Female / Transgender Female">Male to Female / Transgender Female</Select.Option>
                                    <Select.Option value="Genderqueer, neither Exclusivly male or female">Genderqueer, neither Exclusivly male or female</Select.Option>
                                    <Select.Option value="Other, Please Describ">Other, Please Describ</Select.Option>
                                    <Select.Option value="Choose not disclose">Choose not disclose</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="patientEthnicity" label="Ethnicity" >
                                <Select showSearch size="small">
                                    <Select.Option value="Chicano">Chicano</Select.Option>
                                    <Select.Option value="Cuban">Muslim</Select.Option>
                                    <Select.Option value="Mexican">Bisexual</Select.Option>
                                    <Select.Option value="Mexican American">Something Else</Select.Option>
                                    <Select.Option value="Latino">Latino</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="patientRace" label="Race">
                                <Select showSearch size="small">
                                    <Select.Option value="Asian">Asian</Select.Option>
                                    <Select.Option value="Chinese">Chinese</Select.Option>
                                    <Select.Option value="Black or African American">Black or African American</Select.Option>
                                    <Select.Option value="Mexican American">Something Else</Select.Option>
                                    <Select.Option value="European">European</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={5}>
                            <Form.Item name="patientMotherMaidenLastname" label="Maiden Last Name">
                                <Select showSearch size="small">
                                    <Select.Option value="No Advance Directive">No Advance Directive</Select.Option>
                                    <Select.Option value="Living Will">Living Will</Select.Option>
                                    <Select.Option value="Durable Power of Attoreny">Durable Power of Attoreny</Select.Option>
                                    <Select.Option value="Do not Resusicate">Do not Resusicate</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="patientMotherMaidenfirstname" label="Maiden First Name">
                                <Input size="small" />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item name="patientAdvanceDirectivetype" label="Advance Directive Type">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="patientAdvanceDirectivereivew" label="Advance Directive Review">
                                <DatePicker size="small" format={'MM/DD/YYYY'} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation="left" className="lineStyle">Patient Contact Information</Divider>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="patientAddress" label='Address' rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a patient Address!',
                                        },
                                    ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="patientAddress2" label='Address line2'>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="patientState" label="State" rules={[
                                        {
                                            required: true,
                                            message: 'Please select a patient state!',
                                        },
                                    ]}>
                                <Select showSearch size="small"
                                    optionFilterProp="children"
                                    onSelect={onState}
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                    {stateList?.map((s) => <Option key={s.id} value={s.state_code}>{s.state_code}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="patientCity" label="City" rules={[
                                        {
                                            required: true,
                                            message: 'Please select a patient city!',
                                        },
                                    ]}>
                                <Select showSearch size="small"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                    {cities?.map((s) => <Option key={s.id} value={s.name}>{s.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="patientZip" label='Zip code' rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a patient zipcode!',
                                        },
                                    ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={6}>
                            <Form.Item name="patientHomePhone" label="Home phone">
                                <Input size="small" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name='patientWorkPhone' label="Work Phone" >
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name='patientCellPhone' label="Cell Phone" rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a patient cell number!',
                                        },
                                    ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="patientPrefferedphonne" label="Preffered Phone" >
                                <Select showSearch size="small">
                                    <Select.Option value="Home">Home</Select.Option>
                                    <Select.Option value="Cell">Cell</Select.Option>
                                    <Select.Option value="Work">Work</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="patientemail" label="Email">
                                <Input size="small" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name='patientfaxno' label="Fax" >
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="patientCommunicationphone" label="communication Preffered" >
                                <Select showSearch size="small">
                                    <Select.Option value="Home">Home</Select.Option>
                                    <Select.Option value="Cell">Cell</Select.Option>
                                    <Select.Option value="Work">Work</Select.Option>
                                    <Select.Option value="email">E-Mail</Select.Option>
                                    <Select.Option value="postBox">Regural Mail</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation="left" className="lineStyle">Smoking History</Divider>
                    <Row gutter={8}>
                        <Col span={5}>
                            <Form.Item name="patientSmokingMU" label="Smoking MU" >
                                <Select showSearch size="small">
                                    <Select.Option value="Smoker">Smoker</Select.Option>
                                    <Select.Option value="Former Smoker">Former Smoker</Select.Option>
                                    <Select.Option value="Never Smoked">Never Smoked</Select.Option>
                                    <Select.Option value="Current Every Day Smoker">Current Every Day Smoker</Select.Option>
                                    <Select.Option value="Current Some Day Smoker">Current Some Day Smoker</Select.Option>
                                    <Select.Option value="Smoker, Current Status Unknown">Smoker, Current Status Unknown</Select.Option>
                                    <Select.Option value="Unknown if Ever Smoked">Unknown if Ever Smoked</Select.Option>
                                    <Select.Option value="Heavy Tobacco Smoker">Heavy Tobacco Smoker</Select.Option>
                                    <Select.Option value="Light Tobacco Smoker">Light Tobacco Smoker</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="patientSmokingfrent" label="Frequent" >
                                <Select showSearch size="small">
                                    <Select.Option key="psf1" value="0">0</Select.Option>
                                    <Select.Option key="psf2" value="1-5">1-5</Select.Option>
                                    <Select.Option key="psf3" value="6-10">6-10</Select.Option>
                                    <Select.Option key="psf4" value="1/2 a Pack a Day">1/2 a Pack a Dayr</Select.Option>
                                    <Select.Option key="psf5" value="1 1/2 Packs a Day">1 1/2 Packs a Day</Select.Option>
                                    <Select.Option key="psf6" value="2 Packs a Day">2 Packs a Day</Select.Option>
                                    <Select.Option key="psf7" value="2 1/2 Packs a Day">2 1/2 Packs a Day</Select.Option>
                                    <Select.Option key="psf8" value="3 or more Packs a Day">3 or more Packs a Day</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="patientSmokingStartEnd" label="Smoking Start and End" >
                                <RangePicker size="small" format={'MM/DD/YYYY'}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={5}>
                            <Form.Item name="patientOtherTabacco" label="Other Tobacco" >
                                <Select showSearch size="small">
                                    <Select.Option value="Chews fine cut tobacco">SmoChews fine cut tobaccoker</Select.Option>
                                    <Select.Option value="Chews loose leaf tobacco">Chews loose leaf tobacco</Select.Option>
                                    <Select.Option value="Chews plug tobacco">Chews plug tobacco</Select.Option>
                                    <Select.Option value="Chews products containing tobacco">
                                        Chews products containing tobacco</Select.Option>
                                    <Select.Option value="Chews tobacco">Chews tobacco</Select.Option>
                                    <Select.Option value="Chews twist tobacco">Chews twist tobacco</Select.Option>
                                    <Select.Option value="Snuff user">Snuff user</Select.Option>
                                    <Select.Option value="User of moist powdered tobacco">User of moist powdered tobacco</Select.Option>
                                    <Select.Option value="Does not use moist powdered tobacco">Does not use moist powdered tobacco</Select.Option>
                                    <Select.Option value="Ex-user of moist powdered tobacco">Ex-user of moist powdered tobacco</Select.Option>
                                    <Select.Option value="Intolerant non-smoker">Intolerant non-smoker</Select.Option>
                                    <Select.Option value="Never chewed tobacco">Never chewed tobacco</Select.Option>
                                    <Select.Option value="Never used moist powdered tobacco">Never used moist powdered tobacco</Select.Option>
                                    <Select.Option value="Tolerant ex-smoker">Tolerant ex-smoker</Select.Option>
                                    <Select.Option value="Tolerant non-smoker">Tolerant non-smoker</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="patientOtherTabaccofrent" label="Frequent" >
                                <Select showSearch size="small">
                                    <Select.Option  key="pOtf1" value="0">0</Select.Option>
                                    <Select.Option key="pOtf2" value="1-5">1-5</Select.Option>
                                    <Select.Option key="pOtf3" value="6-10">6-10</Select.Option>
                                    <Select.Option key="pOtf4" value="half a Pack a Day">1/2 a Pack a Dayr</Select.Option>
                                    <Select.Option key="pOtf5" value="1 half Packs a Day">1 1/2 Packs a Day</Select.Option>
                                    <Select.Option key="pOtf6" value="2 Packs a Day">2 Packs a Day</Select.Option>
                                    <Select.Option key="pOtf7" value="2 half Packs a Day">2 1/2 Packs a Day</Select.Option>
                                    <Select.Option key="pOtf8" value="3 or more Packs a Day">3 or more Packs a Day</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="patienttobaccoStartEnd" label="Tobacco Start and End" >
                                <RangePicker size="small" format='MM/DD/YYYY' />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="patienttobaccolastreview" label="Last Tobacco use Reviewe Date" >
                                <DatePicker size="small" format='MM/DD/YYYY' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="mt22">
                        <Col span={4}>
                            <span className="formLable">Smoking Comment</span>
                        </Col>
                        <Col span={20} >
                            <Form.Item name="patientSomkingComment" >
                                < Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider orientation="left" className="lineStyle">Employee Information</Divider>

                    <Row gutter={8}>
                        <Col span={6}>
                            <Form.Item name="patientEmpName" label="Employer name">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="patientEmpcontact" label="Employer phone">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={6}>
                            <Form.Item name='patientEmpAddress' label="Address" >
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name='patientEmpAddress2' label="Address line2" >
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="patientEmpState" label="State" >
                                <Select showSearch size="small"
                                    optionFilterProp="children"
                                    onSelect={onempState}
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                    {stateList?.map((s) => <Option key={s.id} value={s.state_code}>{s.state_code}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="patientEmpCity" label="City">
                                <Select showSearch size="small"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                    {empCities?.map((s) => <Option key={s.id} value={s.name}>{s.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item name='patientEmpZip' label="Zip Code" >
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider orientation="left" className="lineStyle">Emergency Contact</Divider>

                    <Row gutter={8}>
                        <Col span={6}>
                            <Form.Item name="patientEmrgName" label="Contact name">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="patientEmrmRelationship" label="Contact Relatioship">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={6}>
                            <Form.Item name='patientEmrgAddress' label="Address" >
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name='patientEmrgAddress2' label="Address line2" >
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="patientEmrgState" label="State" >
                                <Select showSearch size="small"
                                    optionFilterProp="children"
                                    onSelect={onemrgState}
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                    {stateList?.map((s) => <Option key={s.id} value={s.state_code}>{s.state_code}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="patientEmrgCity" label="City">
                                <Select showSearch size="small"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                    {emrgCities?.map((s) => <Option key={s.id} value={s.name}>{s.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item name='patientEmrgZip' label="Zip Code" >
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={6}>
                            <Form.Item name="patientEmrgHomePhone" label="Home phone">
                                <Input size="small" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name='patientEmrgWorkPhone' label="Work Phone" >
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name='patientEmrgCellPhone' label="Work Phone" >
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider orientation="left" className="lineStyle">Next of Kin</Divider>

                    <Row gutter={8}>
                        <Col span={6}>
                            <Form.Item name="patientKinName" label="Contact name">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="patientKinRelationship" label="Contact Relatioship">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={6}>
                            <Form.Item name='patientKinAddress' label="Address" >
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name='patientKinAddress2' label="Address line2" >
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="patientKinState" label="State" >
                                <Select showSearch size="small"
                                    optionFilterProp="children"
                                    onSelect={onkinState}
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                    {stateList?.map((s) => <Option key={s.id} value={s.state_code}>{s.state_code}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="patientKinCity" label="City">
                                <Select showSearch size="small"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                    {kinCities?.map((s) => <Option key={s.id} value={s.name}>{s.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item name='patientKinZip' label="Zip Code" >
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={6}>
                            <Form.Item name="patientKinHomePhone" label="Home phone">
                                <Input size="small" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name='patientKinWorkPhone' label="Work Phone" >
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name='patientKinCellPhone' label="Work Phone" >
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider orientation="left" className="lineStyle">User Defined Fields</Divider>
                    <Row gutter={8}>
                        <Col span={6}>
                            <Form.Item name="patientUserfield1" label="Field 1">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="patientUserfield2" label="Field 2">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="patientUserfield3" label="Field 3">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="patientUserfield4" label="Field 4">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="patientUserfield5" label="Field 5">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="patientUserfield6" label="Field 6">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider orientation="left" className="lineStyle">Account Status</Divider>
                    <Row gutter={8}>
                        <Col span={8}>
                            <Form.Item name='patientAccStartDate' label="Patient Start and End">
                                <RangePicker size="small" format={'MM/DD/YYYY'}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={24}>
                            <Form.Item name="patientExmptcheck">
                                <Checkbox checked={patientExmptcheck}
        onChange={(e) => setPatientExmptcheck(e.target.checked)}>This patient is exempt from all reporting functions.</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="patientHealthcheck">
                                <Checkbox checked={patientHealthcheck}
                                onChange={(e) => setPatientHealthcheck(e.target.checked)}>This patient's health information is confidential.</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
        </>
    )
}

export default PatientData;