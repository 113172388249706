import React, { useState, useEffect } from 'react';
import { Anchor, Avatar, Card, Col, Row, Statistic, Table, Tag, Typography } from "antd";
import './dashboard.scss'
import { ArrowDownOutlined, ArrowUpOutlined, AuditOutlined, CalendarOutlined, DollarCircleOutlined, DollarOutlined, DownOutlined, DownloadOutlined, LeftCircleOutlined, MoreOutlined, PrinterOutlined, RightCircleOutlined, ShareAltOutlined, TeamOutlined, UserAddOutlined, UserOutlined } from "@ant-design/icons";
import { Area, Pie, Tiny } from '@ant-design/plots';
import dayjs from 'dayjs';
import { headerName } from '../../layout/headerName.hook';
import { useDispatch } from 'react-redux';
// import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
// import { Chart } from "react-google-charts";

const { title } = Typography;

const Dashboard = () => {
    // const [configArea, setConfigArea] = useState([]);
    const dispatch = useDispatch();
    dispatch(headerName(`Dashboard`));
    const ColorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];

    const [dognutTitle, setDognutTitle] = useState('Claims Overview');
    const [dognutData, setDognutData] = useState([]);
    const [configDognut, setConfigDognut] = useState();
    const [isPatientPie, setIsPatientPie] = useState(false);
    const [isClaimPie, setIsClaimPie] = useState(true);

    const anchorLink = (txt, r) => {
    }


    const dataArea = [
        {
            "date": "Nov-2023",
            "industry": "Claim Submitted",
            "unemployed": 314
        },
        {
            "date": "Dec-2023",
            "industry": "Claim Submitted",
            "unemployed": 523
        },
        {
            "date": "Jan-2024",
            "industry": "Claim Submitted",
            "unemployed": 767
        },
        {
            "date": "Feb-2024",
            "industry": "Claim Submitted",
            "unemployed": 798
        },
        {
            "date": "Mar-2024",
            "industry": "Claim Submitted",
            "unemployed": 854
        },


        {
            "date": "Nov-2023",
            "industry": "Claim Inprogress",
            "unemployed": 234
        },
        {
            "date": "Dec-2023",
            "industry": "Claim Inprogress",
            "unemployed": 859
        },
        {
            "date": "Jan-2024",
            "industry": "Claim Inprogress",
            "unemployed": 438
        },
        {
            "date": "Feb-2024",
            "industry": "Claim Inprogress",
            "unemployed": 687
        },
        {
            "date": "Mar-2024",
            "industry": "Claim Inprogress",
            "unemployed": 843
        },


        {
            "date": "Nov-2023",
            "industry": "Claim Completed",
            "unemployed": 134
        },
        {
            "date": "Dec-2023",
            "industry": "Claim Completed",
            "unemployed": 658
        },
        {
            "date": "Jan-2024",
            "industry": "Claim Completed",
            "unemployed": 400
        },
        {
            "date": "Feb-2024",
            "industry": "Claim Completed",
            "unemployed": 679
        },
        {
            "date": "Mar-2024",
            "industry": "Claim Completed",
            "unemployed": 789
        },

        {
            "date": "Nov-2023",
            "industry": "Appiontment",
            "unemployed": 234
        },

        {
            "date": "Dec-2023",
            "industry": "Appiontment",
            "unemployed": 324
        },
        {
            "date": "Jan-2024",
            "industry": "Appiontment",
            "unemployed": 234
        },
        {
            "date": "Feb-2024",
            "industry": "Appiontment",
            "unemployed": 576
        },
        {
            "date": "Mar-2024",
            "industry": "Appiontment",
            "unemployed": 643
        },

        {
            "date": "Nov-2023",
            "industry": "Appointment Convert Claim",
            "unemployed": 106
        },
        {
            "date": "Dec-2023",
            "industry": "Appointment Convert Claim",
            "unemployed": 230
        },
        {
            "date": "Jan-2024",
            "industry": "Appointment Convert Claim",
            "unemployed": 567
        },
        {
            "date": "Feb-2024",
            "industry": "Appointment Convert Claim",
            "unemployed": 256
        },
        {
            "date": "Mar-2024",
            "industry": "Appointment Convert Claim",
            "unemployed": 745
        }
    ]

    const configArea = {
        data: dataArea,
        xField: 'date',
        yField: 'unemployed',
        colorField: 'industry',
        shapeField: 'smooth',
        // stack: true, // Try to remove this line.
    };

    const claimList = [
        {
            claimId: 1223434,
            patientName: 'Geoffery Moot',
            reason: 'Muscle Weakness',
            date: dayjs().format('MM/DD/YYYY'),
            insurance: "Medicare",
            status: 'InProgress',
            action: ''
        },
        {
            claimId: 1223435,
            patientName: 'Florencio Dorrance',
            reason: 'Loss of Sensation',
            date: dayjs().format('MM/DD/YYYY'),
            insurance: "Medicare",
            status: 'Not Started',
            action: ''
        },
        {
            claimId: 1223436,
            patientName: 'Darron Kulikkowski',
            reason: 'Muscle Weakness',
            date: dayjs().format('MM/DD/YYYY'),
            insurance: "Medicare",
            status: 'Submitted',
            action: ''
        },
        {
            claimId: 1223437,
            patientName: 'Merill Kervin',
            reason: 'Seizuers',
            date: dayjs().format('MM/DD/YYYY'),
            insurance: "Medicare",
            status: 'InProgress',
            action: ''
        },
        {
            claimId: 1223438,
            patientName: 'Daryl Nehls',
            reason: 'Difficulty Reading',
            date: dayjs().format('MM/DD/YYYY'),
            insurance: "Medicare",
            status: 'Completed',
            action: ''
        }
    ]

    const columnsCLaim = [
        {
            title: 'Claim Id',
            dataIndex: 'claimId',
            key: 'claimId',
            render: (txt, r) => (<p onClick={() => anchorLink(txt, r)}>{txt}</p>)
        },
        {
            title: 'Patient Name',
            dataIndex: 'patientName',
            key: 'patientName'
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date'
        },
        {
            title: 'Insurance',
            dataIndex: 'insurance',
            key: 'insurance'
        },
        {
            title: 'Appointment for',
            dataIndex: 'reason',
            key: 'reason'
        },
        {
            title: 'Claim Status',
            dataIndex: 'status',
            key: 'status',
            render: (txt, r) =>
            (
                <Tag color={txt === 'Not Started' || txt === 'InProgress' ? 'geekblue' : 'green'} key={r.claimId}>
                    {txt}
                </Tag>
            )

        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (r) => (
                <>
                    <span style={{
                        fontSize: '12px',
                        padding: '5px'
                    }}> <ShareAltOutlined /></span>
                    <span style={{
                        fontSize: '12px',
                        padding: '5px'
                    }}> <DownloadOutlined /> </span>
                </>
            )
        },
    ]

    const columns = [
        {
            title: 'Patient Id',
            dataIndex: 'patientId',
            key: 'patientId',
            render: (txt, r) => (<p onClick={() => anchorLink(txt, r)}>{txt}</p>)
        },
        {
            title: 'Patient Name',
            dataIndex: 'patientName',
            key: 'patientName'
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date'
        },
        {
            title: 'Appointment for',
            dataIndex: 'reason',
            key: 'reason'
        },
        {
            title: 'Report',
            dataIndex: 'report',
            key: 'report'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (r) => (
                <>
                    <span style={{
                        fontSize: '16px',
                        padding: '5px'
                    }}> <ShareAltOutlined /></span>
                    <span style={{
                        fontSize: '16px',
                        padding: '5px'
                    }}> <DownloadOutlined /> </span>
                    <span style={{
                        fontSize: '16px',
                        padding: '5px'
                    }}> <PrinterOutlined /> </span>
                </>
            )
        },
    ]

    const userList = [
        {
            patientId: 1265899,
            patientName: 'Geoffery Moot',
            color: ColorList[0],
            reason: 'Muscle Weakness',
            time: '10:00 AM',
            date: dayjs().format('MM/DD/YYYY'),
            report: '',
            action: ''
        },
        {
            patientId: 1265900,
            patientName: 'Florencio Dorrance',
            color: ColorList[0],
            reason: 'Loss of Sensation',
            time: '10:15 AM',
            date: dayjs().format('MM/DD/YYYY'),
            report: '',
            action: ''
        },
        {
            patientId: 1265901,
            patientName: 'Darron Kulikkowski',
            color: ColorList[0],
            reason: 'Seizuers',
            time: '10:30 AM',
            date: dayjs().format('MM/DD/YYYY'),
            report: '',
            action: ''
        },
        {
            patientId: 1265902,
            patientName: 'Merill Kervin',
            color: ColorList[0],
            reason: 'Muscle Weakness',
            time: '10:45 AM',
            date: dayjs().format('MM/DD/YYYY'),
            report: '',
            action: ''
        },
        {
            patientId: 1265903,
            patientName: 'Geoffery Moot',
            color: ColorList[0],
            reason: 'Difficulty Reading',
            time: '11:00 AM',
            date: dayjs().format('MM/DD/YYYY'),
            report: '',
            action: ''
        },
        {
            patientId: 1265904,
            patientName: 'Daryl Nehls',
            color: ColorList[0],
            reason: 'Muscle Weakness',
            time: '11:15 AM',
            date: dayjs().format('MM/DD/YYYY'),
            report: '',
            action: ''
        },
        {
            patientId: 1265905,
            patientName: 'Geoffery Moot',
            color: ColorList[0],
            reason: 'Muscle Weakness',
            time: '11:30 AM',
            date: dayjs().format('MM/DD/YYYY'),
            report: '',
            action: ''
        },
        {
            patientId: 1265906,
            patientName: 'Florencio Dorrance',
            color: ColorList[0],
            reason: 'Loss of Sensation',
            time: '11:45 AM',
            date: dayjs().format('MM/DD/YYYY'),
            report: '',
            action: ''
        },
        {
            patientId: 1265907,
            patientName: 'Darron Kulikkowski',
            color: ColorList[0],
            reason: 'Seizuers',
            time: '12:00 PM',
            date: dayjs().format('MM/DD/YYYY'),
            report: '',
            action: ''
        },
        {
            patientId: 1265908,
            patientName: 'Merill',
            color: ColorList[0],
            reason: 'Muscle Weakness',
            time: '12:15 PM',
            date: dayjs().format('MM/DD/YYYY'),
            report: '',
            action: ''
        },
        {
            patientId: 1265909,
            patientName: 'Geoffery',
            color: ColorList[0],
            reason: 'Difficulty Reading',
            time: '12:30 PM',
            date: dayjs().format('MM/DD/YYYY'),
            report: '',
            action: ''
        },
        {
            patientId: 1265911,
            patientName: 'Nehls',
            color: ColorList[0],
            reason: 'Muscle Weakness',
            time: '01:00 PM',
            date: dayjs().format('MM/DD/YYYY'),
            report: '',
            action: ''
        }
    ]

    const dataPatient = [
        {
            type: 'Child',
            value: 20,
        },
        {
            type: 'Teen',
            value: 20,
        },
        {
            type: 'Old',
            value: 50,
        },
        {
            type: 'Audlt',
            value: 10,
        },
    ];

    const dataClaim = [
        {
            type: 'Total Claims',
            value: 50,
        },
        {
            type: 'Paid',
            value: 25,
        },
        {
            type: 'Denied',
            value: 5,
        },
        {
            type: 'In Progress',
            value: 20,
        }
    ]

    // useEffect(() => {
    //     setDognutData(dataPatient);
    // }, []);
    const config = {
        appendPadding: 10,
        data: dataPatient,
        angleField: 'value',
        colorField: 'type',
        height: 244,
        radius: 1,
        innerRadius: 0.6,
        label: {
            text: 'value',
            style: {
                fontWeight: 'bold',
            },
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                content: '',
            },
        },
        color: ['#26bf94', '#f5b849', '#845adf', '#23b7e5'],
    };

    const configClaim = {
        appendPadding: 10,
        data: dataClaim,
        angleField: 'value',
        colorField: 'type',
        height: 244,
        radius: 1,
        innerRadius: 0.6,
        label: {
            text: 'value',
            style: {
                fontWeight: 'bold',
            },
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                content: '',
            },
        },
        color: ['#26bf94', '#f5b849', '#845adf', '#23b7e5'],
    }


    const dataTiny = [
        264, 417, 438, 887, 309, 397, 550, 575, 563, 430, 525, 546, 539, 243, 226, 192,
      ].map((value, index) => ({ value, index }));
      const configTiny = {
        data: dataTiny,
        width: 80,
        height: 40,
        shapeField: 'smooth',
        xField: 'index',
        yField: 'value',
      };

      const dataTinyArea = [
        264, 417, 430, 525, 592, 492, 467, 513, 546, 983, 340, 539, 243, 226, 192,
      ].map((value, index) => ({ value, index }));
      const configTinyArea = {
        data: dataTinyArea,
        width: 90,
        height: 40,
        padding: 10,
        shapeField: 'smooth',
        xField: 'index',
        yField: 'value',
      };

    // useEffect(() => {

    //     setConfigDognut(config);
    // }, [dognutData]);

    const updatePieDognut = (name) => {
        if (name === 'patient') {
            setIsPatientPie(true);
            setIsClaimPie(false);
            setDognutTitle('Patient Overview');
        } else if (name === 'claim') {
            setIsPatientPie(false);
            setIsClaimPie(true);
            setDognutTitle('Claim Overview');

        }
    }

    return (
        <div className="dashboard-wrapper">
            <div className="dashboard">
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={17} xl={17}>
                        <Row gutter={16}>
                            <Col xs={24} sm={24} md={24} xl={14}>
                                <Row gutter={16}>
                                    <Col xs={24} sm={24} md={12} xl={12}>
                                        <Card className="mini-card" style={{ cursor: 'pointer' }} onClick={() => updatePieDognut('patient')}>
                                            <Row>
                                                <Col span="12">
                                                    <div className="pt-icon">
                                                        <TeamOutlined />
                                                    </div>

                                                </Col>
                                                <Col span="12">
                                                    <Tiny.Line {...configTiny } style={{marginLeft: '-13px'}} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="pt-count">
                                                        1245
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={12}>
                                                    <div className="pt-text">
                                                        Total Patient
                                                    </div>
                                                </Col>
                                                <Col span={12} style={{
                                                    textAlign: 'right'
                                                }}>
                                                    <Statistic
                                                        value={11.28}
                                                        precision={2}
                                                        valueStyle={{
                                                            color: '#3f8600',
                                                        }}
                                                        className="ds-statistic"

                                                        prefix={<ArrowUpOutlined />}
                                                        suffix="%"
                                                    />
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} xl={12}>
                                        <Card className="mini-card" style={{ cursor: 'pointer' }} onClick={() => updatePieDognut('claim')}>
                                            <Row>
                                                <Col span="12" 
                                                    >
                                                    <div className="pt-icon pt-new">
                                                        {/* <UserAddOutlined /> */}
                                                        {/* <DollarCircleOutlined /> */}
                                                        <AuditOutlined />
                                                    </div>

                                                </Col>
                                                <Col span="12" style={{
                                                    textAlign: 'right'
                                                }}>
                                                    <Tiny.Line {...configTinyArea} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="pt-count">
                                                        234/345
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={12}>
                                                    <div className="pt-text">
                                                        Paid / Submitted
                                                    </div>
                                                </Col>
                                                <Col span={12} style={{
                                                    textAlign: 'right'
                                                }}>
                                                    <Statistic
                                                        value={1.28}
                                                        precision={2}
                                                        valueStyle={{
                                                            color: '#3f8600',
                                                        }}
                                                        className="ds-statistic"

                                                        prefix={<ArrowUpOutlined />}
                                                        suffix="%"
                                                    />
                                                </Col>

                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col xs={24} sm={24} md={12} xl={12}>
                                        <Card className="mini-card" >
                                            <Row>
                                                <Col span="12">
                                                    <div className="pt-icon pt-old">
                                                        {/* <UserOutlined /> */}
                                                        <DollarOutlined />
                                                    </div>

                                                </Col>
                                                <Col span="12" style={{
                                                    textAlign: 'right'
                                                }}>

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="pt-count">
                                                        $11,780
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={12}>
                                                    <div className="pt-text">
                                                        Claim
                                                    </div>
                                                </Col>
                                                <Col span={12} style={{
                                                    textAlign: 'right'
                                                }}>
                                                    <Statistic
                                                        value={8.87}
                                                        precision={2}
                                                        valueStyle={{
                                                            color: 'red',
                                                        }}
                                                        className="ds-statistic"

                                                        prefix={<ArrowDownOutlined />}
                                                        suffix="%"
                                                    />
                                                </Col>

                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} xl={12}>
                                        <Card className="mini-card" >
                                            <Row>
                                                <Col span="12">
                                                    <div className="pt-icon apptotl">
                                                        <CalendarOutlined />
                                                    </div>

                                                </Col>
                                                <Col span="12" style={{
                                                    textAlign: 'right'
                                                }}>

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="pt-count">
                                                        313
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={12}>
                                                    <div className="pt-text">
                                                        Appoinment
                                                    </div>
                                                </Col>
                                                <Col span={12} style={{
                                                    textAlign: 'right'
                                                }}>
                                                    <Statistic
                                                        value={5.09}
                                                        precision={2}
                                                        valueStyle={{
                                                            color: '#3f8600',
                                                        }}
                                                        className="ds-statistic"

                                                        prefix={<ArrowUpOutlined />}
                                                        suffix="%"
                                                    />
                                                </Col>

                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={24} md={24} xl={10}>
                                <Card className='ds-card' >
                                    <div className='title-pt-pie'>
                                        <p>{dognutTitle}</p>
                                    </div>
                                    {isPatientPie && (
                                        <Pie {...config} />
                                    )}

                                    {isClaimPie && (
                                        <Pie {...configClaim} />
                                    )}
                                </Card>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <div className='ds-title'>
                                <p>Claim Status</p>
                            </div>
                            <Col span={22}>
                                <Area {...configArea} />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Table className='ds-table' columns={columnsCLaim} dataSource={claimList} />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={7} xl={7} style={{
                        background: '#f6f7f7'
                    }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <div className='title-app'>
                                Appointments
                            </div>
                            {/* <div className='title-mon'>
                                May 2022
                            </div> */}
                        </div>

                        {/* <div className='appointment-cal'>
                            <div className='appointment-date'>
                                <div className='previous-date'>
                                    <LeftCircleOutlined />
                                </div>
                                <div className='app-dates'>
                                    <div className='app-date'>
                                        <p> Sun </p>
                                        <p> 10 </p>
                                    </div>
                                    <div className='app-date active'>
                                        <p> Mon </p>
                                        <p> 11 </p>
                                    </div>
                                    <div className='app-date'>
                                        <p> Tue </p>
                                        <p> 12 </p>
                                    </div>
                                    <div className='app-date'>
                                        <p> Web </p>
                                        <p> 13 </p>
                                    </div>
                                </div>
                                <div className='previous-date'>
                                    <RightCircleOutlined />
                                </div>
                            </div>
                        </div> */}
                        <div>
                            {userList?.map((d) => (
                                <Card key={d.patientId} style={{ margin: '5px', marginTop: '5px' }}>
                                    <Row gutter={4}>
                                        <Col span={4}>
                                            <Avatar
                                                style={{
                                                    backgroundColor: d.color,
                                                    verticalAlign: 'middle',
                                                }}
                                                size="small"
                                            >
                                                {d.patientName[0]}
                                            </Avatar>
                                        </Col>
                                        <Col span={18} className='patientInfo'>
                                            <div className='ptInfo patientname'>
                                                {d.patientName}
                                            </div>
                                            <div className='ptInfo'>
                                                {d.reason}
                                            </div>
                                            <div className='ptInfo'>
                                                {d.time}
                                            </div>
                                        </Col>
                                        <Col span={2}>
                                            <MoreOutlined />
                                        </Col>
                                    </Row>
                                </Card>

                            ))}
                        </div>
                    </Col>


                </Row>
                <Row gutter={16}>
                    <div className='ds-title'>
                        <p>Patients Overview</p>
                    </div>
                    <Col span={24}>
                        <Table className='ds-table' columns={columns} dataSource={userList} />
                    </Col>
                </Row>
            </div >
        </div >
    )
}

export default Dashboard;