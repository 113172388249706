import { Button, Col, Form, Row, Tabs, Typography } from "antd";
import './style.scss';
import PatientData from "./form/patientdata";
import PatientInsurance from "./form/patientinsurance";
import moment from "moment";
import axios from "axios";
import { PatientdataAPI } from "./patientData.api";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import patientApi from "../patient/hook/patient.api";
import { useDispatch } from "react-redux";
import { headerName } from "../../layout/headerName.hook";
import dayjs from "dayjs";
import InsuranceAPI from "../insurance/insurance.api";
import PayerModel from "../../components/payer.modal";
import Swal from "sweetalert2";

const { Title } = Typography;

const CreatePatient = () => {
    const [form] = Form.useForm();
    const [searchParams] = useSearchParams();
    const [patientdocid, setPatientdocid] = useState();
    const [mode, setMode] = useState();
    const [patientData, setPatientData] = useState();
    const [ispayerModal, setIspayerModal] = useState(false);
    const [payerList, setPayerList] = useState([]);
    const [selctedItm, setSelctedItm] = useState('');
    const [isSelf, setIsSelf] = useState({});


    const dispatch = useDispatch();
    dispatch(headerName("Patient Management"));

    const getIsSelf = (key, v) => {
        const d = {
            ...isSelf,
            [key]: v
        }
        setIsSelf({
            ...d
        });
    }

    const selfInsurance = (e, type) => {
        console.log(e, type);
        // const type = 'primary';
        if (type === 'primary') {
            if (e === 'Self') {
                getIsSelf('primary', true);
            } else {
                getIsSelf('primary', false);
            }
        } else if (type === 'secondary') {
            if (e === 'Self') {
                getIsSelf('secondary', true);
            } else {
                getIsSelf('secondary', false);
            }
        } else if (type === 'thrid') {
            if (e === 'Self') {
                getIsSelf('thrid', true);
            } else {
                getIsSelf('thrid', false);
            }
        }
        else if (type === 'altPrimary') {
            if (e === 'Self') {
                getIsSelf('altPrimary', true);
            } else {
                getIsSelf('altPrimary', false);
            }
        }
    }

    const setPatitentInfo = (data) => {
        const newdata = {
            ...data,
            patientDob: dayjs(data?.patientDob),
            patientPrimaryInsurancedSignatureDate: dayjs(data?.patientPrimaryInsurancedSignatureDate),
            patientPrimaryInsurancedEffStartDate: dayjs(data?.patientPrimaryInsurancedEffStartDate),
            patientPrimaryInsurancedEffEndDate: dayjs(data?.patientPrimaryInsurancedEffEndDate),
            patientSecondaryInsurancedSignatureDate: dayjs(data?.patientSecondaryInsurancedSignatureDate),
            patientAdvanceDirectivereivew: dayjs(data?.patientAdvanceDirectivereivew),
            patienttobaccolastreview: dayjs(data?.patienttobaccolastreview),
            patientaltPrimaryInsurancedEffEndDate: dayjs(data?.patientaltPrimaryInsurancedEffEndDate),
            patientaltPrimaryInsurancedSignatureDate: dayjs(data?.patientaltPrimaryInsurancedSignatureDate),
            patientaltPrimaryInsurancedEffStartDate: dayjs(data?.patientaltPrimaryInsurancedEffStartDate),
            patientSmokingStartEnd: [
                dayjs(data?.patientSmokingStartEnd[0]),
                dayjs(data?.patientSmokingStartEnd[1]),
            ],
            patienttobaccoStartEnd: [
                dayjs(data?.patienttobaccoStartEnd[0]),
                dayjs(data?.patienttobaccoStartEnd[1])
            ],
            patientAccStartDate: [
                dayjs(data?.patientAccStartDate[0]),
                dayjs(patientData?.patientAccStartDate[1]),
            ],

        }
        setPatientData(newdata)
    }
    useEffect(() => {
        form.setFieldsValue({ ...patientData });
    }, [patientData, form]);

    useEffect(() => {
        const pId = searchParams.get('id');
        const pmode = searchParams.get('mode');
        setPatientdocid(pId)
        setMode(pmode === 'edit');
        if (pId) {
            const getPatient = async (id) => {
                const resp = await PatientdataAPI.getPatientbyId(id);
                console.log(resp);
                setPatitentInfo(resp[0])

            }
            getPatient(pId)
        }

    }, []);

    const onPullpayer = (r, s, rt) => {
        // console.log(r, selctedItm);
        let data = {};
        if (selctedItm === 'primary') {
            data = {
                patientPrimaryInsuranceName: r?.PayerName,
                patientPrimaryInsurancePayerId: r?.PayerID,
            }
        }
        if (selctedItm === 'secondary') {
            data = {
                patientSecondaryInsuranceName: r?.PayerName,
                patientSecondaryInsurancePayerId: r?.PayerID,
            }
        }
        if (selctedItm === 'Third') {
            data = {
                patientThirdInsuranceName: r?.PayerName,
                patientThirdInsurancePayerId: r?.PayerID,
            }
        }
        if (selctedItm === 'altprimary') {
            data = {
                patientaltPrimaryInsuranceName: r?.PayerName,
                patientatlPrimaryInsurancePayerId: r?.PayerID,
            }
            console.log('alt perimary', data);
        }
        if (selctedItm === 'patientEligDenPayername') {
            data = {
                patientEligDenPayername: r?.PayerName
            }
        }
        form.setFieldsValue({ ...data })
        setIspayerModal(false);
    };

    const handlepayerCancel = () => {
        setIspayerModal(false);
    }

    const insurancePop = async (sel) => {
        setIspayerModal(true);
        const resp = await InsuranceAPI.getAllPayer();
        setPayerList(resp);
        console.log(resp);
        setSelctedItm(sel);
    }

    const pullMorePayer = async () => {
        console.log('pullMorePayer');
    }

    const onFinish = async (v) => {
        // console.log(v);
        const payload = {
            ...v,
            patientDob: v?.patientDob?.format('MM/DD/YYYY'),
            patientaltPrimaryInsurancedSignatureDate: v?.patientaltPrimaryInsurancedSignatureDate?.format('MM/DD/YYYY'),
            patientaltPrimaryInsurancedEffStartDate: v?.patientaltPrimaryInsurancedEffStartDate?.format('MM/DD/YYYY'),
            patientaltPrimaryInsurancedEffEndDate: v?.patientaltPrimaryInsurancedEffEndDate?.format('MM/DD/YYYY'),
            patientPrimaryInsurancedSignatureDate: v?.patientPrimaryInsurancedSignatureDate?.format('MM/DD/YYYY'),
            patientPrimaryInsurancedEffStartDate: v?.patientPrimaryInsurancedEffStartDate?.format('MM/DD/YYYY'),
            patientPrimaryInsurancedEffEndDate: v?.patientPrimaryInsurancedEffEndDate?.format('MM/DD/YYYY'),
            patientSecondaryInsurancedSignatureDate: v?.patientSecondaryInsurancedSignatureDate?.format('MM/DD/YYYY'),
            patientAdvanceDirectivereivew: v?.patientAdvanceDirectivereivew?.format('MM/DD/YYYY'),
            patienttobaccolastreview: v?.patienttobaccolastreview?.format('MM/DD/YYYY'),
            patientSmokingStartEnd: v?.patientSmokingStartEnd?.length ? [
                v?.patientSmokingStartEnd[0]?.format('MM/DD/YYYY'),
                v?.patientSmokingStartEnd[1]?.format('MM/DD/YYYY'),
            ] : [],
            patienttobaccoStartEnd: v?.patienttobaccoStartEnd?.length ? [
                v?.patienttobaccoStartEnd[0]?.format('MM/DD/YYYY'),
                v?.patienttobaccoStartEnd[1]?.format('MM/DD/YYYY'),
            ] : [],
            patientAccStartDate: v?.patientAccStartDate?.length ? [
                v?.patientAccStartDate[0]?.format('MM/DD/YYYY'),
                v?.patientAccStartDate[1]?.format('MM/DD/YYYY'),
            ] : [],
            patientStatus: 'Active',
            createBy: 'admin',
            createTime: new Date().getTime(),
            createDate: dayjs().format('MM/DD/YYYY'),
            facilityId: '',
            patientId: new Date().getTime()
        }
        // console.log(payload);
        if (mode) {
            const data = {
                ...payload,
                _id: patientdocid,
            }
            try{
                const resp = await PatientdataAPI.updatePatient(data);
            // console.log(resp);
            } catch(err){
                console.log(err);
            }
            form.resetFields();
            Swal({
                title: 'Update!',
                text: 'Patient Update Successfully!',
                icon: 'success',
                confirmButtonText: 'Okay'
            });
        } else {
        try{
            const resp = await PatientdataAPI.CreatePatient(payload);
            console.log(resp);
            Swal.fire({
                title: 'Success!',
                text: 'Patient Created Successfully!',
                icon: 'success',
                confirmButtonText: 'Okay'
            });
        } catch(err){
            console.log(err);
            Swal.fire({
                title: 'Duplicate!',
                text: err?.response?.data?.message,
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
            // form.resetFields();

            
        }

    }

    const homePath = () => {
        console.log('homePath');
    }


    const menuItem = [
        {
            label: 'Patient Data',
            key: 'patientData',
            children: <PatientData />,
        },
        {
            label: 'Insurance',
            key: 'insurance',
            children: <PatientInsurance
                insurancePop={insurancePop}
                isSelf={isSelf}
                selfInsurance={selfInsurance}
            />,
        }
    ]

    return (
        (<>
            <div className="patientWrapper">
                {/* <Row>
                    <Col span={24}>
                        <Title level={5} className="patientViewheader" >
                            Add New Patient</Title>
                    </Col>
                </Row> */}
                <Form layout="vertical"
                    initialValues={{}}
                    className="paitentForm"
                    onFinish={onFinish}
                    form={form} >

                    <Tabs
                        defaultActiveKey="1"
                        centered
                        items={menuItem}
                    />
                    <Row gutter={8} justify="end" className="mt30">
                        {mode ? (
                            <Col span={3}>
                                <Button type="primary" htmlType="submit" className="btnPrimary">Update Patient</Button>
                            </Col>
                        ) : (
                            <>
                                <Col span={3}>
                                    <Button type="primary" htmlType="submit" className="btnPrimary">Add + Enroll PHR</Button>
                                </Col>
                                <Col span={3}>
                                    <Button type="primary" htmlType="submit" className="btnPrimary">Add Patient</Button>
                                </Col>
                            </>
                        )}

                        <Col span={3}>
                            <Button type="default" onClick={() => homePath()} className="btnPrimary">Cancel</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <PayerModel
                ispayerModal={ispayerModal}
                handlepayerCancel={handlepayerCancel}
                payerList={payerList}
                modalTitle={'Payer Search'}
                onPullpayer={onPullpayer}
                selctedItm={selctedItm}
                pullMorePayer
            />
        </>)
    )
}

export default CreatePatient;