import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Table } from "antd";
import { useEffect, useState } from "react";


const PatientModel = ({
    ispatntModal,
    handlepatntCancel,
    patientList,
    modalTitle,
    onPullpatient,
    pullMorePatient }) => {
    const [filterpatnt, setFilterpatnt] = useState([]);
    const [inputValue, setInputValue] = useState('');
console.log('Patient Modal');
    const rowSelection = (r) => {
        console.log(r);
        onPullpatient(r);
    }

    const searchMore = (key, val) => {
        let Obj = {
            [key]: val,
        }
        setInputValue(Obj)
    }

    const searchPatient = (e, type) => {
        console.log(e.target.value, type);
        const filterdata = patientList?.filter((d) => d[type].toLowerCase().includes(e.target.value));
        console.log(filterdata);
        setFilterpatnt(filterdata);
        searchMore(type, e.target.value)
    }

    const columns = [
        {
            title: 'Patient ID',
            dataIndex: 'patientId',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Last name',
            dataIndex: 'patientLastName',
        },
        {
            title: 'First name',
            dataIndex: 'patientFirstName',
        },
        {
            title: 'DoB',
            dataIndex: 'patientDob',
        },
        {
            title: 'Address',
            dataIndex: 'patientAddress',
        },
        {
            title: 'City',
            dataIndex: 'patientCity',
        },
        {
            title: 'State',
            dataIndex: 'patientState',
        },
        {
            title: 'Zip',
            dataIndex: 'patientZip',
        },{
            title: 'Cell Phone',
            dataIndex: 'patientCellPhone',
        }
    ];

    useEffect(() => { 
        setFilterpatnt(filterpatnt);
    }, []);


    return (
        <Modal  title={modalTitle} open={ispatntModal} width={950} onOk={handlepatntCancel} onCancel={handlepatntCancel}>
            <div style={{ marginTop: '30px', display: 'inline-block', marginBottom: '20px', width: '200px' }}>
                <Input placeholder='Last name' suffix={<SearchOutlined />} name="patientLastName" onChange={(e) => searchPatient(e, 'patientLastName')} />
            </div>
            <div style={{ display: 'inline-block', margin: '30px 0px 20px 10px', width: '200px' }}>
                <Input placeholder='First name' suffix={<SearchOutlined />} name="patientFirstName" onChange={(e) => searchPatient(e, 'patientFirstName')} />
            </div>
            <div style={{ display: 'inline-block', margin: '30px 0px 20px 10px', width: '200px' }}>
                <Input placeholder='Patient ID' suffix={<SearchOutlined />} name="patientId" onChange={(e) => searchPatient(e, 'patientId')} />
            </div>
            <div style={{ display: 'inline-block', margin: '30px 0px 20px 10px', width: '200px' }}>
                <Button type="primary" onClick={() => pullMorePatient(inputValue)} > Search more </Button>
            </div>

            <Table
                rowKey={(record) => record.patientId}
                onRow={(record) => ({
                    onClick : () => rowSelection(record),
                })}
                columns={columns}
                dataSource={filterpatnt.length > 0 ? filterpatnt : patientList}
                pagination={{
                    pageSize: 8                                                            
                }}
            />

        </Modal>
    )

}

export default PatientModel;