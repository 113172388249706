import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Table } from "antd";
import { useEffect, useState } from "react";


const PayerModel = ({
    ispayerModal,
    handlepayerCancel,
    payerList,
    modalTitle,
    onPullpayer,
    selctedItm,
    pullMorePayer }) => {
    const [filterpayer, setFilterpayer] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const rowSelection = (r) => {
        onPullpayer(r);
    }

    const searchMore = (key, val) => {
        let Obj = {
            [key]: val,
        }
        setInputValue(Obj)
    }

    const searchPatient = (e, type) => {
        // console.log(e.target.value, type);
        const filterdata = payerList?.filter((d) => d[type].toLowerCase().includes(e.target.value));
        // console.log(filterdata);
        setFilterpayer(filterdata);
        searchMore(type, e.target.value)
    }

    const columns = [
        {
            title: 'Payer Name',
            dataIndex: 'PayerName',
            render: (text) => <a>{text}</a>,
            width: '30%'
        },
        {
            title: 'PayerID',
            dataIndex: 'PayerID',
        },
        {
            title: 'State',
            dataIndex: 'State',
        },
        {
            title: 'Reports',
            dataIndex: 'Reports',
        },
        {
            title: 'Secondary',
            dataIndex: 'Secondary',
        },
        {
            title: 'PayerType',
            dataIndex: 'PayerType',
        }
    ];

    useEffect(() => { 
        setFilterpayer(filterpayer);
    }, []);


    return (
        <Modal  title={modalTitle} open={ispayerModal} width={950} onOk={handlepayerCancel} onCancel={handlepayerCancel}>
            <div style={{ marginTop: '30px', display: 'inline-block', marginBottom: '20px', width: '200px' }}>
                <Input placeholder='Payer Name' suffix={<SearchOutlined />} name="PayerName" onChange={(e) => searchPatient(e, 'PayerName')} />
            </div>
            <div style={{ display: 'inline-block', margin: '30px 0px 20px 10px', width: '200px' }}>
                <Input placeholder='Payer ID' suffix={<SearchOutlined />} name="PayerID" onChange={(e) => searchPatient(e, 'PayerID')} />
            </div>
            <div style={{ display: 'inline-block', margin: '30px 0px 20px 10px', width: '200px' }}>
                <Button type="primary" onClick={() => pullMorePayer(inputValue)} > Search more </Button>
            </div>

            <Table
                rowKey={(record) => record._id}
                onRow={(record) => ({
                    onClick : () => rowSelection(record),
                })}
                columns={columns}
                dataSource={filterpayer.length > 0 ? filterpayer : payerList}
                pagination={{
                    pageSize: 8                                                            
                }}
            />

        </Modal>
    )

}

export default PayerModel;