import axios from "axios";
import Swal from "sweetalert2";
import { API } from "../config";


const axiosHttp = axios.create({
    baseURL: API
});

axiosHttp.interceptors.request.use((config) => {
    const getAccTok = sessionStorage.getItem('token');
    config.headers['x-auth-token'] = getAccTok;
    return config;
}, function (error) {
    return Promise.reject(error);
});

axiosHttp.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        console.log(err);
        if (err?.response?.status === 401 || err.response?.status === 400) {
          Swal.fire({
                title: err.response.status,
                text: 'Something went wrong!, please contect administrator',
                icon: 'error',
                confirmButtonText: 'Okay',
                confirmButtonColor: '#005154'
          });
        }

        return Promise.reject(err);
    }
);

export default axiosHttp;
