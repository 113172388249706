import axios from "axios";
import { API } from "../../config";
import axiosHttp from "../../middleware/axios";
import { errorHandler } from "../../utilities/helpers";


const userList = async () => {
    try{
        const response = await axiosHttp.get('/user');
        return response.data;
    } catch(err){
        errorHandler(err);
    }
    
  };

  const getbyUserId = async (userId) => {
    const response = await axios.get(API+`/user/${userId}`);
    return response.data;
  }

  const createUser = async (data) => {
    const response = await axios.post(API+`/user`, data);
    return response.data;
  }


export const UserServiceAPI = {
    userList,
    getbyUserId,
    createUser
};