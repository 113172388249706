import React, { useEffect, useState } from 'react';
import './style.scss';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import dayLocaleData from 'dayjs/plugin/localeData';
import AppointmentFrom from './appointmentFrom';
import AppointmentMenu from './components/appointmentsubMenu';
import DailyViewAppointment from "./dailyview";
import WeeklyViewAppointment from "./weeklyview";
import MonthlyView from "./montlyview";
import OptionView from "./optionView";
import SpecialEvent from "./sepcialEvent";
import OfficeDaily from "./officeDaily";
import SearchPatient from "./searchPatient";
import { useDispatch, useSelector } from 'react-redux';
import { headerName } from '../../layout/headerName.hook';
import patientApi from '../patient/hook/patient.api';
import { PatientdataAPI } from '../patientmanagement/patientData.api';
import appointmentAPI from './appointment.hook';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UserAddOutlined } from '@ant-design/icons';
import { getUserInfo } from '../../store/hook/user.hook';

dayjs.extend(dayLocaleData);

const AppointmentBook = () => {
    const [patients, setPatients] = useState([]);
    const [payerList, setPayerList] = useState([]);
    const [subHeader, setSubHeader] = useState('Add New Appointment');
    const [isEditmode, setIsEditmode] = useState(false);
    const [isEditApp, setIsEditApp] = useState();
    const [activeTabKey, setActiveTabKey] = useState('dailyViewApp');
    const [activeNewApp, setActiveNewApp] = useState(true);
    const navigate = useNavigate();

    const userInfo = useSelector(getUserInfo);
    // console.log(userInfo);
    const isAuthenticated = userInfo?.userId ? true : false;
    if(!isAuthenticated) navigate('/login');

    useEffect(() => {
        // const getAllPatient = async () => {
        //     const getPateint = await patientApi.patientList();
        //     setPatients(getPateint);
        // }

        const getAllPatient = async () => {
            const resp = await PatientdataAPI.getPatientList();
            // console.log(resp);
        }

        const getAllPayer = async () => {
            const getPayer = await patientApi.payerList();
            setPayerList(getPayer);
        }

        getAllPatient();
        getAllPayer();
    }, []);

    const tabClick = (e) => {
        setActiveTabKey(e);
    }


    const editApp = async (r) => {
        // console.log(r)
        const resp = await appointmentAPI.getAppointmentId(r.data.id);
        console.log(resp)
        setIsEditmode(true);
        setIsEditApp(resp[0]);
        setActiveTabKey('addNewAppointmentMenu');
    }

    const items = [
        {
            label: 'Add New Appointment',
            key: 'addNewAppointmentMenu',
            children: <AppointmentFrom
                payerList={payerList}
                patients={patients}
                isEditmode={isEditmode}
                isEditApp={isEditApp}
                userInfo={userInfo}
            />,
        },
        {
            label: 'Search',
            key: 'searchAppointmentMenu',
            children: <SearchPatient editApp={editApp} />,
        },
        {
            label: 'Daily View',
            key: 'dailyViewApp',
            children: <DailyViewAppointment
                payerList={payerList}
                patients={patients} editApp={editApp} />,
        },
        {
            label: 'Weekly View',
            key: 'weeklyViewApp',
            children: <WeeklyViewAppointment />,
        },
        {
            label: 'Monthly View',
            key: 'monthlyViewApp',
            children: <MonthlyView />,
        },
        // {
        //     label: 'Option',
        //     key: 'OptionViewApp',   
        //     children: <OptionView />,
        // },
        // {
        //     label: 'Special Event',
        //     key: 'SpecialEventViewApp',
        //     children: <SpecialEvent />,
        // },
        // {
        //     label: 'Office Daily Message',
        //     key: 'officeDailyMessage',
        //     children: <OfficeDaily />,
        // },
    ];
    const newPatient = () => {
        navigate('/managepatient');
    };

    const tabButn = (
        <Button onClick={() => newPatient()}>
            <UserAddOutlined /> New Patient
        </Button>
    )


    const dispatch = useDispatch();
    dispatch(headerName(`Appointment Schedule`));


    return (
        <>
            {activeNewApp ? (<AppointmentMenu payerList={payerList}
                patients={patients} items={items} activeTabKey={activeTabKey}
                tabClick={tabClick} tabButn={tabButn} />)
                : (<AppointmentFrom
                    payerList={payerList}
                    patients={patients}
                    isEditmode={isEditmode}
                    isEditApp={isEditApp}
                />)}
        </>
    );
}

export default AppointmentBook;
