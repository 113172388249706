import { Button, Col, DatePicker, Divider, Form, Input, Row, Select, Space, Typography } from "antd"
import './style.scss'
import moment from "moment";
import { useEffect, useState } from "react";
import { DashOutlined, EditOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
import InsuranceAPI from "./insurance.api";
import patientApi from "../patient/hook/patient.api";
import { useDispatch } from "react-redux";
import { headerName } from "../../layout/headerName.hook";
import InsuranceListModal from "../../components/insuranceList.modal";
import dayjs from "dayjs";
import Swal from "sweetalert2";

const { Title } = Typography;
const { Option } = Select;

const NewInsurance = () => {
    const [form] = Form.useForm();
    const [searchParams] = useSearchParams();
    const [cities, setCities] = useState();
    const [stateList, setStateList] = useState();
    const [insurancedocid, setInsurancedocid] = useState();
    const [mode, setMode] = useState();
    const [insuranceData, setInsuranceData] = useState();
    const [ispayerModal, setIspayerModal] = useState(false);
    const [payerList, setPayerList] = useState([]);
    const [updatefromList, setUpdatefromList] = useState({});
    const [insuranceType, setInsuranceType] = useState('')
    const dispatch = useDispatch();
    dispatch(headerName(`Insurance`));

    const InsurnType = [
        "Medicare",
        "Medicaid",
        "Tricare Champus",
        "ChampVA",
        "Group Health Plan",
        "Feca Blk Lung",
        "Other",
        "Commercial",
        "Self-Pay",
        "Workers Comp",
        "Personal Injury",
        "Auto",
        "EAP"]

    useEffect(() => {
        const pId = searchParams.get('id');
        const pmode = searchParams.get('mode');
        setInsurancedocid(pId)
        setMode(pmode === 'edit');
        if (pId) {
            const getFacility = async (id) => {
                const resp = await InsuranceAPI.getInsurancebyId(id);
                console.log(resp);
                setInsuranceData(resp[0]);
            }
            getFacility(pId)
        }

    }, []);

    const handlepayerCancel = () => {
        setIspayerModal(false);
    }
    const handlepayerOk = (type) => {
        setInsuranceType(type)
        setIspayerModal(true);
    }

    const onPullpayer = (r) => {
        console.log(r, insuranceType);
        setIspayerModal(false);
        if(insuranceType === 'payer'){
            const data = {
                insuranceIcd10DOS: dayjs('10/01/2015', 'MM/DD/YYYY'),
                insuranceName: r.PayerName,
                insurancePayerId: r.PayerID,
                insuranceState: r.State,
                insuranceType: r.PayerType,
                payerListId: r._id
            }
            setUpdatefromList({...data});
        }
        if(insuranceType === 'Eligibility'){
            const data = {
                InsuranceEligBenPayer: r.PayerName
            }
            setUpdatefromList({...data});
        }
    }

    useEffect(() => {
        form.setFieldsValue(updatefromList);
    }, [updatefromList, form])

    const pullMorePayer = (r) => {
        console.log(r);
    }

    const onFinish = async (v) => {
        const payload = {
            ...v,
            insuranceIcd10DOS: v?.insuranceIcd10DOS?.format('MM/DD/YYYY'),
            insuranceId: new Date().getTime(),
            createBy: 'admin',
            createTime: new Date().getTime(),
            createDate: moment().format('MM/DD/YYYY'),
        }
        console.log(payload);

        if (mode) {
            const data = {
                ...payload,
                _id: insurancedocid,
            }
            const resp = await InsuranceAPI.updateInsurance(data);
            console.log(resp);
            Swal.fire({
                title: 'Success!',
                text: 'Facility updated Successfully!',
                icon: 'success',
                confirmButtonText: 'Okay',
                confirmButtonColor: '#005154'
            });
            form.resetFields();
        } else {
            const resp = await InsuranceAPI.createInsurance(payload);
            console.log(resp);
            Swal.fire({
                title: 'Success!',
                text: 'Facility created Successfully!',
                icon: 'success',
                confirmButtonText: 'Okay',
                confirmButtonColor: '#005154'
            });
            form.resetFields();
        }
    }

    const onState = async (v) => {
        const cityList = await patientApi.cityList(v);
        setCities(cityList);
    }
    useEffect(() => {
        const getStateList = async () => {
            const stateLists = await patientApi.stateList();
            setStateList(stateLists);
        }
        getStateList();

        const getPayerList = async () => {
            const resp = await InsuranceAPI.getAllPayer();
            console.log(resp);
            setPayerList(resp);
        }
        getPayerList();
    }, []);

    useEffect(() => {
        const pId = searchParams.get('id');
        const pmode = searchParams.get('mode');
        setInsurancedocid(pId)
        setMode(pmode === 'edit');
        if (pId) {
            const getbInsuranceId = async (id) => {
                const resp = await InsuranceAPI.getInsurancebyId(id);
                console.log(resp);
                const d = {
                    ...resp[0],
                    insuranceIcd10DOS : dayjs(resp[0]?.insuranceIcd10DOS)
                }
                setInsuranceData(d);
                form.setFieldsValue(d);
            }
            getbInsuranceId(pId)
        }

    }, [form, searchParams]);

    return (
        <>
            <div className="providerFormWrapper">
            <Row>
                <Col span={24}>
                        <Title level={4} style={{marginTop: '10px'}}> 
                            Insurance</Title>
                            <Divider style={{margin: '8px 0'}}/>
                    </Col>
                </Row>
                <Form layout="vertical"
                    initialValues={{}}
                    className="providerForm"
                    onFinish={onFinish}
                    form={form} >
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="insuranceCoId" label="Insurance Co. Id">
                                <Input disabled size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item name="insuranceName" label="Insurance Name" 
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a Insurance name!',
                                },
                            ]}>
                                <Input size="small" disabled />
                            </Form.Item>
                        </Col>
                        <Col span={1} className="mt22">
                            <span className="iconSearch" onClick={() => handlepayerOk('payer')}><DashOutlined /></span>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="insurancePayerId" label="Payer ID"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a payer id!',
                                },
                            ]} >
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="insuranceAddress" label="Address">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="insuranceAddressline2" label="Address line 2">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="insuranceState" label="State"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a state!',
                                },
                            ]}>
                                <Select showSearch size="small"
                                    optionFilterProp="children"
                                    onSelect={onState}
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                    {stateList?.map((s) => <Option key={s.id} value={s.state_code}>{s.state_code}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="insuranceCity" label="City">
                                <Select showSearch size="small"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                    {cities?.map((s) => <Option key={s.id} value={s.name}>{s.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="insuranceZip" label="Zip">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="insuranceContactPersion" label="Contact Person">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="insuranceEmail" label="Email">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="insurancePhone" label="Phone">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="insuranceFax" label="Fax">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="insuranceType" label="Insurance Type">
                                <Select showSearch size="small"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                    {InsurnType?.map((s) => <Option key={s} value={s}>{s}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="insuranceBilling" label="Type" 
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a type!',
                                },
                            ]}>
                                <Select showSearch size="small">
                                    <Select.Option value="EDI">EDI</Select.Option>
                                    <Select.Option value="Paper">Paper</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={6}>
                        <Col span={8}>
                            <Form.Item name="InsuranceEligBenPayer" label="Eligibility & Benefit Payer Name">
                                <Input size="small" disabled />
                            </Form.Item>
                        </Col>
                        <Col span={1} className="mt22">
                            <span className="iconSearch"><DashOutlined onClick={() => handlepayerOk('Eligibility')}/></span>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={6}>
                            <Form.Item name="insuranceIcd10DOS" label="Default to ICD-10 On/For DOS"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a DoS!',
                                },
                            ]}>
                                <DatePicker format={'MM/DD/YYYY'} size="small" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={6} justify="end" style={{ marginTop: '30px' }}>
                        <Col span={4}>
                            <Button htmlType="submit" className="btnPrimary"> {mode ? 'Update' : 'Submit'} </Button>
                        </Col>
                    </Row>

                </Form>
                {ispayerModal && (
                    <InsuranceListModal
                        ispayerModal={ispayerModal}
                        handlepayerCancel={handlepayerCancel}
                        payerList={payerList}
                        modalTitle={'Payer Search'}
                        onPullpayer={onPullpayer}
                        pullMorePayer={pullMorePayer} />
                )

                }
            </div>
        </>
    )
};

export default NewInsurance;