import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Form, Row, Select, Tabs, Modal } from "antd";
import axios from "axios";
import moment from "moment";
import NewPatientVisit from "../newpatientvisit";
import BillingInfoForm from "../BillingInfoForm";
import BillingOption from "../BillingOption";
import AddNewPatient from "../addNewPatient";
import { useDispatch, useSelector } from "react-redux";
import patientApi from "../hook/patient.api";
import { getPatients } from "../hook/patient.hook";
import { useNavigate, useSearchParams } from "react-router-dom";
import appointmentAPI from "../../appointment/appointment.hook";
import CPTModal from "./cptModal";
import ProviderModel from "../../../components/providerModel";
import { PatientdataAPI } from "../../patientmanagement/patientData.api";
import dayjs from "dayjs";
import ProviderAPI from "../../provider/provider.api";
import IcdModal from "../../../components/icdcode.modal";
import BillingProviderAPI from "../../billingProvider/billingprovider.api";
import BillingProviderModal from "../../../components/billingProvider.modal";
import FacilityModal from "../../../components/facility.modal";
import facilityAPI from "../../facility/facility.api";
import IcdcodeAPI from "../../../utilities/datalist/icdcode.api";
import CptcodeAPI from "../../../utilities/datalist/cptcode.api";
import Swal from "sweetalert2";


const { Option } = Select;

const PatientVisitSubMenu = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [patientList, setPatientList] = useState([]);
    const [payerlists, setPayerlists] = useState([]);
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [visitFormValue, setVisitFormValue] = useState({});
    const [stateList, setStateList] = useState([]);
    const [cptList, setCptList] = useState([]);
    const [providerList, setProviderList] = useState([]);
    const [searchParams] = useSearchParams();
    const [appDetails, setAppDetails] = useState([]);
    const [patientDetail, setPatientDetail] = useState({});
    const [dataIcd9, setDataIcd9] = useState([]);
    const [icdFields, setIcdFields] = useState([]);
    const [icd10Fields, setIcd10Fields] = useState([]);
    const [prodVal, setProdVal] = useState({});
    const [iscptModal, setIscptModal] = useState(false);
    const [fdsname, setFdsname] = useState({});
    const [selIcdField, setSelIcdField] = useState();
    const [isprovdModal, setIsprovdModal] = useState(false);
    const [popProvd, setPopProvd] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [total, setTotal] = useState(0);
    const [isPopIcdModal, setIsPopIcdModal] = useState(false);
    const [icdCodeList, setIcdCodeList] = useState([])
    const [bpList, setBpList] = useState([]);
    const [isPrimaryinsurance, setIsPrimaryinsurance] = useState(false);

    const [fList, setFList] = useState([]);
    const [isbpModal, setIsbpModal] = useState(false);
    const [isfModal, setIsfModal] = useState(false);

    useEffect(() => {
        const data = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'];
        let arry = []
        for (var i = 0; i < 12; i++) {
            let dt =
            {
                name: data[i],
                iv: `(${i + 1})`,
            }
            arry.push(dt);
        }
        setDataIcd9(arry);
    }, []);

    const onSearch = async (e, i, d) => {
        if (e.length > 2) {
            const resp = await patientApi.getcptcode(e);
        }
    }


    const onSel = (e, i, d) => {
        if(e.target.value){
            const selIcd = icdCodeList?.filter((c) => c.code === e.target.value);
            console.log(selIcd);
            if(selIcd.length === 0){
                Swal.fire({
                    title: 'Error!',
                text: 'Enter diagnosis code is invalid, Please enter valid code!',
                icon: 'error',
                confirmButtonText: 'Okay',
                confirmButtonColor: '#005154'
                })
            }
            let getField = form.getFieldValue('icd9');
            getField[i].desp = selIcd[0]?.desp;
            setIcdFields(getField);
        }
    };

    const descClear = (i) => {
        let guests = form.getFieldValue('icd9');
        guests[i].desp = '';
        guests[i].code = '';
        setIcdFields(guests);
    }

    useEffect(() => { form.setFieldValue({ icdFields }) }, [icdFields])

    const setpatientformvalue = (patientInfo, apps, getAppinfo) => {
        setIsPrimaryinsurance(patientInfo[0]?.patientPrimaryInsuranceRel ==='Yes') 
        setVisitFormValue({
            ...visitFormValue,
            visitpatientFirstName: patientInfo[0]?.patientFirstName,
            visitpatientLastName: patientInfo[0]?.patientLastName,
            visitpatientDob: patientInfo[0]?.patientDob,
            visitpatientId: patientInfo[0]?.patientId,
            visitpatientMiddleName: patientInfo[0]?.patientMiddleName,
            visitpatientSex: patientInfo[0]?.patientSex,
            visitpatientAddressPhoneCell: patientInfo[0]?.patientCellPhone,
            visitpatientAddress: patientInfo[0]?.patientAddress,
            visitpatientAddressCity: patientInfo[0]?.patientCity,
            visitpatientAddressState: patientInfo[0]?.patientState,
            visitpatientAddressZipCode: patientInfo[0]?.patientZip,
            visitpatientAddressline2: patientInfo[0]?.patientAddress2,

            visitDate: dayjs(apps?.appointmentVisitDate),
            visitReason: apps?.appointmetReason,
            visitProviderFirstName: getAppinfo?.providerFirstName,
            visitProviderID: getAppinfo?.providerId,
            visitProviderLastName: getAppinfo?.providerLastName,
            visitProviderOffice: getAppinfo?.providerAddNewFacility,

            insuranceId: patientInfo[0]?.patientPrimaryInsuranceId,
            insuranceName: patientInfo[0]?.patientPrimaryInsuranceName,
            insurancePatientRelation: patientInfo[0]?.patientPrimaryInsuranceRel,
            insuranceLastName: patientInfo[0]?.patientPrimaryInsurancedLastName,
            insuranceFirstName: patientInfo[0]?.patientPrimaryInsurancedFirstName,
            insuranceMiddleName: patientInfo[0]?.patientPrimaryInsurancedMiddleName,
            insurancePrimary: patientInfo[0]?.patientPrimaryInsurancedID,
            insuranceSubscriberId: patientInfo[0]?.patientPrimaryInsurancedSubscribeId,
            insuranceGroupId: patientInfo[0]?.patientPrimaryInsurancedGroupNo,
            insurancePlanName: patientInfo[0]?.patientPrimaryInsurancedPlanName,

            insuranceSecondaryFirstName: patientInfo[0]?.patientSecondaryInsurancedFirstName,
            insuranceSecondaryGroupId: patientInfo[0]?.patientSecondaryInsurancedGroupNo,
            insuranceSecondaryLastName: patientInfo[0]?.patientSecondaryInsurancedLastName,
            insuranceSecondaryMiddleName: patientInfo[0]?.patientSecondaryInsurancedMiddleName,
            insuranceSecondaryName: patientInfo[0]?.patientSecondaryInsuranceName,
            insuranceSecondaryPlanName: patientInfo[0]?.patientSecondaryInsurancedPlanName,
            insuranceSecondarySubscriberId: patientInfo[0]?.patientSecondaryInsurancedSubscribeId,
            insurancePatientSecondaryRelation: patientInfo[0]?.patientSecondaryInsuranceRel,
            insurancesecondaryId: patientInfo[0]?.patientSecondaryInsuranceId,
        });
    }

    const getPatientDetails = async (patientData, apps, getAppinfo) => {
        // const patientData = await patientApi.getPatient(id);
        // console.log('patientData', patientData);
        setPatientDetail(patientData);
        setpatientformvalue(patientData, apps, getAppinfo);
    };

    const getProviderDetails = (plist) => {
        if (plist?.length > 0 && appDetails) {
            const p = plist?.filter((d) => {
                return d.providerNPI === appDetails[0]?.appointmenPatientProvider
            });
            console.log(p);
            if (p[0]) {
                const pProd = {
                    visitProviderID: p[0]?.providerNPI,
                    visitProviderFirstName: p[0]?.providerFirstName,
                    visitProviderLastName: p[0]?.providerLastName,
                    visitProviderOffice: appDetails[0]?.appointmentoffice,
                    visitProviderNote: appDetails[0]?.appointmentPatientNote
                }
                setProdVal(pProd)
            }
        }
    }

    useEffect(() => {
        form.setFieldsValue(prodVal);
    }, [prodVal, form])

    // useEffect(() => {
    //     getProviderDetails(providerList);
    // }, [appDetails, providerList])

    useEffect(() => {
        const appId = searchParams.get('appId');
        if (appId) {
            const getAppiontments = async (id) => {
                const appDetails = await appointmentAPI.getAppointmentId(id);
                if (appDetails[0]?.appointmentPatientId) {
                    const queryPId = parseInt(appDetails[0]?.appointmentPatientId);
                    const qury = {
                        patientId: queryPId
                    }
                    // console.log(qury);
                    const resp = await PatientdataAPI.getPatientbyQuery(qury);
                    // console.log(resp);
                    const obj = { providerNPI: appDetails[0]?.appointmenPatientProvider };
                    const getAppinfo = await ProviderAPI.queryProvider(obj);
                    setAppDetails(appDetails);
                    getPatientDetails(resp, appDetails[0], getAppinfo[0]);
                }

            }
            getAppiontments(appId);
        }
    }, [searchParams]);

    const handleprovd = (title) => {
        setModalTitle(title);
        setPopProvd(title);
        setIsprovdModal(true);
    }

    const handleprovdCancel = () => {
        setIsprovdModal(false);
    }

    const del =(i) =>{
        let frm = form.getFieldsValue();
        for(let key in frm?.icdcode[i]){
            if(key === 'modified')
            frm.icdcode[i][key] = ['','','',''];
            else
            frm.icdcode[i][key] = '';
        }
        form.setFieldsValue(frm);
    }

    const addNewPatientVisit = async (v) => {
        const icodeUpdate = v.icdcode?.map((d) => {
            d = {
                ...d,
                fromDate: d.fromDate ? d.fromDate.format('MM/DD/YYYY') : '',
                toDate: d.toDate ? d.toDate.format('MM/DD/YYYY') : '',
            }
            return d
        });

        const payload = {
            ...v,
            icdcode: icodeUpdate,

            visitDate: v.visitDate ? v.visitDate.format('MM/DD/YYYY') : '',
            visitpatientDob: v.visitpatientDob ? v.visitpatientDob.format('MM/DD/YYYY') : '',
            billingOptiondateAccidentDate: v.billingOptiondateAccidentDate ? v.billingOptiondateAccidentDate.format('MM/DD/YYYY') : '',
            billingOptiondateActueManifest: v.billingOptiondateActueManifest ? v.billingOptiondateActueManifest.format('MM/DD/YYYY') : '',
            billingOptiondateAssumedCareDate: v.billingOptiondateAssumedCareDate ? v.billingOptiondateAssumedCareDate.format('MM/DD/YYYY') : '',
            billingOptiondateCompensationWorked: v.billingOptiondateCompensationWorked ? v.billingOptiondateCompensationWorked.format('MM/DD/YYYY') : '',
            billingOptiondateConsultationDate: v.billingOptiondateConsultationDate ? v.billingOptiondateConsultationDate.format('MM/DD/YYYY') : '',
            billingOptiondateDateAuth: v.billingOptiondateDateAuth ? v.billingOptiondateDateAuth.format('MM/DD/YYYY') : '',
            billingOptiondateEstDelivery: v.billingOptiondateEstDelivery ? v.billingOptiondateEstDelivery.format('MM/DD/YYYY') : '',
            billingOptiondateHopstialto: v.billingOptiondateHopstialto ? v.billingOptiondateHopstialto.format('MM/DD/YYYY') : '',
            billingOptiondateHospitalfrom: v.billingOptiondateHospitalfrom ? v.billingOptiondateHospitalfrom.format('MM/DD/YYYY') : '',
            billingOptiondateInitialTreatment: v.billingOptiondateInitialTreatment ? v.billingOptiondateInitialTreatment.format('MM/DD/YYYY') : '',
            billingOptiondateLastMenstrual: v.billingOptiondateLastMenstrual ? v.billingOptiondateLastMenstrual.format('MM/DD/YYYY') : '',
            billingOptiondateLastSeen: v.billingOptiondateLastSeen ? v.billingOptiondateLastSeen.format('MM/DD/YYYY') : '',
            billingOptiondateLastWorked: v.billingOptiondateLastWorked ? v.billingOptiondateLastWorked.format('MM/DD/YYYY') : '',
            billingOptiondateLastXrayDate: v.billingOptiondateLastXrayDate ? v.billingOptiondateLastXrayDate.format('MM/DD/YYYY') : '',
            billingOptiondateOnset: v.billingOptiondateOnset ? v.billingOptiondateOnset.format('MM/DD/YYYY') : '',
            billingOptiondatePrescriptionDate: v.billingOptiondatePrescriptionDate ? v.billingOptiondatePrescriptionDate.format('MM/DD/YYYY') : '',
            billingOptiondateRelinquishedCareDate: v.billingOptiondateRelinquishedCareDate ? v.billingOptiondateRelinquishedCareDate.format('MM/DD/YYYY') : '',
            billingOptiondateSimilar: v.billingOptiondateSimilar ? v.billingOptiondateSimilar.format('MM/DD/YYYY') : '',
            billingOptiondateunableWorkedfrom: v.billingOptiondateunableWorkedfrom ? v.billingOptiondateunableWorkedfrom.format('MM/DD/YYYY') : '',
            billingOptiondateunableWorkedto: v.billingOptiondateunableWorkedto ? v.billingOptiondateunableWorkedto.format('MM/DD/YYYY') : '',
            creatAt: new Date(),
            createUser: 'admin',
            createtime: new Date().getTime(),
            update: '',
            patientvisitId: new Date().getTime(),

        };
        setConfirmLoading(true);
        const resp = await patientApi.createPatientVisit(payload);
        console.log(resp);
        Swal.fire({
            title: 'Success!',
            text: `Patient create successfully`,
            icon: 'success',
            confirmButtonText: 'Okay',
            confirmButtonColor: '#005154'
        })
        form.resetFields();
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
        }, 2000);
    }

    const onPullprovider = async (key, value, type) => {
        const val = form.getFieldsValue();
        console.log(key);
        console.log(type);
        setIsprovdModal(false);
        setIsbpModal(false);
        setIsfModal(false);
        if (type === 'visitProviderID') {
            if (key) {
                const pProd = {
                    visitProviderID: key?.providerNPI,
                    visitProviderFirstName: key?.providerFirstName,
                    visitProviderLastName: key?.providerLastName,
                    visitProviderOffice: appDetails?.appointmentoffice,
                    visitProviderNote: appDetails?.appointmentPatientNote
                }
                setProdVal(pProd)
            }
        }
        if (type === 'RefferingPhysician') {
            if (key) {
                const lProd = {
                    billingRefferingPhysician: key?.providerNPI,
                    billingFirstname: key?.providerFirstName,
                    billinMiddlename: key?.providerMiddleName,
                    billingLastname: key?.providerLastName,
                    billingPhysicicanID: key?.providerGroupNPI || key?.providerNPI,
                    billingPhysicicanNpi: key?.providerNPI,
                }
                setProdVal(lProd)
            }
        }
        if (type === 'billingSupervisingPhysician') {
            const p = providerList?.filter((d) => key.providerNPI === d.providerNPI);
            if (p[0]) {
                const lProd = {
                    billingSupervisingPhysician: key?.providerNPI,
                    billingSupervisingFirstname: key?.providerFirstName,
                    billinSupervisingMiddlename: key?.providerMiddleName,
                    billingSupervisingLastname: key?.providerLastName,
                    billingSupervisingPhysicicanID: key?.providerGroupNPI || key?.providerNPI,
                    billingSupervisingPhysicicanNpi: key?.providerNPI,
                }
                setProdVal(lProd)
            }
        }
        if (type === 'OderingPhysician') {
            if (key) {
                const lProd = {
                    billingOderingPhysician: key?.providerNPI,
                    billingOderingFirstname: key?.providerFirstName,
                    billinOderingMiddlename: key?.providerMiddleName,
                    billingOderingLastname: key?.providerLastName,
                    billingOderingPhysicicanID: key?.providerGroupNPI || key?.providerNPI,
                    billingPOderinghysicicanNpi: key?.providerNPI
                }
                setProdVal(lProd)
            }
        }

        if (type === 'facility') {
            if (key) {
                const lProd = {
                    billingOptionFacilityProID: key?.facility,
                    billingOptionFacilityName: `${key?.facilityName}`,
                    billingOptionFacilityAddress2: key?.facilityAddressline2,
                    billingOptionFacilityState: key?.facilityState,
                    billingOptionFacilityCity: key?.facilityCity,
                    billingOptionFacilityAddress: key?.facilityCity,
                    billingOptionFacilityId: key?.facilityOrganizationalNPI,
                    
                    billingOptionFacilityNPI: key?.facilityOrganizationalNPI,
                    billingOptionFacilityZip: key?.facilityZip

                }
                setProdVal(lProd)
            }
        }

        if (type === 'billingProvider') {
            if (key) {
                const lProd = {
                    billingOptionBProviderID: key?.billingProviderId,
                    billingOptionBProviderName: `${key?.billingProvidefirstname} ${key?.billingProvideMiddlename} ${key?.billingProvideLastname}`,
                    billingOptionBProviderAddress2: key.billingProvideAdress2,
                    billingOptionBProviderState: key?.billingProvideState,
                    billingOptionBProviderCity: key?.billingProvideCity,
                    billingOptionBProviderAddress: key?.billingProvideAddress,
                    billingOptionBProviderGroupId: key?.billingProvideGroupNo,
                    billingOptionBProviderNPI: key?.billingProvideOrganizationalNPI,
                    billingOptionBProviderZip: key?.billingProvideZip,
                    billingOptionBProviderTaxID: key?.billingProvideTaxId,
                    billingProvidePhonenumber: key?.billingProvidePhonenumber,
                    billingProvideFaxnumber: key?.billingProvideFaxnumber,
                }
                setProdVal(lProd)
            }
        }

        setIsprovdModal(false);
    };

    useEffect(() => {
        form.setFieldsValue({
            ...visitFormValue,
            visitpatientFirstName: visitFormValue?.visitpatientFirstName,
            visitpatientLastName: visitFormValue?.visitpatientLastName,
            visitpatientDob: moment(visitFormValue?.visitpatientDob, 'MM/DD/YYYY'),
            visitpatientId: visitFormValue?.visitpatientId,
            visitpatientMiddleName: visitFormValue?.visitpatientMiddleName,
            visitpatientSex: visitFormValue?.visitpatientSex,
            visitpatientAddressPhoneCell: visitFormValue?.visitpatientAddressPhoneCell,
            visitpatientAddress: visitFormValue?.visitpatientAddress,
            visitpatientAddressline2: visitFormValue?.visitpatientAddressline2,
            visitpatientAddressCity: visitFormValue?.visitpatientAddressCity,
            visitpatientAddressState: visitFormValue?.visitpatientAddressState,
            visitpatientAddressZipCode: visitFormValue?.visitpatientAddressZipCode,
            // insuranceId: visitFormValue?.insuranceId,
            // insuranceName: visitFormValue?.insuranceName,
            // insurancePatientRelation: visitFormValue?.insurancePatientRelation,
            // insuranceLastName: visitFormValue?.insuranceLastName,
            // insuranceFirstName: visitFormValue?.insuranceFirstName,
            // insuranceMiddleName: visitFormValue?.insuranceMiddleName,
            // insurancePrimary: visitFormValue?.insurancePrimary,
            // insuranceSubscriberId: visitFormValue?.insuranceSubscriberId,
            // insuranceGroupId: visitFormValue?.insuranceGroupId,
            // visitProviderOffice: visitFormValue?.appointmentoffice,
            // visitProviderNote: visitFormValue?.appointmentPatientNote
        });
    }, [visitFormValue, form]);

    const getPhoneNumber = (isd, ext, numb) => {
        return `${isd}-${ext}-${numb}`;
    }


    const selectPatient = (d) => {
        const patientInfo = patientList?.filter((p) => p.patientId === d);
        setpatientformvalue(patientInfo);
    }

    const calcTotal = (e, i, d, h) => {
        let currVal = form.getFieldValue('icdcode');
        currVal[i].balance = parseInt(currVal[i].lineCharge);
        setTotal(total + currVal[i].balance);
        // setIcd10Fields({
        //     total: total
        // })
    }

    const newPatient = () => {
        navigate('/managepatient');
    }

    useEffect(() => { form.setFieldsValue({ total }); }, [total]);

    const showModal = () => {
        setOpen(true);
    };
    const handleOk = async (v) => {
        console.log(v)
        if(!v[0]?.fromDate && v[0]?.toDate){
            Swal.fire({
                title: 'Warning!',
                text: 'Please enter CPT code',
                icon: 'warning',
                confirmButtonText: 'Okay',
                confirmButtonColor: '#005154'
            });
            return false;
        }
        const data = {
            ...v,
            insuranceEffStartDate: v.insuranceEffStartDate ? dayjs(v.insuranceEffStartDate).format('MM/DD/YYYY') : '',
            insuranceEffToDate: v.insuranceEffToDate ? dayjs(v.insuranceEffToDate).format('MM/DD/YYYY') : '',
            patientDob: v.patientDob ? dayjs(v.patientDob).format('MM/DD/YYYY') : '',
        }
        try {
            const resp = await axios.post('http://localhost:3001/api/patient', data);
            form.resetFields();
            setOpen(false);
            return resp.data;
        } catch (error) {
            console.log(error.response); // this is the main part. Use the response property from the error object
            return error.response;
        }
    };

    const handleCancel = () => {
        form.resetFields();
        setOpen(false);
    };

    const handlefCancel = () => {
        setIsfModal(false);
    }

    const handlebpCancel = () => {
        setIsbpModal(false);
    }

    const handlecptCancel = () => {
        setIscptModal(false);
    }

    const handleCpt = () => {
        console.log('handleCpt');
    }

    const searchcpt = (n) => {
        setIscptModal(true);
        setFdsname({
            name: 'icd9',
            index: n,
            subname: ''
        })
    }

    const popIcd = (n, i, s) => {
        setIsPopIcdModal(true);
    }
    const handleIcdCancel = () => {
        handleIcdCancel(true)
    }

    const popUp = () => {
        setIsbpModal(true);
    }
    const popUpfacility = () => {
        setIsfModal(true);
    }

    const onPullIcdCode = (r) => {
        console.log(r);
    }

    const searchProvd = (id, type, title) => {
        setModalTitle(title);
        setPopProvd(type);
        setIsprovdModal(true);
    }

    const updateCpt = (field, key, obj) => {
        console.log(field, key, obj);
        if (field.name === 'icd9') {
            let geticdFields = form.getFieldsValue('icd9');
            geticdFields['icd9'][field.index].code = key.code;
            geticdFields['icd9'][field.index].desp = key.desp;
            setSelIcdField(geticdFields);
            setIscptModal(false);
        }
    }

    useEffect(() => { form.setFieldsValue({ ...selIcdField }) }, [selIcdField, form])

    useEffect(() => {
        const getPatientList = async () => {
            const patientList = await PatientdataAPI.getPatientList();
            setPatientList(patientList);
        }
        const getpayerList = async () => {
            const payers = await patientApi.payerList();
            setPayerlists(payers);
        }
        const getStateList = async () => {
            const stateLists = await patientApi.stateList();
            setStateList(stateLists);
        }
        const getcptList = async () => {
            const cptLists = await CptcodeAPI.getCptcode();
            setCptList(cptLists);
        }
        const getProvider = async () => {
            const ProviderLists = await patientApi.providerList();
            setProviderList(ProviderLists);
            // getProviderDetails(ProviderLists)
        }

        const geticd = async () => {
            const getAllIcd = await IcdcodeAPI.getIcdcode();
            setIcdCodeList(getAllIcd);
        }

        const getBillingProvider = async () => {
            const resp = await BillingProviderAPI.getBillingProvider();
            setBpList(resp)
        }


        const getFacility = async () => {
            const resp = await facilityAPI.facilityList();
            setFList(resp)
        }

        getFacility();
        getBillingProvider();
        getPatientList();
        getpayerList();
        getStateList();
        getcptList();
        getProvider();
        geticd();
    }, []);

    const subItemmenu = [
        {
            label: 'Visit Info',
            key: 'visitInfo',
            children: <NewPatientVisit isPrimaryinsurance={isPrimaryinsurance} showModal={showModal} selectPatient={selectPatient}
                patientList={patientList} stateList={stateList} providerList={providerList}
                cptList={cptList} onPullprovider={onPullprovider} newPatient={newPatient} searchProvd={searchProvd} handleprovd={handleprovd} />,
        },
        {
            label: 'Billing Info',
            key: 'billingInfo',
            children: <BillingInfoForm cptList={cptList} onSearch={onSearch}
                calcTotal={calcTotal} from={form} dataIcd9={dataIcd9} icdCodeList={icdCodeList}
                descClear={descClear} onSel={onSel} searchicd={searchcpt} popIcd={popIcd} del={del} />,
        },
        {
            label: 'Billing Option',
            key: 'billingOption',
            children: <BillingOption onPullprovider={onPullprovider}
                searchProvd={searchProvd} popUp={popUp} popUpfacility={popUpfacility} />,
        }
    ];

    return (
        <>
            <Form form={form} className="visitForm"
                name="visitFrom"
                layout="vertical"
                initialValues={{}}
                onFinish={addNewPatientVisit}>
                <Tabs
                    defaultActiveKey="1"
                    type="card"
                    items={subItemmenu}
                />
                <Row>
                    <Divider />

                    <Col span={22}>
                        <Row justify="end" >
                            <Col span={4}>
                                <Button type="primary" htmlType="submit" className="btnPrimary">Submit</Button>
                            </Col>
                            <Col span={4}>
                                <Button type="default" className="btnDefault">Cancel</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row >
            </Form>
            <>
                <AddNewPatient open={open} handleOk={handleOk} newPatient={newPatient} stateList={stateList}
                    confirmLoading={confirmLoading} handleCancel={handleCancel} searchProvd={searchProvd} providerList={providerList} />
            </>
            <>
                {iscptModal && (
                    <CPTModal iscptModal={iscptModal} fdsname={fdsname} updateCpt={updateCpt} handleCpt={handleCpt} handlecptCancel={handlecptCancel} icdCodeList={icdCodeList} />

                )}
            </>
            <>
                {isprovdModal && (
                    <ProviderModel
                        modalTitle={modalTitle}
                        isprovdModal={isprovdModal}
                        handleprovd={handleprovd}
                        handleprovdCancel={handleprovdCancel}
                        handlecptCancel={handlecptCancel}
                        onPullprovider={onPullprovider}
                        providerList={providerList}
                        popProvd={popProvd}
                    />

                )}

                {isPopIcdModal && (
                    <IcdModal
                        isPopIcdModal={isPopIcdModal}
                        modalTitle="ICD code"
                        handleIcdCancel={handleIcdCancel}
                        onPullIcdCode={onPullIcdCode}
                        icdCodeList={icdCodeList}
                    />
                )}

                {isbpModal && (
                    <BillingProviderModal
                        isbpModal={isbpModal}
                        modalTitle="Billing Provider"
                        handlebpCancel={handlebpCancel}
                        onPullbp={onPullprovider}
                        bpList={bpList}
                    />
                )}

                {isfModal && (
                    <FacilityModal
                        isfModal={isfModal}
                        modalTitle="Facilities"
                        handlefCancel={handlefCancel}
                        onPullf={onPullprovider}
                        fList={fList}
                    />
                )}

            </>
        </>
    );
};

export default PatientVisitSubMenu;