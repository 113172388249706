import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Table } from "antd";
import { useEffect, useState } from "react";


const ReferringProviderModal = ({
    isrpModal,
    handlerpCancel,
    rpList,
    modalTitle,
    onPullrp,
    selctedItm,
    pullMorerp }) => {
    const [filterpayer, setFilterpayer] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const rowSelection = (r, val) => {
        onPullrp(r, val, 'referringProvider');
    }

    const searchMore = (key, val) => {
        let Obj = {
            [key]: val,
        }
        setInputValue(Obj)
    }

    const searchPatient = (e, type) => {
        // console.log(e.target.value, type);
        const filterdata = rpList?.filter((d) => d[type].toLowerCase().includes(e.target.value));
        // console.log(filterdata);
        setFilterpayer(filterdata);
        searchMore(type, e.target.value)
    }

    const columns = [
        {
            title: 'Last Name',
            dataIndex: 'referringProvideLastname',
            key: 'referringProvideLastname',
            render: (txt, r) => (<p className="tdClick" onClick={() => rowSelection(r, txt)}>{txt}</p>)
        },
        {
            title: 'First Name',
            dataIndex: 'referringProvidefirstname',
            key: 'referringProvidefirstname'
        },
        {
            title: 'State',
            dataIndex: 'referringProvideState',
            key: 'referringProvideState'
        }, {
            title: 'City',
            dataIndex: 'referringProvideCity',
            key: 'referringProvideCity'
        },
        {
            title: 'Zip code',
            dataIndex: 'referringProvideZip',
            key: 'referringProvideZip'
        },
        {
            title: 'Phone',
            dataIndex: 'referringProvidePhonenumber',
            key: 'referringProvidePhonenumber'
        },
        {
            title: 'Tax ID',
            dataIndex: 'referringProvideTaxId',
            key: 'referringProvideTaxId'
        },
        {
            title: 'Individual NPI',
            dataIndex: 'referringProviderIndividualNPI',
            key: 'referringProviderIndividualNPI'
        }
    ]

    useEffect(() => {
        setFilterpayer(filterpayer);
    }, []);


    return (
        <Modal title={modalTitle} open={isrpModal} width={950} onOk={handlerpCancel} onCancel={handlerpCancel}>
            <div style={{ marginTop: '30px', display: 'inline-block', marginBottom: '20px', width: '200px' }}>
                <Input placeholder='Payer Name' suffix={<SearchOutlined />} name="PayerName" onChange={(e) => searchPatient(e, 'PayerName')} />
            </div>
            <div style={{ display: 'inline-block', margin: '30px 0px 20px 10px', width: '200px' }}>
                <Input placeholder='Payer ID' suffix={<SearchOutlined />} name="PayerID" onChange={(e) => searchPatient(e, 'PayerID')} />
            </div>
            <div style={{ display: 'inline-block', margin: '30px 0px 20px 10px', width: '200px' }}>
                <Button type="primary" onClick={() => pullMorerp(inputValue)} > Search more </Button>
            </div>

            <Table
                rowKey={(record) => record._id}
                onRow={(record) => ({
                    onClick: () => rowSelection(record),
                })}
                columns={columns}
                dataSource={filterpayer.length > 0 ? filterpayer : rpList }
                pagination={{
                    pageSize: 8
                }}
            />

        </Modal>
    )

}

export default ReferringProviderModal;