import { jwtDecode } from 'jwt-decode';
import Swal from 'sweetalert2';

const userValidate = (token) => {
    console.log(token);
    if (token) {
        const decoded = jwtDecode(token);
        console.log(decoded);
        return decoded;
    }

}

const errorHandler = (err) => {
    console.log(err);
    if (err) {
        Swal.fire({
            title: 'Error!',
            text: err?.response?.data?.message || 'Somthing went wrong!, Please contact administrator',
            icon: 'error',
            confirmButtonText: 'Okay',
            confirmButtonColor: '#005154'
        })
    } else {
        Swal.fire({
            title: 'Error!',
            text: err?.response?.data?.message || 'Somthing went wrong!, Please contact administrator',
            icon: 'error',
            confirmButtonText: 'Okay',
            confirmButtonColor: '#005154'
        })
    }
}



export { userValidate, errorHandler };