import { Button, Col, DatePicker, Divider, Form, Input, Row, Select, Space, Typography } from "antd"
import './style.scss'
import moment from "moment";
import { useEffect, useState } from "react";
import { EditOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
import patientApi from "../patient/hook/patient.api";
import facilityAPI from "./facility.api";
import { headerName } from "../../layout/headerName.hook";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
const { Title } = Typography;
const { Option } = Select;

const NewFacility = () => {
    const [form] = Form.useForm();
    const [searchParams] = useSearchParams();
    const [cities, setCities] = useState();
    const [stateList, setStateList] = useState();
    const [facilitydocid, setFacilitydocid] = useState();
    const [mode, setMode] = useState();
    const [facilityData, setFacilityData] = useState();

    const dispatch = useDispatch();
    dispatch(headerName(`Facility`));

    useEffect(() => {
        form.setFieldsValue({...facilityData});
    }, [facilityData])

    useEffect(() => {
        const pId = searchParams.get('id');
        const pmode = searchParams.get('mode');
        setFacilitydocid(pId)
        setMode(pmode === 'edit');
        if (pId) {
            const getFacility = async (id) => {
                const resp = await facilityAPI.getFacilitybyId(id);
                console.log(resp);
                setFacilityData(resp[0]);
            }
            getFacility(pId)
        }

    }, []);

    const onFinish = async (v) => {
        console.log(v);
        const payload = {
            ...v,
            facility: new Date().getTime(),
            createBy: 'admin',
            createTime: new Date().getTime(),
            createDate: moment().format('MM/DD/YYYY'),
        }
        console.log(payload);

        // console.log(payload);
        if (mode) {
            const data = {
                ...payload,
                _id: facilitydocid,
            }
            const resp = await facilityAPI.updateFacility(data);
            console.log(resp);
            Swal.fire({
                title: 'Success!',
                text: 'Facility updated Successfully!',
                icon: 'success',
                confirmButtonText: 'Okay',
                confirmButtonColor: '#005154'
            });
            form.resetFields();
        } else {
            const resp = await facilityAPI.createFacility(payload);
            console.log(resp);
            Swal.fire({
                title: 'Success!',
                text: 'Facility created Successfully!',
                icon: 'success',
                confirmButtonText: 'Okay',
                confirmButtonColor: '#005154'
            });
            form.resetFields();
        }
    }

    const onState = async (v) => {
        const cityList = await patientApi.cityList(v);
        setCities(cityList);
    }
    useEffect(() => {
        const getStateList = async () => {
            const stateLists = await patientApi.stateList();
            setStateList(stateLists);
        }
        getStateList();
    }, []);

    return (
        <>
            <div className="providerFormWrapper">
                <Row>
                <Col span={24}>
                        <Title level={4} >
                            Facility</Title>
                            <Divider/>
                    </Col>
                </Row>
                <Form layout="vertical"
                    initialValues={{}}
                    className="providerForm"
                    onFinish={onFinish}
                    form={form} >
                    <Row gutter={16}>
                    <Col span={6}>
                            <Form.Item name="facilityId" label="Facility Id">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        
                        <Col span={6}>
                            <Form.Item name="facilityOrganizationalNPI" label="Organizational NPI" rules={[
                                {
                                    required: true,
                                    message: 'Please enter a NPI!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="facilityName" label="Facility Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a facility name!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="facilityPrint" label="Print on Claim"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a print claim!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name="facility" label="Facility">
                                <Input disabled size="small" />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="facilityAddress" label="Address" 
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a Address!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="facilityAddressline2" label="Address line 2">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="facilityState" label="State"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a state!',
                                },
                            ]}>
                                <Select showSearch size="small"
                                    optionFilterProp="children"
                                    onSelect={onState}
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                    {stateList?.map((s) => <Option key={s.id} value={s.state_code}>{s.state_code}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="facilityCity" label="City" 
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a city!',
                                },
                            ]}>
                                <Select showSearch size="small"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                    {cities?.map((s) => <Option key={s.id} value={s.name}>{s.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="facilityZip" label="Zip code"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a zip code!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="facilityPhone" label="Facility Phone" 
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a phone!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="facilityFax" label="Facility Fax">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={6} justify="end" style={{ marginTop: '30px' }}>
                        <Col span={4}>
                            <Button htmlType="submit" className="btnPrimary"> {mode ? 'Update' : 'Submit'} </Button>
                        </Col>
                    </Row>

                </Form>
            </div>
        </>
    )
};

export default NewFacility;