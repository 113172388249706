import axios from "axios";
import { API } from "../../config";

const getBillingProvider = async () => {
  const response = await axios.get(API+'/billingprovider');
  return response.data;
};

const getBillingProviderId = async (id) => {
  const payload = {
    params: {
      id
    }
  }
  console.log(payload);
  const response = await axios.get(API+'/billingprovider/id', payload);
  return response.data;
};

const createBillingProvider = async (payload) => {
  // console.log(payload);
  const response = await axios.post(API+'/billingprovider', payload);
  return response.data;
};

const updateBillingProvider = async (payload) => {
  console.log(payload);
  const response = await axios.put(API+'/billingprovider/update', payload);
  return response.data;
}


const BillingProviderAPI = {
    getBillingProvider,
    createBillingProvider,
    updateBillingProvider,
    getBillingProviderId,
  }
  
  export default BillingProviderAPI;