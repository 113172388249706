import { Tabs } from "antd";
import CMS1500 from "../forms/CMS1500";
import UB04Form from "../forms/UB04Form";
import ClaimList from "../claimList";
import ClaimAwaiting from "../claimawaiting";
import ClaimRepair from "../claimRepair";
import { useState } from "react";


const ClaimMenu = () => {
    const [tabKey, setTabKey] = useState();
    const handleTabs = (key) => {
        console.log('handleTabss', key);
        setTabKey(key)
    }
    
    const items = [
        {
            label: 'Add New Claim',
            key: 'addNewClaim',
            children: <CMS1500 tabKey={tabKey} handleTabs={handleTabs}/>,
        },
        // {
        //     label: 'UB04 Claim form',
        //     key: 'ub04claimform',
        //     children: <UB04Form />,
        // },
        {
            label: 'Search',
            key: 'searchClaimMenu',
            children: <ClaimList handleTabs={handleTabs} />,
        },
        // {
        //     label: 'Claim Waiting Batch',
        //     key: 'claimWaitingBatch',
        //     children: <ClaimAwaiting />,
        // },
        // {
        //     label: 'Repairable Claim',
        //     key: 'rapairbleClaim',
        //     children: <ClaimRepair />,
        // },
        // {
        //     label: 'Report',
        //     key: 'claimReport',
        //     children: <>Claim Report</>,
        // }
    ]
    return (
        <>
            <Tabs
                defaultActiveKey="addNewClaim"
                centered
                onTabClick={handleTabs}
                activeKey={tabKey}
                items={items}
            />
        </>
    )
}

export default ClaimMenu;