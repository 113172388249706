import { EditOutlined } from "@ant-design/icons";
import { Button, Card, Col, DatePicker, Divider, Form, Input, Row, Select, Table, Tag, Typography } from "antd";
import { useEffect, useState } from "react";
import patientApi from "./hook/patient.api";
import { createSearchParams, useNavigate } from "react-router-dom";
const { Title } = Typography;


const PatientVisit = () => {
    const navigate = useNavigate();
    const [patientVisitData, setPatientVisitData] = useState([]);
    const [fullPatientData, setFullPatientData] = useState([]);
    const [typedate, setTypedate] = useState('');
    const [filterField, setFilterField] = useState('');
    const [filterfieldData, setFilterFieldData] = useState('');

    const createClaim = (r) => {
        console.log(r);
        let params = { visitId: r._id, mode: 'visit' };
        let path = {
            pathname: '/claim',
            search: createSearchParams(params).toString()
        }
        navigate(path);
    }

    const editVisit = (r) => {
        console.log(r);
        let params = { visitId: r._id, mode: 'edit' };
        let path = {
            pathname: '/patient',
            search: createSearchParams(params).toString()
        }
        navigate(path);
    }

    const columns = [
        {
            title: 'Visit ID',
            dataIndex: 'createtime',
            key: 'createtime',
            width: '5%',
            render: (txt, r) => (<p onClick={() => editVisit(r)} style={{ color: '#007a7a', cursor: "pointer" }}>{txt}</p>)
        },
        {
            title: 'Visit Type',
            dataIndex: 'visitReason',
            key: 'visitReason',
            width: '5%',
        },
        {
            title: 'Date',
            dataIndex: 'visitDate',
            key: 'visitDate',
        },
        {
            title: 'Patient Name',
            dataIndex: 'visitpatientFirstName',
            key: 'visitpatientFirstName',
        },
        {
            title: 'DoB',
            dataIndex: 'visitpatientDob',
            key: 'visitpatientDob',
        },
        {
            title: 'Provider',
            dataIndex: 'visitProviderFirstName',
            key: 'visitProviderFirstName',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (d) => (<Tag>{d}</Tag>)
        },
        {
            title: 'Charges',
            dataIndex: 'total',
            key: 'lineCharge',
            render: (d, r) => (<>{r.total ? `$${r.total}` : `$${r.icdcode[0]?.lineCharge}`}</>)
        },
        {
            title: 'Balance',
            dataIndex: ['icdcode', 'balance'],
            key: 'balance',
            render: (d, r) => (<>{r.total ? `$${r.total}` : `$${r.icdcode[0]?.balance}`}</>)
        },
        {
            title: 'Print BS',
            dataIndex: 'printBS',
            key: 'printBS',
        },
        {
            title: 'Primary Insurance/ Claim No',
            dataIndex: 'insurancePrimary',
            key: 'insurancePrimary',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (d, r) => <Tag color="green" onClick={() => createClaim(r)}>
                <EditOutlined /> claim
            </Tag>

        }
    ];

    const getPatientLookup = async () => {
        console.log(typedate);
        if (typedate) {
            const date = typedate.format('MM/DD/YYYY');
            const getpatientvisits = await patientApi.getPatientVisitquery(date);
            console.log(getpatientvisits);
            setPatientVisitData(getpatientvisits);
            setFullPatientData(getpatientvisits)
        }
    }



    const filterData = () => {
        console.log(filterfieldData);
        if (filterfieldData) {
            let pData = [];
            for (var i = 0; i < patientVisitData.length; i++) {
                if (patientVisitData[i].visitpatientFirstName.includes(filterfieldData)) {
                    console.log(patientVisitData[i]);
                    pData.push(patientVisitData[i]);
                }
            }
            setPatientVisitData(pData);
        }
    }
    const getChangeFields = (e) => {
        console.log(e.target.value);
        setFilterFieldData(e.target.value);
    }

    useEffect(() => {
        const getpatientVisit = async () => {
            const getpatientvisits = await patientApi.getPatientVisit();
            console.log(getpatientvisits);
            setPatientVisitData(getpatientvisits);
            setFullPatientData(getpatientvisits)
        }
        getpatientVisit();
    }, [])


    return (<>
        <div>
            <Row>
                {/* <Col span={24}>
                    <Title level={5} className="patientViewheader">
                        Patient Visit
                    </Title>
                </Col> */}
                <Col span={24}>
                    <Card className="shadowCard">
                        <Form layout="vertical" className="patientVisit">
                            <Row gutter={16}>
                                <Col span={4}>
                                    <Form.Item name="patientVisitDate" label="Date">
                                        <DatePicker size="small" format="MM/DD/YYYY" onChange={value => setTypedate(value)} />
                                    </Form.Item>
                                </Col>
                                <Col span={6} style={{ marginTop: '24px' }}>
                                    <Button type="primary" className="btnPrimary" onClick={() => getPatientLookup()}>
                                        Get Results
                                    </Button>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={6}>
                                    <Form.Item name="patientVisitSearchFor" label="Search for">
                                        <Select size="small" onChange={v => setFilterField(v)}>
                                            <Select.Option value="patientlastName">Patient Name </Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6} style={{ marginTop: '22px' }}>
                                    <Form.Item onChange={(e) => getChangeFields(e)} name="patientVisiSearchforValue" >
                                        <Input size="small" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={6}>
                                    <Form.Item name="patientVisitSearchForand" label="Search for">
                                        <Select size="small">
                                            <Select.Option value="patientfirstName">First Name </Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6} style={{ marginTop: '22px' }}>
                                    <Form.Item name="patientVisiSearchforAndValue" >
                                        <Input size="small" onChange={() => getChangeFields()} />
                                    </Form.Item>
                                </Col>
                                <Col span={3} >
                                    <Form.Item name="patientVisitSearcAndmaxResult" label="Max Result">
                                        <Select size="small">
                                            <Select.Option value="10">10</Select.Option>
                                            <Select.Option value="20">20 </Select.Option>
                                            <Select.Option value="50">50 </Select.Option>
                                            <Select.Option value="100">100 </Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={4} style={{ marginTop: '22px' }} >
                                    <Button type="primary" onClick={() => filterData()} className="btnPrimary">
                                        Search
                                    </Button>
                                </Col>
                            </Row>

                        </Form>
                        {/* <Divider /> */}
                        {/* <Form key="patientVisitDiplsySearch"
                            className="patientVisit" layout="vertical">
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item name="patitentVisitFilteroffice" label='Office'>
                                        <Select size="small">
                                            <Select.Option value="All">All</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="patitentVisitFilterProvider" label='Provider'>
                                        <Select size="small">
                                            <Select.Option value="All">All</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="patitentVisitFilterStatus" label='Status'>
                                        <Select size="small">
                                            <Select.Option value="All">All</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item name="patitentVisitFilterVisit" label='Visit Type'>
                                        <Select size="small">
                                            <Select.Option value="All">All</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="patitentVisitFilterBalance" label='Balance'>
                                        <Select size="small">
                                            <Select.Option value="All">All</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="patitentVisitFilterBalanceRspo" label='Balance Responsibility'>
                                        <Select size="small">
                                            <Select.Option value="All">All</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form> */}
                    </Card>
                </Col>
                <Col span={24}>
                    <Table
                        rowKey="_id"
                        dataSource={patientVisitData}
                        columns={columns} />
                </Col>
            </Row>
        </div>
    </>);
}

export default PatientVisit;