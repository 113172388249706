import { SearchOutlined } from "@ant-design/icons";
import { Button, Calendar, Card, Checkbox, Col, DatePicker, Form, Input, Radio, Row, Select, Typography } from "antd";
const { Title } = Typography;

const ClaimRepair = () => {
    return (
        <>
            <Row justify="center">
                {/* <Col span={24}>
                    <Title level={5} className="patientViewheader" >
                    Repairable Claims (Claim Fix) - [For 19-12-2023]</Title>
                </Col> */}
                <Col span={24}>
                    <Form layout="vertical" className="specialEventForm">
                        <Card className="shadowCard">
                            <Row gutter={8}>
                                <Col span={18}>
                                    <Row gutter={8}>
                                        <Col span={12}>
                                        <Calendar fullscreen={false}  />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={6}>
                                    <Row justify={"end"}>
                                        <Col span={24}>
                                            <Button className="btnPrimary" >
                                                Set Default
                                            </Button>
                                            
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                        </Card>
                    </Form>
                </Col>
            </Row>
        </>
    )
};

export default ClaimRepair;