import { DashOutlined, SearchOutlined } from "@ant-design/icons";
import { Row, Col, Typography, Form, Input, Divider, Radio, DatePicker, Select, Button } from "antd";

import { useEffect, useState } from "react";
import claimAPI from "../hook/claim.hook";
import CPTModal from "../../patient/components/cptModal";
import patientApi from "../../patient/hook/patient.api";
import { useSearchParams } from "react-router-dom";
import ProviderModel from "../../../components/providerModel";
import dayjs from "dayjs";
import CptcodeAPI from "../../../utilities/datalist/cptcode.api";
import IcdModal from "../../../components/icdcode.modal";
import IcdcodeAPI from "../../../utilities/datalist/icdcode.api";
import Swal from "sweetalert2";
import { PatientdataAPI } from "../../patientmanagement/patientData.api";
import PatientModel from "../../../components/patient.modal";
import BillingProviderModal from "../../../components/billingProvider.modal";
import BillingProviderAPI from "../../billingProvider/billingprovider.api";
import FacilityModal from "../../../components/facility.modal";
import facilityAPI from "../../facility/facility.api";
import ReferringProviderModal from "../../../components/referringProvider.modal";
import ReferringProviderAPI from "../../referringProvider/referringprovider.api";

const { Title } = Typography;
const { Option } = Select;

const CMS1500 = (props) => {
    const { tabKey, handleTabs } = props;

    const [searchParams] = useSearchParams();
    // const [detVisitails, setDetVisitails] = useState('');
    const [claimData, setClaimData] = useState('');
    const [patientDetails, setPatientDetails] = useState('');
    const [form] = Form.useForm();
    const [medicCare, setMedicCare] = useState({
        medicare: 'medicCare'
    });
    const [patientRel, setPatientRel] = useState('Self');
    const [employe, setEmpolye] = useState('yes');
    const [accident, setAccident] = useState('No');
    const [providerList, setProviderList] = useState([]);
    const [isprovdModal, setIsprovdModal] = useState(false);
    const [patientAuth, setPatientAuth] = useState('No');
    const [isIcdCode, setIsIcdCode] = useState(false);
    const [icdCodeList, setIcdCodeList] = useState([]);
    const [icdCodefield, setIcdCodefield] = useState();
    const [otheraccident, setOtheraccident] = useState('No');
    const [anotherhealthPlane, setAnotherhealthPlane] = useState('No');
    const [currentlyIllness, setCurrentlyIllness] = useState('No');
    const [employment, setEmployment] = useState('No');
    const [insuredAuthourPerson, setInsuredAuthourPerson] = useState('No');
    const [diagnousList, setDiagnousList] = useState();
    const [formValue, setFormValue] = useState({});
    const [iscptModal, setIscptModal] = useState(false);
    const [cptList, setCptList] = useState([])
    const [fdsname, setFdsname] = useState([]);
    const [icd9code, setIcd9code] = useState([]);
    const [outsideLab, setOutsideLab] = useState('No')
    const [popProvd, setPopProvd] = useState('');
    const [isShowDiv, setIsShowDiv] = useState(false);
    const [ispatntModal, setIspatntModal] = useState(false);
    const [searchType, setSearchType] = useState('');
    const [patientList, setPatientList] = useState([]);
    const [isBillingProvd, setIsBillingProvd] = useState(false);
    const [bpList, setBpList] = useState([]);
    const [isFacility, setIsFacility] = useState(false);
    const [fList, setFList] = useState([]);
    const [isRefProvd, setIsRefProvd] = useState(false);
    const [rpList, setRpList]  = useState([]);
    const [modalTitle, setModalTitle] = useState('');
    const [isprovdersModal, setIsprovdersModal] = useState(false);

    const opt = ['01-Proof of Eligibility unknown or Unavailable',
        '02-Litigation',
        '03-Authorization Delays',
        '04-Delay in Certifying Providers',
        '05-Delay in Supplying Billing Forms',
        '06-Delay in Delivery of Custom-made Appliance',
        '07-Third Party Processing Delay',
        '08-Delay eligibility of determination',
        '09-Original claim rejected or denied due to reason unrealed to the billing limitation rules',
        '10-Administration Delay in the prior Approval process',
        '15-Natural Disaster',
        '11-Other']
    const apot = [
        { value: "03", label: "03 - Report Justifying Treatment Beyond Utilization Guidelines" },
        { value: "04", label: "04 - Drugs Administered" },
        { value: "05", label: "05 - Treatment Diagnosis" },
        { value: "06", label: "06 - Initial Assessment" },
        { value: "07", label: "07 - Functional Goals" },
        { value: "08", label: "08 - Plan of Treatment" },
        { value: "09", label: "09 - Progress Report" },
        { value: "10", label: "10 - Continued Treatment" },
        { value: "11", label: "11 - Chemical Analysis" },
        { value: "13", label: "13 - Certified Test Report" },
        { value: "15", label: "15 - Justification for Admission" },
        { value: "21", label: "21 - Recovery Plan" },
        { value: "77", label: "77 - Support Data for Verification (Use this code to indicate a completed referral form)" },
        { value: "A3", label: "A3 - Allergies/Sensitivities Document" },
        { value: "A4", label: "A4 - Autopsy Report" },
        { value: "AM", label: "AM - Ambulance Certification" },
        { value: "AS", label: "AS - Admission Summary" },
        { value: "B2", label: "B2 - Prescription" },
        { value: "B3", label: "B3 - Physician Order" },
        { value: "B4", label: "B4 - Referral Form" },
        { value: "BR", label: "BR - Benchmark Testing Results" },
        { value: "BS", label: "BS - Baseline" },
        { value: "BT", label: "BT - Blanket Test Results" },
        { value: "CB", label: "CB - Chiropractic Justification" },
        { value: "CK", label: "CK - Consent Form(s)" },
        { value: "CT", label: "CT - Certification" },
        { value: "D2", label: "D2 - Drug Profile Document" },
        { value: "DA", label: "DA - Dental Models" },
        { value: "DB", label: "DB - Durable Medical Equipment Prescription" },
        { value: "DG", label: "DG - Diagnostic Report" },
        { value: "DJ", label: "DJ - Discharge Monitoring Report" },
        { value: "DS", label: "DS - Discharge Summary" },
        { value: "EB", label: "EB - Explanation of Benefits (Coordination of Benefits or Medicare Secondary Payor)" },
        { value: "HC", label: "HC - Health Certificate" },
        { value: "HR", label: "HR - Health Clinic Records" },
        { value: "I5", label: "I5 - Immunization Record" },
        { value: "IR", label: "IR - State School Immunization Records" },
        { value: "J1", label: "J1 - Doctors First Report (5021)" },
        { value: "J2", label: "J2 - Progress Reports (PR-2)" },
        { value: "J3", label: "J3 - Medical Permanent Impairment (DWC69)" },
        { value: "J4", label: "J4 - Qualified Medical Evaluation" },
        { value: "J5", label: "J5 - Vocational Progress Report" },
        { value: "J6", label: "J6 - Work Status Report (DWC73)" },
        { value: "J7", label: "J7 - Consultation Report" },
        { value: "J8", label: "J8 - Perm and Stat Report" },
        { value: "J9", label: "J9 - Detail Bill" },
        { value: "LA", label: "LA - Laboratory Results" },
        { value: "M1", label: "M1 - Medical Record Attachment" },
        { value: "MT", label: "MT - Models" },
        { value: "NN", label: "NN - Nursing Notes" },
        { value: "OB", label: "OB - Operative Note" },
        { value: "OC", label: "OC - Oxygen Content Averaging Report" },
        { value: "OD", label: "OD - Orders and Treatments Document" },
        { value: "OE", label: "OE - Objective Physical Examination (including vital signs) Document" },
        { value: "OX", label: "OX - Oxygen Therapy Certification" },
        { value: "OZ", label: "OZ - Support Data for Claim" },
        { value: "P4", label: "P4 - Pathology Report" },
        { value: "P5", label: "P5 - Patient Medical History Document" },
        { value: "PE", label: "PE - Parenteral or Enteral Certification" },
        { value: "PN", label: "PN - Physical Therapy Notes" },
        { value: "PO", label: "PO - Prosthetics or Orthotic Certification" },
        { value: "PQ", label: "PQ - Paramedical Results" },
        { value: "PY", label: "PY - Physician’s Report" },
        { value: "PZ", label: "PZ - Physical Therapy Certification" },
        { value: "RB", label: "RB - Radiology Films" },
        { value: "RR", label: "RR - Radiology Reports" },
        { value: "RT", label: "RT - Report of Tests and Analysis Report" },
        { value: "RX", label: "RX - Renewable Oxygen Content Averaging Report" },
        { value: "SG", label: "SG - Symptoms Document" },
        { value: "V5", label: "V5 - Death Notification" },
        { value: "XP", label: "XP - Photographs" },
    ];

    useEffect(() => {
        const getPatient = async () => {
            const ptList = await PatientdataAPI.getPatientList();
            setPatientList(ptList);
        }
        getPatient();
    }, []);

    const onPullpatient = async (p, value, type) => {
        console.log(p, value, type);
        console.log(patientDetails);
        let formfeilds = form.getFieldsValue();
        console.log(formfeilds, p.patientPrimaryInsuranceRel);
        let fPatient = {};
        if (searchType === 'patient') {
            fPatient = {
                patientAddress: p.patientAddress,
                patientCellPhone: p.patientCellPhone,
                patientCity: p.patientCity,
                patientState: p.patientState,
                patientDob: dayjs(p?.patientDob, 'MM/DD/YYY'),
                patientFirstName: p.patientFirstName,
                patientHomePhone: p.patientHomePhone,
                patientId: p.patientId,
                patientSSN: p.patientSSN,
                patientSex: p.patientSex,
                patientZip: p.patientZip,
                patientLastName: p.patientLastName,
                patientMiddleName: p.patientMiddleName,
                patientPrimaryInsuranceRel: p.patientPrimaryInsuranceRel,
            }
            if (p.patientPrimaryInsuranceRel === 'Self') {
                fPatient = {
                    ...fPatient,
                    insuredLastName: p.patientLastName,
                    insuredFirstName: p.patientFirstName,
                    insuredMiddleName: p.patientMiddleName,
                    insuredAddress: p.patientAddress,
                    insuredCity: p.patientCity,
                    insuredState: p.patientState,
                    insuredZip: p.patientZip,
                    insuredCellPhone: p.patientCellPhone,
                    Isuredgroupnofeca: p.patientPrimaryInsurancedSubscribeId,
                    insureddob: dayjs(p?.patientDob, 'MM/DD/YYY'),
                    insuredSex: p.patientSex,
                }
            }
            if (p.patientPrimaryInsuranceRel !== 'Self') {
                fPatient = {
                    ...fPatient,
                    insuredLastName: p?.patientPrimaryInsurancedLastName,
                    insuredFirstName: p?.patientPrimaryInsurancedFirstName,
                    insuredMiddleName: p?.patientPrimaryInsurancedMiddleName,
                    insuredAddress: '',
                    insuredCity: '',
                    insuredState: '',
                    insuredZip: '',
                    insuredCellPhone: '',
                    Isuredgroupnofeca: '',
                    insureddob: '',
                    insuredSex: '',
                    otherIsurancePlanName: ''
                }
            }
        }

        if (searchType === 'insured') {
            fPatient = {
                ...fPatient,
                insuredLastName: p.patientLastName,
                insuredFirstName: p.patientFirstName,
                insuredMiddleName: p.patientMiddleName,
                insuredAddress: p.patientAddress,
                insuredCity: p.patientCity,
                insuredState: p.patientState,
                insuredZip: p.patientZip,
                insuredCellPhone: p.patientCellPhone,
                Isuredgroupnofeca: p.patientPrimaryInsurancedSubscribeId,
                insureddob: dayjs(p?.patientDob, 'MM/DD/YYY'),
                insuredSex: p.patientSex,
            }
        }

        if (searchType === 'otherinsured') {
            fPatient = {
                ...fPatient,
                insureOtherLastname: p.patientLastName,
                insureOtherFirstname: p.patientFirstName,
                insureOthermiddlename: p.patientMiddleName,
                otherIsuredPolcyNo: '',
            }
        }
        form.setFieldsValue({
            cms: {
                ...fPatient,
            }
        })



        setIspatntModal(false);
    };

    const handleFacility = async () => {
        console.log('handleFacility');
        setIsFacility(true);
    }

    const onPullfacility = async (val, key, type) => {
        console.log(val, key, type);
        if (val) {
            const resp = {
                billingOptionFacilityName: val?.facilityName,
                billingOptionFacilityAddress: `${val?.facilityAddress} ${val?.facilityAddressline2}`,
                billingOptionFacilityCity: val?.facilityCity,
                billingOptionFacilityState: val?.facilityState,
                billingOptionFacilityZip: val?.facilityZip,
                billingOptionFacilityNPI: val?.facilityOrganizationalNPI,
                billingOptionFacilityProID: val?.facilityId
            }
            form.setFieldsValue({ cms: { ...resp } });
        }
        setIsFacility(false);
    }

    const searchPatient = (type) => {
        console.log(type);
        setSearchType(type);
        setIspatntModal(true);
    }


    const handlepatntCancel = () => {
        setIspatntModal(false);
    }
    const searchBillingProvd = () => {
        setIsBillingProvd(true);
    }

    const searchReferringProvd = () => {
        setIsRefProvd(true);
    }

    const handleBillProvdCancel = () => {
        setIsBillingProvd(false);
    }

    const handlerpCancel = () => {
        setIsRefProvd(false);
    }

    useEffect(() => {
        const getBillingProvider = async () => {
            const resp = await BillingProviderAPI.getBillingProvider();
            setBpList(resp)
        };
        getBillingProvider();

        const getrplist = async () => {
            const resp = await ReferringProviderAPI.getReferringProvider();
            setRpList(resp);
        }
        getrplist();
    }, [])

    const onPullbp = async (key, value, type) => {
        console.log(key, value, type);
        if (key) {
            const resp = {
                billingOptionBProviderName: key?.billingProvideClaimName,
                billingOptionBProviderAddress: `${key?.billingProvideAddress} ${key?.billingProvideAdress2}`,
                billingOptionBProviderCity: key?.billingProvideCity,
                billingOptionBProviderState: key?.billingProvideState,
                billingOptionBProviderZip: key?.billingProvideZip,
                bilingOptionTelephone: `${key?.billingProvidePhoneext} ${key?.billingProvidePhonestate} ${key?.billingProvidePhonenumber}`,
                billingOptionBProviderTaxonomy: '',
                billingOptionBProvidergroupno: key?.billingProvideGroupNo,
                billingOptionBProviderNPI: key?.billingProvideOrganizationalNPI,
            }
            form.setFieldsValue({ cms: { ...resp } })
        }
        setIsBillingProvd(false);
    }

    const onPullrp = async (key, val, type) => {
        if(key) {
            const resp = {
                facilityRenderingProdFirst: key?.referringProvidefirstname,
                facilityRenderingProdLast: key?.referringProvideLastname,
                facilityRenderingProdMI: key?.referringProvideMiddlename,
                facilityProvideSepc: key?.referringProvideTaxIdtype,
                facilityProviderPin: key?.referringProviderUPIN
            }
            form.setFieldsValue({cms: {...resp}});
        }
        setIsRefProvd(false);
        
    }

    useEffect(() => {
        setPatientDetails(patientDetails);
    }, [patientDetails]);

    const updateClaim = (data) => {
        // console.log(data);
        let totl = 0;
        for (var i = 0; i < data?.icdcode.length; i++) {
            if (data?.icdcode[i]?.fromDate && data?.icdcode[i]?.fromDate !== '') {
                data.icdcode[i] = {
                    ...data?.icdcode[i],
                    fromDate: dayjs(data?.icdcode[i]?.fromDate, 'MM/DD/YYYY'),
                }
            }

            if (data?.icdcode[i]?.toDate && data?.icdcode[i]?.toDate !== '') {
                data.icdcode[i] = {
                    ...data?.icdcode[i],
                    toDate: dayjs(data?.icdcode[i]?.toDate, 'MM/DD/YYYY'),
                }
            }
            totl +=data?.icdcode[i]?.lineCharge;
            console.log(totl, data?.icdcode[i]?.lineCharge);
        }
        setClaimData({
            ...data,
            patientDob: dayjs(data?.patientDob, 'MM/DD/YYYY'),
            patientAzthorSign: dayjs(data?.patientAzthorSign, 'MM/DD/YYYY'),
            insureddob: dayjs(data?.insureddob, 'MM/DD/YYYY'),
            othDateBirth9b: dayjs(data?.othDateBirth9b, 'MM/DD/YYYY'),
            otherDate: dayjs(data?.otherDate, 'MM/DD/YYYY'),
            similIllnessFirstDate15: dayjs(data?.similIllnessFirstDate15, 'MM/DD/YYYY'),
            billingOptiondateunableWorkedfrom: dayjs(data?.billingOptiondateunableWorkedfrom, 'MM/DD/YYYY') || null,
            billingOptiondateunableWorkedto: dayjs(data?.billingOptiondateunableWorkedto, 'MM/DD/YYYY') || null,
            billingOptiondateHospitalfrom: dayjs(data?.billingOptiondateHospitalfrom, 'MM/DD/YYYY'),
            billingOptiondateHopstialto: dayjs(data?.billingOptiondateHopstialto, 'MM/DD/YYYY'),
            dateCurrent: dayjs(data?.dateCurrent, 'MM/DD/YYYY'),
            billingOptiondateInitialTreatment: dayjs(data?.billingOptiondateInitialTreatment, 'MM/DD/YYYY') || '',
            visitDate: dayjs(data?.visitDate, 'MM/DD/YYYY') || null,
            billingOptiondateLastXrayDate: dayjs(data?.billingOptiondateLastXrayDate, 'MM/DD/YYYY') || '',
            billingOptiondateAccidentDate: dayjs(data?.billingOptiondateAccidentDate, 'MM/DD/YYYY') || null,
            // billingOptiondateMMammography: data?.billingOptiondateMMammography,
            billingOptiondateLastWorked: dayjs(data?.billingOptiondateLastWorked, 'MM/DD/YYYY') || null,
            billingOptiondateDateAuth: dayjs(data?.billingOptiondateDateAuth, 'MM/DD/YYYY') || null,
            billingOptiondateAssumedCareDate: dayjs(data?.billingOptiondateAssumedCareDate, 'MM/DD/YYYY') || null,
            billingOptiondateRelinquishedCareDate: dayjs(data?.billingOptiondateRelinquishedCareDate, 'MM/DD/YYYY') || null,
            billingOptiondateActueManifest: dayjs(data?.billingOptiondateActueManifest, 'MM/DD/YYYY') || null,
            billingOptiondateConsultationDate: dayjs(data?.billingOptiondateConsultationDate, 'MM/DD/YYYY') || null,
            billingOptiondatePrescriptionDate: dayjs(data?.billingOptiondatePrescriptionDate, 'MM/DD/YYYY') || null,
            icdcode: data?.icdcode ? [...data?.icdcode] : [],
            totalChr: totl
        });
    }
    useEffect(() => {
        form.setFieldsValue({
            cms: {
                ...claimData
            }
        });
    }, [claimData, form])

    useEffect(() => {
        const appId = searchParams.get('visitId');
        const claimId = searchParams.get('claimId');

        if (appId) {
            const getVisit = async (id) => {
                const pvDetails = await claimAPI.getpatientvisitforclaim(id);
                // console.log(pvDetails);
                setPatientDetails(pvDetails);
            }
            getVisit(appId);
        }
        if (claimId) {
            const getClaim = async (id) => {
                const claimDetails = await claimAPI.getClaimbyId(id);
                // console.log(claimDetails[0]);
                setClaimData(claimDetails[0])
                updateClaim(claimDetails[0])
            }
            getClaim(claimId);
        }

    }, [tabKey]);


    const searchProvd = (id, type) => {
        // console.log('searchProvd', id, type);
        setPopProvd(type);
        setIsprovdModal(true);
    }

    const searchProvders = (title) =>{
        setIsprovdersModal(true);
        setModalTitle(title);
    }

    const handleprovdersCancel = () => {
        setIsprovdersModal(false);
    }

    const handleprovd = (key, i, lab) => {
        // console.log('handleprovd', key, i, lab);
        setPopProvd([key, i, lab]);
        setIsprovdModal(true);
    }

    const handleProviders = (key, val, type) => {
        console.log("handleProviders:",key, val, type);
        
        
    }

    const onPullprovider = (key, val, type) => {
        console.log("onPullprovider:",key, val, type);
        console.log(modalTitle)
        if(key && modalTitle === 'Supervising Provider'){
            const resp ={
                billingSupervisingFirstname: `${key?.providerFirstName} ${key?.providerMiddleName} ${key?.providerLastName}`,
                billingSupervisingPhysicicanNpi: key?.providerNPI,
                billingSupervisingPhysicicanID: key?.providerId
            }
            form.setFieldsValue({cms: {...resp}});
        }
        if( key && modalTitle === 'Order Provider'){
            const resp = {
                billingOderingLastname : key?.providerLastName,
                billingOderingFirstname: key?.providerFirstName,
                billinOderingMiddlename: key?.providerMiddleName,
                billingPOderinghysicicanNpi :key?.providerNPI,
                billingOderingPhysicicanID: key?.providerId
            }
            form.setFieldsValue({cms: {...resp}});
        }
        setIsprovdersModal(false);
    }

    const handleprovdCancel = () => {
        setIsprovdModal(false);
    }

    const handleCpt = (i) => {
        setIscptModal(true)
        setFdsname(i);
    }

    const handleIcd = (i) => {
        setIsIcdCode(true);
        setIcdCodefield(i)
    }

    useEffect(() => {
        const getcptcode = async () => {
            const data = await CptcodeAPI.getCptcode();
            // console.log('getcptcode',data);
            setCptList(data);
        };
        getcptcode();
    }, []);

    const updateCpt = (a, b, c) => {
        setIscptModal(false)
        let fdNme = form.getFieldsValue();
        fdNme.cms.icd9[a] = {
            code: b?.code,
            descp: c?.descp
        }
        setIcd9code(fdNme)
    }

    useEffect(() => {
        // console.log(icd9code);
        form.setFieldsValue({
            cms: {
                icd9:
                {
                    ...icd9code?.cms?.icd9,
                }
            }
        })
    }, [icd9code, form])

    const handlecptCancel = () => {
        setIscptModal(false);
    }
    const handleIcdCancel = () => {
        setIsIcdCode(false)
    }

    const handleChangeMedic = (e, type) => {
        console.log(e.target.value);
        let radioType = {
            ...medicCare,
            [type]:e.target.value
        }
        setMedicCare({...radioType});
    }

    const handleChangePRelation = (e, v) => {
        // console.log(e?.target?.value, v);
        const prel = e?.target?.value || v;
        // console.log('handleChangePRelation', prel);
        setPatientRel(prel);
    }
    const handleChangeEmp = (e, v) => {
        const pemp = e?.target?.value || v;
        setEmpolye(pemp);
    }
    // const handleChangeAccident = (e) => {
    //     setAccident(e.target.value);
    // }
    const handleChangeOtherAccident = (e, name) => {
        console.log(e, name);
        // setOtheraccident(e.target.value);
        if (name === 'employment') {
            setEmployment(e.target.value);
        }
        if (name === 'anotherhealthPlane') {
            setAnotherhealthPlane(e.target.value);
        }
        if (name === 'currentlyIllness') {
            setCurrentlyIllness(e.target.value);
        }
        if (name === 'insuredAuthourPerson') {
            setInsuredAuthourPerson(e.target.value);
        }
        if (name === 'accident') {
            setAccident(e.target.value);
        }
        if (name === 'patientAuth') {
            setPatientAuth(e.target.value);
        }

        if (name === 'outsideLab') {
            setOutsideLab(e.target.value);
        }

    }
    const getDiagnous = () => {
        const icdcode = {
            fromDate: '',
            toDate: '',
            pos: '',
            emg: '',
            cpt: '',
            modified: ['', '', '', ''],
            icd10Pointer: '',
            lineCharge: '',
            dayorunit: '',
            epsdt: '',
            idqual: '',
            renderid: '',
            lineNote: '',
            anesStart: '',
            anesStop: '',
            NDC: '',
            NDCcode: '',
            NDCUnit: '',
            qty: '',
            qtyqual: '',
            render1: '',
            render2: ''
        }
        let arrayDiagous = []
        for (var i = 0; i < 12; i++) {
            arrayDiagous.push(icdcode)
        }
        setDiagnousList(arrayDiagous);
    }

    const getdatedayjs = (date) => {
        return dayjs(date, 'MM/DD/YYYY');
    }

    const updateDetail = (data) => {
        // const cms = form.getFieldsValue();

        for (var i = 0; i < data?.visit?.icdcode.length; i++) {
            if (data?.visit?.icdcode[i]?.fromDate) {
                data.visit.icdcode[i] = {
                    ...data?.visit?.icdcode[i],
                    fromDate: data?.visit?.icdcode[i]?.fromDate ? getdatedayjs(data?.visit?.icdcode[i]?.fromDate) : ''
                }
            }

            if (data?.visit?.icdcode[i]?.toDate) {
                data.visit.icdcode[i] = {
                    ...data?.visit?.icdcode[i],
                    toDate: data?.visit?.icdcode[i]?.toDate ? getdatedayjs(data?.visit?.icdcode[i]?.toDate) : ''
                }
            }
        }

        let insuredData = {};
        if (data?.patient?.patientPrimaryInsuranceRel === 'Self') {
            // insuredData.insuredLastName = data?.patient?.patientPrimaryInsurancedLastName;
            // insuredData.insuredMiddleName = data?.patient?.patientPrimaryInsurancedMiddleName;
            // insuredData.insuredFirstName = data?.patient?.patientPrimaryInsurancedFirstName;
            handleChangePRelation('', data?.patient?.patientPrimaryInsuranceRel)
            insuredData.patientPrimaryInsuranceRel = data?.patient?.insurancePatientRelation;
            insuredData.insuredLastName = data?.patient?.patientLastName;
            insuredData.insuredMiddleName = data?.patient?.patientMiddleName;
            insuredData.insuredFirstName = data?.patient?.patientFirstName;
            insuredData.insuredAddress = data?.patient?.patientAddress;
            insuredData.insuredCity = data?.patient?.patientCity;
            insuredData.insuredState = data?.patient?.patientState;
            insuredData.insuredZip = data?.patient?.patientZip;
            insuredData.insuredCellPhone = data?.patient?.patientCellPhone;
            insuredData.Isuredgroupnofeca = data?.patient?.patientPrimaryInsurancedGroupNo;
            insuredData.insureddob = data?.patient?.patientDob ? getdatedayjs(data?.patient?.patientDob) : getdatedayjs("");
            insuredData.insuredSex = data?.patient?.patientSex;
            insuredData.insuredAuthourPerson = data?.patient?.patientaltPrimaryInsurancedSignFile;
        }
        // console.log('patientPrimaryInsurancedAuthoriz', data?.patient?.patientPrimaryInsurancedAuthoriz);
        const formVal = {
            ...data?.visit,
            ...data?.patient,
            ...insuredData,
            employment: data?.visit?.billingOptionConditionEmpCP,
            accident: data?.visit?.billingOptionConditionAutoAccident,
            outsideLab: data?.visit?.billingOptiondateMOutside,
            pregnancydateCurrent: data?.visit?.billingOptiondateEstDelivery ? getdatedayjs(data?.visit?.billingOptiondateEstDelivery) : '',
            patientDob: dayjs(data?.patient?.patientDob, 'MM/DD/YYYY'),
            patientAuth: data?.patient?.patientPrimaryInsurancedAuthoriz,
            patientAzthorSign: dayjs(data?.patient?.patientPrimaryInsurancedSignatureDate, 'MM/DD/YYYY'),
            // currentlyIllness: dayjs(data?.patient?.billingOptiondateOnset, 'MM/DD/YYYY'),
            // currentlyIllness: dayjs(data?.visit?.billingOptiondateOnset, 'MM/DD/YYYY'), patientPrimaryInsurancedSignatureDate
            billingOptiondateEstDelivery: dayjs(data?.visit?.billingOptiondateEstDelivery, 'MM/DD/YYYY'),
            // patientAddressPhoneCell: `(${data?.patient?.patientAddressPhoneCell?.ct})-${data.patient?.patientAddressPhoneCell?.st}-${data.patient?.patientAddressPhoneCell?.number}`,
            insuranceInsuredRelat: data?.patient?.patientPrimaryInsuranceRel,
            billingOptiondateunableWorkedfrom: data?.visit?.billingOptiondateunableWorkedfrom ? getdatedayjs(data?.visit?.billingOptiondateunableWorkedfrom) : '',
            billingOptiondateunableWorkedto: data?.visit?.billingOptiondateunableWorkedto ? getdatedayjs(data?.visit?.billingOptiondateunableWorkedto) : '',
            billingOptiondateHospitalfrom: data?.visit?.billingOptiondateHospitalfrom ? getdatedayjs(data?.visit?.billingOptiondateHospitalfrom) : '',
            billingOptiondateHopstialto: data?.visit?.billingOptiondateHopstialto ? getdatedayjs(data?.visit?.billingOptiondateHopstialto) : '',
            billingOptiondateInitialTreatment: data?.visit?.billingOptiondateInitialTreatment ? getdatedayjs(data?.visit?.billingOptiondateInitialTreatment) : '',
            visitDate: dayjs(data?.visit?.visitDate, 'MM/DD/YYYY'),
            billingOptiondateLastXrayDate: dayjs(data?.visit?.billingOptiondateLastXrayDate, 'MM/DD/YYYY'),
            billingOptiondateAccidentDate: dayjs(data?.visit?.billingOptiondateAccidentDate, 'MM/DD/YYYY'),
            // billingOptiondateMMammography: data?.visit?.billingOptiondateMMammography,
            billingOptiondateLastWorked: dayjs(data?.visit?.billingOptiondateLastWorked, 'MM/DD/YYYY'),
            billingOptiondateDateAuth: dayjs(data?.visit?.billingOptiondateDateAuth, 'MM/DD/YYYY'),
            billingOptiondateAssumedCareDate: dayjs(data?.visit?.billingOptiondateAssumedCareDate, 'MM/DD/YYYY'),
            billingOptiondateRelinquishedCareDate: dayjs(data?.visit?.billingOptiondateRelinquishedCareDate, 'MM/DD/YYYY'),
            billingOptiondateActueManifest: dayjs(data?.visit?.billingOptiondateActueManifest, 'MM/DD/YYYY'),
            billingOptiondateConsultationDate: dayjs(data?.visit?.billingOptiondateConsultationDate, 'MM/DD/YYYY'),
            billingOptiondatePrescriptionDate: dayjs(data?.visit?.billingOptiondatePrescriptionDate, 'MM/DD/YYYY'),
        }
        setPatientRel(data?.patient?.insuranceInsuredRelat);
        setFormValue(formVal)
        // console.log(cms);
    }

    useEffect(() => {
        form.setFieldsValue({ cms: { ...formValue } });
    }, [formValue, form]);

    useEffect(() => {
        getDiagnous();
        // console.log(patientDetails, !patientDetails, patientDetails === '');
        if (patientDetails !== '') {
            updateDetail(patientDetails);
        }
    }, [patientDetails]);

    useEffect(() => {
        const getProvider = async () => {
            const ProviderLists = await patientApi.providerList();
            // console.log(ProviderLists);
            setProviderList(ProviderLists);
            // getProviderDetails(ProviderLists);
        }
        getProvider();
    }, [])

    const onFinish = async (v) => {
        console.log(v);
        const claimData = {
            ...v.cms,
            patientDob: v?.cms?.patientDob?.format('MM/DD/YYYY'),
            // patientAddressPhoneCell: `(${data?.patientAddressPhoneCell?.ct})-${data.patientAddressPhoneCell?.st}-${data.patientAddressPhoneCell?.number}`,
            // insuranceInsuredRelat: data?.insuranceInsuredRelat,
            billingOptiondateInitialTreatment: v?.cms?.billingOptiondateInitialTreatment? v?.cms?.billingOptiondateInitialTreatment?.format('MM/DD/YYYY') : '',
            visitDate: v?.cms?.visitDate?.format('MM/DD/YYYY'),
            billingOptiondateLastXrayDate: v?.cms?.billingOptiondateLastXrayDate?.format('MM/DD/YYYY'),
            billingOptiondateAccidentDate: v?.cms?.billingOptiondateAccidentDate?.format('MM/DD/YYYY'),
            // billingOptiondateMMammography: data?.billingOptiondateMMammography,
            billingOptiondateLastWorked: v?.cms?.billingOptiondateLastWorked?.format('MM/DD/YYYY'),
            billingOptiondateDateAuth: v?.cms?.billingOptiondateDateAuth?.format('MM/DD/YYYY'),
            billingOptiondateAssumedCareDate: v?.cms?.billingOptiondateAssumedCareDate?.format('MM/DD/YYYY'),
            billingOptiondateRelinquishedCareDate: v?.cms?.billingOptiondateRelinquishedCareDate?.format('MM/DD/YYYY'),
            billingOptiondateActueManifest: v?.cms?.billingOptiondateActueManifest?.format('MM/DD/YYYY'),
            billingOptiondateConsultationDate: v?.cms?.billingOptiondateConsultationDate?.format('MM/DD/YYYY'),
            billingOptiondatePrescriptionDate: v?.cms?.billingOptiondatePrescriptionDate?.format('MM/DD/YYYY'),
            creatAt: new Date(),
            createUser: 'admin',
            createtime: new Date().getTime(),
            update: '',
            claimId: new Date().getTime()

        };
        console.log(claimData)
        const resp = await claimAPI.createClaim(claimData);
        console.log(resp);
        Swal.fire({
            title: 'Success!',
            text: 'Claim created Successfully!',
            icon: 'success',
            confirmButtonText: 'Okay',
            confirmButtonColor: '#005154'
        });
        handleTabs('searchClaimMenu');
        form.resetFields();
    }

    const onPullprovidericdRender = (key, value, type) => {

        let gtef = form.getFieldsValue();
        console.log(gtef)
        gtef.cms.icdcode[popProvd[1]][popProvd[2]] = key.providerNPI
        form.setFieldsValue({
            cms: {
                ...gtef.cms
            }
        });
        setPopProvd(false);
        setIsprovdModal(false);
    }

    const onPullIcdCode = (key, values, type) => {
        console.log(key, values, type);
        let icdForm = form.getFieldsValue();
        icdForm.cms.icd9[icdCodefield].code = key.code;
        form.setFieldsValue(icdForm);
        handleIcdCancel()
    }



    useEffect(() => {
        const geticdCodeList = async () => {
            const resp = await IcdcodeAPI.getIcdcode();
            setIcdCodeList(resp);
        }

        const getFacility = async () => {
            const resp = await facilityAPI.facilityList();
            setFList(resp)
        }
        geticdCodeList();
        getFacility()
    }, [])


    return (
        <div className="formWrapper">
            <Row justify="center">
                {/* <Col span={24}>
                    <Title level={5} className="patientViewheader" >CMS 1500 02/12 Form - [Create Claim]</Title>
                </Col> */}
                <Col span={24}>
                    <Form layout="horizontal"
                        initialValues={{}}
                        className="appointmentForm"
                        onFinish={onFinish}
                        form={form} >
                        <Row gutter={16} justify='end'>
                            <Col span={10}>
                                <Col span={24}>
                                    <Form.Item name={["cms", "insuranceName"]} label="Payer Name">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name={["cms", "insuranceId"]} label="Address / Payer ID">

                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name={["cms", "payer2Ad"]} label="2nd Address">

                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Row gutter={8}>
                                        <Col span={12}>
                                            <Form.Item name={["cms", "insuranceCity"]} label="City, State, Zip">

                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item name={["cms", "insuranceState"]}>

                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item name={["cms", "insuranceZip"]}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </Col>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />
                            <Col span={18} style={{ height: '100px', borderRight: '1px dashed' }} >
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">1</span>
                                    </div>
                                    <span>MEDICARE</span>
                                    <span>MEDICAID</span>
                                    <span>TRICARE</span>
                                    <span>CHAMPVA</span>
                                    <span>GROUP HEALTH PLAN</span>
                                    <span>FECA BLK LUNG</span>
                                </div>

                                <Radio.Group className="mediContainer" onChange={(e) => handleChangeMedic(e, 'medicCare')} name={['cms', 'medicCare']} value={medicCare?.medicCare}>
                                    <div className="mediRadio">
                                        <Radio size='small' value="medicare">(Medicare#)</Radio>
                                    </div>
                                    <div className="mediRadio">
                                        <Radio size='small' value="medicaid">(Medicaid#)</Radio>
                                    </div>
                                    <div className="mediRadio">
                                        <Radio size='small' value="tricare">(ID#DoD#)</Radio>
                                    </div>
                                    <div className="mediRadio">
                                        <Radio size='small' value="champva">(VAfile#)</Radio>
                                    </div>
                                    <div className="mediRadio">
                                        <Radio size='small' value="groupID">/{`(ID#)`}</Radio>
                                    </div>
                                    <div className="mediRadio">
                                        <Radio size='small' value="feca">{`(ID#)`} </Radio>
                                    </div>
                                </Radio.Group>

                            </Col>
                            <Col span={6} style={{ height: '100px' }}>
                                <div className="lableText m10tb">
                                    <span className="lableHightlight">1a</span>
                                    <span className="lable">INSURED'S I.D. NUMBER</span>
                                </div>
                                <Form.Item name={['cms', 'insuranceId']}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />
                        </Row>
                        <Row gutter={16}>
                            <Col span={9} className="dashRight" style={{ height: '100px' }}>
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">2</span>
                                    </div>
                                    <span>
                                        PATIENT'S NAME (Last Name, First Name, Middle Init)
                                    </span>
                                </div>
                                <Row gutter={8} style={{ marginTop: '10px' }}>
                                    <Col span={9} className="cmsFormFontStyle">
                                        Last
                                    </Col>
                                    <Col span={9} className="cmsFormFontStyle">
                                        First
                                    </Col>
                                    <Col span={3} className="cmsFormFontStyle">
                                        MI
                                    </Col>

                                </Row>
                                <Row gutter={8} style={{ marginTop: '3px' }}>
                                    <Col span={9}>
                                        <Form.Item name={['cms', 'patientLastName']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={9}>
                                        <Form.Item name={['cms', 'patientFirstName']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item name={['cms', 'patientMiddleName']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2} style={{ textAlign: 'center', cursor: "pointer" }}>
                                        <DashOutlined className="searchdotbtn" onClick={() => searchPatient('patient')} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={4} className="dashRight">
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">3</span>
                                    </div>
                                    <span>
                                        PATIENT'S BIRTHDATE
                                    </span>
                                </div>
                                <Form.Item name={['cms', 'patientDob']} style={{ marginTop: '28px' }}>
                                    <DatePicker className="cmsFormFontStyle" format={'MM/DD/YYYY'} placeholder="MM/DD/YYYY" />
                                </Form.Item>
                            </Col>
                            <Col span={3} className="dashRight">
                                <div className="mediContainer">
                                    <span>
                                        SEX
                                    </span>
                                </div>
                                <Form.Item name={['cms', 'patientSex']} style={{ marginTop: '33px' }}>
                                    <Select>
                                        <Select.Option value="Male">Male</Select.Option>
                                        <Select.Option value="Female">Female</Select.Option>
                                        <Select.Option value="Other">Other</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">4</span>
                                    </div>
                                    <span>
                                        INSURED'S NAME (Last Name, First Name, Middle Init)
                                    </span>
                                </div>
                                <Row gutter={8} style={{ marginTop: '10px' }}>
                                    <Col span={9} className="cmsFormFontStyle">
                                        Last
                                    </Col>
                                    <Col span={9} className="cmsFormFontStyle">
                                        First
                                    </Col>
                                    <Col span={3} className="cmsFormFontStyle">
                                        MI
                                    </Col>

                                </Row>
                                <Row gutter={8} style={{ marginTop: '3px' }}>
                                    <Col span={9}>
                                        <Form.Item name={['cms', 'insuredLastName']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={9}>
                                        <Form.Item name={['cms', 'insuredFirstName']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item name={['cms', 'insuredMiddleName']}>
                                            <Input />
                                        </Form.Item>

                                    </Col>
                                    <Col span={2} style={{ textAlign: 'center', cursor: 'pointer' }}>
                                        <DashOutlined className="searchdotbtn" onClick={() => searchPatient('insured')} />
                                    </Col>
                                </Row>
                            </Col>
                            <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />
                        </Row>
                        <Row gutter={16}>
                            <Col span={9} className="dashRight" style={{ height: '85px' }}>
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">5</span>
                                    </div>
                                    <span>
                                        PATIENT'S ADDRESS (No. Street):
                                    </span>
                                </div>
                                <Row gutter={8} style={{ marginTop: '10px' }}>
                                    <Col span={24}>
                                        <Form.Item name={['cms', 'patientAddress']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={7} className="dashRight">
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">6</span>
                                    </div>
                                    <span>
                                        PATIENT RELATIONSHIP TO INSURED
                                    </span>
                                </div>
                                <Form.Item name={['cms', 'patientPrimaryInsuranceRel']} >
                                    <Radio.Group className="mediContainer" onChange={(e) => handleChangePRelation(e)}
                                        value={patientRel} initialValues={patientRel} >
                                        <div className="mediRadio">
                                            <Radio size='small' value="Self">Self</Radio>
                                        </div>
                                        <div className="mediRadio">
                                            <Radio size='small' value="Child">Child</Radio>
                                        </div>
                                        <div className="mediRadio">
                                            <Radio size='small' value="Spouse">Spouse</Radio>
                                        </div>
                                        <div className="mediRadio">
                                            <Radio size='small' value="Other">Other</Radio>
                                        </div>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">7</span>
                                    </div>
                                    <span>
                                        INSURED'S ADDRESS (No. Street)
                                    </span>
                                </div>

                                <Row gutter={8} style={{ marginTop: '10px' }}>
                                    <Col span={24}>
                                        <Form.Item name={['cms', 'insuredAddress']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                </Row>
                            </Col>
                            <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />
                        </Row>
                        <Row gutter={16} style={{ height: '150px' }}>
                            <Col span={9} className="dashRight">
                                <Row gutter={6}>
                                    <Col span={12}>
                                        <div className="mediContainer">
                                            <span>
                                                CITY
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="mediContainer">
                                            <span>
                                                STATE
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={8} style={{ marginTop: '10px' }}>
                                    <Col span={12}>
                                        <Form.Item name={['cms', 'patientCity']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name={['cms', 'patientState']}>
                                            <Select />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={6}>
                                    <Col span={12}>
                                        <div className="mediContainer">
                                            <span>
                                                ZIP CODE
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="mediContainer">
                                            <span>
                                                TELEPHONE
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={8} style={{ marginTop: '10px' }}>
                                    <Col span={12}>
                                        <Form.Item name={['cms', 'patientZip']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name={['cms', 'patientCellPhone']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={7} className="dashRight">
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">8</span>
                                    </div>
                                    <span>
                                        RESERVED FOR NUCC USE
                                    </span>
                                </div>
                                <Form.Item name={['cms', 'reservednucc']} style={{ marginTop: '10px' }}>
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Row gutter={6}>
                                    <Col span={12}>
                                        <div className="mediContainer">
                                            <span>
                                                CITY
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="mediContainer">
                                            <span>
                                                STATE
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={8} style={{ marginTop: '10px' }}>
                                    <Col span={12}>
                                        <Form.Item name={['cms', 'insuredCity']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name={['cms', 'insuredState']}>
                                            <Select />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={6}>
                                    <Col span={12}>
                                        <div className="mediContainer">
                                            <span>
                                                ZIP CODE
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="mediContainer">
                                            <span>
                                                TELEPHONE
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={8} style={{ marginTop: '10px' }}>
                                    <Col span={12}>
                                        <Form.Item name={['cms', 'insuredZip']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name={['cms', 'insuredCellPhone']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />
                        </Row>
                        <Row gutter={16} style={{ height: '300px' }}>
                            <Col span={9} className="dashRight" >
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">9</span>
                                    </div>
                                    <span>
                                        OTHER INSURED'S NAME (Last Name, First Name, Middle Init)
                                    </span>
                                </div>
                                <Row gutter={8} style={{ marginTop: '10px' }}>
                                    <Col span={9} className="cmsFormFontStyle">
                                        Last
                                    </Col>
                                    <Col span={9} className="cmsFormFontStyle">
                                        First
                                    </Col>
                                    <Col span={3} className="cmsFormFontStyle">
                                        MI
                                    </Col>
                                </Row>
                                <Row gutter={8} style={{ marginTop: '3px' }}>
                                    <Col span={9}>
                                        <Form.Item name={['cms', 'insureOtherLastname']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={9}>
                                        <Form.Item name={['cms', 'insureOtherFirstname']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item name={['cms', 'insureOthermiddlename']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2} style={{ textAlign: 'center' }}>
                                        <DashOutlined className="searchdotbtn" onClick={() => searchPatient('otherinsured')} />
                                    </Col>
                                </Row>

                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">a</span>
                                    </div>
                                    <span>
                                        OTHER INSURED'S POLICY OR GROUP NUMBER
                                    </span>
                                </div>
                                <Col span={24}>
                                    <Form.Item name={['cms', 'otherIsuredPolcyNo']}>
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">b</span>
                                    </div>
                                    <span>
                                        RESERVED FOR NUCC USE
                                    </span>
                                </div>
                                <Col span={24}>
                                    <Form.Item name={['cms', 'reservedfornuccb']}>
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">c</span>
                                    </div>
                                    <span>
                                        RESERVED FOR NUCC USE
                                    </span>
                                </div>
                                <Col span={24}>
                                    <Form.Item name={['cms', 'reservedfornuccc']}>
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                            </Col>
                            <Col span={7} className="dashRight ">
                                <Row>
                                    <Col span={24}>
                                        <div className="mediContainer">
                                            <div className="lableText">
                                                <span className="lableHightlight">10</span>
                                            </div>
                                            <span>
                                                IS PATIENT'S CONDITION RELATED TO:
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div className="mediContainer">
                                            <div className="lableText">
                                                <span className="lableHightlight">a</span>
                                            </div>
                                            <span>
                                                EMPLOYMENT? (CURRENT OR PREVIOUS)
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={18} justify="center">
                                        <Form.Item name={['cms', 'employment']} >
                                            <Radio.Group className="mediContainer"
                                                onChange={handleChangeEmp}
                                                value={employe} initialValues={employe}>
                                                <div className="mediRadio w50">
                                                    <Radio size='small' value="Yes">Yes</Radio>
                                                </div>
                                                <div className="mediRadio w50">
                                                    <Radio size='small' value="No">No</Radio>
                                                </div>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col span={16}>
                                        <div className="mediContainer">
                                            <div className="lableText">
                                                <span className="lableHightlight">b</span>
                                            </div>
                                            <span>
                                                AUTO ACCIDENT?
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div className="mediContainer">
                                            <span>
                                                PLACE(State)
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={16}>
                                        <Form.Item name={['cms', 'accident']} >
                                            <Radio.Group className="mediContainer"
                                                onChange={(e) => handleChangeOtherAccident(e, 'accident')}
                                                value={accident} initialValues={accident}>
                                                <div className="mediRadio w50">
                                                    <Radio size='small' value="Yes">Yes</Radio>
                                                </div>
                                                <div className="mediRadio w50">
                                                    <Radio size='small' value="No">No</Radio>
                                                </div>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name={['cms', 'accidentPlace']} >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={16}>
                                        <div className="mediContainer">
                                            <div className="lableText">
                                                <span className="lableHightlight">c</span>
                                            </div>
                                            <span>
                                                OTHER ACCIDENT?
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={16}>
                                        <Form.Item name={['cms', 'employment']}>
                                            <Radio.Group className="mediContainer"
                                                onChange={(e) => handleChangeOtherAccident(e, 'employment')}
                                                value={employment} initialValues={employment}>
                                                <div className="mediRadio w50">
                                                    <Radio size='small' value="Yes">Yes</Radio>
                                                </div>
                                                <div className="mediRadio w50">
                                                    <Radio size='small' value="No">No</Radio>
                                                </div>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={8}>
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">11</span>
                                    </div>
                                    <span>
                                        INSURED'S POLICY GROUP OR FECA NUMBER
                                    </span>
                                </div>
                                <Col span={24}>
                                    <Form.Item name={['cms', 'Isuredgroupnofeca']}>
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Row gutter={8}>
                                        <Col span={14}>
                                            <div className="mediContainer">
                                                <div className="lableText">
                                                    <span className="lableHightlight">a</span>
                                                </div>
                                                <span>
                                                    INSURED'S DATE OF BIRTH
                                                </span>
                                            </div>
                                            <Col span={24}>
                                                <Form.Item name={['cms', 'insureddob']}>
                                                    <DatePicker format={'MM/DD/YYYY'} />
                                                </Form.Item>
                                            </Col>
                                        </Col>
                                        <Col span={10}>
                                            <div className="mediContainer">
                                                <span>
                                                    SEX
                                                </span>
                                            </div>
                                            <Col span={24}>
                                                <Form.Item name={['cms', 'insuredSex']}>
                                                    <Select>
                                                        <Select.Option value="Male">Male</Select.Option>
                                                        <Select.Option value="Female">Female</Select.Option>
                                                        <Select.Option value="Other">Other</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <div className="mediContainer">
                                        <div className="lableText">
                                            <span className="lableHightlight">b</span>
                                        </div>
                                        <span>
                                            Other Claim ID (Designated by NUCC)
                                        </span>
                                    </div>
                                    <Col span={24}>
                                        <Form.Item name={['cms', 'otherIsuredclaimIdnucc']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Col>
                                <Col span={24}>
                                    <div className="mediContainer">
                                        <div className="lableText">
                                            <span className="lableHightlight">c</span>
                                        </div>
                                        <span>
                                            INSURANCE PLAN NAME OR PROGRAM NAME
                                        </span>
                                    </div>
                                    <Col span={24}>
                                        <Form.Item name={['cms', 'otherIsurancePlanName']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Col>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={9} className="dashRight">
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">d</span>
                                    </div>
                                    <span>
                                        INSURANCE PLAN NAME OR PROGRAM NAME
                                    </span>
                                </div>
                                <Form.Item name={['cms', 'insurancePlaneName']}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={7} className="dashRight">
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">d</span>
                                    </div>
                                    <span>
                                        CLAIM CODES (Designated by NUCC)
                                    </span>
                                </div>
                                <Form.Item name={['cms', 'claimCodeNucc']}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={8} >
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">d</span>
                                    </div>
                                    <span>
                                        IS THERE ANOTHER HEALTH BENEFIT PLAN?
                                    </span>
                                </div>
                                <Col span={14} style={{ textAlign: 'center' }} >
                                    <Form.Item name={['cms', 'anotherhealthPlane']}>
                                        <Radio.Group className="mediContainer"
                                            onChange={(e) => handleChangeOtherAccident(e, 'anotherhealthPlane')}
                                            value={anotherhealthPlane}>
                                            <div className="mediRadio w50">
                                                <Radio size='small' value="Yes">Yes</Radio>
                                            </div>
                                            <div className="mediRadio w50">
                                                <Radio size='small' value="No">No</Radio>
                                            </div>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>

                            </Col>
                            <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />

                        </Row>
                        <Row gutter={16}>
                            <Col span={16} className="dashRight">
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">12</span>
                                    </div>
                                    <span>
                                        PATIENT'S OR AUTHORIZED PERSON'S SIGNATURE:
                                    </span>
                                </div>
                                <Row gutter={8}>
                                    <Col span={6} className="radioXS">
                                        <Form.Item name={['cms', 'patientAuth']}>
                                            <Radio.Group className="mediContainer"
                                                onChange={(e) => handleChangeOtherAccident(e, 'patientAuth')}
                                                label='SIGNED' value={patientAuth} initialValues={patientAuth}>
                                                <div className="mediRadio w50">
                                                    <Radio size='small' value="Yes">Yes</Radio>
                                                </div>
                                                <div className="mediRadio w50">
                                                    <Radio size='small' value="No">No</Radio>
                                                </div>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name={['cms', 'patientAzthorSign']}>
                                            <DatePicker format={'MM/DD/YYYY'} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={8} >
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">13</span>
                                    </div>
                                    <span>
                                        INSURED'S OR AUTHORIZED PERSON'S SIGNATURE
                                    </span>
                                </div>
                                <Col span={14} style={{ textAlign: 'center' }} >
                                    <Row>
                                        <Col span={8} className="radioXS">
                                            <span > SIGNED</span>
                                        </Col>
                                        <Col span={14} style={{ marginLeft: '8px' }}>
                                            <Form.Item name={['cms', 'insuredAuthourPerson']}>
                                                <Radio.Group className="mediContainer"
                                                    onChange={(e) => handleChangeOtherAccident(e, 'insuredAuthourPerson')}
                                                    value={insuredAuthourPerson}>
                                                    <div className="mediRadio w50">
                                                        <Radio size='small' value="Yes">Yes</Radio>
                                                    </div>
                                                    <div className="mediRadio w50">
                                                        <Radio size='small' value="No">No</Radio>
                                                    </div>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                    </Row>


                                </Col>

                            </Col>
                            <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />

                        </Row>
                        <Row gutter={16} style={{ height: '85px' }}>
                            <Col span={9} className="dashRight">
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">14</span>
                                    </div>
                                    <span>
                                        DATE OF CURRENT ILLNESS, INJURY, or PREGNANCY (LMP):
                                    </span>
                                </div>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item name={['cms', 'pregnancydateCurrent']}>
                                            <DatePicker format={'MM/DD/YYYY'} />
                                        </Form.Item>

                                    </Col>
                                    <Col span={12}>
                                        QUAL <Select style={{ width: '100px' }}>
                                            <Option value="431">431 - Onset of Current Symptom of illness </Option>
                                            <Option value="484">484 - Last Menstrual Date </Option>
                                        </Select>
                                    </Col>
                                </Row>

                            </Col>
                            <Col span={7} className="dashRight">
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">15</span>
                                    </div>
                                    <span>
                                        OTHER DATE
                                    </span>
                                </div>
                                <Row gutter={8}>
                                    <Col span={12}>
                                        <Form.Item name={['cms', 'otherDate']}>
                                            <DatePicker format="MM/DD/YYYY" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name={['cms', 'otherDateType']}>
                                            <Select style={{ width: '100%' }}>
                                                <Option value="454">454 - Initial Treatment</Option>
                                                <Option value="304">304 - Latest Visit or Consultation</Option>
                                                <Option value="453">453 - Acute Manifestation of a Chronic Condition</Option>
                                                <Option value="439">439 - Accident</Option>
                                                <Option value="455">455 - Last X-ray</Option>
                                                <Option value="471">471 - Prescription</Option>
                                                <Option value="90">090 - Report Start (Assumed Care Date)</Option>
                                                <Option value="91">091 - Report End (Relinquished Care Date)</Option>
                                                <Option value="444">444 - First Visit or Consultation</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={8}>
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">16</span>
                                    </div>
                                    <span>
                                        DATES PATIENT UNABLE TO WORK IN CURRENT OCCUPATION
                                    </span>
                                </div>
                                <Row gutter={8}>
                                    <Col span={3}>
                                        From
                                    </Col>
                                    <Col span={9}>
                                        <Form.Item name={['cms', 'billingOptiondateunableWorkedfrom']}>
                                            <DatePicker format={'MM/DD/YYYY'} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        To
                                    </Col>
                                    <Col span={9}>
                                        <Form.Item name={['cms', 'billingOptiondateunableWorkedto']}>
                                            <DatePicker format={'MM/DD/YYYY'} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />

                        </Row>
                        <Row gutter={16} style={{ height: '85px' }}>
                            <Col span={9} className="dashRight">
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">17</span>
                                    </div>
                                    <span>
                                        NAME OF REFERRING PROVIDER OR OTHER SOURCE
                                    </span>
                                </div>
                                <Row gutter={8}>
                                    <Col span={5}>
                                        <Form.Item name={['cms', 'referringproviderpref']}>
                                            <Select>
                                                <Select.Option value="DN">Referring Provider</Select.Option>
                                                <Select.Option value="DK">Ordering Provider</Select.Option>
                                                <Select.Option value="DQ">Supervising Provider</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={7}>
                                        <Form.Item name={['cms', 'referringproviderfirst']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item name={['cms', 'referringprovidermi']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={7}>
                                        <Form.Item name={['cms', 'referringproviderlast']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <DashOutlined />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={7} className="dashRight">
                                <Row gutter={8}>
                                    <Col span={8}>
                                        <div className="mediContainer">
                                            <div className="lableText">
                                                <span className="lableHightlight">17a</span>
                                            </div>
                                            <span>
                                                <Form.Item name={['cms', 'a17']}>
                                                    <Select showSearch size="small" style={{ fontSize: '11px', width: '55px' }}>
                                                        <Option value="0B">0B - State License Number</Option>
                                                        <Option value="1A">1A - Blue Cross Provider Number</Option>
                                                        <Option value="1B"> 1B - Blue Shield Provider Number</Option>
                                                        <Option value="1C"> 1C - Medicare Provider Number </Option>
                                                        <Option value="1D" >1D - Medicaid Provider Number </Option>
                                                        <Option value="1G" >1G - Provider UPIN Number  </Option>
                                                        <Option value="1H">1H - CHAMPUS Identification Number</Option>
                                                        <Option value="B3"> B3 - Preferred Provider Organization Number</Option>
                                                        <Option value="BQ"> BQ - Health Maintenance Organization Code Number</Option>
                                                        <Option value="EI"> EI - 	Employer's Identification Number</Option>
                                                        <Option value="FH"> FH - Clinic Number </Option>
                                                        <Option value="G2"> G2- Provider Commercial Number </Option>
                                                        <Option value="IJ">IJ-  Facility ID Number</Option>
                                                        <Option value="LU"> LU -Location Number</Option>
                                                        <Option value="N5"> N5 - Provider Plan Network Identification Number</Option>
                                                        <Option value="SY"> SY - Social Security Number (The social security number may not be used for Medicare.)</Option>
                                                        <Option value="U3">U3 - Unique Supplier Identification Number</Option>
                                                        <Option value="X5">X5 - State Industrial Accident Provider Number</Option>
                                                        <Option value="ZZ">ZZ - Provider Taxonomy</Option>
                                                    </Select>
                                                </Form.Item>
                                            </span>
                                            {/* <span>
                                                <DashOutlined />
                                            </span> */}
                                        </div>
                                    </Col>
                                    <Col span={16}>
                                        <Form.Item name={['cms', 'inpouta17']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={8}>
                                    <Col span={8}>
                                        <div className="mediContainer">
                                            <div className="lableText">
                                                <span className="lableHightlight">17b</span>
                                            </div>
                                            <span>
                                                NPI
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={16}>
                                        <Form.Item name={['cms', 'inpoutb17']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={8}>
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">16</span>
                                    </div>
                                    <span>
                                        HOSPITALIZATION DATES RELATED TO CURRENT SERVICES
                                    </span>
                                </div>
                                <Row gutter={8}>
                                    <Col span={3}>
                                        From
                                    </Col>
                                    <Col span={9}>
                                        <Form.Item name={['cms', 'billingOptiondateHospitalfrom']}>
                                            <DatePicker format={'MM/DD/YYYY'} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        To
                                    </Col>
                                    <Col span={9}>
                                        <Form.Item name={['cms', 'billingOptiondateHopstialto']}>
                                            <DatePicker format={'MM/DD/YYYY'} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />

                        </Row>
                        <Row gutter={16}>
                            <Col span={16} className="dashRight">
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">19</span>
                                    </div>
                                    <span>
                                        ADDITIONAL CLAIM INFORMATION (Designated by NUCC)
                                    </span>
                                </div>
                                <Form.Item name={['cms', 'ClaimInformation']}>
                                    <Input />
                                </Form.Item>

                            </Col>
                            <Col span={8}>
                                <Row gutter={8}>
                                    <Col span={12}>
                                        <div className="mediContainer">
                                            <div className="lableText">
                                                <span className="lableHightlight">20</span>
                                            </div>
                                            <span>
                                                OUTSIDE LAB?
                                            </span>
                                        </div>
                                        <Form.Item name={['cms', 'outsideLab']}>
                                            <Radio.Group className="mediContainer"
                                                onChange={(e) => handleChangeOtherAccident(e, 'outsideLab')}
                                                value={outsideLab} initialValues={outsideLab}>
                                                <div className="mediRadio w50">
                                                    <Radio size='small' value="Yes">Yes</Radio>
                                                </div>
                                                <div className="mediRadio w50">
                                                    <Radio size='small' value="No">No</Radio>
                                                </div>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <div className="mediContainer">
                                            <span>
                                                CHARGES
                                            </span>
                                        </div>
                                        <Form.Item name={['cms', 'charges']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />
                        </Row>
                        <Row gutter={16}>
                            <Col span={16} className="dashRight">
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">20</span>
                                    </div>
                                    <span>
                                        DIAGNOSIS OR NATURE OF ILLNESS OR INJURY. (Relate A-L to service line below (24E)) ICD Ind
                                        <Select style={{ width: '80px' }} />
                                    </span>
                                </div>
                                <Row gutter={4} style={{ marginTop: '10px' }}>
                                    <Col span={6}>
                                        <Row gutter={2}>
                                            <Col span={4}>
                                                <span>A: </span>
                                            </Col>
                                            <Col span={14}>
                                                <Form.Item name={['cms', 'icd9', 0, 'code']}>
                                                    <Input prefix={<SearchOutlined />} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <DashOutlined onClick={() => handleIcd(0)} className="searchdotbtn" />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={6}>
                                        <Row gutter={2}>
                                            <Col span={4}>
                                                <span>B: </span>
                                            </Col>
                                            <Col span={14}>
                                                <Form.Item name={['cms', 'icd9', 1, 'code']}>
                                                    <Input prefix={<SearchOutlined />} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}><DashOutlined onClick={() => handleIcd(1)} className="searchdotbtn" /></Col>
                                        </Row>
                                    </Col>
                                    <Col span={6}>
                                        <Row gutter={2}>
                                            <Col span={4}>
                                                <span>C: </span>
                                            </Col>
                                            <Col span={14}>
                                                <Form.Item name={['cms', 'icd9', 2, 'code']}>
                                                    <Input prefix={<SearchOutlined />} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}><DashOutlined onClick={() => handleIcd(2)} className="searchdotbtn" /></Col>
                                        </Row>
                                    </Col>
                                    <Col span={6}>
                                        <Row gutter={2}>
                                            <Col span={4}>
                                                <span>D: </span>
                                            </Col>
                                            <Col span={14}>
                                                <Form.Item name={['cms', 'icd9', 3, 'code']}>
                                                    <Input prefix={<SearchOutlined />} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}><DashOutlined onClick={() => handleIcd(3)} className="searchdotbtn" /></Col>
                                        </Row>
                                    </Col>
                                    <Col span={6}>
                                        <Row gutter={2}>
                                            <Col span={4}>
                                                <span>E: </span>
                                            </Col>
                                            <Col span={14}>
                                                <Form.Item name={['cms', 'icd9', 4, 'code']}>
                                                    <Input prefix={<SearchOutlined />} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}><DashOutlined onClick={() => handleIcd(4)} className="searchdotbtn" /></Col>
                                        </Row>
                                    </Col>
                                    <Col span={6}>
                                        <Row gutter={2}>
                                            <Col span={4}>
                                                <span>F: </span>
                                            </Col>
                                            <Col span={14}>
                                                <Form.Item name={['cms', 'icd9', 5, 'code']}>
                                                    <Input prefix={<SearchOutlined />} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}><DashOutlined onClick={() => handleIcd(5)} className="searchdotbtn" /></Col>
                                        </Row>
                                    </Col>
                                    <Col span={6}>
                                        <Row gutter={2}>
                                            <Col span={4}>
                                                <span>G: </span>
                                            </Col>
                                            <Col span={14}>
                                                <Form.Item name={['cms', 'icd9', 6, 'code']}>
                                                    <Input prefix={<SearchOutlined />} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}><DashOutlined onClick={() => handleIcd(6)} className="searchdotbtn" /></Col>
                                        </Row>
                                    </Col>
                                    <Col span={6}>
                                        <Row gutter={2}>
                                            <Col span={4}>
                                                <span>H: </span>
                                            </Col>
                                            <Col span={14}>
                                                <Form.Item name={['cms', 'icd9', 7, 'code']}>
                                                    <Input prefix={<SearchOutlined />} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}><DashOutlined onClick={() => handleIcd(7)} className="searchdotbtn" /></Col>
                                        </Row>
                                    </Col>
                                    <Col span={6}>
                                        <Row gutter={2}>
                                            <Col span={4}>
                                                <span>I: </span>
                                            </Col>
                                            <Col span={14}>
                                                <Form.Item name={['cms', 'icd9', 8, 'code']}>
                                                    <Input prefix={<SearchOutlined />} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}><DashOutlined onClick={() => handleIcd(8)} className="searchdotbtn" /></Col>
                                        </Row>
                                    </Col>
                                    <Col span={6}>
                                        <Row gutter={2}>
                                            <Col span={4}>
                                                <span>J: </span>
                                            </Col>
                                            <Col span={14}>
                                                <Form.Item name={['cms', 'icd9', 9, 'code']}>
                                                    <Input prefix={<SearchOutlined />} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}><DashOutlined onClick={() => handleIcd(9)} className="searchdotbtn" /></Col>
                                        </Row>
                                    </Col>
                                    <Col span={6}>
                                        <Row gutter={2}>
                                            <Col span={4}>
                                                <span>K: </span>
                                            </Col>
                                            <Col span={14}>
                                                <Form.Item name={['cms', 'icd9', 10, 'code']}>
                                                    <Input prefix={<SearchOutlined />} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}><DashOutlined onClick={() => handleIcd(10)} className="searchdotbtn" /></Col>
                                        </Row>
                                    </Col>
                                    <Col span={6}>
                                        <Row gutter={2}>
                                            <Col span={4}>
                                                <span>L: </span>
                                            </Col>
                                            <Col span={14}>
                                                <Form.Item name={['cms', 'icd9', 11, 'code']}>
                                                    <Input prefix={<SearchOutlined />} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}><DashOutlined onClick={() => handleIcd(11)} className="searchdotbtn" /></Col>
                                        </Row>
                                    </Col>

                                </Row>
                            </Col>
                            <Col span={8}>
                                <Row gutter={2}>
                                    <Col span={12}>
                                        <div className="mediContainer">
                                            <div className="lableText">
                                                <span className="lableHightlight">22</span>
                                            </div>
                                            <span>
                                                RESUBMISSIONCODE
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={12}><div className="mediContainer">
                                        <span>ORIGINAL REF. NO</span>
                                    </div>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name={['cms', 'billingOptiondateMMedicaidCode']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name={['cms', 'billingOptiondateOriginal']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={2}>
                                    <Col span={24}>
                                        <div className="mediContainer">
                                            <div className="lableText">
                                                <span className="lableHightlight">23</span>
                                            </div>
                                            <span>
                                                PRIOR AUTHORIZATION NUMBER
                                            </span>
                                        </div>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item name={['cms', 'billingOptiondateMPriorAuthorization']}>
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                        <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />
                        <Row style={{ marginTop: '15px', marginLeft: '-2px' }}>
                            <Col span={4} className="tableClaimHeader">
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">24a</span>
                                    </div>
                                </div>
                            </Col>
                            <Col span={2} className="tableClaimHeader" >
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight txtCenter">b</span>
                                    </div>
                                </div>
                            </Col>
                            <Col span={2} className="tableClaimHeader">
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">c</span>
                                    </div>
                                </div>
                            </Col>
                            <Col span={4} className="tableClaimHeader">
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">d</span>
                                    </div>
                                    <span>
                                        PROCEDURES, SERVICES, OR SUPPLIES
                                    </span>
                                </div>
                            </Col>
                            <Col span={2} className="tableClaimHeader">
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">e</span>
                                    </div>
                                </div>
                            </Col>
                            <Col span={2} className="tableClaimHeader">
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">f</span>
                                    </div>
                                </div>
                            </Col>
                            <Col span={2} className="tableClaimHeader">
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">g</span>
                                    </div>
                                </div>
                            </Col>
                            <Col span={2} className="tableClaimHeader">
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">h</span>
                                    </div>
                                </div>
                            </Col>
                            <Col span={2} className="tableClaimHeader">
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">i</span>
                                    </div>
                                </div>
                            </Col>
                            <Col span={2} className="tableClaimHeader">
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">j</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={4} style={{ marginTop: '2px', paddingBottom: '5px' }} className="tableClaimHeader">
                            <Col span={4} className="tableClaimHeader">
                                <Row >
                                    <Col span={24} className="txtCenter">
                                        <div className="mediContainer">
                                            <span>
                                                Date(s) if Service
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={12} className="txtCenter">
                                        <div className="mediContainer">
                                            <span>
                                                From
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={12} className="txtCenter">
                                        <div className="mediContainer">
                                            <span>
                                                To
                                            </span>
                                        </div>
                                    </Col>
                                </Row>

                            </Col>
                            <Col span={2} className="tableClaimHeader txtCenter" >
                                <div className="mediContainer">
                                    <span>
                                        POS
                                    </span>
                                </div>
                            </Col>
                            <Col span={2} className="tableClaimHeader txtCenter">
                                <div className="mediContainer">
                                    <span>
                                        EMG
                                    </span>
                                </div>
                            </Col>
                            <Col span={4} className="tableClaimHeader">
                                <Row>
                                    <Col span={10} className="borderWhite txtCenter">
                                        <div className="mediContainer">
                                            <span>
                                                CPT/HCPCS
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={14}>
                                        <div className="mediContainer txtCenter">
                                            <span>
                                                Modifier
                                            </span>
                                        </div>
                                        <Row>
                                            <Col span={6} className="txtCenter">
                                                <div className="mediContainer">
                                                    <span>
                                                        A
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col span={6} className="txtCenter">
                                                <div className="mediContainer">
                                                    <span>
                                                        B
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col span={6} className="txtCenter">
                                                <div className="mediContainer">
                                                    <span>
                                                        C
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col span={6} className="txtCenter">
                                                <div className="mediContainer">
                                                    <span>
                                                        D
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </Col>
                            <Col span={2} className="tableClaimHeader txtCenter">
                                <div className="mediContainer">
                                    <span> DIAGNOSIS POINTER</span>
                                </div>
                            </Col>
                            <Col span={2} className="tableClaimHeader txtCenter">
                                <div className="mediContainer">
                                    <span>$ CHARGES</span>
                                </div>
                            </Col>
                            <Col span={2} className="tableClaimHeader txtCenter">
                                <div className="mediContainer">
                                    <span>Days or Units</span>
                                </div>
                            </Col>
                            <Col span={2} className="tableClaimHeader txtCenter">
                                <div className="mediContainer">
                                    <span>EPSDT Family Plan</span>
                                </div>
                            </Col>
                            <Col span={2} className="tableClaimHeader txtCenter">
                                <div className="mediContainer">
                                    <span>
                                        ID QUAL
                                    </span>
                                </div>
                            </Col>
                            <Col span={2} className="tableClaimHeader txtCenter">
                                <div className="mediContainer">
                                    <span>
                                        RENDERING PROVIDER ID. #
                                    </span>
                                </div>
                            </Col>
                        </Row>
                        {diagnousList?.map((d, i) => {
                            return (
                                <div key={`${i}-diagno`}>
                                    <Row gutter={2} className="tableLineNote">
                                        <Col span={3} className="txtTable">
                                            <Form.Item name={['cms', 'icdcode', i, 'lineNote']} label={`${i + 1}. Line note`}>
                                                <Input className="xs" size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Form.Item name={['cms', 'icdcode', i, 'anesStart']} label="Anes Start">
                                                <Input className="xs" size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Form.Item name={['cms', 'icdcode', i, 'anesStop']} label="Stop">
                                                <Input className="xs" size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item name={['cms', 'icdcode', i, 'NDC']} label="NDC Qual">
                                                <Input className="xs" size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item name={['cms', 'icdcode', i, 'NDCcode']} label="NDC Code">
                                                <Input className="xs" size="small" suffix={<SearchOutlined />} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item name={['cms', 'icdcode', i, 'NDCUnit']} label="NDC Unit Price">
                                                <Input className="xs" size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Form.Item name={['cms', 'icdcode', i, 'qty']} label="Qty">
                                                <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item name={['cms', 'icdcode', i, 'qtyqual']} label="Qty Qual">
                                                <Select className="xs" size="small" >
                                                    <Option value="F2">
                                                        F2
                                                    </Option>
                                                    <Option value="GR">
                                                        GR
                                                    </Option>
                                                    <Option value="ML">
                                                        ML
                                                    </Option>
                                                    <Option value="UN">
                                                        UN
                                                    </Option>
                                                    <Option value="ME">
                                                        ME
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={1}>
                                            <Row gutter={1}>
                                                <Col span={16}>
                                                    <Form.Item name={['cms', 'icdcode', i, 'render1']}>
                                                        <Input size="small" />

                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <DashOutlined onClick={() => handleprovd('icdcode', i, 'render1')} className="searchdotbtn sm" />
                                                </Col>
                                            </Row>

                                        </Col>

                                        <Col span={2}>
                                            <Row gutter={1}>
                                                <Col span={16}>
                                                    <Form.Item name={['cms', 'icdcode', i, 'render2']}>
                                                        <Input size="small" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <DashOutlined onClick={() => handleprovd('icdcode', i, 'render2')} className="searchdotbtn sm" />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row gutter={2} className="tableIcdcode">
                                        <Col span={4}>
                                            <Row gutter={2}>
                                                <Col span={12} className="txtCenter">
                                                    <Form.Item name={['cms', 'icdcode', i, 'fromDate']}>
                                                        <DatePicker className="xs" size="small" format={'MM/DD/YYYY'} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12} className="txtCenter">
                                                    <Form.Item name={['cms', 'icdcode', i, 'toDate']}>
                                                        <DatePicker className="xs" size="small" format={'MM/DD/YYYY'} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                        </Col>
                                        <Col span={2}  >
                                            <Form.Item name={['cms', 'icdcode', i, 'pos']}>
                                                <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Form.Item name={['cms', 'icdcode', i, 'emg']}>
                                                <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4} >
                                            <Row gutter={1}>
                                                <Col span={10} >
                                                    <Form.Item name={['cms', 'icdcode', i, 'cpt']}>
                                                        <Input size="small" suffix={<SearchOutlined />} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={14}>
                                                    <Row gutter={1}>
                                                        <Col span={6} className="txtCenter">
                                                            <Form.Item name={['cms', 'icdcode', i, 'modified', 0]}>
                                                                <Input size="small" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={6} className="txtCenter">
                                                            <Form.Item name={['cms', 'icdcode', i, 'modified', 1]}>
                                                                <Input size="small" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={6} className="txtCenter">
                                                            <Form.Item name={['cms', 'icdcode', i, 'modified', 2]}>
                                                                <Input size="small" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={6} className="txtCenter">
                                                            <Form.Item name={['cms', 'icdcode', i, 'modified', 3]}>
                                                                <Input size="small" />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>

                                        </Col>
                                        <Col span={2}>
                                            <Form.Item name={['cms', 'icdcode', i, 'icd10Pointer']}>
                                                <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Form.Item name={['cms', 'icdcode', i, 'lineCharge']}>
                                                <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Form.Item name={['cms', 'icdcode', i, 'dayorunit']}>
                                                <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Form.Item name={['cms', 'icdcode', i, 'epsdt']}>
                                                <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2} >
                                            <Form.Item name={['cms', 'icdcode', i, 'idqual']}>
                                                <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2} >
                                            <Form.Item name={['cms', 'icdcode', i, 'renderid']}>
                                                <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })}
                        <Row gutter={16}>
                            <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />
                            <Col span={6} className="dashRight">
                                <Row gutter={4}>
                                    <Col span={14}>
                                        <div className="mediContainer">
                                            <div className="lableText">
                                                <span className="lableHightlight">25</span>
                                            </div>
                                            <span style={{ display: 'inline-block', width: '200px' }}>
                                                FEDERAL TAX I.D. NUMBER
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={10}>
                                        <div className="mediContainer">
                                            <span>
                                                SSN
                                            </span>
                                            <span>
                                                EIN
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={4}>
                                    <Col span={12}>
                                        <Form.Item name={['cms', 'fdtax']}>
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Radio.Group className="mediContainer" style={{
                                            marginTop: '0px',
                                            textAlign: 'center'
                                        }} onChange={(e) => handleChangeMedic(e, 'snnorein')} name={['cmd', 'snnorein']} value={medicCare?.snnorein}>
                                            <div className="mediRadio">
                                                <Radio size='small' value="SNN" />
                                            </div>
                                            <div className="mediRadio">
                                                <Radio size='small' value="EIN" />
                                            </div>
                                        </Radio.Group>
                                    </Col>
                                </Row>

                            </Col>
                            <Col span={4} className="dashRight">
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">25</span>
                                    </div>
                                    <span>
                                        PATIENT'S ACCOUNT NO.
                                    </span>
                                </div>
                                <Form.Item name={['cms', 'pAcc']}>
                                    <Input size="small" />
                                </Form.Item>
                            </Col>

                            <Col span={4} className="dashRight">
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">25</span>
                                    </div>
                                    <span>
                                        ACCEPT ASSIGNMENT?
                                    </span>
                                </div>
                                <Radio.Group className="mediContainer" onChange={(e) => handleChangeMedic(e, 'pACC')} name={['cmd', 'pACC']} value={medicCare?.pACC}>
                                    <div className="mediRadio">
                                        <Radio size='small' value="yes" >Yes</Radio>
                                    </div>
                                    <div className="mediRadio">
                                        <Radio size='small' value="no" > No </Radio>
                                    </div>
                                </Radio.Group>
                            </Col>
                            <Col span={3} className="dashRight">
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">25</span>
                                    </div>
                                    <span>
                                        TOTAL CHARGE
                                    </span>
                                </div>

                                <Form.Item name={['cms', 'totalChr']}>
                                    <Input size="small" />
                                </Form.Item>
                            </Col>
                            <Col span={3} className="dashRight">
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">29</span>
                                    </div>
                                    <span>
                                        AMOUNT PAID
                                    </span>
                                </div>
                                <Form.Item name={['cms', 'amPaid']}>
                                    <Input size="small" />
                                </Form.Item>
                            </Col>
                            <Col span={4} >
                                <div className="mediContainer">
                                    <div className="lableText">
                                        <span className="lableHightlight">30</span>
                                    </div>
                                    <span>
                                        Rsvd for NUCC use
                                    </span>
                                </div>
                                <Form.Item name={['cms', 'rsvdNucc']}>
                                    <Input size="small" />
                                </Form.Item>
                            </Col>
                            <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />
                        </Row>
                        <Row gutter={8}>
                            <Col span={8} className="dashRight">
                                <Row>
                                    <Col span={11}>
                                        <div className="mediContainer">
                                            <div className="lableText">
                                                <span className="lableHightlight">31</span>
                                            </div>
                                            <span>
                                                Date Of Initial Treatment (mm/dd/yyyy)
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={13}>
                                        <Form.Item name={['cms', 'billingOptiondateInitialTreatment']} >
                                            <DatePicker size="small" format="MM/DD/YYYY" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={11}>
                                        <div className="mediContainer">
                                            <span>
                                                Latest Visit or Consultation Date (mm/dd/yyyy)
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={13}>
                                        <Form.Item name={['cms', 'visitDate']} label="">
                                            <Input size="small" format="MM/DD/YYYY" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={11}>
                                        <div className="mediContainer">
                                            <span>
                                                Supervising Physician
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item name={['cms', 'billingSupervisingFirstname']}>
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <DashOutlined className="searchdotbtn sm" onClick={() => searchProvders('Supervising Provider')} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={11}>
                                        <div className="mediContainer">
                                            <span>
                                                Supervising Physician NPI
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={13}>
                                        <Form.Item name={['cms', 'billingSupervisingPhysicicanNpi']} >
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={11}>
                                        <div className="mediContainer">
                                            <span>
                                                Supervising Physician ID
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={13}>
                                        <Form.Item name={['cms', 'billingSupervisingPhysicicanID']}>
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                </Row> 
                                <Row>
                                    <Col span={11}>
                                        <div className="mediContainer">
                                            <span>
                                                Ordering Physician (Last, First, MI)
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={11}>
                                        <Row gutter={2}>
                                            <Col span={10}>
                                                <Form.Item name={['cms', 'billingOderingLastname']} >
                                                    <Input size="small" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={10}>
                                                <Form.Item name={['cms', 'billingOderingFirstname']} >
                                                    <Input size="small" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={4}>
                                                <Form.Item name={['cms', 'billinOderingMiddlename']}>
                                                    <Input size="small" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col> 
                                    <Col span={2}>
                                        <DashOutlined className="searchdotbtn sm" onClick={() => searchProvders('Order Provider')} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={11}>
                                        <div className="mediContainer">
                                            <span>
                                                Ordering Physician NPI
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={13}>
                                        <Form.Item name={['cms', 'billingPOderinghysicicanNpi']}>
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={11}>
                                        <div className="mediContainer">
                                            <span>
                                                Ordering Physician ID:
                                            </span>
                                        </div>
                                    </Col> 
                                    <Col span={13}>
                                        <Form.Item name={['cms', 'billingOderingPhysicicanID']}>
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={11}>
                                        <div className="mediContainer">
                                            <span>
                                                CLIA
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={13}>
                                        <Form.Item name={['cms', 'clia']}>
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={11}>
                                        <div className="mediContainer">
                                            <span>
                                                Accident Date
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={13}>
                                        <Form.Item name={['cms', 'billingOptiondateAccidentDate']}>
                                            <DatePicker size="small" format="MM/DD/YYYY" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={11}>
                                        <div className="mediContainer">
                                            <span>
                                                Mammography Certificate
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={13}>
                                        <Form.Item name={['cms', 'billingOptiondateMMammography']}>
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={16}>
                                <Row gutter={4}>
                                    <Col span={12} className="dashRight">

                                        <div className="mediContainer">
                                            <div className="lableText">
                                                <span className="lableHightlight">32</span>
                                            </div>
                                            <span>
                                                SERVICE FACILITY LOCATION AND INFORMATION
                                            </span>
                                        </div>
                                        <Row gutter={4}>
                                            <Col span={6}>
                                                <div className="mediContainer">
                                                    <span>
                                                        Facility Name
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col span={16}>
                                                <Form.Item name={['cms', 'billingOptionFacilityName']} >
                                                    <Input size="small" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                <DashOutlined className="searchdotbtn sm" onClick={() => handleFacility()} />
                                            </Col>
                                        </Row>
                                        <Row gutter={4}>
                                            <Col span={6}>
                                                <div className="mediContainer">
                                                    <span>
                                                        Address
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col span={18}>
                                                <Form.Item name={['cms', 'billingOptionFacilityAddress']} >
                                                    <Input size="small" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={4}>
                                            <Col span={6}>
                                                <div className="mediContainer">
                                                    <span>
                                                        City, State, Zip
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col span={18}>
                                                <Row gutter={4}>
                                                    <Col span={8}>
                                                        <Form.Item name={['cms', 'billingOptionFacilityCity']} >
                                                            <Input size="small" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item name={['cms', 'billingOptionFacilityState']} >
                                                            <Select size="small" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item name={['cms', 'billingOptionFacilityZip']} >
                                                            <Input size="small" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>

                                    </Col>
                                    <Col span={12} >
                                        <div className="mediContainer">
                                            <div className="lableText">
                                                <span className="lableHightlight">32</span>
                                            </div>
                                            <span>
                                                BILLING PROVIDER INFO. & PHONE #
                                            </span>
                                        </div>
                                        <Row gutter={4}>
                                            <Col span={6}>
                                                <div className="mediContainer">
                                                    <span>
                                                        Billing Provider Name
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col span={16}>
                                                <Form.Item name={['cms', 'billingOptionBProviderName']} >
                                                    <Input size="small" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                <DashOutlined className="searchdotbtn sm" onClick={() => searchBillingProvd()} />
                                            </Col>
                                        </Row>
                                        <Row gutter={4}>
                                            <Col span={6}>
                                                <div className="mediContainer">
                                                    <span>
                                                        Address
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col span={18}>
                                                <Form.Item name={['cms', 'billingOptionBProviderAddress']} >
                                                    <Input size="small" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={4}>
                                            <Col span={6}>
                                                <div className="mediContainer">
                                                    <span>
                                                        City, State, Zip
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col span={18}>
                                                <Row gutter={4}>
                                                    <Col span={8}>
                                                        <Form.Item name={['cms', 'billingOptionBProviderCity']} >
                                                            <Input size="small" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item name={['cms', 'billingOptionBProviderState']} >
                                                            <Select size="small" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item name={['cms', 'billingOptionBProviderZip']} >
                                                            <Input size="small" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row gutter={4}>
                                            <Col span={6}>
                                                <div className="mediContainer">
                                                    <span>
                                                        Telephone
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col span={18}>
                                                <Form.Item name={['cms', 'bilingOptionTelephone']} >
                                                    <Input size="small" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={4}>
                                            <Col span={6}>
                                                <div className="mediContainer">
                                                    <span>
                                                        Specialty/ Taxonomy
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col span={18}>
                                                <Form.Item name={['cms', 'billingOptionBProviderTaxonomy']} >
                                                    <Input size="small" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={4}>
                                            <Col span={6}>
                                                <div className="mediContainer">
                                                    <span>
                                                        Rendering Provider (Last, First, MI)
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col span={18}>
                                                <Row gutter={2}>
                                                    <Col span={9}>
                                                        <Form.Item name={['cms', 'facilityRenderingProdLast']} >
                                                            <Input size="small" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={9}>
                                                        <Form.Item name={['cms', 'facilityRenderingProdFirst']} >
                                                            <Input size="small" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4}>
                                                        <Form.Item name={['cms', 'facilityRenderingProdMI']} >
                                                            <Input size="small" />
                                                        </Form.Item>
                                                    </Col> 
                                                    <Col span={2}>
                                                        <DashOutlined className="searchdotbtn sm" onClick={() => searchReferringProvd()} />
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>
                                        <Row gutter={4}>
                                            <Col span={6}>
                                                <div className="mediContainer">
                                                    <span>
                                                        Provider Specialty
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col span={18}>

                                                <Form.Item name={['cms', 'facilityProvideSepc']} >
                                                    <Input size="small" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={4}>
                                            <Col span={6}>
                                                <div className="mediContainer">
                                                    <span>
                                                        Provider
                                                        PIN#:
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col span={18}>

                                                <Form.Item name={['cms', 'facilityProviderPin']} >
                                                    <Input size="small" />
                                                </Form.Item>


                                            </Col>

                                        </Row>
                                    </Col>
                                </Row>
                                <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />
                                <Row gutter={4}>
                                    <Col span={6}>
                                        <div className="mediContainer">
                                            <div className="lableText">
                                                <span className="lableHightlight">a</span>
                                            </div>
                                            <span>
                                                NPI
                                            </span>
                                        </div>
                                        <Form.Item name={['cms', 'billingOptionFacilityNPI']} >
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} className="dashRight">
                                        <div className="mediContainer">
                                            <div className="lableText">
                                                <span className="lableHightlight">b</span>
                                            </div>
                                            <span>
                                                Facility ID
                                            </span>
                                        </div>
                                        <Form.Item name={['cms', 'billingOptionFacilityProID']} >
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <div className="mediContainer">
                                            <div className="lableText">
                                                <span className="lableHightlight">a </span>
                                            </div>
                                            <span>
                                                Billing /Group NPI
                                            </span>
                                        </div>
                                        <Form.Item name={['cms', 'billingOptionBProviderNPI']} >
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <div className="mediContainer">
                                            <div className="lableText">
                                                <span className="lableHightlight">b</span>
                                            </div>
                                            <span>
                                                Billing /Group No ID Qual
                                            </span>
                                        </div>
                                        <Form.Item name={['cms', 'billingOptionBProvidergroupno']} >
                                            <Input size="small" />
                                        </Form.Item>
                                    </Col>

                                </Row>
                            </Col>

                            <Divider dashed={true} style={{ borderColor: 'rgb(5 5 5 / 47%)', margin: '0px' }} />
                        </Row>
                        <Row >
                            <Col span={4} style={{ cursor: 'pointer' }} onClick={() => setIsShowDiv(!isShowDiv)}>
                                more fields....
                            </Col>
                        </Row>
                        {isShowDiv && (
                            <Row style={{ marginTop: '15px' }}>
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Last XRay Date (yyyy/mm/dd):
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'billingOptiondateLastXrayDate']}>
                                        <DatePicker format='MM/DD/YYYY' />
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Date Last Worked:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'billingOptiondateLastWorked']}>
                                        <DatePicker format={'MM/DD/YYYY'} />
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Date Authorized to Return to Work:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'billingOptiondateDateAuth']}>
                                        <DatePicker format={'MM/DD/YYYY'} />
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Assumed Care Date:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'billingOptiondateAssumedCareDate']}>
                                        <DatePicker format={'MM/DD/YYYY'} />
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Relinquished Care Date:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'billingOptiondateRelinquishedCareDate']}>
                                        <DatePicker format={'MM/DD/YYYY'} />
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Acute Manifestation Date:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'billingOptiondateActueManifest']}>
                                        <DatePicker format={'MM/DD/YYYY'} />
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            First Visit or Consultation Date:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'billingOptiondateConsultationDate']}>
                                        <DatePicker format={'MM/DD/YYYY'} />
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Prescription Date:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'billingOptiondatePrescriptionDate']}>
                                        <DatePicker format={'MM/DD/YYYY'} />
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Oth. Ins. Date of Birth (9b):
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'othDateBirth9b']}>
                                        <DatePicker format={'MM/DD/YYYY'} />
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Oth. Ins. Emp./Sch. Name (9c):
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'empSchemaName9c']}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Ins. Empl. Name or Schl. Name (11b):
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'empSchlName11b']}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Reserved For Local Use (10d):
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'billingOptiondateMReserve']}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Same or Similar Illness First Date(15):
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'similIllnessFirstDate15']}>
                                        <DatePicker format={'MM/DD/YYYY'} />
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Patient Marital Status (8):
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'patientMartialStatus8']}>
                                        <Select >
                                            <Select.Option value="Single">Single</Select.Option>
                                            <Select.Option value="Married">Married</Select.Option>
                                            <Select.Option value="Other">Other</Select.Option>

                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Patient Empl. Status (8):
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'patientEmplStatus8']}>
                                        <Select >
                                            <Select.Option value="Employed">Employed</Select.Option>
                                            <Select.Option value="FullTime Student">FullTime Student</Select.Option>
                                            <Select.Option value="PartTime Student">PartTime Student</Select.Option>

                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Balance Due (30):
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'balanceDue30']}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Delay Reason Code:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'billingOptiondateMDelayReason']}>
                                        <Select sho>
                                            {opt?.map((d) => <Option key={d} value={d}>{d}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Ambulance Transport Code:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'billingOptiondateMAmbulance']}>
                                        <Select showSearch>
                                            <Option value="I-Initial trip only">I- Initial trip only</Option>
                                            <Option value="R-Return trip only">R- Return trip only</Option>
                                            <Option value="T-Transport trip only">T- Transport trip only</Option>
                                            <Option value="X-Round trip only">T- Round trip</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Ambulance Transport Reason Code:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'billingOptiondateMAmbulancereasoncode']}>
                                        <Select showSearch>
                                            <Option value="A">
                                                A - Patient was transported to nearest facility for care of Symptom, complaints or both</Option>
                                            <Option value="B">
                                                B - Patient was transported for the benefit of preffered Physician
                                            </Option>
                                            <Option value="C">C-Patient was transported for the nearess of family member</Option>
                                            <Option value="D">D-Patient was transported for the care specialities or availablitiy of  specialitiezed euqipment  </Option>
                                            <Option value="E"> E- Patient Transferred to Rehabilitation Facility </Option>

                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Ambulance Condition Code:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'billingOptiondateMAmbulanceCondition']}>
                                        <Select >
                                            <Option value="01">
                                                01 - Patient was admitted to a hospital
                                            </Option>
                                            <Option value="02">
                                                02 - Patient was bed confined before the ambulance service
                                            </Option>
                                            <Option value="03">
                                                03 - Patient was bed confined after the ambulance service
                                            </Option>
                                            <Option value="04">
                                                04 - Patient was moved by stretcher
                                            </Option>
                                            <Option value="05">
                                                05 - Patient was unconscious or in shock
                                            </Option>
                                            <Option value="06">
                                                06 - Patient was transported in an emergency situation
                                            </Option>
                                            <Option value="07">
                                                07 - Patient had to be physically restrained
                                            </Option>
                                            <Option value="08">
                                                08 - Patient had visible hemorrhaging
                                            </Option>
                                            <Option value="09">
                                                09 - Ambulance service was medically necessary
                                            </Option>
                                            <Option value="60">
                                                60 - Transportation Was To the Nearest Facility
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Ambulance Miles:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'billingOptiondateMAmbulanceMiles']}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8} />

                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Attachment Report Type Code:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'billingOptionattachreporttypecode']}>
                                        <Select >
                                            {apot?.map((d) => <Option key={d.value} value={d.value}>{d.label}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Attachment Transmission Code:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'billingOptiondateMattachmntTrans']}>
                                        <Select >
                                            <Option value="AA">
                                                AA- Available on Request at Provider site
                                            </Option>
                                            <Option value="BM">
                                                By Mail
                                            </Option>
                                            <Option value="EL">
                                                Electronically only
                                            </Option>
                                            <Option value="EM">
                                                E-Mail
                                            </Option>
                                            <Option value="FX">
                                                By Fax
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Attachment Control Number:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'attcchControlno']}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Hospice Employee:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'billingOptionhopiceempl']}>
                                        <Select >
                                            <Select.Option value="Yes">Yes</Select.Option>
                                            <Select.Option value="No">No</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Referral Number:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'billingOptiondateReferralnumber']}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Contract Type Code:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'billingOptiondateContractTypecode']}>
                                        <Select >
                                            < Option value="01">
                                                Diagnous Related Group - DRG
                                            </Option>
                                            < Option value="02">
                                                Per Diem
                                            </Option>< Option value="03">
                                                Variable Per Diem
                                            </Option>
                                            < Option value="04">
                                                Flat
                                            </Option>
                                            < Option value="05">
                                                Capitated
                                            </Option>
                                            < Option value="06">
                                                Percent
                                            </Option>
                                            < Option value="09">
                                                Other
                                            </Option>

                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Contract Amount:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'billingOptiondateMContractAmount']}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Contract Percentage:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'billingOptiondateMContractPercentage']}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Contract Code:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'billingOptiondateMContractCode']}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Contract Term Discount Percent:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'billingOptiondateMContractDiscount']}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Contract Version Identifier:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'billingOptiondateMContractIdentifier']}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Pay To Name:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'payToName']}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8} />

                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Pay To Address:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={5}>
                                    <Form.Item name={['cms', 'payToAdress']}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={1}>
                                    <div className="mediContainer">
                                        <span>
                                            City
                                        </span>
                                    </div>
                                </Col>
                                <Col span={4}>
                                    <Form.Item name={['cms', 'payToCity']}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={1}>
                                    <div className="mediContainer">
                                        <span>
                                            State
                                        </span>
                                    </div>
                                </Col>

                                <Col span={3}>
                                    <Form.Item name={['cms', 'payToState']}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={1}>
                                    <div className="mediContainer">
                                        <span>
                                            Zip
                                        </span>
                                    </div>
                                </Col>
                                <Col span={3}>
                                    <Form.Item name={['cms', 'payToZip']}>
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Pay To Tax ID:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'payToTaxID']}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Pay To NPI:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'payToNPI']}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8} />
                                <Col span={6}>
                                    <div className="mediContainer">
                                        <span>
                                            Pay To ID:
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={['cms', 'payToID']}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8} />

                            </Row>
                        )}


                        <Row justify={"end"}>
                            <Col>
                                <Button type="primary" htmlType="submit"> Submit</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row >
            {cptList.length > 0 && (<CPTModal
                iscptModal={iscptModal}
                handleCpt={handleCpt}
                handlecptCancel={handlecptCancel}
                cptList={cptList}
                updateCpt={updateCpt}
                fdsname={fdsname}
            />)}

            {isBillingProvd && (
                <BillingProviderModal
                    isbpModal={isBillingProvd}
                    bpList={bpList}
                    modalTitle=""
                    onPullbp={onPullbp}
                    handlebpCancel={handleBillProvdCancel}
                />
            )}

            {ispatntModal && (
                <PatientModel
                    ispatntModal={ispatntModal}
                    handlepatntCancel={handlepatntCancel}
                    patientList={patientList}
                    modalTitle=''
                    onPullpatient={onPullpatient}
                    pullMorePatient
                />
            )}

            {isIcdCode && (
                <IcdModal
                    modalTitl=''
                    isPopIcdModal={isIcdCode}
                    handleIcdCancel={handleIcdCancel}
                    onPullIcdCode={onPullIcdCode}
                    icdCodeList={icdCodeList}
                />)}

            {isprovdModal && (<ProviderModel
                isprovdModal={isprovdModal}
                handleprovd={handleprovd}
                handleprovdCancel={handleprovdCancel}
                handlecptCancel={handlecptCancel}
                onPullprovider={onPullprovidericdRender}
                providerList={providerList}
                popProvd={popProvd}
            />)}

            {isRefProvd && (
                <ReferringProviderModal 
                isrpModal={isRefProvd}
                handlerpCancel={handlerpCancel}
                rpList={rpList}
                modalTitl=''
                onPullrp={onPullrp}
                pullMorerp
            />
            )}

            {isprovdersModal &&(
                <ProviderModel 
                    modalTitle={modalTitle}
                    isprovdModal={isprovdersModal}
                    handleprovd={handleProviders}
                    handleprovdCancel={handleprovdersCancel}
                    onPullprovider={onPullprovider}
                    providerList={providerList}
                    popProvd={popProvd}
                />
            )}
            
            {isFacility && (
                <FacilityModal
                    isfModal={isFacility}
                    modalTitle="Facilities"
                    handlefCancel={() => setIsFacility(false)}
                    onPullf={onPullfacility}
                    fList={fList}
                />
            )}

        </div >
    )
}
// 8. RESERVED FOR NUCC USE
export default CMS1500;