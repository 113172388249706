import { createSlice } from '@reduxjs/toolkit';

const patientSlice = createSlice({
    name: 'patientList',
    initialState: [],
    reducers: {
      getPatients: (state, action) => {
        state.push(action.payload);
      },
    },
  });
  
  export const { getPatients } = patientSlice.actions;
  
  export default patientSlice.reducer;