import { Button, Col, Divider, Form, Input, Row, Select, Table, Typography } from "antd";
import { useEffect, useState } from "react";
// import referringProviderrAPI from "./referringProviderr.api";
import { useDispatch } from "react-redux";
import { headerName } from "../../layout/headerName.hook";
import { createSearchParams, useNavigate } from "react-router-dom";
import ReferringProviderAPI from "./referringprovider.api";
const { Title } = Typography;

const ReferringProviderList = () => {
    const [dataList, setDataList] = useState([]);
    const [dataListfull, setDataListfull] = useState([]);
    const navigate = useNavigate();

    const dispatch = useDispatch();
    dispatch(headerName(`Referring Provider List`));

    const editBpProvider = (txt, r) => {
        let params = { id: r._id, mode: 'edit' };
        let path = {
            pathname: '/referringProvider',
            search: createSearchParams(params).toString()
        }
        navigate(path);
    }

    const columns = [
        {
            title: 'Last Name',
            dataIndex: 'referringProvideLastname',
            key: 'referringProvideLastname',
            render: (txt, r) => (<p className="tdClick" onClick={() => editBpProvider(txt, r)}>{txt}</p>)
        },
        {
            title: 'First Name',
            dataIndex: 'referringProvidefirstname',
            key: 'referringProvidefirstname',
            render: (txt) => (<p onClick={() => editBpProvider(txt)}>{txt}</p>)
        },
        {
            title: 'State',
            dataIndex: 'referringProvideState',
            key: 'referringProvideState'
        }, {
            title: 'City',
            dataIndex: 'referringProvideCity',
            key: 'referringProvideCity'
        },
        {
            title: 'Zip code',
            dataIndex: 'referringProvideZip',
            key: 'referringProvideZip'
        },
        {
            title: 'Phone',
            dataIndex: 'referringProvidePhonenumber',
            key: 'referringProvidePhonenumber'
        },
        {
            title: 'Tax ID',
            dataIndex: 'referringProvideTaxId',
            key: 'referringProvideTaxId'
        },
        {
            title: 'Individual NPI',
            dataIndex: 'referringProviderIndividualNPI',
            key: 'referringProviderIndividualNPI'
        }
    ]

    const handlefilter = (val) => {
        let arry = [];
            console.log(dataListfull[0][val['searchby']]);
            for (var i = 0; i < dataListfull; i++) {
                console.log(dataListfull[i][val['key']])
                if (dataListfull[i][val['key']].includes(val['searchValue'])) {
                    arry.push(dataListfull[i]);
                }
            
        };  
    }

    useEffect(() => {
        const getbplist = async () => {
            const resp = await ReferringProviderAPI.getReferringProvider();
            setDataList(resp);
            setDataListfull(resp)
        }
        getbplist();
    }, [])
    return (
        <>
            <div className="billingproviderlistwrapper">
            <Row>
                <Col span={24}>
                        <Title level={4} >
                            Reffering Provider list</Title>
                            <Divider/>
                    </Col>
                </Row>
                <Form layout="vertical" onFinish={handlefilter}>
                    <Row gutter={16}>
                        <Col span={6}>

                            <Form.Item name="searchby" label="Filter By" >
                                <Select
                                    style={{ width: '100%' }}
                                    size="small" >
                                    <Select.Option value="referringProviderLastname"> Last name</Select.Option>
                                    <Select.Option value="referringProviderfirstname">First Name</Select.Option>
                                    <Select.Option value="referringProviderState">State</Select.Option>
                                    <Select.Option value="referringProviderCity">City</Select.Option>
                                    <Select.Option value="referringProviderZip">Zip</Select.Option>
                                    <Select.Option value="referringProviderPhonenumber">Phone</Select.Option>
                                    <Select.Option value="referringProviderTaxId">Tax Id</Select.Option>
                                    <Select.Option value="referringProviderGroupNo">Group No</Select.Option>
                                    <Select.Option value="referringProviderOrganizationalNPI">Organizational NPI</Select.Option>
                                    <Select.Option value="referringProviderSpecialty">Specialty</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="searchwith" style={{ marginTop: '22px' }} >
                                <Select size="small" name="searchwith" style={{ width: '100%' }} >
                                    <Select.Option value="referringProviderStart">Start with</Select.Option>
                                    <Select.Option value="referringProviderEnd">Ends with</Select.Option>
                                    <Select.Option value="referringProviderContian">Contains</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="searchValue" label="Search value" >
                                <Input size="small" name="searchValue" />
                            </Form.Item>
                        </Col>
                        <Col span={6} justify="end" style={{ marginTop: '30px', color: '#fff' }} >
                            <Button htmlType="submit" className="btnPrimary">
                                Filter
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className="providerTable">
                <Row gutter={8}>
                    <Col span={24}>
                        <Table
                            rowKey="_id"
                            dataSource={dataList}
                            columns={columns} />
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default ReferringProviderList;