import axios from "axios";
import { API } from "../../config";

const facilityList = async () => {
  const response = await axios.get(API+'/facility');
  return response.data;
};

const createFacility = async (payload) => {
    console.log(payload);
    const response = await axios.post(API+'/facility', payload);
    return response.data;
  };
  
  const updateFacility = async (payload) => {
      console.log(payload);
      const response = await axios.put(API+'/facility/update', payload);
      return response.data;
  }
  
  const getFacilitybyId = async (id) => {
      const payload = {
          params: {
            id
          }
        }
        console.log(payload);
      const response = await axios.get(API+'/facility/id', payload);
      return response.data;
    };


const facilityAPI = {
    facilityList,
    createFacility,
    updateFacility,
    getFacilitybyId,
}

export default facilityAPI;