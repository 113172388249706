import { Button, Col, DatePicker, Divider, Form, Input, Row, Select, Space, Typography } from "antd"
import './style.scss'
import moment from "moment";
import providerAPI from "./provider.api";
import { useEffect, useState } from "react";
import patientApi from "../patient/hook/patient.api";
import { EditOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { headerName } from "../../layout/headerName.hook";
import Swal from "sweetalert2";
const { Title } = Typography;
const { Option } = Select;

const NewProvider = () => {
    const [form] = Form.useForm();
    const [searchParams] = useSearchParams();
    const [cities, setCities] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [providetData, setProviderData] = useState({});
    const [providerdocid, setProviderdocid] = useState(false);
    const [mode, setMode] = useState(false);

    const dispatch = useDispatch();
    dispatch(headerName(`Provider`));


    useEffect(() => {
        for (var i = 0; i < providetData?.DEA?.length; i++) {
            providetData.DEA[i].expirationDate = moment(providetData.DEA[i].expirationDate, 'MM/DD/YYYY')
        }
        form.setFieldsValue({ ...providetData })
    }, [providetData])


    useEffect(() => {
        const pId = searchParams.get('id');
        const pmode = searchParams.get('mode');
        setProviderdocid(pId)
        setMode(pmode === 'edit');
        if (pId) {
            const getProvider = async (id) => {
                const resp = await providerAPI.getProviderbyId(id);
                console.log(resp);
                setProviderData(resp[0]);
            }

            getProvider(pId)
        }

    }, []);

    const onFinish = async (v) => {
        // console.log(v);
        const payload = {
            ...v,
            providerId: new Date().getTime(),
            createBy: 'admin',
            createTime: new Date().getTime(),
            createDate: moment().format('MM/DD/YYYY'),
            facilityId: '',
        }
        if (mode) {
            try {
            const data = {
                ...payload,
                _id: providerdocid,
            }
            await providerAPI.updateProvider(data);
            Swal.fire({
                title: 'Successfully!',
                text: 'Provider Updated Successfully!',
                icon: 'success',
                confirmButtonText: 'Okay'
            });
            form.resetFields();
        } catch (err){
            Swal.fire({
                title: 'Error!',
                text: err?.response?.data?.message,
                icon: 'error',
                confirmButtonText: 'Okay'
            });
        }
        } else {
            try {
                await providerAPI.createProvider(payload);
                Swal.fire({
                    title: 'Successfully!',
                    text: 'Provider created Successfully!',
                    icon: 'success',
                    confirmButtonText: 'Okay'
                });
                form.resetFields();
            } catch (err){
                Swal.fire({
                    title: 'Error!',
                    text: err?.response?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                });
            }
            
            
        }

    }

    const onState = async (v) => {
        const cityList = await patientApi.cityList(v);
        setCities(cityList);
    }

    const edit = (v) => {
        console.log(v);
    }

    useEffect(() => {
        const getStateList = async () => {
            const stateLists = await patientApi.stateList();
            setStateList(stateLists);
        }
        getStateList();
    }, []);

    return (
        <>
            <div className="providerFormWrapper">
                <Row>
                    <Col span={24}>
                        <Title level={4} >
                            Add New Provider</Title>
                        <Divider />
                    </Col>
                </Row>
                <Form layout="vertical"
                    initialValues={{}}
                    className="providerForm"
                    onFinish={onFinish}
                    form={form} >
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="providerOffice" label="Provider Office" rules={[
                                {
                                    required: true,
                                    message: 'Please enter a provider office!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="providerLastName" label="Provider Last Name" rules={[
                                {
                                    required: true,
                                    message: 'Please enter a last name!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="providerFirstName" label="Provider First Name" rules={[
                                {
                                    required: true,
                                    message: 'Please enter a first name!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="providerMiddleName" label="Provider MI Name">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="providerSuffix" label="Suffix">
                                <Select size="small" showSearch mode="multiple">
                                    <Option value="ARPN">ARPN</Option>
                                    <Option value="CNA">CNA</Option>
                                    <Option value="CNM">CNM</Option>
                                    <Option value="CNP">CNP</Option>
                                    <Option value="CNS">CNS</Option>
                                    <Option value="CPNS">CPNS</Option>
                                    <Option value="DACBSP">DACBSP</Option>
                                    <Option value="DC">DC</Option>
                                    <Option value="DDS">DDS</Option>
                                    <Option value="DPM">DPM</Option>
                                    <Option value="DrPH">DrPH</Option>
                                    <Option value="Esq">Esq</Option>
                                    <Option value="FACC">FACC</Option>
                                    <Option value="I">I</Option>
                                    <Option value="II">II</Option>
                                    <Option value="III">III</Option>
                                    <Option value="IV">IV</Option>
                                    <Option value="Jr">Jr</Option>
                                    <Option value="Jr.">Jr.</Option>
                                    <Option value="LAc">LAc</Option>
                                    <Option value="LCMHC">LCMHC</Option>
                                    <Option value="LCSW">LCSW</Option>
                                    <Option value="LCSW-R">LCSW-R</Option>
                                    <Option value="LMFT">LMFT</Option>
                                    <Option value="LOTA">LOTA</Option>
                                    <Option value="LPC">LPC</Option>
                                    <Option value="LPN">LPN</Option>
                                    <Option value="LPTA">LPTA</Option>
                                    <Option value="LVN">LVN</Option>
                                    <Option value="MA">MA</Option>
                                    <Option value="MD">MD</Option>
                                    <Option value="MFT">MFT</Option>
                                    <Option value="ND">ND</Option>
                                    <Option value="NMD">NMD</Option>
                                    <Option value="NP">NP</Option>
                                    <Option value="OD">OD</Option>
                                    <Option value="OT">OT</Option>
                                    <Option value="PA">PA</Option>
                                    <Option value="PAC">PAC</Option>
                                    <Option value="PharmD">PharmD</Option>
                                    <Option value="PhD">PhD</Option>
                                    <Option value="PsyD">PsyD</Option>
                                    <Option value="PT">PT</Option>
                                    <Option value="RD">RD</Option>
                                    <Option value="RN">RN</Option>
                                    <Option value="RPAC">RPAC</Option>
                                    <Option value="RPH">RPH</Option>
                                    <Option value="SLP">SLP</Option>
                                    <Option value="SLPA">SLPA</Option>
                                    <Option value="Sr">Sr</Option>
                                    <Option value="Sr.">Sr.</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="providerAddress" label="Address" rules={[
                                {
                                    required: true,
                                    message: 'Please enter a address!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="providerAddress2" label="Address 2 line">
                                <Input size="small" />
                            </Form.Item>
                        </Col>

                        <Col span={2}>
                            <Form.Item name="providerState" label="state" rules={[
                                {
                                    required: true,
                                    message: 'Please enter a state!',
                                },
                            ]}>
                                <Select showSearch size='small'
                                    optionFilterProp="children"
                                    onSelect={onState}
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                    {stateList?.map((s) => <Option key={s.id} value={s.state_code}>{s.state_code}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="providerCity" label="City" rules={[
                                {
                                    required: true,
                                    message: 'Please enter a city!',
                                },
                            ]}>
                                <Select showSearch size='small'
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                    {cities?.map((s) => <Option key={s.id} value={s.name}>{s.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="providerZip" label="Zip Code" rules={[
                                {
                                    required: true,
                                    message: 'Please enter a zipcode!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="providerTax" label="Tax ID" rules={[
                                {
                                    required: true,
                                    message: 'Please enter a tax id!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="provideSpecialty" label="Specialty">
                                <Select size="small" >
                                    <Select.Option value="EIN">EIN</Select.Option>
                                    <Select.Option value="SSN">SSN</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="providerContact" label="Contact">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16} justify="start">
                        <Col span={6}>
                            <Form.Item name="providerPhoneetx" label="Phone" rules={[
                                {
                                    required: true,
                                    message: 'Please enter a phone number!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        {/* <Col span={2} style={{ marginTop: '22px' }}>
                            <Form.Item name="providerPhonestate">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={4} style={{ marginTop: '22px' }}>
                            <Form.Item name="providerPhonenumber" >
                                <Input size="small" />
                            </Form.Item>
                        </Col> */}

                        <Col span={6}>
                            <Form.Item name="providerFaxetx" label="Fax">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        {/* <Col span={2} style={{ marginTop: '22px' }}>
                            <Form.Item name="providerFaxstate">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={4} style={{ marginTop: '22px' }}>
                            <Form.Item name="providerFaxnumber" >
                                <Input size="small" />
                            </Form.Item>
                        </Col> */}

                        <Col span={8}>
                            <Form.Item name="providerEmail" label="Email">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="providerSSN" label="Social Security No" rules={[
                                {
                                    required: true,
                                    message: 'Please enter a SSN.',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name="providerSLI" label="State License ID" rules={[
                                {
                                    required: true,
                                    message: 'Please enter a State Liense Id!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="providerNPI" label="Individual NPI" rules={[
                                {
                                    required: true,
                                    message: 'Please enter a NPI!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name="providerSuperBill" label="Defualt SuperBill">
                                <Select size="small" >
                                    <Select.Option value="EIN">EIN</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="providerAddNewVisitOffice" label="Add New Visit Office Default">
                                <Select size="small" >
                                    <Select.Option value="office">Office</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="providerAddNewFacility" label="Add New Visit Facility Default">
                                <Select size="small" >
                                    <Select.Option value="office">Office</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="providerAddNewBillingProvider" label="Add New Visit Billing Provider Default">
                                <Select size="small" >
                                    <Select.Option value="office">Office</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="providernarcoticsDEAno" label="Narcotics Addiction DEA Number">
                                <Select size="small" >
                                    <Select.Option value="office">Office</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row gutter={16} >
                        <Col span={18}>
                            <Row gutter={16} style={{ background: '#eee', border: '1px solid #000', height: '48px', lineHeight: '48px' }}>
                                <Col span={6}>
                                    DEA Number
                                </Col>
                                <Col span={6}>
                                    Expiration Date
                                </Col>
                                <Col span={4}>
                                    state
                                </Col>
                                <Col span={2}>
                                    Edit
                                </Col>
                                <Col span={2}>
                                    Delete
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row gutter={16} style={{ height: '200px', overflowY: 'scroll' }}>
                        <Form.List name="DEA">
                            {(fields, { add, remove }) => (
                                <Col span={18}>

                                    {fields.map(({ key, name, ...restField }) => (
                                        <Row gutter={16} key={key} style={{ height: '38px', margin: '8px 0px 3px 0px' }}>

                                            <Col

                                                span={6}
                                            >
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'number']}
                                                >
                                                    <Input placeholder="DEA number" />
                                                </Form.Item>
                                            </Col>
                                            <Col

                                                span={6}
                                                align="baseline"
                                            >
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'expirationDate']}
                                                >
                                                    <DatePicker format={'MM/DD/YYYY'} />
                                                </Form.Item>

                                            </Col>
                                            <Col span={4}>
                                                <Form.Item name={[name, 'state']} >
                                                    <Select showSearch
                                                        optionFilterProp="children"
                                                        onSelect={onState}
                                                        filterOption={(input, option) =>
                                                            option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        } >
                                                        {stateList?.map((s) => <Option key={s.id} value={s.state_code}>{s.state_code}</Option>)}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                <EditOutlined onClick={() => edit(name)} />
                                            </Col>
                                            <Col span={1}>
                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                            </Col>
                                        </Row>
                                    ))}
                                    <Col span={6}>
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Add field
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </ Col>
                            )}
                        </Form.List>
                    </Row>
                    <Row gutter={6} justify="end" style={{ marginTop: '30px' }}>
                        <Col span={4}>
                            <Button htmlType="submit" className="btnPrimary"> {mode ? 'Update' : 'Submit'} </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}

export default NewProvider;