import { useEffect, useState } from "react";
import claimAPI from "./hook/claim.hook";
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Radio, Row, Select, Table, Tag, Typography } from "antd";
import { DotChartOutlined, EditOutlined, EllipsisOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { createSearchParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
const { Title } = Typography;


const ClaimList = ({ handleTabs }) => {
    const [dataList, setDataList] = useState();
    const navigate = useNavigate();

    const editClaim = (id) => {
        let params = { claimId: id };
        let path = {
            pathname: '/claim',
            search: createSearchParams(params).toString()
        }
        navigate(path);
        handleTabs('addNewClaim');
        
    }

    const claimStatus = (id) => {
        Swal.fire({
            title: id,
            text: "Claim status submitted to clearing house.",
            icon: "success"
        });
    }

    const columns = [
        {
            title: 'Claim No',
            dataIndex: 'claimId',
            key: 'claimId',
            render: (txt) => (<p className="tdClick" onClick={() => claimStatus(txt)}>{txt}</p>)
        },
        {
            title: 'Date Create',
            dataIndex: 'dateCreate',
            key: 'dateCreate',
            render: (txt) => moment(txt).format('MM/DD/YYYY')
        },
        {
            title: 'Form Type',
            dataIndex: 'formType',
            key: 'formType',
            render: () => (<p> HCFA</p>)
        },
        {
            title: 'Form DOS',
            dataIndex: 'createAt',
            key: 'createAt',
            render: (txt) => moment(txt).format('MM/DD/YYYY')
        },
        {
            title: 'To DOS',
            dataIndex: 'createAt',
            key: 'createAt',
            render: (txt) => moment(txt).format('MM/DD/YYYY')
        },
        {
            title: 'Patient Name',
            dataIndex: 'patientFirstName',
            key: 'patientFirstName'
        },
        {
            title: 'Total Charges',
            dataIndex: 'totalChr',
            key: 'totalChr',
        },
        {
            title: 'Insurance Co',
            dataIndex: 'insuranceName',
            key: 'insuranceName',
        },
        {
            title: 'Ins type',
            dataIndex: 'insurancePlaneName',
            key: 'insurancePlaneName',
        },
        {
            title: 'Sec claim',
            dataIndex: 'secClaim',
            key: 'secClaim',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (txt) => (<p>Submitted</p>)
        },
        // {
        //     title: 'Action',
        //     dataIndex: 'action',
        //     key: 'action',
        //     render: (txt, r) => (
        //         <Tag color="green" onClick={() => editClaim(r.claimId)}>
        //             <EditOutlined />  Edit
        //         </Tag>
        //     )
        // },

    ];


    useEffect(() => {

        const getClaimList = async () => {
            const resp = await claimAPI.getClaim();
            setDataList(resp);
            console.log(resp);
        };

        getClaimList();

    }, []);

    return (
        <>
            <Row justify="center">
                <Col span={24}>
                    <Form layout="vertical" className="specialEventForm">
                        <Card className="shadowCard">
                            <Row gutter={8}>
                                <Col span={18}>
                                    <Row gutter={8}>
                                        <Col span={12}>
                                            <Form.Item name="searchDateClaim" label="Dated Created">
                                                <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} style={{ marginTop: '22px' }}>
                                            <Form.Item name="searchDateClaimValue">
                                                <DatePicker size="small" format="MM/DD/YYYY" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={8}>
                                        <Col span={16}>
                                            <Row gutter={8}>
                                                <Col span={6}>
                                                    <Form.Item name="searchDateClaim" label="Search For">
                                                        <Select size="small" >
                                                            <Select.Option value="ProviderLastname">Provider / Staff  Lastname</Select.Option>
                                                            <Select.Option value="providerFirstname">Provider / Staff  Firstname</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6} style={{ marginTop: '22px' }}>
                                                    <Form.Item name="searchDateClaimValue">
                                                        <Select size="small">
                                                            <Select.Option value="Start With">Start With</Select.Option>
                                                            <Select.Option value="Ends With">Ends With</Select.Option>
                                                            <Select.Option value="Contians">Contians</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6} style={{ marginTop: '22px' }}>
                                                    <Form.Item name="searchproviderClaimValue">
                                                        <Input size="small" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={4}>
                                                <Col span={12}>
                                                    <Form.Item name="searchDateClaim" label="Patient name">
                                                        < Input size="small" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={2}>
                                                    <SearchOutlined className="searchBtn" />
                                                </Col>
                                            </Row>


                                        </Col>
                                    </Row>

                                </Col>
                                <Col span={6}>
                                    <Row>
                                        <Col span={24}>
                                            <Radio value="CMS 1500 Old" label="CMS 1500 08/05 (old)" >CMS 1500 08/05 (old)
                                            </Radio>
                                            <Radio value="CMS 1500 New" label="CMS 1500 02/12 (New)" >CMS 1500 02/12 (New)
                                            </Radio>
                                        </Col>
                                        <Col span={24}>
                                            <Button className="btnPrimary" >
                                                Set Default
                                            </Button>
                                            <Checkbox >Automated Eligibility</Checkbox>
                                            <Select size="small" placeholder="Select Option" className="btnPrimary" >
                                                <Select.Option value="default">default</Select.Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                            <Row>
                                <Col span={18}>
                                    <Row gutter={16}>
                                        <Col span={8}>
                                            <span> Display Filter</span>
                                            <Form.Item name='formType'>
                                                <Select size="small">
                                                    <Select.Option value="All">All</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <span> Status</span>
                                            <Form.Item name='formStatus'>
                                                <Select size="small">
                                                    <Select.Option value="All">All</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <span> Facility</span>
                                            <Form.Item name='formFacility'>
                                                <Select size="small">
                                                    <Select.Option value="All">All</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>

                        </Card>
                    </Form>
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={24}>
                    <Table
                        rowKey="_id"
                        dataSource={dataList}
                        columns={columns} />
                </Col>
            </Row>
        </>
    )
}

export default ClaimList;