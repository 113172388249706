import { combineReducers } from '@reduxjs/toolkit';
import pageNameReducer from '../layout/headerName.hook';
import getPatientList from '../modules/patient/hook/patient.hook';
import accesstokenReducer from '../modules/login/login.hook';
import userInfoReducer from './hook/user.hook';

const rootReducer = combineReducers({
    page: pageNameReducer,
    getPatientList,
    accesstoken: accesstokenReducer,
    userInfo: userInfoReducer,

});

export default rootReducer;