import React, { useEffect, useState } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  PieChartOutlined,
  CalendarOutlined,
  AuditOutlined,
  AccountBookOutlined,
  FolderOpenOutlined,
  SolutionOutlined,
  SaveOutlined,
  DownOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Button, theme, Avatar, Row, Col, Dropdown, Space } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';
import { pageName } from "./headerName.hook";
import { useSelector } from 'react-redux';
import logo from '../asset/img/MedQuik-logo.svg'
import logoxs from '../asset/img/MedQuik-icon.svg'
import { getUserInfo } from '../store/hook/user.hook';
const { Header, Sider, Content } = Layout;


const LayoutWrapper = () => {
  const [collapsed, setCollapsed] = useState(false);
  const headerPage = useSelector(pageName);
  
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [isUserInfo, setIsUserInfo] = useState();
  const navigate = useNavigate();
  const userInfo = useSelector(getUserInfo);

  useEffect(() => {
    setIsUserInfo(userInfo);
  }, [isUserInfo, userInfo]);

  const isAuthenticated = userInfo?.userId ? true : false;
  if (!isAuthenticated) navigate('/login');

  const handleClick = (e) => {
    console.log(e);
    // const navPath = menuList.filter((i) => i.key === e.key)[0].key;
    navigate(`/${e.key}`);
  };

  useEffect(() => {
    //     let hasTouchScreen = false;

    //   let UA = navigator.userAgent;
    //           hasTouchScreen = (
    //               /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
    //               /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
    //           ); 
    // console.log('hasTouchScreen', hasTouchScreen);
    //   // if (hasTouchScreen) {
    //   //     // Device is likely mobile, so do stuff for mobile devices here.
    //   // }

    const isUserUsingMobile = () => {

      // User agent string method
      let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

      // Screen resolution method
      if (!isMobile) {
        let screenWidth = window.screen.width;
        let screenHeight = window.screen.height;
        isMobile = (screenWidth < 768 || screenHeight < 768);
      }

      // Touch events method
      if (!isMobile) {
        isMobile = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
      }

      // CSS media queries method
      if (!isMobile) {
        let bodyElement = document.getElementsByTagName('body')[0];
        isMobile = window.getComputedStyle(bodyElement).getPropertyValue('content').indexOf('mobile') !== -1;
      }

      return isMobile
    }
    const isMobile = isUserUsingMobile();
    console.log('isMobile', isMobile);
    setCollapsed(isMobile);

  }, [])



  const menuList = [
    {
      key: "home",
      icon: <PieChartOutlined />,
      label: "Dashboard",
      path: "home",
      disabled: false
    },
    // {
    //   key: "home2",
    //   icon: <PieChartOutlined />,
    //   label: "Dashboard-2",
    //   path: "home2",
    //   disabled: false
    // },
    // {
    //   key: "home3",
    //   icon: <PieChartOutlined />,
    //   label: "Dashboard-3",
    //   path: "home3",
    //   disabled: false
    // },
    // {
    //   key: "home4",
    //   icon: <PieChartOutlined />,
    //   label: "Dashboard-4",
    //   path: "home4",
    //   disabled: false
    // },
    {
      key: "appointment",
      icon: <CalendarOutlined />,
      label: "Appoinment",
      path: "addAppointment",
    },
    {
      key: "menupatient",
      icon: <UserOutlined />,
      label: "Patient Visit",
      children: [
        {
          key: "patient",
          icon: <UserOutlined />,
          label: "Patient Visit",
          path: "patient",
        }

      ]
    },
    {
      key: "claim",
      icon: <AuditOutlined />,
      label: "Claim /Billing",
      path: "claim",
    },
    {
      key: "menumanageoffice",
      icon: <SaveOutlined />,
      label: "Manage Office",
      children: [
        {
          key: "managepatient",
          icon: <FolderOpenOutlined />,
          label: "Patient",
          path: "managepatient",
        },
        // {
        //   key: "managepatientProfile",
        //   icon: <FolderOpenOutlined />,
        //   label: "Patient profile",
        //   path: "managepatientprofile",
        // },
        {
          key: "managepatientlist",
          icon: <FolderOpenOutlined />,
          label: "Patient list",
          path: "managepatientlist",
        },
        {
          key: "provider",
          icon: <AuditOutlined />,
          label: "New Provider",
          path: "provider",
        },
        {
          key: "providerlist",
          icon: <AuditOutlined />,
          label: "Provider list",
          path: "providerlist",
        },
        {
          key: "billingprovider",
          icon: <AuditOutlined />,
          label: "New Billing Provider",
          path: "billingprovider",
        },
        {
          key: "billingProviderlist",
          icon: <AuditOutlined />,
          label: "Billing Provider",
          path: "billingProviderlist",
        },
        {
          key: "referringprovider",
          icon: <AuditOutlined />,
          label: "New Referring Provider",
          path: "referringprovider",
        },
        {
          key: "referringproviderlist",
          icon: <AuditOutlined />,
          label: "Referring Provider",
          path: "referringproviderlist",
        },
        {
          key: "facility",
          icon: <AuditOutlined />,
          label: "Facility",
          path: "facility",
        },
        {
          key: "facilitylist",
          icon: <AuditOutlined />,
          label: "Facility List",
          path: "facilitylist",
        },
        {
          key: "insurance",
          icon: <AuditOutlined />,
          label: "Insurance",
          path: "insurance",
        },
        {
          key: "insurancelist",
          icon: <AuditOutlined />,
          label: "Insurance List",
          path: "insuranceList",
        },
        {
          key: "icdcode",
          icon: <AuditOutlined />,
          label: "ICD Code List",
          path: "icdcode",
        },
        {
          key: "cptcode",
          icon: <AuditOutlined />,
          label: "CPT Code List",
          path: "cptcode",
        },
        {
          key: "user",
          icon: <UserOutlined />,
          label: "User Management",
          path: "user",
        },
        {
          key: "userlist",
          icon: <UserOutlined />,
          label: "User Management list",
          path: "userlist",
        }
      ]
    }

    // {
    //   key: "patientportal",
    //   icon: <SolutionOutlined />,
    //   label: "Patient Portal",
    //   path: "patientportal",
    //   disabled: true
    // },
    // {
    //   key: "accounting",
    //   icon: <AccountBookOutlined />,
    //   label: "Accounting",
    //   path: "accounting",
    //   disabled: true
    // },
  ];

  // const dropdownitems = [
  //   {
  //     label: 'Profile',
  //     key: '0',
  //   },
  //   {
  //     label: 'Provider',
  //     key: '1',
  //   },
  //   {
  //     type: 'divider',
  //   },
  //   {
  //     label: 'logout',
  //     key: '3',
  //   },
  // ];

  const dropdownitems = [
    {
      key: '1',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
          1st menu item
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
          2nd menu item
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
          3rd menu item
        </a>
      ),
    },
  ];

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo-vertical" >
          <div className='medqick-logo'>
            {
              collapsed ? <img src={logoxs} style={{
                height: '48px',
                paddingLeft: '10px'
              }} alt="Medquik Solution logo" /> : <img src={logo} style={{
                height: '48px',
                paddingLeft: '10px'
              }} alt="Medquik Solution logo" />
            }

          </div>
        </div>
        <Menu
          mode="inline"
          defaultSelectedKeys={['/']}
          onClick={(e) => handleClick(e)}
          items={menuList}

        />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <Row>
            <Col xs={3} sm={3} md={2} xl={1}>
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: '16px',
                  width: 64,
                  height: 64,
                }}
              />
            </Col>
            <Col xs={10} sm={10} md={14} xl={19}>
              <div style={{
                display: 'inline-block',
                fontSize: '20px',
                marginLeft: '20px',
                fontWeight: 300,
              }
              }>
                {headerPage}
              </div>
            </Col>
            <Col xs={10} sm={10} md={8} xl={4}
              style={{
                textAlign: 'right'
              }} >
              <div className='header-right'>
                <Row gutter={24}>
                  <Col span={4}>
                    <Avatar
                      style={{
                        backgroundColor: 'green',
                        verticalAlign: 'middle',
                      }}
                      size="default"
                    >
                      {isUserInfo?.userId[0]}
                    </Avatar>

                  </Col>
                  <Col span={16} style={{ textAlign: 'left', fontSize: '16px', paddingLeft: '16px' }}>

                    {isUserInfo?.userId}

                  </Col>
                  <Col span={2}>
                    {/* <Dropdown menu={{
                      items: dropdownitems,
                    }}
                    >  */}
                    <DownOutlined />
                    {/* </Dropdown> */}
                  </Col>
                </Row>


              </div>
            </Col>
          </Row>



        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: '88vh',
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
export default LayoutWrapper;