import axios from "axios";
import { API } from "../../config";

const getIcdcode = async () => {
  const response = await axios.get(API+'/code/icdcode');
  return response.data;
};

const getIcdcodeId = async (id) => {
  const payload = {
    params: {
      id
    }
  }
  const response = await axios.get(API+'/code/icdcode/id', payload);
  return response.data;
};

const createIcdcode = async (payload) => {
  // console.log(payload);
  const response = await axios.post(API+'/code/icdcode', payload);
  return response.data;
};

const updateIcdcode = async (payload) => {
  console.log(payload);
  const response = await axios.put(API+'/code/icdcode/update', payload);
  return response.data;
}


const IcdcodeAPI = {
    getIcdcode,
    createIcdcode,
    updateIcdcode,
    getIcdcodeId,
  }
  
  export default IcdcodeAPI;