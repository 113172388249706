const generateCalendar = () => {
    let data = [];
    let init = 7;
    for (let i = 0; i < 36; i++) {
        const divd = i % 4;
        let min = 0;
        if (divd === 0) {
            min = 0;
            init = init + 1;
        } else if (divd === 1) {
            min = 15
        } else if (divd === 2) {
            min = 30
        } else if (divd === 3) {
            min = 45
        }
        const appTime = {
            key: i,
            id: i,
            time: init <=12 ? init: init-12,
            min: min,
            timeAt: i < 16 ? 'am' : 'pm',
            data: {
                appointmenVisitDuration: '',
                appointmentPatientfirstName: '',
                appointmentPatientdob: '',
                appointmenPatientPhone: '',
                appointmenPatientProvider: '',
                reasonVisit: '',
                status: '',
                appointmentPatientautoElgili: '',
                appointmentCheckIn: '',
                appointmentCheckOut: '',
                appointmetofficeRadio: '',
                id: '',
            },
            createTime: '',
            createBy: ''
        }

        data.push(appTime)
    }
    return data;
}

const weeklyGenerate = () => {
    let weeklydata = [];
    for (var i = 0; i < 6; i++) {
        const weekDay = generateCalendar([]);
        weeklydata.push(
            {
                day: i + 1,
                apppoint: weekDay
            }
        )
    }
    return weeklydata;
}

const getGenerateTime = (t) =>{
    let time ={};
    switch (t){
        case '8am':
            time = {
                hour: 8,
                min: 0,
                at: 'AM'
            }
            break;
        case '8:15am':
            time = {
                hour: 8,
                min: 15,
                at: 'AM'
            }
        break;
        case '8:30am':
            time = {
                hour: 8,
                min: 30,
                at: 'AM'
            }
        break;
        case '8:45am':
            time = {
                hour: 8,
                min: 45,
                at: 'AM'
            }
        break;
        case '9am':
            time = {
                hour: 9,
                min: 0,
                at: 'AM'
            }
        break;
        case '9:15am':
            time = {
                hour: 9,
                min: 15,
                at: 'AM'
            }
        break;
        case '9:30am':
            time = {
                hour: 9,
                min: 30,
                at: 'AM'
            }
        break;
        case '9:45am':
            time = {
                hour: 9,
                min: 45,
                at: 'AM'
            }
        break;
        case '10am':
            time = {
                hour: 10,
                min: 0,
                at: 'AM'
            }
        break;
        case '10:15am':
            time = {
                hour: 10,
                min: 15,
                at: 'AM'
            }
        break;
        case '10:30am':
            time = {
                hour: 10,
                min: 30,
                at: 'AM'
            }
        break;
        case '10:45am':
            time = {
                hour: 10,
                min: 45,
                at: 'AM'
            }
        break;
        case '11am':
            time = {
                hour: 11,
                min: 0,
                at: 'AM'
            }
        break;
        case '11:15am':
            time = {
                hour: 11,
                min: 15,
                at: 'AM'
            }
        break;
        case '11:30am':
            time = {
                hour: 11,
                min: 30,
                at: 'AM'
            }
        break;
        case '11:45am':
            time = {
                hour: 11,
                min: 45,
                at: 'AM'
            }
        break;
        case '12pm':
            time = {
                hour: 12,
                min: 0,
                at: 'PM'
            }
        break;
        case '12:15pm':
            time = {
                hour: 12,
                min: 15,
                at: 'PM'
            }
        break;
        case '12:30pm':
            time = {
                hour: 12,
                min: 30,
                at: 'PM'
            }
        break;
        case '12:45pm':
            time = {
                hour: 12,
                min: 45,
                at: 'PM'
            }
        break;
        case '1pm':
            time = {
                hour: 13,
                min: 0,
                at: 'PM'
            }
        break;
        case '1:15pm':
            time = {
                hour: 13,
                min: 15,
                at: 'PM'
            }
        break;
        case '1:30pm':
            time = {
                hour: 13,
                min: 30,
                at: 'PM'
            }
        break;
        case '1:45pm':
            time = {
                hour: 13,
                min: 45,
                at: 'PM'
            }
        break;
        case '2pm':
            time = {
                hour: 14,
                min: 0,
                at: 'PM'
            }
        break;
        case '2:15pm':
            time = {
                hour: 14,
                min: 15,
                at: 'PM'
            }
        break;
        case '2:30pm':
            time = {
                hour: 14,
                min: 30,
                at: 'PM'
            }
        break;
        case '2:45pm':
            time = {
                hour: 14,
                min: 45,
                at: 'PM'
            }
        break;
        case '3pm':
            time = {
                hour: 15,
                min: 0,
                at: 'PM'
            }
        break;
        case '3:15pm':
            time = {
                hour: 15,
                min: 15,
                at: 'PM'
            }
        break;
        case '3:30pm':
            time = {
                hour: 15,
                min: 30,
                at: 'PM'
            }
        break;
        case '3:45pm':
            time = {
                hour: 15,
                min: 45,
                at: 'PM'
            }
        break;
        case '4pm':
            time = {
                hour: 16,
                min: 0,
                at: 'PM'
            }
        break;
        case '4:15pm':
            time = {
                hour: 16,
                min: 15,
                at: 'PM'
            }
        break;
        case '4:30pm':
            time = {
                hour: 16,
                min: 30,
                at: 'PM'
            }
        break;
        case '4:45pm':
            time = {
                hour: 16,
                min: 45,
                at: 'PM'
            }
        break;
        
        default: 
            return time;
    
    }
    return time;
}

export { generateCalendar, weeklyGenerate, getGenerateTime };