
import { Button, Form, Input, Row, Col, Checkbox, DatePicker, Select, Radio, Divider, Typography } from "antd"
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { getGenerateTime } from "./components/generateCalendar";
import appointmentAPI from "./appointment.hook";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import patientApi from "../patient/hook/patient.api";
import PatientModel from "../../components/patient.modal";
import { PatientdataAPI } from "../patientmanagement/patientData.api";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import ErrorMsg from "../../static/ErrorMsg";
const { Title } = Typography;


const { Option } = Select;
const timeList = [
    { lable: "8am", disabled: false },
    { lable: "8:15am", disabled: false },
    { lable: "8:30am", disabled: false },
    { lable: "8:45am", disabled: false },
    { lable: "9am", disabled: false },
    { lable: "9:15am", disabled: false },
    { lable: "9:30am", disabled: false },
    { lable: "9:45am", disabled: false },
    { lable: "10am", disabled: false },
    { lable: "10:15am", disabled: false },
    { lable: "10:30am", disabled: false },
    { lable: "10:45am", disabled: false },
    { lable: "11am", disabled: false },
    { lable: "11:15am", disabled: false },
    { lable: "11:30am", disabled: false },
    { lable: "11:45am", disabled: false },
    { lable: "12pm", disabled: false },
    { lable: "12:15pm", disabled: false },
    { lable: "12:30pm", disabled: false },
    { lable: "12:45pm", disabled: false },
    { lable: "1pm", disabled: false },
    { lable: "1:15pm", disabled: false },
    { lable: "1:30pm", disabled: false },
    { lable: "1:45pm", disabled: false },
    { lable: "2pm", disabled: false },
    { lable: "2:15pm", disabled: false },
    { lable: "2:30pm", disabled: false },
    { lable: "2:45pm", disabled: false },
    { lable: "3pm", disabled: false },
    { lable: "3:15pm", disabled: false },
    { lable: "3:30pm", disabled: false },
    { lable: "3:45pm", disabled: false },
    { lable: "4pm", disabled: false },
    { lable: "4:15pm", disabled: false },
    { lable: "4:30pm", disabled: false },
    { lable: "4:45pm", disabled: false },
]

const AppointmentFrom = ({ payerList, patients, isEditmode, isEditApp, userInfo }) => {
    const [form] = Form.useForm();
    const [patientFormValue, setPatientFormValue] = useState({});
    const [fieldDisabled, setFieldDisabled] = useState(true);
    const [appmntDate, setAppmntDate] = useState('');
    const [appmntTime, setAppmntTime] = useState('');
    const [appmntDurtn, setAppmntDurtn] = useState('');
    const [isTimeUpdate, setIsTimeUpdate] = useState(true)
    const [appointmentList, setAppointmentsList] = useState([]);
    const [providerList, setProviderList] = useState([]);
    const [timeLists, setTimeLists] = useState([]);
    const [ispatntModal, setIspatntModal] = useState(false);
    const [patientList, setPatientList] = useState([]);
    const [ptsearchValue, setPtsearchValue] = useState('');
    // const [isEditMode, setIsEditMode] = useState(false);
    const [eligilibilty, setEligilibilty] = useState(false);
    const [ptInfo, setPtInfo] = useState('');
    const navigate = useNavigate();


    const getTimatAt = (atr) => {
        const checkoutAT = (8 <= atr && atr < 12) ? 'AM' : 'PM';
        return checkoutAT;
    }

    const getProperTime = (tme) => {
        const ptme = (tme < 10) ? `0${tme}` : `${tme}`;
        return ptme;
    }

    const getCalcDate = (t, h, m) => {
        const d = new Date(t);
        d.setHours(h);
        d.setMinutes(m);
        return d;
    }

    const handlepatntCancel = () => {
        setIspatntModal(false);
    }

    const getCheckInOut = () => {
        console.log(appmntDurtn, appmntTime);
        const checkInHour = getProperTime(appmntTime.hour);
        const checkInMin = getProperTime(appmntTime.min);
        const checkinAT = getTimatAt(appmntTime.hour);
        console.log(appmntDate, checkInHour, checkInMin);
        const calcDate = getCalcDate(appmntDate, checkInHour, checkInMin)
        const intimeDate = calcDate; //new Date(dateString);
        const checkInTime = intimeDate.getTime();
        const checkIn = `${checkInHour}:${checkInMin} ${checkinAT}`;
        console.log(checkInTime, checkIn);
        const outtimeDate = intimeDate.setMinutes(intimeDate.getMinutes() + appmntDurtn);
        const checkOutTime = outtimeDate;
        const checkoutAT = getTimatAt(new Date(outtimeDate).getHours());
        const checkOut = `${getProperTime(new Date(outtimeDate).getHours())}:${getProperTime(new Date(outtimeDate).getMinutes())} ${checkoutAT}`;
        const check = {
            checkIn,
            checkOut,
            checkInTime,
            checkOutTime
        }
        return check;
    }

    const selectDuration = (v) => {
        setAppmntDurtn(v);
    }

    const selectTime = (v) => {
        console.log(v);
        const time = getGenerateTime(v);
        setAppmntTime(time);
    }

    const getDurgt = useCallback((i, dd) => {
        const dt = i + dd;
        for (var j = i; j < dt; j++) {
            timeLists[j].disabled = true;
        }
        setIsTimeUpdate(false)
        setTimeLists(timeLists);
    }, [timeLists]);

    const notAvaiable = (t, d) => {
        const dd = d / 15;
        for (var i = 0; i < timeLists.length; i++) {
            if (timeList[i].lable === t) {
                getDurgt(i, dd);
            }
        }
    }

    const disableTime = (data) => {
        data.map((p) => {
            notAvaiable(p.appointmenVisitTime, p.appointmenVisitDuration);
            return p;
        })
    }

    const selectDate = async (v) => {
        if (v) setFieldDisabled(false);
        const selDate = v.format('MM/DD/YYYY');
        setAppmntDate(selDate);
        const appList = await appointmentAPI.getDateAppointment(selDate, 'day');
        if (appList.length) {
            disableTime(appList)
        }
    }

    useEffect(() => {
        setTimeLists(timeList);
    }, [form, getDurgt, appmntDate]);


    const handleReset = () => {
        form.resetFields();
    }

    const handleOk = async (v) => {
        const check = await getCheckInOut();
        const payload = {
            ...v,
            appointmentPatientdob: dayjs(v.appointmentPatientdob).format('MM/DD/YYYY'),
            appointmentVisitDate: dayjs(v.appointmentVisitDate).format('MM/DD/YYYY'),
            appointmentCheckIn: check.checkIn,
            appointmentCheckOut: check.checkOut,
            appointmentcheckInTime: check.checkInTime,
            appointmentcheckOutTime: check.checkOutTime,
            facilityId: userInfo.facilityId,
            userId: userInfo.userId,
            createBy: userInfo.userId,
            createDate: new Date().getTime(),
            updateDate: '',
            status: 'Scheduled',
            createfor: 'Appointment'

        }
        if (isEditmode) {
            const data = {
                ...payload,
                _id: isEditApp._id,
            }
            try {
                await appointmentAPI.updateAppointment(data);
                Swal.fire({
                    title: 'Success!',
                    text: 'Appointment Updated Successfully!',
                    icon: 'success',
                    confirmButtonText: 'Okay',
                    confirmButtonColor: '#005154'
                });
            } catch (err) {
                Swal.fire({
                    title: 'Error!',
                    text: 'Oops!, Somthing went wrong, Please contact with adminitstrator',
                    icon: 'error',
                    confirmButtonText: 'Okay',
                    confirmButtonColor: '#005154'
                });
            }


        } else {
            const createApp = await appointmentAPI.createAppointment(payload);
            if (createApp._id) {
                Swal.fire({
                    title: 'Success!',
                    text: 'Appointment Created Successfully!',
                    icon: 'success',
                    confirmButtonText: 'Okay',
                    confirmButtonColor: '#005154'
                })
                // msgName, success, error, errorMsg, create, update, msgDelete, status, code
                ErrorMsg("Appoinment", true, false, null, true, false, false, 'success', 200);
                setTimeout(() => {
                    navigate('/appointment');
                }, 2000)
            }
        }

        form.resetFields();
    };

    const searchPatient = async (p) => {
        setIspatntModal(true)
    }

    const onPullpatient = async (patient, value, type) => {
        const data = {
            appointmentPatientId: patient.patientId,
            appointmentPatientInsuranceName: patient.patientPrimaryInsuranceName,
            appointmentPatientdob: dayjs(patient.patientDob),
            appointmentPatientfirstName: patient.patientFirstName,
            appointmentPatientlastName: patient.patientLastName,
            appointmenPatientPhone: patient.patientCellPhone,
        }
        setPtInfo(data)
        setIspatntModal(false);
    };

    useEffect(() => {
        form.setFieldsValue({ ...ptInfo });
    }, [ptInfo, form])

    const pullMorePatient = async (q) => {
        //setPtsearchValue;
        const ptList = await PatientdataAPI.getPatientbyQuery(q);
        setPatientList(ptList);
    }

    const editPatient = async (ep) => {
        return false;
    }

    const onPanelChange = (value, mode) => {
        console.log(value.format('MM/DD/YYYY'), mode);
    }

    const getPhoneNumber = (isd, ext, numb) => {
        return `${isd}-${ext}-${numb}`;
    }

    const disabledDate = (current) => {
        return moment().add(-1, 'days') >= current;
    }

    const selectPatient = (v) => {
        const patient = patients?.filter(d => d.patientId === v);
        setPatientFormValue({
            ...patientFormValue,
            appointmentPatientfirstName: patient[0].patientFirstName,
            appointmentPatientlastName: patient[0].patientLastName,
            appointmentPatientdob: dayjs(patient[0].patientDob),
            appointmenPatientPhone: getPhoneNumber(patient[0].patientAddressPhoneCell.ct,
                patient[0].patientAddressPhoneCell.st,
                patient[0].patientAddressPhoneCell.number),
            appointmentPatientInsuranceName: patient[0].insuranceName,
        });
    };

    useEffect(() => {
        const getProviders = async () => {
            const providerLists = await patientApi.providerList();
            setProviderList(providerLists);
        }
        getProviders();

        const getPatient = async () => {
            const ptList = await PatientdataAPI.getPatientList();
            setPatientList(ptList);
        }
        getPatient();
    }, []);

    useEffect(() => {
        if (isEditmode) {
            const data = {
                ...isEditApp,
                appointmentPatientdob: dayjs(isEditApp?.appointmentVisitDate),
                appointmentVisitDate: dayjs(isEditApp?.appointmentVisitDate)
            }
            selectDate(dayjs(data?.appointmentVisitDate))
            setAppmntDurtn(data.appointmenVisitDuration)
            selectTime(data.appointmenVisitTime);
            setPatientFormValue(data);
        }
    }, [isEditmode, isEditApp])

    useEffect(() => {
        form.setFieldsValue({
            ...patientFormValue
        });
    }, [patientFormValue, form]);

    return (
        <>
            <div className="providerFormWrapper">
                {/* <Row>
                    <Col span={24}>
                        <Title level={4} style={{ fontWeight: '300', fontSize: '24px', color: '#007a7a' }} >
                            New Appoinment</Title>
                        <Divider />
                    </Col>
                </Row> */}
                <Form layout="vertical"
                    initialValues={{}}
                    className="appointmentForm"
                    onFinish={handleOk}
                    form={form}
                >
                    <Row gutter={16}>
                        <Col span={20}>
                            <Divider className="formSubTitle" orientation="left">Patient Information</Divider>
                        </Col>
                        {/* <Col span={4} onClick={() => navigate('/managepatient')}>
                        <span> New Patient</span>
                    </Col> */}
                    </Row>
                    <Row gutter={8}>
                        <Col span={6}>
                            <Form.Item label="Patient ID" name='appointmentPatientId'>
                                <Select
                                    size="small"
                                    showSearch
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a patient!',
                                        },
                                    ]}
                                    optionFilterProp="children"
                                    onSelect={selectPatient}
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {patients?.map(p => <Option key={p.patientId} value={p.patientId}>{`${p.patientFirstName} | ${p.patientId}`}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={1} className="mt24">
                            <SearchOutlined className="iconBtn" onClick={() => searchPatient()} />
                        </Col>
                        <Col span={1} className="mt24">
                            <EditOutlined className="iconBtn" onClick={() => editPatient()} />
                        </Col>
                        <Col span={5}>
                            <Form.Item label="First name" name='appointmentPatientfirstName' rules={[
                                {
                                    required: true,
                                    message: 'Please enter paitent name',
                                },
                            ]}>
                                <Input size="small" disabled />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item label="Last name" name='appointmentPatientlastName'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a last patient!',
                                    },
                                ]}>
                                <Input size="small" disabled />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Date of brith" name='appointmentPatientdob'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a patient DoB!',
                                    },
                                ]}>
                                <DatePicker size="small" disabled width="100%" format="MM/DD/YYYY" />
                            </Form.Item>
                        </Col>
                        <Col span={5} className="mt22">
                            <Form.Item name="eligilibilty">
                                <Checkbox checked={eligilibilty} disabled>Automated Eligibility</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item label="Automated Eligilibilty" name='appointmentPatientautoElgili'>
                                <Select
                                    size="small"
                                    showSearch
                                    optionFilterProp="children"
                                    disabled
                                    // onSearch={onSearch}
                                    // onSelect={selectPatient}
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {payerList?.map(p => <Option key={p._id} value={p.payerId}>{p.payerName}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col style={{ paddingLeft: '20px' }} span={5}>
                            <Form.Item label="Insurance name" name='appointmentPatientInsuranceName'>
                                <Input disabled size="small" />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item label="Phone number" name='appointmenPatientPhone'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter phone number',
                                    },
                                ]}>
                                <Input disabled size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider className="formSubTitle" orientation="left">Appointment Information</Divider>
                    <Row gutter={16}>
                        <Col span={5}>
                            <Form.Item label="Office" name='appointmentoffice'>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6} className="mt22">
                            <Form.Item name='appointmetofficeRadio'>
                                <Radio.Group>
                                    <Radio value="inOffice"> In Office </Radio>
                                    <Radio value="virtual"> Virtual Visit </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name='appointmetReason' label="Reason" rules={[
                                {
                                    required: true,
                                    message: 'Please select a reason!',
                                }
                            ]}>
                                <Select size="small"
                                >
                                    <Select.Option value="CCM">CCM</Select.Option>
                                    <Select.Option value="Established Patient">Established Patient</Select.Option>
                                    <Select.Option value="Follow up">Follow up</Select.Option>
                                    <Select.Option value="New Patient">New Patient</Select.Option>
                                    <Select.Option value="RPM">RPM</Select.Option>
                                    <Select.Option value="TELEMED">TELEMED</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item label="With" name='appointmentWith'>
                                <Select size="small"  >
                                    <Select.Option value="provider">Provider</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Provider / Staff" name='providerId' rules={[
                                {
                                    required: true,
                                    message: 'Please select a Provider!',
                                },
                            ]}>
                                <Select
                                    size="small"
                                    showSearch
                                    optionFilterProp="children"

                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {providerList?.map(p => <Option key={p._id} value={p.providerId}>{p.providerFirstName}{p.provderLastName}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Resource" name='appointmentResource'>
                                <Select size="small"  >
                                    <Select.Option value="Machine"> Machine</Select.Option>
                                    <Select.Option value="Room"> Room</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Repeat" name='appointmentRepeat'>
                                <Select size="small"  >
                                    <Select.Option value="1"> 1 Time</Select.Option>
                                    <Select.Option value="2"> 2 Times</Select.Option>
                                    <Select.Option value="3"> 3 Times</Select.Option>
                                    <Select.Option value="4"> 4 Times</Select.Option>
                                    <Select.Option value="5"> 5 Times</Select.Option>
                                    <Select.Option value="6"> 6 Times</Select.Option>
                                    <Select.Option value="7"> 7 Times</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Days between visit" name='appointmentDaysVisit'>
                                <Select size="small"  >
                                    <Select.Option value="1">1</Select.Option>
                                    <Select.Option value="2">2</Select.Option>
                                    <Select.Option value="3">3</Select.Option>
                                    <Select.Option value="4">4</Select.Option>
                                    <Select.Option value="5">5</Select.Option>
                                    <Select.Option value="6">6</Select.Option>
                                    <Select.Option value="7">7</Select.Option>
                                    <Select.Option value="8">8</Select.Option>
                                    <Select.Option value="9">9</Select.Option>
                                    <Select.Option value="10">10</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item label="Appointment Date" name='appointmentVisitDate'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a appointment date!',
                                    },
                                ]}>
                                <DatePicker width="100%"

                                    size="small"
                                    format={'MM/DD/YYYY'}
                                    onChange={selectDate}
                                    disabledDate={disabledDate} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Duration" name='appointmenVisitDuration'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a duration!',
                                    },
                                ]}>
                                <Select size="small" disabled={fieldDisabled} onChange={selectDuration}>
                                    <Select.Option value={15} >15 mins</Select.Option>
                                    <Select.Option value={30} >30 mins</Select.Option>
                                    <Select.Option value={45} >45 mins</Select.Option>
                                    <Select.Option value={60} >60 mins</Select.Option>
                                    <Select.Option value={75} >75 mins</Select.Option>
                                    <Select.Option value={90} >90 mins</Select.Option>
                                    <Select.Option value={105} >105 mins</Select.Option>
                                    <Select.Option value={120}> 120 mins</Select.Option>
                                    <Select.Option value={135} >135 mins</Select.Option>
                                    <Select.Option value={150} >150 mins</Select.Option>
                                    <Select.Option value={165} >165 mins</Select.Option>
                                    <Select.Option value={180} >180 mins</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            {timeLists.length && (
                                <Form.Item label="Time" name='appointmenVisitTime' rules={[
                                    {
                                        required: true,
                                        message: 'Please select a checkin!',
                                    },
                                ]}>
                                    <Select size="small" disabled={fieldDisabled} onChange={selectTime}>

                                        {timeLists?.map(p => <Option disabled={p.disabled} key={p.lable} value={p.lable}>{p.lable}</Option>)}
                                    </Select>
                                </Form.Item>
                            )}

                        </Col>
                        <Col span={12}>
                            <Form.Item label="Note" name='appointmentPatientNote'>
                                <TextArea rows={4} />
                            </Form.Item>
                        </Col>
                        {isEditmode &&
                            <Col span={6}>
                                <Form.Item label="Status" name='status'>
                                    <Select size="small" >
                                        <Option value="Active">Active</Option>
                                        <Option value="Cancelled by Office">Cancelled by Office</Option>
                                        <Option value="Cancelled by Patient">Cancelled by Patient</Option>
                                        <Option value="Cancelled by Provider">Cancelled by Provider</Option>
                                        <Option value="Checked In">Checked In</Option>
                                        <Option value="Checked Out">Checked Out</Option>
                                        <Option value="Completed">Completed</Option>
                                        <Option value="Confirmed">Confirmed</Option>
                                        <Option value="In Room">In Room</Option>
                                        <Option value="Last Minute Cancel by Patient">Last Minute Cancel by Patient</Option>
                                        <Option value="Last Minute Reschedule">Last Minute Reschedule</Option>
                                        <Option value="Left Message">Left Message</Option>
                                        <Option value="Patient Did Not Come">Patient Did Not Come</Option>
                                        <Option value="Rescheduled">Rescheduled</Option>
                                        <Option selected="selected" value="326">Visit Created</Option>
                                    </Select>
                                </Form.Item>
                            </Col>}

                    </Row>
                    <Divider className="formSubTitle" orientation="left">Intake / Agreementform</Divider>
                    <Row gutter={16}>
                        <Col span={20}>
                            <div className="agree">
                                <p>With patient Unique PMS, you can have intake forms built for you and have patients fill out your intake forms at home before they come in for their appointment.  </p>
                                <p style={{ textAlign: 'right' }}>
                                    <Button type="primary" style={{ fontSize: '10px' }} size="small">Getting Start</Button>
                                    learn more</p>
                            </div>
                        </Col>
                        <Col span={4}>
                            <Button type="primary" htmlType="submit" style={{ width: '100%' }} >Submit</Button>
                            <Button type="default" style={{ width: '100%' }} onClick={handleReset} >Reset</Button>
                        </Col>
                    </Row>
                </Form>

                {ispatntModal && (
                    <PatientModel
                        modalTitle={'Patient Search'}
                        ispatntModal={ispatntModal}
                        handlepatntCancel={handlepatntCancel}
                        onPullpatient={onPullpatient}
                        patientList={patientList}
                        pullMorePatient={pullMorePatient}
                    />

                )}
            </div>
        </>
    )
}

export default AppointmentFrom;