import { SearchOutlined, PlusOutlined, EditOutlined, DashOutlined } from "@ant-design/icons";
import { Form, Row, Col, Input, Typography, Button, Select, Divider, DatePicker } from "antd";
import patientApi from "./hook/patient.api";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import appointmentAPI from "../appointment/appointment.hook";
import dayjs from "dayjs";

const { Option } = Select;


const { TextArea } = Input;
const { Title } = Typography;

const NewPatientVisit = ({ isPrimaryinsurance, showModal, newPatient, selectPatient, patientList, stateList, onPullprovider, providerList, getcptOptionCode, getcptOptionDesp, handleprovd, searchProvd }) => {

    const [cities, setCities] = useState([]);

    const onState = async (v) => {
        console.log(v);
        const cityList = await patientApi.cityList(v);
        console.log(cityList);
        setCities(cityList);
    }


    return (
        <>
            <Row justify="center">
                {/* <Col span={22}>
                    <Title level={5} className="patientViewheader" >Add New Visit</Title>
                </Col> */}
                <Col span={24}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="searchPatient">
                                {/* <Input  size="small"placeholder="Search Patient"
                                        prefix={<SearchOutlined />}
                                    /> */}
                                <Select size="small"
                                    showSearch
                                    optionFilterProp="children"
                                    // onSearch={onSearch}
                                    onSelect={selectPatient}
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {patientList?.map(p => <Option key={p.patientId} value={p.patientId}>{`${p.patientFirstName}-${p.patientId}`}</Option>)}
                                </Select>
                            </Form.Item>

                        </Col>
                        <Col span={12}>
                            <Button type="primary" className="btnPrimary" onClick={() => newPatient()}><PlusOutlined />Add Patient</Button>
                            <Button type="default" className="btnDefault"><EditOutlined />Edit Patient</Button>
                        </Col>
                    </Row>

                    <Row gutter={8}>
                        <Col span={6}>
                            <Form.Item label="Patient ID" name='visitpatientId' key="visitPatientID" 
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a patient!',
                                },
                            ]}
                            >
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Last Name" name='visitpatientLastName' key="visitPatientlastName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a patient name!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="First Name" name='visitpatientFirstName' 
                            key="visitPatientfirstName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a patient name!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Middle Name" name='visitpatientMiddleName' 
                            key="visitPatientmiddleName"
                            >
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="DoB" name='visitpatientDob' 
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a patient DoB!',
                                },
                            ]}>
                                <DatePicker size="small" format={"MM/DD/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Sex" name='visitpatientSex' key="visitPatientSex" 
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a patient gender!',
                                },
                            ]}>
                                <Select size="small">
                                    <Select.Option value="Male">Male</Select.Option>
                                    <Select.Option value="Female">Female</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Phone" name='visitpatientAddressPhoneCell'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a patient phone!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={6}>
                            <Form.Item name='visitpatientAddress' label="Address">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name='visitpatientAddressline2' label="Address line2">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name='visitpatientAddressState' label="State">
                                <Select size="small" showSearch
                                    optionFilterProp="children"
                                    onSelect={onState}
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                    {stateList?.map((s) => <Option key={s.state_code} value={s.state_code}>{s.state_code}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name='visitpatientAddressCity' label="City">
                                <Select size="small" showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                    {cities?.map((s) => <Option key={s.key} value={s.name}>{s.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name='visitpatientAddressZipCode' label="Zip code">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Divider />
            <Row gutter={8}>
                <Col span={6}>
                    <Form.Item label="Visit Date" name="visitDate" rules={[
                                {
                                    required: true,
                                    message: 'Please select a patient visit date!',
                                },
                            ]}>
                        <DatePicker size="small" format="MM/DD/YYYY" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Vital Sign" name="visitVitalSign" rules={[
                                {
                                    required: true,
                                    message: 'Please enter a patient vital sign!',
                                },
                            ]}>
                        <Input size="small" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Reason for Visit" name="visitReason" rules={[
                                {
                                    required: true,
                                    message: 'Please select a visit reason!',
                                },
                            ]}>
                        <Select size="small">
                            <Select.Option value="CCM">CCM</Select.Option>
                            <Select.Option value="establishedPatient">Established Patient</Select.Option>
                            <Select.Option value="followUp">Follow up</Select.Option>
                            <Select.Option value="newPatient">New Patient</Select.Option>
                            <Select.Option value="RPM">RPM</Select.Option>
                            <Select.Option value="TELEMED">TELEMED</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Weight" name="visitVitalWeight">
                        <Input size="small" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Height" name="visitVitalHeight">
                        <Input size="small" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Temperature" name="visitVitalTemperature">
                        <Input size="small" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Blood Pressure" name="visitVitalBloodPress">
                        <Input size="small" />
                    </Form.Item>
                </Col>
                <Col span={8}>

                    <Form.Item label="Chief Complaints" name="visitchieComplaint">
                        <TextArea rows={3} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="Allergies" name="visitAllergies">
                        <TextArea rows={3} />
                    </Form.Item>
                </Col>

            </Row>

            <Divider />
            <Row gutter={8}>
                <Col span={22}>
                    <Row gutter={8}>
                        <Col span={4}>
                            <Form.Item label="Provider ID" name="visitProviderID">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <DashOutlined className="searchBtn" onClick={() => searchProvd('visitProviderID', 'visitProviderID', 'Visit Provider')} />
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Provider Last Name" name="visitProviderLastName" rules={[
                                {
                                    required: true,
                                    message: 'Please enter a provider name!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Provider First Name" name="visitProviderFirstName" rules={[
                                {
                                    required: true,
                                    message: 'Please enter a provider name!',
                                },
                            ]}>
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Provider Office" name="visitProviderOffice">
                                <Select size="small">
                                    <Select.Option value="Office">Office</Select.Option>
                                    <Select.Option value="ScottTong">tong</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Provider Note" name="visitProviderNote">
                                <TextArea rows={3} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Divider />
            <Row gutter={8}>
                <Col span={22}>
                    <Title level={5}> Primary Insurance</Title>
                    <Row gutter={18}>
                        <Col span={6}>
                            <Form.Item label="Insurance Co. ID" name="insuranceId">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Insurance Name" name="insuranceName">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={18}>
                        <Col span={6}>
                            <Form.Item label="Patient Relationship" name="insurancePatientRelation">
                                <Select size="small">
                                    <Select.Option value="Self">Self</Select.Option>
                                    <Select.Option value="Spouse">Spouse</Select.Option>
                                    <Select.Option value="Child">Child</Select.Option>
                                    <Select.Option value="OtherRelationship">Other Relationship</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Accept Assignment" name="insuranceAcceptAssignment">
                                <Select size="small">
                                    <Select.Option value="Yes">Yes</Select.Option>
                                    <Select.Option value="No">No</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={18}>
                        <Col span={6}>
                            <Form.Item label="Primary Insurance Co. ID" name="insurancePrimary">
                                <Input size="small" disabled={isPrimaryinsurance} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Last name" name="insuranceLastName">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="First name" name="insuranceFirstName">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Middle name" name="insuranceMiddleName">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Address" name="insuranceAddress">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Address line2" name="insuranceAddress2">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="State" name="insuranceState">
                                <Select size="small" showSearch
                                    optionFilterProp="children"
                                    onSelect={onState}
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                    {stateList?.map((s) => <Option key={s.state_code} value={s.state_code}>{s.state_code}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="City" name="insuranceCity">
                                <Select size="small" showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                    {cities?.map((s) => <Option key={s.key} value={s.name}>{s.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Phone" name="insurancePhone">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Subscriber ID" name="insuranceSubscriberId">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Group ID" name="insuranceGroupId">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Plan Name" name="insurancePlanName">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <Title level={5}> Secondary Insurance</Title>
                    <Row gutter={18}>
                        <Col span={6}>
                            <Form.Item label="Insurance Co. ID" name="insurancesecondaryId">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Insurance Name" name="insuranceSecondaryName">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={18}>
                        <Col span={6}>
                            <Form.Item label="Patient Relationship" name="insurancePatientSecondaryRelation">
                            <Select size="small">
                                    <Select.Option value="Self">Self</Select.Option>
                                    <Select.Option value="Spouse">Spouse</Select.Option>
                                    <Select.Option value="Child">Child</Select.Option>
                                    <Select.Option value="OtherRelationship">Other Relationship</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Accept Assignment" name="insuranceSecondaryAcceptAssignment">
                                <Select size="small">
                                    <Select.Option value="Yes">Yes</Select.Option>
                                    <Select.Option value="No">No</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={18}>
                        <Col span={6}>
                            <Form.Item label="Secondary Insurance Co. ID" name="insuranceSecondary">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Last name" name="insuranceSecondaryLastName">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="First name" name="insuranceSecondaryFirstName">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Middle name" name="insuranceSecondaryMiddleName">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Address" name="insuranceSecondaryAddress">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Address line2" name="insuranceSecondaryAddress2">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="State" name="insuranceSecondaryState">
                                <Select size="small"
                                    showSearch
                                    optionFilterProp="children"
                                    onSelect={onState}
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                    {stateList?.map((s) => <Option key={s.state_code} value={s.state_code}>{s.state_code}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="City" name="insuranceSecondaryCity">
                                <Select size="small"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } >
                                    {cities?.map((s) => <Option key={s.key} value={s.name}>{s.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Phone" name="insuranceSecondaryPhone">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Subscriber ID" name="insuranceSecondarySubscriberId">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Group ID" name="insuranceSecondaryGroupId">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Plan Name" name="insuranceSecondaryPlanName">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </>
    )
}

export default NewPatientVisit;