import { SearchOutlined } from "@ant-design/icons";
import { Input, Modal, Table } from "antd";
import { useEffect, useState } from "react";


const ProviderModel = ({
    isprovdModal,
    handleprovd,
    handleprovdCancel,
    providerList,
    modalTitle,
    onPullprovider, popProvd }) => {
    const [filterProvd, setFilterProvd] = useState([])

    // const rowSelection = {
    //     onChange: (selectedRowKeys, selectedRows) => {
    //         console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    //         onPullprovider(selectedRowKeys, selectedRows, popProvd);
    //     }
    // };

    const rowSelection = (r) => {
        console.log(r);
        onPullprovider(r, '', popProvd);
    }

    const searchProvider = (e, type) => {
        console.log(e.target.value, type);
        const filterdata = providerList?.filter((d) => d[type].toLowerCase().includes(e.target.value.toLowerCase()));
        console.log(filterdata);
        setFilterProvd(filterdata);
    }

    const columns = [
        {
            title: 'Last name',
            dataIndex: 'providerLastName',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'First name',
            dataIndex: 'providerFirstName',
        },
        {
            title: 'Address',
            dataIndex: 'providerAddress',
        },
        {
            title: 'City',
            dataIndex: 'providerCity',
        },
        {
            title: 'State',
            dataIndex: 'providerState',
        },
        {
            title: 'Zip',
            dataIndex: 'providerZip',
        },
        {
            title: 'NPI',
            dataIndex: 'providerNPI',
        },
        {
            title: 'Group Id',
            dataIndex: 'providerGroupNPI',
        },
        {
            title: 'Tax',
            dataIndex: 'providerTax',
        }
    ];

    useEffect(() => { 
        setFilterProvd(filterProvd);
    }, []);


    return (
        <Modal  title={modalTitle} open={isprovdModal} width={950} onOk={handleprovd} onCancel={handleprovdCancel}>
            <div style={{ marginTop: '30px', display: 'inline-block', marginBottom: '20px', width: '200px' }}>
                <Input placeholder='Last name' suffix={<SearchOutlined />} onChange={(e) => searchProvider(e, 'providerLastName')} />
            </div>
            <div style={{ display: 'inline-block', margin: '30px 0px 20px 10px', width: '200px' }}>
                <Input placeholder='First name' suffix={<SearchOutlined />} onChange={(e) => searchProvider(e, 'providerFirstName')} />
            </div>
            <div style={{ display: 'inline-block', margin: '30px 0px 20px 10px', width: '200px' }}>
                <Input placeholder='NPI' suffix={<SearchOutlined />} onChange={(e) => searchProvider(e, 'providerNPI')} />
            </div>
            <Table
                rowKey={(record) => record.providerNPI}
                onRow={(record) => ({
                    onClick : () => rowSelection(record),
                })}
                columns={columns}
                dataSource={filterProvd.length > 0 ? filterProvd : providerList}
                pagination={{
                    pageSize: 8
                }}
            />

        </Modal>
    )

}

export default ProviderModel;