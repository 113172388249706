import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Table } from "antd";
import { useEffect, useState } from "react";


const IcdModal = ({
    modalTitle,
    isPopIcdModal,
    handleIcdCancel,
    onPullIcdCode,
    icdCodeList }) => {
    const [FilterIcd, setFilterIcd] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const rowSelection = (r) => {
        onPullIcdCode(r);
    }

    const searchMore = (key, val) => {
        let Obj = {
            [key]: val,
        }
        setInputValue(Obj)
    }

    const searchPatient = (e, type) => {
        // console.log(e.target.value, type);
        const filterdata = icdCodeList?.filter((d) => d[type].toLowerCase().includes(e.target.value.toLowerCase()));
        // console.log(filterdata);
        setFilterIcd(filterdata);
        searchMore(type, e.target.value)
    }

    const columns = [
        {
            title: 'Code',
            dataIndex: 'code',
            render: (text) => <a>{text}</a>,
            width: '30%'
        },
        {
            title: 'Descriptions',
            dataIndex: 'desp',
        }
    ];

    useEffect(() => {
        setFilterIcd(FilterIcd);
    }, []);


    return (
        <Modal title="ICD Code" open={isPopIcdModal} width={950} onOk={handleIcdCancel} onCancel={handleIcdCancel}>
            <div style={{ marginTop: '30px', display: 'inline-block', marginBottom: '20px', width: '200px' }}>
                <Input placeholder='Code' suffix={<SearchOutlined />} name="code" onChange={(e) => searchPatient(e, 'code')} />
            </div>
            <div style={{ display: 'inline-block', margin: '30px 0px 20px 10px', width: '200px' }}>
                <Input placeholder='Descriptions' suffix={<SearchOutlined />} name="desp" onChange={(e) => searchPatient(e, 'desp')} />
            </div>
            {/* <div style={{ display: 'inline-block', margin: '30px 0px 20px 10px', width: '200px' }}>
                <Button type="primary" onClick={() => pullMorePayer(inputValue)} > Search more </Button>
            </div> */}

            <Table
                rowKey={(record) => record._id}
                onRow={(record) => ({
                    onClick: () => rowSelection(record),
                })}
                columns={columns}
                dataSource={FilterIcd.length > 0 ? FilterIcd : icdCodeList}
                pagination={{
                    pageSize: 8
                }}
            />

        </Modal>
    )

}

export default IcdModal;