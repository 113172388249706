import axios from "axios";
import Swal from "sweetalert2";
import { API } from "../../config";
import axiosHttp from "../../middleware/axios";

const alertFun = (type, text, err) => {

    console.log(err);

    let context;
    if (type === 'success') {
        context = 'Appointment Created Successfully!';
    } else {
        context = 'Oops something thing went wrong!';
    }
    Swal.fire({
        title: text,
        text: err || context,
        icon: type,
        confirmButtonText: 'Okay'
    })
}


const getAllappointment = async () => {
    try {
        const response = await axiosHttp.get('/appointment');
        return response.data;
    } catch (err) {
        console.log(err);
        alertFun('error', 'Error!', err.response.data.message);
    }

};

// const getAppointmentQuery = async (query) => {
//     const payload = {
//       params: {
//         ...query
//       }
//     }
//     const response = await axios.get(API+'/appointment/search', payload);
//       return response.data;
//   }

const getDateAppointment = async (date, type) => {
    
    const payload = {
        params: {
            ...date,
            type
        }
    }
    try {
        const response = await axiosHttp.get('/appointment/query', payload);
        return response.data;
    } catch (err) {
        console.log(err);
        alertFun('error', 'Error!', err.response.data.message);
    }
};

const getDateAppointmentweekly = async (date, type) => {
    const payload = {
        params: {
            ...date,
            type
        }
    }
    console.log(payload);
    try {
        const response = await axiosHttp.get('/appointment/weekquery', payload);
        return response.data;
    } catch (err) {
        alertFun('error', 'Error!', err.response.data.message);
    }
};

const getAppointmentId = async (id) => {
    const payload = {
        params: {
            id,
        }
    }
    try {
        const resp = await axiosHttp.get('/appointment/app', payload);
        return resp.data;
    } catch (err) {
        alertFun('error', 'Error!', err.response.data.message);
    }
}

const createAppointment = async (payload) => {
    try {
        const resp = await axiosHttp.post('/appointment', payload);
        return resp.data;
    } catch (err) {
        alertFun('error', 'Error!', err.response.data.message);
    }
}

const updateCreateVisit = async (payload) => {
    try {
        const resp = await axiosHttp.put('/appointment/updatePatientVisit', payload);
        return resp.data;
    } catch (err) {
        alertFun('error', 'Error!', err.response.data.message);
    }
}

const updateAppointment = async (payload) => {
    try {
        const resp = await axiosHttp.put('/appointment', payload);
        return resp.data;
    } catch (err) {
        alertFun('error', 'Error!', err.response.data.message);
    }
}

const searchAppointment = async (query) => {
    const payload = {
        params: {
            ...query,
        }
    }
    try {
        const resp = await axiosHttp.get('/appointment/search', payload);
        return resp.data;
    } catch (err) {
        alertFun('error', 'Error!', err.response.data.message);
    }
}

const delAppointment = async (id) => {
    try {
        const resp = await axiosHttp.delete(`/appointment/${id}`);
        return resp.data;
    } catch (err) {
        alertFun('error', 'Error!', err.response.data.message);
    }
}

const appointmentAPI = {
    getAllappointment,
    getAppointmentId,
    createAppointment,
    getDateAppointment,
    getDateAppointmentweekly,
    searchAppointment,
    updateAppointment,
    delAppointment,
    updateCreateVisit
}

export default appointmentAPI
