import { API } from "../../config";
import axiosHttp from "../../middleware/axios";


const getPatientList = async () => {
  const response = await axiosHttp.get(API+'/patientdata');
  return response.data;
};

const CreatePatient = async (payload) => {
    const response = await axiosHttp.post(API+'/patientdata', payload);
    return response.data;
  };

const getPatientbyId = async (id) => {
  const payload = {
    params: {
      id
    }
  }
  const response = await axiosHttp.get(API+'/patientdata/id', payload);
    return response.data;
}

const getPatientbyQuery = async (query) => {
  const payload = {
    params: {
      ...query
    }
  }
  const response = await axiosHttp.get(API+'/patientdata/query', payload);
    return response.data;
}

const updatePatient = async (payload) => {
  console.log(payload);
  const response = await axiosHttp.put(API+'/patientdata/update', payload);
  return response.data;
}

export const PatientdataAPI = {
    getPatientList,
    CreatePatient,
    getPatientbyId,
    updatePatient,
    getPatientbyQuery
}