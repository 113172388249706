import { Tabs } from "antd";

const AppointmentMenu = (props) => {
    const { items, activeTabKey, tabClick, tabButn } = props;

    return (
        <>
            <Tabs
                onChange={tabClick}
                defaultActiveKey={'dailyViewApp'}
                activeKey={activeTabKey}
                centered
                items={items}
                tabBarExtraContent={tabButn}
            />
        </>
    )
}

export default AppointmentMenu;
