import Swal from "sweetalert2";

const ErrorMsg = (msgName, success, error, errorMsg, create, update, msgDelete, status, code) => {
    if (success && create) {
        Swal.fire({
            title: 'Success!',
            text: `${msgName} Created Successfully!`,
            icon: 'success',
            confirmButtonText: 'Okay',
            confirmButtonColor: '#005154'
        })
    }
    if ((error && create) || (error && update)) {
        Swal.fire({
            title: 'Error!',
            text: `Sorry, at ${msgName} ${errorMsg || 'facing an issue, Please contact Adminstrator'} !`,
            icon: 'error',
            confirmButtonText: 'Okay',
            confirmButtonColor: '#005154'
        })
    }

}

export default ErrorMsg;