import { useSearchParams } from "react-router-dom";
import ClaimMenu from "./components/claimMenu";
import './style.scss';
import { useEffect, useState } from "react";
import appointmentAPI from "../appointment/appointment.hook";
import patientApi from "../patient/hook/patient.api";
import claimAPI from "./hook/claim.hook";
import { useDispatch } from "react-redux";
import { headerName } from "../../layout/headerName.hook";


const ClaimWrapper = () => {
    const dispatch = useDispatch();
    dispatch(headerName("Claim"));
    return (
        <>
            <ClaimMenu  />
        </>
    )
}

export default ClaimWrapper;