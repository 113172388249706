import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'userInfo',
    initialState: {
        useId: '',
        role: '',
        token: '',
        profileId: null,
        facilityId: null,
        expireAt: null
    },
    reducers: {
      setUserInfo: (state, action) => {
        console.log(action.payload)
        state = action.payload;
        console.log(state)
        return state;
      },
    },
  });
  
  export const { setUserInfo } = userSlice.actions;
  export const getUserInfo = (state) => state.userInfo;
  
  export default userSlice.reducer;