import { Row, Col, Form, DatePicker, Calendar, Button, Card, Input, Select, Tooltip } from "antd";
import { generateCalendar, weeklyGenerate } from "./components/generateCalendar";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import appointmentAPI from "./appointment.hook";
import dayjs from "dayjs";
import { UserOutlined, FieldTimeOutlined, EnvironmentOutlined } from "@ant-design/icons";
import patientApi from "../patient/hook/patient.api";
import { getUserInfo } from '../../store/hook/user.hook';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const weeklyData = weeklyGenerate();
const Option = Select;

const getAppiontments = async (qryDate, queryProvd) => {
    // console.log(qryDate);
    let payload = {
        dates: qryDate,
        providerId: queryProvd,
    }
    const appsList = await appointmentAPI.getDateAppointmentweekly(payload, 'week');
    return appsList;
}


const WeeklyViewAppointment = () => {

    const [querydate, setQuerydate] = useState([]);
    const [appointmentsList, setAppointmentsList] = useState([]);
    const [appointmentData, setAppointmentData] = useState([]);
    const [queryProvd, setQueryProvd] = useState();
    const [providerList, setProviderList] = useState([]);
    const navigate = useNavigate();
    const userInfo = useSelector(getUserInfo);


    const getData = async () => {
        const appointmentData = await generateCalendar();
        setAppointmentData(appointmentData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onPanelChanges = (v) => {
        // console.log(v);
        let weekly = [];
        setQuerydate([]);
        for (var i = 0; i < 10; i++) {
            let d = v.add(i, 'days');
            if (d.day() !== 6 && d.day() !== 0) {
                weekly.push(d.format('MM/DD/YYYY'))
            }
        };
        // console.log(weekly, weekly.slice(0, 5))
        setQuerydate(weekly.slice(0, 6));
        // console.log(weekly.slice(0, 6), queryProvd);
        getAppiontments(weekly.slice(0, 6), queryProvd)
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleProvd = (v) => {
        console.log('handleProvd', querydate, v)
        setQueryProvd(v);
        getAppiontments(querydate, v);
    };

    const getInforApp = (r) => {
        return (
            <Card
                title="Patient Information"
                bordered={false}
                style={{
                    width: 230,
                }}
                size="small"
            >
                <div className="tip">

                    <div>
                        <span className="tipLabel icon"><UserOutlined /> {r?.data?.appointmentPatientfirstName}</span>
                        {/* <span className="tipValue"></span> */}
                    </div>
                    <div style={{ marginTop: '8px' }}><span className="tipLabel">Dob :</span> <span className="tipValue"> {r?.data?.appointmentPatientdob}</span></div>
                    <div style={{ marginTop: '8px' }}><span className="tipLabel">Phone :</span> <span className="tipValue">  {r?.data?.appointmenPatientPhone}</span></div>
                    <div style={{ marginTop: '8px' }}><span className="tipLabel icon"><FieldTimeOutlined />Reason</span> <span className="tipValue">  {r?.data?.reasonVisit}</span></div>
                    <div style={{ marginTop: '8px' }}><span className="tipLabel">check In :</span> <span className="tipValue" style={{ fontSize: '12px' }}>  {r?.data?.appointmentCheckIn} - {r?.data?.appointmentCheckOut}</span></div>
                    <div style={{ marginTop: '8px' }}><span className="tipLabel">Provider :</span> <span className="tipValue">  {r?.data?.appointmenPatientProvider}</span></div>
                    <div style={{ marginTop: '8px' }}><span className="tipLabel status">Status :</span> <span className="tipValue">  {r?.data?.status}</span></div>
                    <div style={{ marginTop: '8px' }}><span className="tipLabel icon"><EnvironmentOutlined /> In person</span></div>
                </div>

            </Card>
        )
    }


    const getWeeklyData = (w, d) => {
        // console.log(weeklyData, w);
        for (var l = 0; l < d.length; l++) {
            for (var j = 0; j < weeklyData.length; j++) {
                for (var m = 0; m < weeklyData[j].apppoint.length; m++) {
                    const timemin = weeklyData[j].apppoint[m]?.min ? `${weeklyData[j].apppoint[m].time}:${weeklyData[j].apppoint[m].min}${weeklyData[j].apppoint[m].timeAt}` : `${weeklyData[j].apppoint[m].time}${weeklyData[j].apppoint[m].timeAt}`;
                    if (w[j]?.length) {
                        for (var f = 0; f < w[j].length; f++) {
                            for (var k = 0; k < querydate.length; k++) {
                                if (querydate[k] === w[j][f].appointmentVisitDate) {
                                    if (timemin === w[j][f].appointmenVisitTime) {
                                        weeklyData[k].apppoint[m].data = w[j][f];
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getSeprated = (data) => {
        const h = data?.map((d) => d.appointmentVisitDate);
        const ud = [...new Set(h)];
        // console.log(ud);
        let weeklyUniData = [];
        for (var i = 0; i < ud.length; i++) {
            let arry = [];
            for (var j = 0; j < data.length; j++) {
                if (ud[i] === data[j].appointmentVisitDate) {
                    arry.push(data[j]);
                }
            }
            weeklyUniData.push(arry);
        };
        getWeeklyData(weeklyUniData, ud);
    }


    useEffect(() => {
        console.log('coming', querydate, queryProvd);
        if (querydate && queryProvd !== undefined) {
            let appsList = getAppiontments(querydate, queryProvd);
            // console.log(appsList);
            getSeprated(appsList);
            setAppointmentsList(appsList);
        }
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getProviders = async () => {
        const providerLists = await patientApi.providerList();
        console.log('providerLists', providerLists)
        if (providerLists.length) {
            console.log(userInfo.providerId, providerLists[0])
            if (userInfo.role === 'provider') {
                handleProvd(userInfo.providerId);
            } else {
                handleProvd(providerLists[0].providerId);
            }

            setProviderList(providerLists);
        }
    }

    useEffect(() => {
        let today = new Date();
        const d = dayjs(today);
        onPanelChanges(d);
        getProviders();
    }, []);


    return (
        <>
            <Row gutter={[16, 16]} style={{ marginBottom: '10px' }}>
                <Col span={4} >
                    <strong>Provider</strong>
                </Col>
                <Col span={4} >
                    {/* <Calendar size="small" fullscreen={false} onChange={onPanelChanges} /> */}
                    <DatePicker size="small" onChange={onPanelChanges} />
                </Col>
                <Col span={4} >
                    {/* <Card>
                        <Form layout='vertical'>
                            <Form.Item label="Office" name="office">
                                <Input />
                            </Form.Item> */}
                    <strong>Provider</strong>
                </Col>
                <Col span={4}>
                    <Select size='small' style={{ width: '200px' }} defaultValue={queryProvd} onChange={(e) => handleProvd(e)} value={queryProvd} name="provider" >
                        {providerList?.map((p) =>
                            <Option value={p.providerId} key={p.providerId} >{p.providerFirstName}</Option>
                        )}

                    </Select>
                    {/* <Form.Item label="Timeline" name="timeline">
                                <Select />
                            </Form.Item> */}
                    {/* </Form>
                    </Card> */}
                </Col>
                {/* <Col span={6} className='shadowCard'>
                    <Button type="primary" >
                        Open Modal with async logic
                    </Button>
                </Col> */}
            </Row>

            <Row>
                <Col span={2} className="headerTime">
                    Time
                </Col>
                <Col span={22}>
                    <Row className="weeklyHeader">
                        {querydate?.map((d) => (
                            <Col span={4} style={{ textAlign: 'center' }}>
                                {new Date(d).toLocaleString('en-us', { weekday: 'long' })}
                                <>({d})</>
                            </Col>
                        ))}
                    </Row>
                </Col>
                <Col span={2} className="timeWrapper">
                    {weeklyData[0].apppoint.map((app, t) => {
                        let hourStart = (((t + 1) % 4) === 1) ? 'hourStart' : '';
                        let hourEnd = (((t + 1) % 4) === 1) ? 'hourEnd' : '';
                        return (
                            <div key={`row-key-time${t}`} className={`weeklyTableRow time ${hourStart} ${hourEnd}`}>
                                <div key={`cell-key-time${t}`} className="weeklyTableCell time">
                                    {app.min === 0 ? (<>{app.time} : 0{app.min} {app.timeAt}</>) : (<>: {app.min} {app.timeAt}</>)}
                                </div>
                            </div>)
                    })}
                </Col>
                <Col span={22}>
                    <Row>
                        {weeklyData.map((data, i) => {
                            return (
                                <>
                                    {(weeklyData.length - 2) <= i ? (
                                        <Col span={4} key={`table-key${i}`}>
                                            {data.apppoint.map((app, d) => {
                                                return (
                                                    <div key={`row-key${d}`} className={`weeklyTableRow date`}>
                                                        <div key={`cell-key${d}`} className="weeklyTableCell">
                                                            <span style={{ fontWeight: '700' }}>
                                                                {app.data.appointmentPatientfirstName ? app.data.appointmentPatientfirstName : ''}
                                                            </span>
                                                        </div>
                                                    </div>)
                                            })}
                                        </Col>
                                    ) : (
                                        <Col span={4} key={`table-key${i}`}>
                                            {data.apppoint.map((app, d) => {
                                                return (
                                                    <div key={`row-key${d}`} className={`weeklyTableRow date`}>
                                                        <div key={`cell-key${d}`} className="weeklyTableCell">
                                                            <span style={{ fontWeight: '700' }}>
                                                                <Tooltip color="#fff" title={getInforApp(app)}>
                                                                    {app.data.appointmentPatientfirstName ? app.data.appointmentPatientfirstName : ''}
                                                                </Tooltip>
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </Col>
                                    )}
                                </>
                            )
                        })}
                    </Row>
                </Col>
                {/* {weeklyData.map((data, i) => {
                    return (
                        <div key={`table-key${i}`} className="weeklyTable">
                            {data.apppoint.map((app, d) => {
                                let hourStart = (((d + 1) % 4) === 1) ? 'hourStart' : '';
                                let hourEnd = (((d + 1) % 4) === 1) ? 'hourEnd' : '';
                                return (
                                    <div key={`row-key${d}`} className={`weeklyTableRow ${hourStart} ${hourEnd}`}>
                                        <div key={`cell-key${d}`} className="weeklyTableCell">
                                            <span style={{ fontWeight: '700' }}> {app.data.appointmentPatientfirstName}
                                            </span>-{app.data.appointmentCheckIn}
                                        </div>
                                    </div>)
                            })}

                        </div>
                    )

                })} */}
            </Row>



            {/* </div> */}
        </>
    )
}

export default WeeklyViewAppointment;