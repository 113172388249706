import React, { Suspense, useEffect, useState } from "react";
import { Provider, useSelector } from "react-redux";
import { Spin } from "antd";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import 'antd/dist/reset.css';
import { ConfigProvider } from "antd";
import store from "./store/store";
import AppointmentBook from "./modules/appointment/appointment";
import LayoutWrapper from "./layout/layoutWrapper";
import PatientWrapper from "./modules/patient/patient";
import ClaimWrapper from "./modules/claim/claim";
// import NewProvider from "./modules/provider/provider";
import NewBillingProvider from "./modules/billingProvider/billingprovider";
import BillingProviderlist from "./modules/billingProvider/billingproviderlist";
import NewProvider from "./modules/provider/provider";
import Providerlist from "./modules/provider/providerList";
import CreatePatient from "./modules/patientmanagement/newpatient";
import Patientdatalist from "./modules/patientmanagement/patientdatalist";
import NewFacility from "./modules/facility/facility";
import Facilitylist from "./modules/facility/facilityList";
import NewInsurance from "./modules/insurance/insurance";
import InsuranceList from "./modules/insurance/insuranceList";
import NewReferringProvider from "./modules/referringProvider/referringprovider";
import ReferringProviderList from "./modules/referringProvider/referringProviderlist";
import Dashboard from "./modules/dashboard/dashboard";
import Dashboard1 from "./modules/dashboard/dashboard";
import Dashboard3 from "./modules/dashboard/dashboard-3";
import Dashboard2 from "./modules/dashboard/dashboard-2";
import Dashboard4 from "./modules/dashboard/dashboard-4";
import IcdCode from "./modules/icdcode/IcdCode";
import CPTCode from "./modules/CptCode.js/CPTCode";
import LoginPage from "./modules/login/login";
import UserCreation from "./modules/userManage/createUser";
import Dashboard5 from "./modules/dashboard/dashboard-5";
import ProtectedRoute from "./middleware/ProtectedRouter";
import UserList from "./modules/userManage/userList";
import { getUserInfo } from "./store/hook/user.hook";
import PatientProfile from "./modules/patientmanagement/patientProfile";

const App = () => {
  return (
    <HashRouter>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: "#007a7a",
            borderRadius: 2,

            // Alias Token
            colorBgContainer: "#ffffff",
          },
        }}
      >
        <Provider store={store}>
          <Suspense fallback={<Spin size="large" />}>
            <Routes>
              <Route index path="/" element={<LoginPage />} />
              <Route path="/login" element={<LoginPage />} />

              <Route path="/" element={<LayoutWrapper />}>
                <Route index path="/" element={<Dashboard3 />} />
                <Route path="/" element={<Dashboard3 />} />
                <Route path="/home" element={<Dashboard3 />} />
                {/* <ProtectedRoute path="/home"  > */}
                {/* <Route path="/login" element={<LoginPage />} /> */}
                {/* <Route path="/home" element={<Dashboard3 />} /> */}
                {/* <Route path="/home" element={<Dashboard />} />  */}
                {/* <Route path="/home" element={<Dashboard2 />} /> */}
                {/* <Route path="/home" element={<Dashboard4 />} /> */}
                {/* <Route path="/home" element={<Dashboard5 />} /> */}
                <Route path="/appointment" element={<AppointmentBook />} />
                <Route path="/patient" element={<PatientWrapper />} />
                <Route path="/claim" element={<ClaimWrapper />} />
                <Route path="/billingprovider" element={<NewBillingProvider />} />
                <Route path="/billingProviderlist" element={<BillingProviderlist />} />
                <Route path="/provider" element={<NewProvider />} />
                <Route path="/providerlist" element={<Providerlist />} />
                <Route path="/managepatient" element={<CreatePatient />} />
                <Route path="/managepatientlist" element={<Patientdatalist />} />
                <Route path="/managepatientprofile" element={<PatientProfile />} />
                <Route path="/facility" element={<NewFacility />} />
                <Route path="/facilityList" element={<Facilitylist />} />
                <Route path="/insurance" element={<NewInsurance />} />
                <Route path="/insuranceList" element={<InsuranceList />} />
                <Route path="/referringprovider" element={<NewReferringProvider />} />
                <Route path="/referringProviderlist" element={<ReferringProviderList />} />
                <Route path="/icdcode" element={<IcdCode />} />
                <Route path="/cptcode" element={<CPTCode />} />
                <Route path="/user" element={<UserCreation />} />
                <Route path="/userlist" element={<UserList />} />
              </Route>


            </Routes>
          </Suspense>
        </Provider>
      </ConfigProvider>
    </HashRouter>
  )
}

export default App;
