import { Checkbox, Col, DatePicker, Divider, Form, Input, Radio, Row, Select } from "antd";
import { InsuranceData } from "./constant";
import { DashOutlined, DotChartOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { headerName } from "../../../layout/headerName.hook";
import dayjs from "dayjs";

const { Option } = Select

const PatientInsurance = ({ insurancePop, selfInsurance, isSelf, isSelfSec }) => {
    const [patientEligAutoEliFreq, setPatientEligAutoEliFreq] = useState();
    let now = dayjs();
    return (<>
        <div className="">
            <span className="titleStyle">
                Eligibility & Benefits Insurance Information:
            </span>
            <Row gutter={8}>
                <Col span={6}>
                    <Form.Item name="patientEligLastcheck" label="Last Checked on">
                        <Input size="small" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="patientEligCheckFreq" label="Eligibility Check Frequency">
                        <Select size="small">
                            <Select.Option value="Each appointment">Each appointment</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="patientEligAutoEliFreq" style={{ marginTop: '22px', marginLeft: '30px' }}>
                        <Checkbox size="small"
                            checked={patientEligAutoEliFreq}
                            onChange={(e) => setPatientEligAutoEliFreq(e.target.checked)}>Automated Eligibility</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={7}>
                    <Form.Item name="patientEligDenPayername" label="Eligibility & Benefit Payer Name">
                        <Input size="small" />
                        {/* <Select size="small" showSearch>
                            <Select.Option value="">empty</Select.Option>
                        </Select> */}
                    </Form.Item>
                </Col>
                < Col span={1}>
                    <Col span={1} className="mt22">
                        <span className="iconSearch" onClick={() => insurancePop('patientEligDenPayername')}><DashOutlined /></span>
                    </Col>
                </Col>
            </Row>

            <Divider orientation="left" className="lineStyle">Primary Insurance</Divider>
            <div className="primaryInsurance">
                <Row gutter={8}>
                    <Col span={7}>
                        <span className="lineStyle">
                            Patient Insurance Type/Source of Payment:(MU) </span>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="patientPrimaryInsuranceType" >
                            <Select showSearch size="small"
                                optionFilterProp="children"
                                // onSelect={onempState}
                                filterOption={(input, option) =>
                                    option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                } >
                                {InsuranceData?.map((s) => <Option key={s} value={s}>{s}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={8}>
                    <Col span={6}>
                        <Form.Item name="patientPrimaryInsuranceId" label='Insurance Co.'>
                            <Input size="small" />
                        </Form.Item>

                    </Col>
                    <Col span={1} className="mt22">
                        <span className="iconSearch" onClick={() => insurancePop('primary')}><DashOutlined /></span>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="patientPrimaryInsuranceName" label="Insurance Name">
                            <Input size="small" disabled />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item name="patientPrimaryInsurancePayerId" label="Insurance Payer ID">
                            <Input size="small" disabled />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item name="patientPrimaryInsuranceCapt" label="Capitation Name">
                            <Select size="small" showSearch>
                                <Select.Option value="Yes">Yes</Select.Option>
                                <Select.Option value="No">No</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={7}>
                        <span className="formLable"> Patient Relationship To Primary Insured </span>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientPrimaryInsuranceRel" >
                            <Select showSearch size="small" onChange={(e) => selfInsurance(e, 'primary')}>
                                <Select.Option value="Self">Self</Select.Option>
                                <Select.Option value="Spouse">Spouse</Select.Option>
                                <Select.Option value="Child">Child</Select.Option>
                                <Select.Option value="Other Replationship">Other Replationship</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={6}>
                        <Form.Item name="patientPrimaryInsurancedID" label='Primary Insured'>
                            <Input size="small" disabled={isSelf.primary} />
                        </Form.Item>
                    </Col>
                    <Col span={1} className="mt22">
                        <span className="iconSearch" ><DashOutlined /></span>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientPrimaryInsurancedLastName" label="Last Name">
                            <Input size="small" disabled={isSelf.primary} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientPrimaryInsurancedFirstName" label="First Name">
                            <Input size="small" disabled={isSelf.primary} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientPrimaryInsurancedMiddleName" label="Middle Name">
                            <Input size="small" disabled={isSelf.primary} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={8}>
                    <Col span={5}>
                        <Form.Item name="patientPrimaryInsurancedSubscribeId" label="Subscribe Id">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientPrimaryInsurancedGroupNo" label="Group No">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientPrimaryInsurancedPlanName" label="Plan Name">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={5}>
                        <Form.Item name="patientPrimaryInsurancedAuthoriz" initialValue={'Yes'} label="Insured Authorization">
                            <Select showSearch size="small">
                                <Select.Option value="Yes">Yes</Select.Option>
                                <Select.Option value="No">No</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientPrimaryInsurancedDeductible" label="Deductible">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientPrimaryInsurancedVisitCoPayment" label="Visit Co-Payment">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={24}>
                        <span className="formLable"> Release of Information: </span>
                    </Col>

                    <Col span={5}>
                        <Form.Item name="patientPrimaryInsurancedSignFile" initialValue={'Yes'} label="Signature On File">
                            <Select showSearch size="small">
                                <Select.Option value="Yes">Yes</Select.Option>
                                <Select.Option value="No">No</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientPrimaryInsurancedSignatureDate" label="Signature Date">
                            <DatePicker format={'MM/DD/YYYY'} initialValue={`${dayjs(now).format('MM/DD/YYYY')}`} size="small" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={24}>
                        <div className="titleStyle blue">
                            Authorization: (by Insurance Co.)
                        </div>
                        <div className="subStyle">
                            Please enter Authorization Number in Visits (Billing Options / Prior Authorization Number) for these counts to be accurate
                        </div>
                    </Col>

                    <Col span={6}>
                        <Form.Item name="patientPrimaryInsurancedAuthNumber" label="Authorization Number">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="patientPrimaryInsurancedVisitAuthNo" label="No. of Visits Authorized">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="patientPrimaryInsurancedEffStartDate" label="Eff. Start Date">
                            <DatePicker size="small" format={'MM/DD/YYYY'} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="patientPrimaryInsurancedEffEndDate" label="Eff. End Date">
                            <DatePicker size="small" format={'MM/DD/YYYY'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={6}>
                        <Form.Item name="patientPrimaryInsurancednoVisitused" label="No of Visit Used">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="patientPrimaryInsurancednoVisitleft" label="No of Visit Left">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                </Row>
            </div>

            <Divider orientation="left" className="lineStyle">Secondary Insurance</Divider>
            <div className="secondaryInsurance">
                <Row gutter={8}>

                    <Col span={7}>
                        <span className="lineStyle">
                            Patient Insurance Type/Source of Payment:(MU) </span>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="patientSecondaryInsuranceType" >
                            <Select showSearch size="small"
                                optionFilterProp="children"
                                // onSelect={onempState}
                                filterOption={(input, option) =>
                                    option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                } >
                                {InsuranceData?.map((s) => <Option key={s} value={s}>{s}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={8}>
                    <Col span={6}>
                        <Form.Item name="patientSecondaryInsuranceId" label='Insurance Co.'>
                            <Input size="small" />
                        </Form.Item>

                    </Col>
                    <Col span={1} className="mt22">
                        <span className="iconSearch" onClick={() => insurancePop('secondary')}><DashOutlined /></span>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="patientSecondaryInsuranceName" label="Insurance Name">
                            <Input size="small" disabled />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item name="patientSecondaryInsurancePayerId" label="Insurance Payer ID">
                            <Input size="small" disabled />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item name="patientSecondaryInsuranceCapt" label="Capitation Name">
                            <Select size="small" showSearch>
                                <Select.Option value="Yes">Yes</Select.Option>
                                <Select.Option value="No">No</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={7}>
                        <span className="formLable"> Patient Relationship To Secondary Insured </span>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientSecondaryInsuranceRel" >
                            <Select showSearch size="small" onChange={(e) => selfInsurance(e, 'secondary')}>
                                <Select.Option value="Self">Self</Select.Option>
                                <Select.Option value="Spouse">Spouse</Select.Option>
                                <Select.Option value="Child">Child</Select.Option>
                                <Select.Option value="Other Replationship">Other Replationship</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={6}>
                        <Form.Item name="patientSecondaryInsurancedID" label='Secondary Insured'>
                            <Input size="small" disabled={isSelf.secondary} />
                        </Form.Item>

                    </Col>
                    <Col span={1} className="mt22">
                        <span className="iconSearch"><DashOutlined /></span>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientSecondaryInsurancedLastName" label="Last Name">
                            <Input size="small" disabled={isSelf.secondary} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientSecondaryInsurancedFirstName" label="First Name">
                            <Input size="small" disabled={isSelf.secondary} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientSecondaryInsurancedMiddleName" label="Middle Name">
                            <Input size="small" disabled={isSelf.secondary} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={8}>
                    <Col span={5}>
                        <Form.Item name="patientSecondaryInsurancedSubscribeId" label="Subscribe Id">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientSecondaryInsurancedGroupNo" label="Group No">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientSecondaryInsurancedPlanName" label="Plan Name">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={5}>
                        <Form.Item name="patientSecondaryInsurancedAuthoriz" label="Sec. Insured Authorization">
                            <Select showSearch size="small">
                                <Select.Option value="Yes">Yes</Select.Option>
                                <Select.Option value="No">No</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientSecondaryInsurancedDeductible" label="Deductible">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientSecondaryInsurancedVisitCoPayment" label="Visit Co-Payment">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={24}>
                        <span className="formLable"> Release of Information: </span>
                    </Col>

                    <Col span={5}>
                        <Form.Item name="patientSecondaryInsurancedSignFile" label="Signature On File">
                            <Select showSearch size="small">
                                <Select.Option value="Yes">Yes</Select.Option>
                                <Select.Option value="No">No</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientSecondaryInsurancedSignatureDate" label="Signature Date">
                            <DatePicker format={'MM/DD/YYYY'} size="small" />
                        </Form.Item>
                    </Col>
                </Row>
            </div>

            <Divider orientation="left" className="lineStyle">Third Insurance </Divider>
            <div className="thirsInsurance">
                <Row gutter={8}>

                    <Col span={7}>
                        <span className="lineStyle">
                            Patient Insurance Type/Source of Payment:(MU) </span>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="patientThirdInsuranceType" >
                            <Select showSearch size="small"
                                optionFilterProp="children"
                                // onSelect={onempState}
                                filterOption={(input, option) =>
                                    option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                } >
                                {InsuranceData?.map((s) => <Option key={s} value={s}>{s}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={8}>
                    <Col span={6}>
                        <Form.Item name="patientThirdInsuranceId" label='Insurance Co.'>
                            <Input size="small" />
                        </Form.Item>

                    </Col>
                    <Col span={1} className="mt22">
                        <span className="iconSearch" onClick={() => insurancePop('Third')}><DashOutlined /></span>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="patientThirdInsuranceName" label="Insurance Name">
                            <Input size="small" disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="patientThirdInsurancePayerId" label="Insurance Payer ID">
                            <Input size="small" disabled />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item name="patientThirdInsuranceCapt" label="Capitation Name">
                            <Select size="small" showSearch>
                                <Select.Option value="Yes">Yes</Select.Option>
                                <Select.Option value="No">No</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={7}>
                        <span className="formLable"> Patient Relationship To Third Insured </span>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientThirdInsuranceRel">
                            <Select showSearch size="small" onChange={(e) => selfInsurance(e, 'thrid')}>
                                <Select.Option value="Self">Self</Select.Option>
                                <Select.Option value="Spouse">Spouse</Select.Option>
                                <Select.Option value="Child">Child</Select.Option>
                                <Select.Option value="Other Replationship">Other Replationship</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={6}>
                        <Form.Item name="patientThirdInsurancedID" label='Third Insured'>
                            <Input size="small" disabled={isSelf.thrid} />
                        </Form.Item>

                    </Col>
                    <Col span={1} className="mt22">
                        <span className="iconSearch"><DashOutlined /></span>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientThirdInsurancedLastName" label="Last Name">
                            <Input size="small" disabled={isSelf.thrid} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientThirdInsurancedFirstName" label="First Name">
                            <Input size="small" disabled={isSelf.thrid} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientThirdInsurancedMiddleName" label="Middle Name">
                            <Input size="small" disabled={isSelf.thrid} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={8}>
                    <Col span={5}>
                        <Form.Item name="patientThirdInsurancedSubscribeId" label="Subscribe Id">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientThirdInsurancedGroupNo" label="Group No">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientThirdInsurancedPlanName" label="Plan Name">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={5}>

                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientThirdInsurancedDeductible" label="Deductible">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientThirdInsurancedVisitCoPayment" label="Visit Co-Payment">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                </Row>

            </div>

            <Divider orientation="left" className="lineStyle">Alternate Primary Insurance</Divider>

            <div className="primaryInsurance">
                <span style={{ fontSize: '10px', fontStyle: 'italic' }}>
                    Add Alternate Primary in Insurance to record, WC, AUTO, DME and other Insurance that could be primary depending upon the type of services performed.
                </span>
                <Row gutter={8}>
                    <Col span={7}>
                        <span className="lineStyle">
                            Alternate Patient Insurance Type/Source of Payment:(MU) </span>

                    </Col>

                    <Col span={12}>
                        <Form.Item name="patientaltPrimaryInsuranceType" >
                            <Select showSearch size="small"
                                optionFilterProp="children"
                                // onSelect={onempState}
                                filterOption={(input, option) =>
                                    option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                } >
                                {InsuranceData?.map((s) => <Option key={s} value={s}>{s}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        < Form.Item name="patientAltPrimaryType" label="Alternate Primary in Insurance for record, WC, AUTO, DME and other Insurance">
                            <Radio.Group >
                                <Radio value="WC">WC</Radio>
                                <Radio value="AUTO">AUTO</Radio>
                                <Radio value="DME">DME</Radio>
                                <Radio value="Other">OTHER</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={6}>
                        <Form.Item name="patientaltPrimaryInsuranceId" label='Alt. Insurance Co.'>
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={1} className="mt22">
                        <span className="iconSearch" onClick={() => insurancePop('altprimary')}><DashOutlined /></span>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="patientaltPrimaryInsuranceName" label="Alt. Insurance Name">
                            <Input size="small" disabled />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="patientaltPrimaryInsuranceCapt" label="Alt. Capitation Name">
                            <Select size="small" style={{ width: '100px' }} showSearch>
                                <Select.Option value="Yes">Yes</Select.Option>
                                <Select.Option value="No">No</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={7}>
                        <span className="formLable">Atl Patient Relationship To Primary Insured </span>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientaltPrimaryInsuranceRel">
                            <Select showSearch size="small" onChange={(e) => selfInsurance(e, 'altPrimary')}>
                                <Select.Option value="Self">Self</Select.Option>
                                <Select.Option value="Spouse">Spouse</Select.Option>
                                <Select.Option value="Child">Child</Select.Option>
                                <Select.Option value="Other Replationship">Other Replationship</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={6}>
                        <Form.Item name="patientaltPrimaryInsurancedID" label='Alt. Primary Insured'>
                            <Input size="small" disabled={isSelf.altPrimary} />
                        </Form.Item>

                    </Col>
                    <Col span={1} className="mt22">
                        <span className="iconSearch"><DashOutlined /></span>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientaltPrimaryInsurancedLastName" label="Last Name">
                            <Input size="small" disabled={isSelf.altPrimary} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientaltPrimaryInsurancedFirstName" label="First Name">
                            <Input size="small" disabled={isSelf.altPrimary} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientaltPrimaryInsurancedMiddleName" label="Middle Name">
                            <Input size="small" disabled={isSelf.altPrimary} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={8}>
                    <Col span={5}>
                        <Form.Item name="patientaltPrimaryInsurancedSubscribeId" label="Alt. Subscribe Id">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientaltPrimaryInsurancedGroupNo" label="Alt. Group No">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientaltPrimaryInsurancedPlanName" label="Alt. Plan Name">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={5}>
                        <Form.Item name="patientaltPrimaryInsurancedAuthoriz" label="Alt. Insured Authorization">
                            <Select showSearch size="small">
                                <Select.Option value="Yes">Yes</Select.Option>
                                <Select.Option value="No">No</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientaltPrimaryInsurancedDeductible" label="Alt. Deductible">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientaltPrimaryInsurancedVisitCoPayment" label="Alt. Visit Co-Payment">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={24}>
                        <span className="formLable"> Release of Information: </span>
                    </Col>

                    <Col span={5}>
                        <Form.Item name="patientaltPrimaryInsurancedSignFile" label="Alt. Signature On File">
                            <Select showSearch size="small">
                                <Select.Option value="Yes">Yes</Select.Option>
                                <Select.Option value="No">No</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name="patientaltPrimaryInsurancedSignatureDate" label="Alt. Signature Date">
                            <DatePicker format={'MM/DD/YYYY'} size="small" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={24}>
                        <div className="titleStyle blue">
                            Authorization: (by Insurance Co.)
                        </div>
                        <div className="subStyle">
                            Please enter Authorization Number in Visits (Billing Options / Prior Authorization Number) for these counts to be accurate
                        </div>
                    </Col>

                    <Col span={6}>
                        <Form.Item name="patientaltPrimaryInsurancedAuthNumber" label="Alt. Authorization Number">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="patientaltPrimaryInsurancedVisitAuthNo" label="Alt. No. of Visits Authorized">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="patientaltPrimaryInsurancedEffStartDate" label="Alt. Eff. Start Date">
                            <DatePicker size="small" format={'MM/DD/YYYY'} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="patientaltPrimaryInsurancedEffEndDate" label="Alt. Eff. End Date">
                            <DatePicker size="small" format={'MM/DD/YYYY'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={6}>
                        <Form.Item name="patientaltPrimaryInsurancednoVisitused" label="Alt. No of Visit Used">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="patientaltPrimaryInsurancednoVisitleft" label="Alt. No of Visit Left">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                </Row>
            </div>

            <Divider />
            <div className="primaryInsurance">
                <Col span={24}>
                    <div className="titleStyle blue">
                        Guarantor Information
                    </div>
                    <div className="subStyle">
                        (if different from primary insured or patient)
                    </div>
                </Col>
                <Row gutter={8}>
                    <Col span={6}>
                        <Form.Item name="patientGarantorId" label="Garantor ID">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={1} className="mt22">
                        <span className="iconSearch"><DashOutlined /></span>
                    </Col>

                    <Col span={6}>
                        <Form.Item name="patientGarantorLastName" label="Last Name">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="patientGarantorFirstName" label="First Name">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="patientGarantorMiddleName" label="Middle Name">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </div>
    </>)

}

export default PatientInsurance;