import { SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import { Row, Col, Input, Form, Select, Typography } from "antd";
import './style.scss';
import ICDCode9 from "./components/icdcode9";
import ICDCode10 from "./components/icdcode10";

const BillingInfoForm = ({cptList, form, onSel, dataIcd9, descClear, searchcpt, searchicd, onSearch, calcTotal, icdCodeList, del}) => {
    
    return (
        <>
            <div>
                <ICDCode9 descClear={descClear} cptList={cptList}
                onSearch={onSearch} calcTotal={calcTotal} onSel={onSel} searchicd={searchicd}
                form={form} dataIcd9={dataIcd9} searchcpt={searchcpt} icdCodeList={icdCodeList} />
            </div>
            <div>
                <ICDCode10 cptList={cptList} calcTotal={calcTotal} del={del}/>
            </div>
            <Row justify='center'>
                <Col span={2}>
                <span> Total</span>
                </Col>
                <Col span={4}>
                    
                    <Form.Item name="total" >
                        <Input disabled/>
                    </Form.Item>
                </Col>
            </Row>

        </>
    )
}

export default BillingInfoForm;