import { Row, Col, Typography, Form, Input, Button } from "antd";
import './style.scss';
import { InfoCircleOutlined, UserOutlined, SearchOutlined, PlusOutlined, EditOutlined } from "@ant-design/icons";
import Patientmenu from "./components/patientVisitMenu";
import { useDispatch } from "react-redux";
import { headerName } from "../../layout/headerName.hook";

const { Title } = Typography;

const PatientWrapper = () => {
    const dispatch = useDispatch();
    dispatch(headerName("Patient Visit"));
    return (
        <>
            <Patientmenu />
        </>
    )
}

export default PatientWrapper;