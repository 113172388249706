import { DashOutlined } from "@ant-design/icons";
import { Col, DatePicker, Divider, Form, Input, Radio, Row, Select, Typography } from "antd"

const { Title } = Typography;
const { Option } = Select;

const BillingOption = ({ searchProvd, popUp, popUpfacility }) => {
    const opt = ['01-Proof of Eligibility unknown or Unavailable',
        '02-Litigation',
        '03-Authorization Delays',
        '04-Delay in Certifying Providers',
        '05-Delay in Supplying Billing Forms',
        '06-Delay in Delivery of Custom-made Appliance',
        '07-Third Party Processing Delay',
        '08-Delay eligibility of determination',
        '09-Original claim rejected or denied due to reason unrealed to the billing limitation rules',
        '10-Administration Delay in the prior Approval process',
        '15-Natural Disaster',
        '11-Other']
    return (
        <>
            <Title level={5}>Other Provider / Contacts</Title>
            <Row gutter={16}>
                <Col span={4}>
                    Reffering Physician:
                    (First, MI & Last Name)
                </Col>
                <Col span={7}>
                    <Form.Item name='billingRefferingPhysician' >
                        <Input size="small" placeholder="Provider NPI to Search" />
                    </Form.Item>
                </Col>
                <Col span={1}>
                    <DashOutlined className="searchBtn" style={{ marginTop: '0px' }}
                        onClick={() => searchProvd('visitProviderID', 'RefferingPhysician', 'Reffering Provider')} />
                </Col>
                <Col span={12} className="hcpaFormLink">
                    <span><a href="https://help.gethealthie.com/article/871-claim-forms-referring-provider-information" target="blank">(HCFA form, box 17)</a></span>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={4}>

                </Col>
                <Col span={4} className="phyFields">
                    <Form.Item name='billingFirstname' rules={[
                        {
                            required: true,
                            message: 'Please select a provider!',
                        },
                    ]}>
                        <Input size="small" />
                    </Form.Item>
                </Col>
                <Col span={3} className="phyFields">
                    <Form.Item name='billinMiddlename'>
                        <Input size="small" />
                    </Form.Item>
                </Col>
                <Col span={4} className="phyFields">
                    <Form.Item name='billingLastname' rules={[
                        {
                            required: true,
                            message: 'Please select a provider!',
                        },
                    ]}>
                        <Input size="small" />
                    </Form.Item>
                </Col>

                <Col span={4} className="phyFieldsId" style={{ marginTop: '-20px ' }}>
                    <Form.Item name='billingPhysicicanID' label='Physician ID' rules={[
                        {
                            required: true,
                            message: 'Please select a provider!',
                        },
                    ]}>
                        <Input size="small" />
                    </Form.Item>
                </Col>
                <Col span={4} className="phyFieldsId" style={{ marginTop: '-20px ' }}>
                    <Form.Item name='billingPhysicicanNpi' label='NPI'
                    rules={[
                        {
                            required: true,
                            message: 'Please select a provider!',
                        },
                    ]}>
                        <Input size="small" />
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <div>
                <Row gutter={16}>
                    <Col span={4}>
                        Supervising Physician:
                        (First, MI & Last Name)
                    </Col>
                    <Col span={7}>
                        <Form.Item name='billingSupervisingPhysician'>
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={1}>
                        <DashOutlined className="searchBtn" style={{ marginTop: '0px' }}
                            onClick={() => searchProvd('visitProviderID', 'billingSupervisingPhysician', 'Spervising Provider')} />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={4}>

                    </Col>
                    <Col span={4} className="phyFields">
                        <Form.Item name='billingSupervisingFirstname' rules={[
                        {
                            required: true,
                            message: 'Please select a provider!',
                        },
                    ]}>
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={3} className="phyFields">
                        <Form.Item name='billinSupervisingMiddlename'>
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={4} className="phyFields">
                        <Form.Item name='billingSupervisingLastname' rules={[
                        {
                            required: true,
                            message: 'Please select a provider!',
                        },
                    ]}>
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={4} className="phyFieldsId" style={{ marginTop: '-20px ' }}>
                        <Form.Item name='billingSupervisingPhysicicanID' label='Physician ID' rules={[
                        {
                            required: true,
                            message: 'Please select a provider!',
                        },
                    ]}>
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={4} className="phyFieldsId" style={{ marginTop: '-20px ' }} >
                        <Form.Item name='billingSupervisingPhysicicanNpi' label='NPI' rules={[
                        {
                            required: true,
                            message: 'Please select a provider!',
                        },
                    ]}>
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
            <Divider />
            <div>
                <Row gutter={16}>
                    <Col span={6}>
                        Odering Physician:
                        (First, MI & Last Name)
                    </Col>
                    <Col span={7}>
                        <Form.Item name='billingOderingPhysician'>
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <DashOutlined className="searchBtn" style={{ marginTop: '0px' }}
                        onClick={() => searchProvd('visitProviderID', 'OderingPhysician', 'Ordering Provider')} />
                </Row>
                <Row gutter={16}>
                    <Col span={6}>

                    </Col>
                    <Col span={4} className="phyFields">
                        <Form.Item name='billingOderingFirstname' rules={[
                        {
                            required: true,
                            message: 'Please select a provider!',
                        },
                    ]}>
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={2} className="phyFields">
                        <Form.Item name='billinOderingMiddlename'>
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={4} className="phyFields">
                        <Form.Item name='billingOderingLastname' rules={[
                        {
                            required: true,
                            message: 'Please select a provider!',
                        },
                    ]}>
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={4} className="phyFieldsId" style={{ marginTop: '-20px ' }}>
                        <Form.Item name='billingOderingPhysicicanID' label='Physician ID' rules={[
                        {
                            required: true,
                            message: 'Please select a provider!',
                        },
                    ]}>
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={4} className="phyFieldsId" style={{ marginTop: '-20px ' }}>
                        <Form.Item name='billingPOderinghysicicanNpi' label='NPI' rules={[
                        {
                            required: true,
                            message: 'Please select a provider!',
                        },
                    ]}>
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
            <Divider />
            <div>
                <Row gutter={16}>
                    <Col span={6}>
                        Facility:
                    </Col>
                    <Col span={6}>
                        <Form.Item name="billingOptionFacilityProID" label="ID">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="billingOptionFacilityName" label="Name" rules={[
                        {
                            required: true,
                            message: 'Please select a facility!',
                        },
                    ]}>
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={1}>
                        <DashOutlined className="searchBtn" style={{ marginTop: '25px' }} onClick={() => popUpfacility('visitProviderID', 'facilityList')} />
                    </Col>
                    <Col span={5} className="hcpaFormLink">
                        <span><a href="https://help.gethealthie.com/article/866-claim-forms-service-facility-box-32" target="blank">
                            (HCFA form, box 32)</a></span>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}> </Col>
                    <Col span={5}>
                        <Form.Item name="billingOptionFacilityAddress" label="Address">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item name="billingOptionFacilityAddress2" label="Address2">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    {/* </Row>
                <Row gutter={16}>
                    <Col span={6}> </Col> */}
                    <Col span={3}>
                        <Form.Item name="billingOptionFacilityState" label="State">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="billingOptionFacilityCity" label="City">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item name="billingOptionFacilityZip" label="Zip">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}> </Col>
                    <Col span={6}>
                        <Form.Item name="billingOptionFacilityId" label="Facility ID">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="billingOptionFacilityNPI" label="NPI" rules={[
                        {
                            required: true,
                            message: 'Please select a facility!',
                        },
                    ]}>
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
            <Divider />
            <div>
                <Row gutter={16}>
                    <Col span={6}>
                        Billing Provider:
                    </Col>
                    <Col span={6}>
                        <Form.Item name="billingOptionBProviderID" label="ID">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="billingOptionBProviderName" label="Name" rules={[
                        {
                            required: true,
                            message: 'Please select a provider!',
                        },
                    ]}>
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={1}>
                        <DashOutlined className="searchBtn" style={{ marginTop: '25px' }} onClick={() => popUp('visitProviderID', 'billingProvider')} />
                    </Col>
                    <Col span={5} className="hcpaFormLink">
                        <span>
                            <a href="https://support.drchrono.com/hc/en-us/articles/6485512120219-HCFA-1500-Box-33b-How-Do-I-Enter-an-Individual-or-Group-Provider-Identifier" target="blank">
                            (HCFA form, box 33)
                            </a>
                            
                            </span>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}> </Col>
                    <Col span={5}>
                        <Form.Item name="billingOptionBProviderAddress" label="Address">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item name="billingOptionBProviderAddress2" label="Address2">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    {/* </Row>
                <Row gutter={16}>
                    <Col span={6}> </Col> */}
                    <Col span={3}>
                        <Form.Item name="billingOptionBProviderState" label="State">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="billingOptionBProviderCity" label="City">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item name="billingOptionBProviderZip" label="Zip">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}> </Col>
                    <Col span={6}>
                        <Form.Item name="billingProvidePhonenumber" label="Phone">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="billingProvideFaxnumber" label="Fax">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}> </Col>
                    <Col span={6}>
                        <Form.Item name="billingOptionBProviderGroupId" label="Billing Gov. Group">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="billingOptionBProviderNPI" label="NPI" rules={[
                        {
                            required: true,
                            message: 'Please select a provider!',
                        },
                    ]}>
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}> </Col>
                    <Col span={6}>
                        <Form.Item name="billingOptionBProviderTaxID" label="Tax ID" rules={[
                        {
                            required: true,
                            message: 'Please select a provider!',
                        },
                    ]}>
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={3} style={{ marginTop: '20px' }}>
                        <Form.Item name="billingOptionBProviderSSN">
                            <Radio> SSN </Radio>
                        </Form.Item>
                    </Col>
                    <Col span={3} style={{ marginTop: '20px' }}>
                        <Form.Item name="billingOptionBProviderEIN">
                            <Radio> EIN </Radio>
                        </Form.Item>
                    </Col>
                    <Col span={6} className="hcpaFormLink">
                        <span>
                            <a href="https://knowledge.therabill.com/hc/en-us/articles/217242463-Box-25-Federal-Tax-ID-Number" target="blank">
                            (HCFA form, box 25)
                            </a>
                            </span>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Divider orientation="left" style={{color: "blue"}}>Conditions</Divider>
                    {/* <Col span={6}> Conditions</Col> */}
                    <Col span={6}> Patient's Conditions Related To </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Employment (current or previous)
                    </Col>
                    <Col span={4}>
                        <Form.Item name="billingOptionConditionEmpCP">
                            <Select size="small" >
                                <Select.Option value="Yes">Yes</Select.Option>
                                <Select.Option value="No">No</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={14} className="hcpaFormLink">
                        <span>
                            <a href="https://knowledge.therabill.com/hc/en-us/articles/217242043-Box-10a-10b-10c-Is-Patients-Condition-Related-To" target="blank">
                            (HCFA form, box 10a)
                            </a>
                            </span>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Auto Accident?
                    </Col>
                    <Col span={4}>
                        <Form.Item name="billingOptionConditionAutoAccident">
                            <Select size="small">
                                <Select.Option value="Yes">Yes</Select.Option>
                                <Select.Option value="No">No</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="billingOptionConditionAutoAccidentPlease">
                            <Input size="small" />
                        </Form.Item>    
                    </Col>
                    <Col span={10} className="hcpaFormLink">
                        <span>
                        <a href="https://knowledge.therabill.com/hc/en-us/articles/217242043-Box-10a-10b-10c-Is-Patients-Condition-Related-To" target="blank">
                            
                            (HCFA form, box 10b)</a></span>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Other Accident?
                    </Col>
                    <Col span={4}>
                        <Form.Item name="billingOptionConditionOtherAccident">
                            <Select size="small">
                                <Select.Option value="Yes">Yes</Select.Option>
                                <Select.Option value="No">No</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={14} className="hcpaFormLink">
                        <span>
                        <a href="https://knowledge.therabill.com/hc/en-us/articles/217242043-Box-10a-10b-10c-Is-Patients-Condition-Related-To" target="blank">
                            
                            (HCFA form, box 10c)</a></span>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Reserve For local use:
                    </Col>
                    <Col span={4}>
                        <Form.Item name="billingOptionConditionEserve">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                </Row>
            </div>

            <div>
                <Row gutter={16}>
                <Divider orientation="left" style={{color: "blue"}}>Date</Divider>

                    <Col span={6}>
                        Onset of Current
                        Symptom or illness :
                    </Col>
                    <Col span={6}>
                        <Form.Item name="billingOptiondateOnset">
                            <DatePicker size="small" format="MM/DD/YYYY" />
                        </Form.Item>
                    </Col>
                    <Col span={12} className="hcpaFormLink">
                        <span>
                            <a href="https://knowledge.therabill.com/hc/en-us/articles/217242143-Box-14-Date-of-Current-Illness-Injury-or-Pregnancy" target="blank">
                            (HCFA form, box 14)
                            </a>
                            </span>
                    </Col>
                </Row>
                {/* <Row gutter={16}>
                    <Col span={6}>
                        Last Menstrual Period
                    </Col>
                    <Col span={6}>
                        <Form.Item name="billingOptiondateOnset">
                            <DatePicker format="MM/DD/YYYY"/>
                        </Form.Item>
                    </Col>
                </Row> */}

                <Row gutter={16}>
                    <Col span={6}>
                        Last Menstrual Period
                    </Col>
                    <Col span={6}>
                        <Form.Item name="billingOptiondateLastMenstrual">
                            <DatePicker size="small" format="MM/DD/YYYY" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Similar First Date
                    </Col>
                    <Col span={6}>
                        <Form.Item name="billingOptiondateSimilar">
                            <DatePicker size="small" format="MM/DD/YYYY" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Date Last Worked
                    </Col>
                    <Col span={6}>
                        <Form.Item name="billingOptiondateLastWorked">
                            <DatePicker size="small" format="MM/DD/YYYY" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Unable To Work Dates:
                    </Col>
                    <Col span={2} >From</Col>
                    <Col span={4}>
                        <Form.Item name="billingOptiondateunableWorkedfrom">
                            <DatePicker size="small" format="MM/DD/YYYY" />
                        </Form.Item>
                    </Col>
                    <Col span={1} >to</Col>
                    <Col span={4}>
                        <Form.Item name="billingOptiondateunableWorkedto">
                            <DatePicker size="small" format="MM/DD/YYYY" />
                        </Form.Item>
                    </Col>
                    <Col span={7} className="hcpaFormLink">
                        <span>
                            <a href="https://knowledge.therabill.com/hc/en-us/articles/217242183-Box-16-Dates-Patient-Unable-to-Work-in-Current-Occupation" target="blank">
                            (HCFA form, box 16)
                            </a>
                            </span>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Date Authorized to Return to Work:
                    </Col>
                    <Col span={6}>
                        <Form.Item name="billingOptiondateDateAuth">
                            <DatePicker size="small" format="MM/DD/YYYY" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Hosptial Dates
                    </Col>
                    <Col span={2} >From</Col>
                    <Col span={4}>
                        <Form.Item name="billingOptiondateHospitalfrom">
                            <DatePicker size="small"  format="MM/DD/YYYY" />
                        </Form.Item>
                    </Col>
                    <Col span={1} >to</Col>
                    <Col span={4}>
                        <Form.Item name="billingOptiondateHopstialto">
                            <DatePicker size="small" format="MM/DD/YYYY" />
                        </Form.Item>
                    </Col>
                    <Col span={7} className="hcpaFormLink">
                        <span>
                            <a href="https://knowledge.therabill.com/hc/en-us/articles/217242203-Box-18-Hospitalization-Dates-Related-to-Current-Services" target="blank">
                            (HCFA form, box 18)
                            </a>
                            </span>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Pregnancy?
                    </Col>
                    <Col span={4}>
                        <Form.Item name="billingOptiondatePregnancy">
                            <Select size="small" style={{ width: '100%' }}>
                                <Select.Option value="Yes">Yes</Select.Option>
                                <Select.Option value="No">No</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        Estimated Delivery Date
                    </Col>
                    <Col span={6}>
                        <Form.Item name="billingOptiondateEstDelivery">
                            <DatePicker size="small" format="MM/DD/YYYY" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Worker Compensation:
                    </Col>
                    <Col span={3}>Status</Col>
                    <Col span={4}>
                        <Form.Item name="billingOptiondateCompensationStatus">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        Last Worked Date:
                    </Col>
                    <Col span={6}>
                        <Form.Item name="billingOptiondateCompensationWorked">
                            <DatePicker size="small" format="MM/DD/YYYY" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Date of Initial Treatment:
                    </Col>
                    <Col span={6}>
                        <Form.Item name="billingOptiondateInitialTreatment">
                            <DatePicker size="small" format="MM/DD/YYYY" />
                        </Form.Item>
                    </Col>
                    <Col span={12} className="hcpaFormLink">
                        <span>
                            <a href="https://knowledge.therabill.com/hc/en-us/articles/217242163-Box-15-Other-Date">
                            (HCFA form, box 15)
                            </a>
                            </span>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Date Last Seen:
                    </Col>
                    <Col span={6}>
                        <Form.Item name="billingOptiondateLastSeen">
                            <DatePicker size="small" format="MM/DD/YYYY" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Acute Manifestation Date:
                    </Col>
                    <Col span={6}>
                        <Form.Item name="billingOptiondateActueManifest">
                            <DatePicker size="small" format="MM/DD/YYYY" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Accident Date:
                    </Col>
                    <Col span={6}>
                        <Form.Item name="billingOptiondateAccidentDate">
                            <DatePicker size="small" format="MM/DD/YYYY" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Last XRay Date:
                    </Col>
                    <Col span={6}>
                        <Form.Item name="billingOptiondateLastXrayDate">
                            <DatePicker size="small" format="MM/DD/YYYY" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Prescription Date:
                    </Col>
                    <Col span={6}>
                        <Form.Item name="billingOptiondatePrescriptionDate">
                            <DatePicker size="small" format="MM/DD/YYYY" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Assumed Care Date:
                    </Col>
                    <Col span={6}>
                        <Form.Item name="billingOptiondateAssumedCareDate">
                            <DatePicker size="small" format="MM/DD/YYYY" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Relinquished Care Date:
                    </Col>
                    <Col span={6}>
                        <Form.Item name="billingOptiondateRelinquishedCareDate">
                            <DatePicker size="small" format="MM/DD/YYYY" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        First Visit Or Consultation:
                    </Col>
                    <Col span={6}>
                        <Form.Item name="billingOptiondateConsultationDate">
                            <DatePicker size="small" format="MM/DD/YYYY" />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
            <div>
                <Divider orientation="left"> Miscellaneous </Divider>
                <Row gutter={16}>
                    <Col span={6}>
                        Reserve For Local Use:
                    </Col>
                    <Col span={8}>
                        <Form.Item name="billingOptiondateMReserve">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={10} className="hcpaFormLink">
                        <span>
                            <a href="https://knowledge.therabill.com/hc/en-us/articles/217242283-Box-19-Additional-Claim-Information-Designated-by-NUCC">
                            (HCFA form, box 19)
                            </a>
                            </span>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Outside Lab?:
                    </Col>
                    <Col span={3}>
                        <Form.Item name="billingOptiondateMOutside">
                            <Select size="small"style={{ width: '100%' }}>
                                <Select.Option value="Yes">Yes</Select.Option>
                                <Select.Option value="No">No</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={3}> Lab Charges:</Col>
                    <Col span={6}>
                        <Form.Item name="billingOptionLabCharge">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={6} className="hcpaFormLink">
                        <span>
                            <a href="https://knowledge.therabill.com/hc/en-us/articles/217242303-Box-20-Outside-Lab-Charges">
                            (HCFA form, box 20)
                            </a>
                            </span>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Medicaid Resubmission:
                    </Col>
                    <Col span={2}>
                        Code
                    </Col>
                    <Col span={4}>
                        <Form.Item name="billingOptiondateMMedicaidCode">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        Original Ref. No
                    </Col>
                    <Col span={4}>
                        <Form.Item name="billingOptiondateOriginal">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={5} className="hcpaFormLink">
                        <span>
                            <a href="https://knowledge.therabill.com/hc/en-us/articles/217242343-Box-22-Resubmission-Code-Original-Ref-No" target="blank">
                            (HCFA form, box 22)
                            </a>
                            </span>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Prior Authorization Number:
                    </Col>
                    <Col span={8}>
                        <Form.Item name="billingOptiondateMPriorAuthorization">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={10} className="hcpaFormLink">
                        <span>
                            <a href="https://knowledge.therabill.com/hc/en-us/articles/217242683-Box-23-Prior-Authorization-Number">
                            (HCFA form, box 23)
                            </a>
                            </span>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        CLIA:
                    </Col>
                    <Col span={8}>
                        <Form.Item name="billingOptiondateMCLIA">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                    
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Mammography Certificate:
                    </Col>
                    <Col span={8}>
                        <Form.Item name="billingOptiondateMMammography">
                            <Input size="small" />
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Delay Reason Code:
                    </Col>
                    <Col span={8}>
                        <Form.Item name="billingOptiondateMDelayReason">
                            <Select size="small">
                                {opt?.map((d) => <Option key={d} value={d}>{d}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Ambulance Transport Code:
                    </Col>
                    <Col span={8}>
                        <Form.Item name="billingOptiondateMAmbulance">
                            <Select size="small" >
                                <Option value="I-Initial trip only">I- Initial trip only</Option>
                                <Option value="R-Return trip only">R- Return trip only</Option>
                                <Option value="T-Transport trip only">T- Transport trip only</Option>
                                <Option value="X-Round trip only">T- Round trip</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Ambulance Transport Reason Code:
                    </Col>
                    <Col span={8}>
                        <Form.Item name="billingOptiondateMAmbulancereasoncode">
                            <Select size="small">
                                <Option value="A">
                                    A - Patient was transported to nearest facility for care of Symptom, complaints or both</Option>
                                <Option value="B">
                                    B - Patient was transported for the benefit of preffered Physician
                                </Option>
                                <Option value="C">C-Patient was transported for the nearess of family member</Option>
                                <Option value="D">D-Patient was transported for the care specialities or availablitiy of  specialitiezed euqipment  </Option>
                                <Option value="E"> E- Patient Transferred to Rehabilitation Facility </Option>

                            </Select>
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Ambulance Condition Code:
                    </Col>
                    <Col span={8}>
                        <Form.Item name="billingOptiondateMAmbulanceCondition">
                            <Select size="small">
                                <Option value="01">
                                    01 - Patient was admitted to a hospital
                                </Option>
                                <Option value="02">
                                    02 - Patient was bed confined before the ambulance service
                                </Option>
                                <Option value="03">
                                    03 - Patient was bed confined after the ambulance service
                                </Option>
                                <Option value="04">
                                    04 - Patient was moved by stretcher
                                </Option>
                                <Option value="05">
                                    05 - Patient was unconscious or in shock
                                </Option>
                                <Option value="06">
                                    06 - Patient was transported in an emergency situation
                                </Option>
                                <Option value="07">
                                    07 - Patient had to be physically restrained
                                </Option>
                                <Option value="08">
                                    08 - Patient had visible hemorrhaging
                                </Option>
                                <Option value="09">
                                    09 - Ambulance service was medically necessary
                                </Option>
                                <Option value="60">
                                    60 - Transportation Was To the Nearest Facility
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Ambulance Miles:
                    </Col>
                    <Col span={8}>
                        <Form.Item name="billingOptiondateMAmbulanceMiles">
                            <Input size="small" />
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Attachment Report Type Code:
                    </Col>
                    <Col span={8}>
                        <Form.Item name="billingOptionattachreporttypecode">
                            <Select size="small">
                                <Option value="77">
                                    77 - Support Data for Veritication Referral
                                </Option>
                                <Option value="AS">
                                    AS - Admission Summary
                                </Option>
                                <Option value="B2">
                                    B2 - Prescription
                                </Option>
                                <Option value="B3">
                                    B3 - Physician Order
                                </Option>
                                <Option value="B4">
                                    B4 - Referral Form
                                </Option>
                                <Option value="CT">
                                    CT - Certification
                                </Option>
                                <Option value="DA">
                                    DA - Dental Models
                                </Option>
                                <Option value="DG">
                                    DG - Diagnostic Report
                                </Option>
                                <Option value="DS">
                                    DS - Discharge Summary
                                </Option>
                                <Option value="EB">
                                    EB - Explanation of Benefits
                                </Option>
                                <Option value="MT">
                                    MT - Models
                                </Option><Option value="MN">
                                    NN - Nursing Notes
                                </Option><Option value="OB">
                                    OB - Operative Note
                                </Option><Option value="OZ">
                                    OZ - Support Data for Claim
                                </Option><Option value="PN">
                                    PN - Physical Therapy Notes
                                </Option>
                                <Option value="PO">
                                    PO - Prosthetics or Orthotic Certification
                                </Option>

                                <Option value="PZ">
                                    PZ - Physical Therapy Certification
                                </Option>
                                <Option value="RB">
                                    RB - Radiology Films
                                </Option>
                                <Option value="RR">
                                    RR - Radiology Reports
                                </Option>
                                <Option value="RT">
                                    RT - Report of Tests and Analusis Report
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Attachment Transmission Code:
                    </Col>
                    <Col span={8}>
                        <Form.Item name="billingOptiondateMattachmntTrans">
                            <Select size="small" >
                                <Option value="AA">
                                    AA- Available on Request at Provider site
                                </Option>
                                <Option value="BM">
                                    By Mail
                                </Option>
                                <Option value="EL">
                                    Electronically only
                                </Option>
                                <Option value="EM">
                                    E-Mail
                                </Option>
                                <Option value="FX">
                                    By Fax
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Attachment Control Number:
                    </Col>
                    <Col span={8}>
                        <Form.Item name="billingOptiondateMattachmntControl">
                            <Input size="small" />
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Hospice Employee:
                    </Col>
                    <Col span={8}>
                        <Form.Item name="billingOptionhopiceempl">
                            <Select size="small">
                                <Option value="Yes">Yes</Option>
                                <Option value="No">No</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Referral Number:
                    </Col>
                    <Col span={8}>
                        <Form.Item name="billingOptiondateReferralnumber">
                            <Input size="small" />
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Contract Type Code:
                    </Col>
                    <Col span={8}>
                        <Form.Item name="billingOptiondateContractTypecode">
                            <Select size="small" >
                                < Option value="01">
                                    Diagnous Related Group - DRG
                                </Option>
                                < Option value="02">
                                    Per Diem
                                </Option>< Option value="03">
                                    Variable Per Diem
                                </Option>
                                < Option value="04">
                                    Flat
                                </Option>
                                < Option value="05">
                                    Capitated
                                </Option>
                                < Option value="06">
                                    Percent
                                </Option>
                                < Option value="09">
                                    Other
                                </Option>

                            </Select>
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Contract Amount:
                    </Col>
                    <Col span={8}>
                        <Form.Item name="billingOptiondateMContractAmount">
                            <Input size="small" />
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Contract Percentage:
                    </Col>
                    <Col span={8}>
                        <Form.Item name="billingOptiondateMContractPercentage">
                            <Input size="small" />
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Contract Code:
                    </Col>
                    <Col span={8}>
                        <Form.Item name="billingOptiondateMContractCode">
                            <Input size="small" />
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Contract Term Discount Percent:
                    </Col>
                    <Col span={8}>
                        <Form.Item name="billingOptiondateMContractDiscount">
                            <Input size="small" />
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        Contract Version Identifier:
                    </Col>
                    <Col span={8}>
                        <Form.Item name="billingOptiondateMContractIdentifier">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default BillingOption;