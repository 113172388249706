import { Tabs } from "antd";
import NewPatientVisit from "../newpatientvisit";
import PatientVisit from "../patientVisit";
import PatientVisitSubMenu from "./patientvisitsubmenu";
import PatientLookUp from "../patientLookup";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const Patientmenu = () => {
    const [defaultKey, setDefaultKey] = useState();
    const [searchParams] = useSearchParams();

    const onCickTab = (e) => {
        console.log(e);
        setDefaultKey(e);
    }
    const menuItem = [
        {
            label: 'Add New Visit',
            key: 'addNewVisit',
            children: <PatientVisitSubMenu />,
        },
        {
            label: 'Patient Visit List',
            key: 'patientVisitList',
            children: <PatientVisit onCickTab={onCickTab} />,
        },
        // {
        //     label: 'Patient look up',
        //     key: 'patientLookUP',
        //     children: <PatientLookUp />,
        // },
        // {
        //     label: 'Report',
        //     key: 'visitReport',
        //     children: <>Report</>,
        // }
    ];

    useEffect(() => {
        const tabkey = searchParams.get('tab');
        console.log(tabkey);
        setDefaultKey(tabkey);
        if (tabkey) { onCickTab(tabkey) } else {
            setDefaultKey('patientVisitList');
        }
    }, []);

    return (
        <Tabs
            defaultActiveKey={defaultKey}
            centered
            items={menuItem}
            onTabClick={onCickTab}
        />
    )
}


export default Patientmenu;