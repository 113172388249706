import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Table } from "antd";
import { useEffect, useState } from "react";


const BillingProviderModal = ({
    isbpModal,
    handlebpCancel,
    bpList,
    modalTitle,
    onPullbp,
    selctedItm,
    pullMorebp }) => {
    const [filterpayer, setFilterpayer] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const rowSelection = (r, val) => {
        onPullbp(r, val, 'billingProvider');
    }

    const searchMore = (key, val) => {
        let Obj = {
            [key]: val,
        }
        setInputValue(Obj)
    }

    const searchPatient = (e, type) => {
        // console.log(e.target.value, type);
        const filterdata = bpList?.filter((d) => d[type].toLowerCase().includes(e.target.value));
        // console.log(filterdata);
        setFilterpayer(filterdata);
        searchMore(type, e.target.value)
    }

    const columns = [
        {
            title: 'Billing Provider ID',
            dataIndex: 'billingProviderId',
            render: (text, r) => <span onClick={() => rowSelection(r, text)}>{text}</span>,
            // width: '30%'
        },
        {
            title: 'Name',
            dataIndex: 'billingProvidefirstname',
        },
        {
            title: 'State',
            dataIndex: 'billingProvideState',
        },
        {
            title: 'Tax Id',
            dataIndex: 'billingProvideTaxId',
        },
        {
            title: 'Tax Id Type',
            dataIndex: 'billingProvideTaxIdtype',
        },
        {
            title: 'Org. NPI',
            dataIndex: 'billingProvideOrganizationalNPI',
        }
    ];

    useEffect(() => {
        setFilterpayer(filterpayer);
    }, []);


    return (
        <Modal title={modalTitle} open={isbpModal} width={950} onOk={handlebpCancel} onCancel={handlebpCancel}>
            <div style={{ marginTop: '30px', display: 'inline-block', marginBottom: '20px', width: '200px' }}>
                <Input placeholder='Payer Name' suffix={<SearchOutlined />} name="PayerName" onChange={(e) => searchPatient(e, 'PayerName')} />
            </div>
            <div style={{ display: 'inline-block', margin: '30px 0px 20px 10px', width: '200px' }}>
                <Input placeholder='Payer ID' suffix={<SearchOutlined />} name="PayerID" onChange={(e) => searchPatient(e, 'PayerID')} />
            </div>
            <div style={{ display: 'inline-block', margin: '30px 0px 20px 10px', width: '200px' }}>
                <Button type="primary" onClick={() => pullMorebp(inputValue)} > Search more </Button>
            </div>

            <Table
                rowKey={(record) => record._id}
                onRow={(record) => ({
                    onClick: () => rowSelection(record),
                })}
                columns={columns}
                dataSource={filterpayer.length > 0 ? filterpayer : bpList }
                pagination={{
                    pageSize: 8
                }}
            />

        </Modal>
    )

}

export default BillingProviderModal;