import axios from "axios"
import { API } from "../../config"
import axiosHttp from "../../middleware/axios";
import Swal from "sweetalert2";

const login = async(data) => {
    try{
        let resp = await axios.post(API + '/auth/login', data);
        return resp.data;
    } catch(err){
        Swal.fire({
            title: 'Error!',
            text: err?.response?.data || 'Something went wrong!',
            icon: 'error',
            confirmButtonText: 'Okay',
            confirmButtonColor: '#005154'
        });
    }   
}

const updatepassword = async (data) =>{
    try {
        let resp = await axiosHttp.post('/auth/updatepassword', data);
        return resp.data; 
    } catch(err){
        Swal.fire({
            title: 'Error!',
            text: err?.response?.data || 'Something went wrong!',
            icon: 'error',
            confirmButtonText: 'Okay',
            confirmButtonColor: '#005154'
        });
    } 
}

const Auth = {
    login,
    updatepassword
}

export default Auth;