import { Col, Form, Row, Select, Typography, Input, Card, Button, Divider, Table, Tag, DatePicker } from "antd"
import appointmentAPI from "./appointment.hook";
import { DeleteFilled, DeleteOutlined, EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import dayjs from "dayjs";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";


const SearchPatient = ({editApp}) => {
    const [appList, setAppList] = useState([])
    const [isDate, setIsDate] = useState(false);
    const [form] = Form.useForm();
    const [search] = useSearchParams();
    const navigate = useNavigate();
    const handleSearch = async (val) => {
        let payload = {};
        if (val.searchWith === 'appointmentPatientdob' || val.searchWith === 'appointmentVisitDate') {
            payload = {
                [val.searchWith]: dayjs(val['searchValue']).format('MM/DD/YYYY')
            }
        } else {
            payload = {
                [val.searchWith]: val['searchValue']
            }
        }

        const search = await appointmentAPI.searchAppointment(payload);
        console.log(search);
        setAppList(search);
    }

    const delApp = async (r) => {
        const resp = await appointmentAPI.delAppointment(r._id);
        const fPlist = appList.filter((p) => p._id !== r._id);
        setAppList(fPlist);
        Swal.fire({
            title: 'Deleted!',
                text: 'Appointment deleted Successfully!',
                icon: 'success',
                confirmButtonText: 'Okay',
                confirmButtonColor: '#005154'
        })
    }


    const handleChange = async (e) => {
        if (e === 'appointmentPatientdob' || e === 'appointmentVisitDate') {
            setIsDate(true)
        }
        else {
            setIsDate(false);
            let formVal = form.getFieldsValue();
            formVal.searchValue = '';
            form.setFieldsValue({ ...formVal });
        }

    }

    // const newApp = (r) => {
    //     let params = { id: r._id, mode: 'newapp' };
    //     let path = {
    //         pathname: '/appointment',
    //         search: createSearchParams(params).toString()
    //     }
    //     navigate(path);
    // }

    const columns = [
        {
            title: 'Date',
            dataIndex: 'appointmentVisitDate',
            key: 'appointmentVisitDate',

        },
        {
            title: 'Time',
            dataIndex: 'appointmentCheckIn',
            key: 'appointmentCheckIn',

        },
        {
            title: 'Patient Name',
            dataIndex: 'appointmentPatientfirstName',
            key: 'appointmentPatientfirstName',
            render: (text, r) => <span>{r.appointmentPatientlastName}, {r.appointmentPatientfirstName}</span>
        },
        {
            title: 'Provider',
            dataIndex: 'appointmentoffice',
            key: 'appointmentoffice',

        }, {

            title: 'Office',
            dataIndex: 'appointmetofficeRadio',
            key: 'appointmetofficeRadio',
        }, {

            title: 'Reason For Visit',
            dataIndex: 'appointmetReason',
            key: 'appointmetReason',
        }, {

            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        }, {

            title: 'Action',
            key: 'action',
            render: (txt, record) => (
                <>
                    <Tag color="green" onClick={() => editApp(record)}>
                        <EditOutlined /> Edit
                    </Tag>
                    <Tag color="red" onClick={() => delApp(record)}>
                        
                        <DeleteOutlined /> delete
                    </Tag>
                </>
            )

        }
    ]

    return (
        <>

            <Divider orientation="left">Search Appoinment by Patient</Divider>
            <Form form={form} layout="vertical" className="appointmentSearchform" onFinish={handleSearch}>
                <Row gutter={16}>
                    <Col span={5}>
                        <Form.Item label="Search With" name="searchWith">
                            <Select size="small" onChange={(e) => handleChange(e)}>
                                <Select.Option value="appointmentPatientfirstName">First name</Select.Option>
                                <Select.Option value="appointmentPatientlastName">Last Name</Select.Option>
                                <Select.Option value="appointmentPatientId">Patient ID</Select.Option>
                                <Select.Option value="appointmentPatientdob">Date of brith</Select.Option>
                                <Select.Option value="appointmentVisitDate">Last Visit date</Select.Option>
                                <Select.Option value="appointmenPatientPhone">Home Phone</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    {/* <Col span={5}>
                        <Form.Item label="with">
                            <Select size="small" >
                                <Select.Option value="start">Start with</Select.Option>
                                <Select.Option value="contains">Contian</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col> */}
                    {isDate ? (
                        <Col span={5}>
                            <Form.Item label="Search Value" name="searchValue">
                                <DatePicker size="small" format={'MM/DD/YYYY'} />
                            </Form.Item>
                        </Col>
                    ) : (
                        <Col span={5}>
                            <Form.Item label="Search Value" name="searchValue">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    )}


                    <Col span={3} className="mt16">
                        <Button type="primary" htmlType="submit">Search</Button>
                    </Col>
                    <Col span={3} className="mt16">
                        <Button type="default" htmlType="reset">Reset</Button>
                    </Col>
                </Row>
            </Form>
            <div className="tableWrapper">
                <Table
                    dataSource={appList}
                    columns={columns} />
            </div>
        </>
    )
}

export default SearchPatient;