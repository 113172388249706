import { Button, Col, Form, Input, Row, Tag, Tooltip } from "antd";
import './login.scss';
import { useState } from "react";
import logo from '../../asset/img/MedQuik-logo.svg';
import Auth from "./login.api";
import { InfoCircleOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { getAccesstoken, setAccesstoken } from "./login.hook";
import { useNavigate } from "react-router-dom";
import {userValidate} from "../../utilities/helpers";
import { setUserInfo } from "../../store/hook/user.hook";

const LoginPage = () => {
    const [form] = Form.useForm();
    const [updatepass, setUpdatepass] = useState(false);
    const [strength, setStrength] = useState('week');
    const accesstoken = useSelector(getAccesstoken)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onfinish = async (e) => {
        try {
            let resp = await Auth.login(e);
            sessionStorage.setItem('token', resp?.token);
            dispatch(setAccesstoken({ token: resp?.token }))
            setUpdatepass(resp?.update);
            let useInfo = userValidate(resp?.token);
            console.log(useInfo);
            dispatch(setUserInfo(useInfo));
            if (resp?.token)
                navigate('/home')
        } catch (err) {
            console.log(err);
        }
        form.resetFields();
    }

    const onUpdate = async (e) => {
        console.log('onUpdate-token', sessionStorage.getItem('token'))
        if (e.password === e.confirmpassword) {
            // console.log(e);
            try {
                let resp = await Auth.updatepassword(e);
                console.log(resp);
                if (resp) {
                    navigate('/');
                }
                
            } catch (err) {
                console.log('err-37', err);
                Swal.fire({
                    title: 'Error!',
                    text: err?.response?.data || 'Something went wrong!',
                    icon: 'error',
                    confirmButtonText: 'Okay',
                    confirmButtonColor: '#005154'
                });
            }
        } else {
            Swal.fire({
                title: 'Error!',
                text: "Please check the password, doesn't match",
                icon: 'error',
                confirmButtonText: 'Okay',
                confirmButtonColor: '#005154'
            });
        }
        return;

    }

    const checkPasswordStrength = (password) => {
        const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
        const result = regex.test(password);
        if (result) {
            setStrength('Strong');
        } else {
            setStrength('Weak');
        }
    };

    const forgotPassword = () => {
        console.log('forgotPassword');
    }

    return (
        <div className="loginWrapper">
            <div className="loginPage">
                <div className="loginForm">
                    {!updatepass ? <p>Sign In</p> : <p> Update Password</p>}
                    <div className="formWrapperlogin">
                        <Row align="middle">
                            <Col span={4}></Col>
                            <Col span={16}>
                                {!updatepass && (
                                    <Form name="signin" onFinish={onfinish} layout="vertical" form={form}>
                                        <Form.Item name='username'>
                                            <Input style={{ height: '40px' }} placeholder="User name" />
                                        </Form.Item>
                                        <Form.Item name='password'>
                                            <Input style={{ height: '40px' }} placeholder="Password" />
                                        </Form.Item>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Button style={{ width: '100%' }} htmlType="submit" > Login</Button>
                                            </Col>
                                            <Col span={12}>
                                                <Button style={{ width: '100%' }} onClick={() => forgotPassword()}> Forgot Password</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                                {updatepass && (
                                    <Form name="signin" onFinish={onUpdate} layout="vertical" form={form} >
                                        <Form.Item name='password' >
                                            <Input style={{ height: '40px' }} onChange={(e) => checkPasswordStrength(e.target.value)} placeholder="Create Password" suffix={
                                                <Tooltip title="Password must 8 characters along with alphabet, number and Special character">
                                                    <InfoCircleOutlined
                                                        style={{
                                                            color: 'rgba(0,0,0,.45)',
                                                        }}
                                                    />
                                                </Tooltip>
                                            } />
                                        </Form.Item>
                                        <Form.Item name='confirmpassword'>
                                            <Input style={{ height: '40px' }} placeholder="Confirm Password"
                                                suffix={
                                                    <Tooltip title="Password must 8 characters along with alphabet, number and Special character">
                                                        <InfoCircleOutlined
                                                            style={{
                                                                color: 'rgba(0,0,0,.45)',
                                                            }}
                                                        />
                                                    </Tooltip>
                                                }
                                            />
                                        </Form.Item>
                                        <Row gutter={16} align={"middle"}>
                                            <Col span={24}>
                                                <Button style={{ width: '100%' }} htmlType="submit"> Update Password</Button>
                                            </Col>
                                            {/* <Col span={12}>
                                                <Button style={{ width: '100%' }} onClick={() => forgotPassword()}> Forgot Password</Button>
                                            </Col> */}
                                        </Row>
                                    </Form>
                                )}
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="loginCompany">
                    <div className="logoCompany">
                        <img src={logo} style={{ width: '300px', marginTop: '20px' }} alt="MediQuik" />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default LoginPage;