import { SearchOutlined } from "@ant-design/icons";
import { Input, Modal, Table } from "antd";
import { useEffect, useState } from "react";

const FacilityModal = ({
    isfModal,
    handlefCancel,
    fList,
    modalTitle,
    onPullf }) => {

    const [filterpayer, setFilterpayer] = useState([]);
    const [inputValue, setInputValue] = useState('');


    const rowSelection = (r, val) => {
        onPullf(r, val, 'facility');
    }

    const searchMore = (key, val) => {
        let Obj = {
            [key]: val,
        }
        setInputValue(Obj)
    }

    const searchPatient = (e, type) => {
        // console.log(e.target.value, type);
        const val = e?.target?.value.toLowerCase();
        const filterdata = fList?.filter((d) => d[type]?.toLowerCase().includes(val));
        // console.log(filterdata);
        setFilterpayer(filterdata);
        searchMore(type, e.target.value)
    }

    const columns = [
        {
            title: 'Facility ID',
            dataIndex: 'facility',
            render: (text, r) => <span onClick={() => rowSelection(r, text)}>{text}</span>,
            // width: '30%'
        },
        {
            title: 'Name',
            dataIndex: 'facilityName',
        },
        {
            title: 'City',
            dataIndex: 'facilityCity',
        },
        {
            title: 'State',
            dataIndex: 'facilityState',
        },
        {
            title: 'Org. NPI',
            dataIndex: 'facilityOrganizationalNPI',
        },
        {
            title: 'Zip',
            dataIndex: 'facilityZip',
        },
        {
            title: 'Phone',
            dataIndex: 'facilityPhone',
        }
    ];

    useEffect(() => {
        setFilterpayer(filterpayer);
    }, []);


    return (
        <Modal title={modalTitle} open={isfModal} width={950} onOk={handlefCancel} onCancel={handlefCancel}>
            <div style={{ marginTop: '30px', display: 'inline-block', marginBottom: '20px', width: '200px' }}>
                <Input placeholder='Payer Name' suffix={<SearchOutlined />} name="PayerName" onChange={(e) => searchPatient(e, 'PayerName')} />
            </div>
            <div style={{ display: 'inline-block', margin: '30px 0px 20px 10px', width: '200px' }}>
                <Input placeholder='Payer ID' suffix={<SearchOutlined />} name="PayerID" onChange={(e) => searchPatient(e, 'PayerID')} />
            </div>

            <Table
                rowKey={(record) => record._id}
                onRow={(record) => ({
                    onClick: () => rowSelection(record),
                })}
                columns={columns}
                dataSource={filterpayer.length > 0 ? filterpayer : fList }
                pagination={{
                    pageSize: 8
                }}
            />

        </Modal>
    )

}
export default FacilityModal;