import { DeleteOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, Row, Select, Typography, Card, Divider, Table, Tag, Space } from "antd";
import { useEffect, useState } from "react";
import OfficeEvent from "./components/officeEvent";
import ProviderEvent from "./components/providerEvent";

const { Title } = Typography;

const SpecialEvent = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isProviderModalOpen, setIsProviderModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const showModalprov = () => {
        setIsProviderModalOpen(true);
    };
    const handleOkprov = () => {
        setIsProviderModalOpen(false);
    };
    const handleCancelprov = () => {
        setIsProviderModalOpen(false);
    };


    const columns = [
        {
            title: 'Event ID',
            dataIndex: 'eventID',
            key: 'eventID',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Office Name',
            dataIndex: 'officename',
            key: 'officename',
        },
        {
            title: 'Event Type',
            dataIndex: 'eventType',
            key: 'eventType',
        },
        {
            title: 'Provider / Staff',
            key: 'provider',
            dataIndex: 'provider',  
        },
        {
            title: 'Description',
            dataIndex: 'desp',
            key: 'desp',
        },
        {
            title: 'Start Date time',
            dataIndex: 'startDate', 
            key: 'startDate',
        },
        {
            title: 'End Date time',
            dataIndex: 'endDate',
            key: 'endDate',
        },
        {
            title: 'Last modified',
            dataIndex: 'lastModified',
            key: 'lastModified',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <EditOutlined />
                    <DeleteOutlined />
                </Space>
            ),
        },
    ];
    const data = [
        {
            key: '1',
            eventID: 12134, 
            officename: 'John Clinic',
            eventType: 'Special meeting', 
            provider: 'Stong',
            desp: 'Description',
            startDate: '02/01/2024 02:00 PM', 
            endDate: '02/01/2024 04:00 PM', 
            lastModified: '02/01/2024 10:00 AM', 
        },
        {
            key: '2',
            eventID: 132424, 
            officename: 'John Clinic',
            eventType: 'Special meeting', 
            provider: 'Stong',
            desp: 'Description',
            startDate: '02/01/2024 02:00 PM', 
            endDate: '02/01/2024 04:00 PM', 
            lastModified: '02/01/2024 10:00 AM', 
        },
        {
            key: '3',
            eventID: 563445, 
            officename: 'John Clinic',
            eventType: 'Special meeting', 
            provider: 'Stong',
            desp: 'Description',
            startDate: '02/01/2024 02:00 PM', 
            endDate: '02/01/2024 04:00 PM', 
            lastModified: '02/01/2024 10:00 AM', 
        },
        {
            key: '4',
            eventID: 132424, 
            officename: 'John Clinic',
            eventType: 'Special meeting', 
            provider: 'Stong',
            desp: 'Description',
            startDate: '02/01/2024 02:00 PM', 
            endDate: '02/01/2024 04:00 PM', 
            lastModified: '02/01/2024 10:00 AM', 
        },
        {
            key: '5',
            eventID: 563445, 
            officename: 'John Clinic',
            eventType: 'Special meeting', 
            provider: 'Stong',
            desp: 'Description',
            startDate: '02/01/2024 02:00 PM', 
            endDate: '02/01/2024 04:00 PM', 
            lastModified: '02/01/2024 10:00 AM', 
        },
    ];

    return (
        <>
            <Row justify="center">
                <Col span={22}>
                    <Title level={5} className="patientViewheader" >Special Date & Event</Title>
                </Col>
                <Col span={22}>
                    <Form layout="vertical" className="specialEventForm">
                        <Card className="shadowCard">
                            <Row gutter={16}>
                                <Col span={11}>
                                    <Form.Item name="specialEvenStartDate" label="Start Date">
                                        <DatePicker />
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item name="specialEvenDate" label="Date">
                                        <DatePicker />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={11}>
                                    <Form.Item name="specialEvenSearchfor" label="Search for">
                                        <Select>
                                            <Select.Option value="Provider / Staff Last name">
                                                Provider / Staff Last Name
                                            </Select.Option>
                                            <Select.Option value="Provider / Staff Last name">
                                                Provider / Staff First Name
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={10} style={{ marginTop: '30px' }}>
                                    <Form.Item name="specialEvensearchWith" >
                                        <Select>
                                            <Select.Option value="Startwith">
                                                Start with
                                            </Select.Option>
                                            <Select.Option value="Endwith">
                                                End with
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={9}>
                                    <Form.Item name="specialEvenPatient" label="Patient Name">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={2} style={{ marginTop: '30px' }}><Button className="btn-primary btn-search">
                                    <SearchOutlined />
                                </Button></Col>

                            </Row>
                            <Divider />
                            <Row>
                                <Col span={22}>
                                    <Title level={5} style={{ marginTop: '5px' }}>
                                        Display Filter
                                    </Title>
                                    <Row gutter={16}>
                                        <Col span={11}>
                                            <Form.Item name="specialEvenfilteroffice" label="office">
                                                <Select>
                                                    <Select.Option value="All">
                                                        All
                                                    </Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item name="specialEvensearEvenType" label="Event Type">
                                                <Select>
                                                    <Select.Option value="All">
                                                        All
                                                    </Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={22}>
                                    <Button type="primary" className="btn-primary" onClick={() => showModal()}>
                                        Add office Event
                                    </Button>
                                    <Button type="primary" className="btn-primary" onClick={() => showModalprov()}>
                                        Add Provider / Staff Event
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Form>

                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Table columns={columns} dataSource={data} />
                </Col>
            </Row>
            <OfficeEvent isModalOpen={isModalOpen} handleOk={handleOk} 
            handleCancel={handleCancel} />
            <ProviderEvent isModalOpen={isProviderModalOpen} handleOk={handleOkprov} 
            handleCancel={handleCancelprov} />
        </>
    )
}
export default SpecialEvent;