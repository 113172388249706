import { Table, Typography, Form, Row, Col, Select, Input, Button, Divider } from "antd";
import { useDispatch } from "react-redux";
import { headerName } from "../../layout/headerName.hook";
import { useEffect, useState } from "react";
import InsuranceAPI from "./insurance.api";
import { createSearchParams, useNavigate } from "react-router-dom";
const { Title } = Typography;


const InsuranceList = () => {
    const [dataList, setDataList] = useState();
    const [dataListfull, setDataListfull] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    dispatch(headerName(`Insurance list`));

    const editInsurance = (txt, r) => {
        let params = { id: r._id, mode: 'edit' };
        let path = {
            pathname: '/insurance',
            search: createSearchParams(params).toString()
        }
        navigate(path);
    }
    const handlefilter = (val) => {
        let arry = [];
        for (var i = 0; i < dataListfull; i++) {
            if (dataListfull[i][val['key']].includes(val['searchValue'])) {
                arry.push(dataListfull[i]);
            }
        };

        setDataList(arry);
    };
    // const dataList = [];
    const columns = [

        {
            title: 'Insurance Name',
            dataIndex: 'insuranceName',
            key: 'insuranceName',
            render: (txt, r) => (<p className="tdClick" onClick={() => editInsurance(txt, r)}>{txt}</p>)
        },
        {
            title: 'Insurance Payer Id',
            dataIndex: 'insurancePayerId',
            key: 'insurancePayerId',
            // render: (txt) => (<p onClick={() => editBpProvider(txt)}>{txt}</p>)
        },
        {
            title: 'Insurance Type',
            dataIndex: 'insuranceType',
            key: 'insuranceType'
        }, {
            title: 'State',
            dataIndex: 'insuranceState',
            key: 'insuranceState'
        },
        {
            title: 'Insurance Phone',
            dataIndex: 'insurancePhone',
            key: 'insurancePhone'
        },
        {
            title: 'Blling Type',
            dataIndex: 'insuranceBilling',
            key: 'insuranceBilling'
        }
    ]

    useEffect(() => {
        const getInsuranceList = async () => {
            const Ilist = await InsuranceAPI.InsuranceList();
            console.log(Ilist);
            setDataListfull(Ilist);
            setDataList(Ilist)
        }
        getInsuranceList();
    }, [])

    return (
        <>
            <div className="billingproviderlistwrapper">
            <Row>
                <Col span={24}>
                        <Title level={4} style={{marginTop: '10px'}}>
                            Insurance List</Title>
                            <Divider style={{margin: '8px 0'}}/>
                    </Col>
                </Row>
                <Form layout="vertical" onFinish={handlefilter}>
                    <Row gutter={16}>
                        <Col span={6}>

                            <Form.Item name="searchby" label="Filter By" >
                                <Select
                                    style={{ width: '100%' }}
                                    size="small" >
                                    <Select.Option value="insuranceName"> Insurance Name</Select.Option>
                                    <Select.Option value="insurancePayerId">Payer ID</Select.Option>
                                    <Select.Option value="insuranceType">Type</Select.Option>
                                    <Select.Option value="insuranceState">State</Select.Option>
                                    <Select.Option value="insurancePhone">Phone</Select.Option>
                                    <Select.Option value="insuranceBilling">Billing Type</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="searchwith" style={{ marginTop: '22px' }} >
                                <Select size="small" name="searchwith" style={{ width: '100%' }} >
                                    <Select.Option value="insuranceStart">Start with</Select.Option>
                                    <Select.Option value="insuranceEnd">Ends with</Select.Option>
                                    <Select.Option value="insuranceContian">Contains</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="searchValue" label="Search value" >
                                <Input size="small" name="searchValue" />
                            </Form.Item>
                        </Col>
                        <Col span={6} justify="end" style={{ marginTop: '22px', color: '#fff' }} >
                            <Button htmlType="submit" className="btnPrimary">
                                Filter
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className="providerTable">
                <Row gutter={8}>
                    <Col span={24}>
                        <Table
                            rowKey="_id"
                            dataSource={dataList}
                            columns={columns} />
                    </Col>
                </Row>
            </div>
        </>
    )
};

export default InsuranceList;