import { useEffect, useState } from "react";
import IcdcodeAPI from "../../utilities/datalist/icdcode.api";
import { Col, Row, Table, Input, Tag, Typography } from "antd";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import CptcodeAPI from "../../utilities/datalist/cptcode.api";
import { isNumber } from "@ant-design/plots/es/core/utils";
import Swal from "sweetalert2";
const { Title } = Typography;


const CPTCode = () => {
    const [dataList, setDataList] = useState([]);
    const [fulldataList, setFulldataList] = useState([]);
    const [editMode, setEditMode] = useState();
    const [cptValue, setCptValue] = useState();

    const saveValue = async (r) => {
        console.log(r);
        if (isNumber(parseInt(cptValue))) {
            const updateCpt = {
                ...r,
                price: parseInt(cptValue)
            }
            dataList.map((d) => r._id === d._id ? d.price = parseInt(cptValue) : r.price)
            console.log(updateCpt);
            const updateCode = await CptcodeAPI.updateCptcode(updateCpt);
            console.log(updateCode);
            Swal.fire({
                title: 'Successfully!',
                text: 'Updated CPT value successfully!',
                icon: 'success',
                confirmButtonText: 'Okay',
                confirmButtonColor: '#005154'
            });
            setEditMode('');
        } else {
            Swal.fire({
                title: 'Warning!',
                text: 'Please enter only numeric value!',
                icon: 'warning',
                confirmButtonText: 'Okay',
                confirmButtonColor: '#005154'
            });
        }


    }

    const enableEdMode = (r) => {
        setEditMode(r?._id);
        setCptValue(r?.price || '');
    }

    const updateVal = (e, r) => {
        setCptValue(e?.target?.value);
    }

    const columns = [
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code'
        },
        {
            title: 'Description',
            dataIndex: 'descp',
            key: 'descp',
            // render: (txt) => (<p onClick={() => editBpProvider(txt)}>{txt}</p>)
        },
        {
            title: 'Value',
            dataIndex: 'price',
            key: 'price',
            width: '30%',
            render: (txt, r) =>
            (editMode === r?._id ?
                <Input type="text" value={cptValue} onChange={(e) => updateVal(e, r)} name="codeValue" /> :
                <span>{txt}</span>
            )

        }, {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '15%',
            render: (txt, r) => (
                <Row gutter={12}>
                    <Col span={12}>
                        <Tag color="blue" onClick={() => enableEdMode(r)} >
                            <EditOutlined /> Edit
                        </Tag>

                    </Col>
                    <Col span={12}>
                        <Tag color="green" onClick={() => saveValue(r)}>
                            <SaveOutlined /> Save
                        </Tag>

                    </Col>
                </Row>
            )
        }
    ]

    useEffect(() => {
        const getCodes = async () => {
            const resp = await CptcodeAPI.getCptcode();
            setFulldataList(resp);
            setDataList(resp);
        };
        getCodes();
    }, []);

    const filterData = (e, type) => {

        const fData = fulldataList.filter((d) => {
            if (type === 'descp') {
                const dtype = d[type].toLowerCase();
                if (dtype.includes(e.target.value.toLowerCase())) {
                    return d;
                }
            }
            if (type === 'code') {
                const dtype = d[type].toString().toLowerCase();
                if (dtype.includes(e.target.value.toString().toLowerCase())) {
                    return d;
                }
            }
        })
        setDataList(fData);
    }

    return (
        <Row gutter={[16, 16]}>

            <Col span={6}>
                <Title level={5}>Code</Title>
                <Input type="text" onChange={(e) => filterData(e, 'code')} />
            </Col>
            <Col span={6}>
                <Title level={5}>Description</Title>
                <Input type="text" onChange={(e) => filterData(e, 'descp')} />
            </Col>
            <Col span={24}>
                <Table
                    rowKey="_id"
                    dataSource={dataList}
                    columns={columns} />
            </Col>
        </Row>
    )
}

export default CPTCode;