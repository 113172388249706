import axios from "axios";
import { API } from "../../config";

const providerList = async () => {
  const response = await axios.get(API+'/provider');
  return response.data;
};

const createProvider = async (payload) => {
  console.log(payload);
  const response = await axios.post(API+'/provider', payload);
  return response.data;
};

const updateProvider = async (payload) => {
    console.log(payload);
    const response = await axios.put(API+'/provider/update', payload);
    return response.data;
}

const queryProvider = async (query) => {
  const payload = {
    params:{
      ...query,
    }
  }
  const response = await axios.get(API+'/provider/query', payload);
  return response.data;
}

const getProviderbyId = async (id) => {
    const payload = {
        params: {
          id
        }
      }
      console.log(payload);
    const response = await axios.get(API+'/provider/id', payload);
    return response.data;
  };

const ProviderAPI = {
    providerList,
    createProvider,
    getProviderbyId,
    updateProvider,
    queryProvider,
  }
  
  export default ProviderAPI;