import { Button, Col, Divider, Form, Input, Row, Select, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import BillingProviderAPI from "./billingprovider.api";
import { useDispatch } from "react-redux";
import { headerName } from "../../layout/headerName.hook";
import { createSearchParams, useNavigate } from "react-router-dom";
const { Title } = Typography;

const BillingProviderlist = () => {
    const [dataList, setDataList] = useState([]);
    const [dataListfull, setDataListfull] = useState([]);
    const navigate = useNavigate();

    const dispatch = useDispatch();
    dispatch(headerName(`Billing Provider List`));

    const editBpProvider = (txt, r) => {
        let params = { id: r._id, mode: 'edit' };
        let path = {
            pathname: '/billingprovider',
            search: createSearchParams(params).toString()
        }
        navigate(path);
    }

    const columns = [

        {
            title: 'Last Name',
            dataIndex: 'billingProvideLastname',
            key: 'billingProvideLastname',
            render: (txt, r) => (<p className="tdClick" onClick={() => editBpProvider(txt, r)}>{txt}</p>)
        },
        {
            title: 'First Name',
            dataIndex: 'billingProvidefirstname',
            key: 'billingProvidefirstname',
            render: (txt) => (<p className="tdClick" onClick={() => editBpProvider(txt)}>{txt}</p>)
        },
        {
            title: 'State',
            dataIndex: 'billingProvideState',
            key: 'billingProvideState'
        }, {
            title: 'City',
            dataIndex: 'billingProvideCity',
            key: 'billingProvideCity'
        },
        {
            title: 'Zip code',
            dataIndex: 'billingProvideZip',
            key: 'billingProvideZip'
        },
        {
            title: 'Phone',
            dataIndex: 'billingProvidePhonenumber',
            key: 'billingProvidePhonenumber'
        },
        {
            title: 'Tax ID',
            dataIndex: 'billingProvideTaxId',
            key: 'billingProvideTaxId'
        },
        {
            title: 'Group No',
            dataIndex: 'billingProvideGroupNo',
            key: 'billingProvideGroupNo'
        }
    ]

    const handlefilter = (val) => {
        let arry = [];
            console.log(dataListfull[0][val['searchby']]);
            for (var i = 0; i < dataListfull; i++) {
                console.log(dataListfull[i][val['key']])
                if (dataListfull[i][val['key']].includes(val['searchValue'])) {
                    arry.push(dataListfull[i]);
                }
            
        };  
    }

    useEffect(() => {
        const getbplist = async () => {
            const resp = await BillingProviderAPI.getBillingProvider();
            setDataList(resp);
            setDataListfull(resp)
        }
        getbplist();
    }, [])
    return (
        <>
            <div className="billingproviderlistwrapper">
            <Row>
                <Col span={24}>
                        <Title level={4} >
                            Billing Provider List</Title>
                            <Divider/>
                    </Col>
                </Row>
                <Form layout="vertical" onFinish={handlefilter}>
                    <Row gutter={16}>
                        <Col span={6}>

                            <Form.Item name="searchby" label="Filter By" >
                                <Select
                                    style={{ width: '100%' }}
                                    size="small" >
                                    <Select.Option value="billingProvideLastname"> Last name</Select.Option>
                                    <Select.Option value="billingProvidefirstname">First Name</Select.Option>
                                    <Select.Option value="billingProvideState">State</Select.Option>
                                    <Select.Option value="billingProvideCity">City</Select.Option>
                                    <Select.Option value="billingProvideZip">Zip</Select.Option>
                                    <Select.Option value="billingProvidePhonenumber">Phone</Select.Option>
                                    <Select.Option value="billingProvideTaxId">Tax Id</Select.Option>
                                    <Select.Option value="billingProvideGroupNo">Group No</Select.Option>
                                    <Select.Option value="billingProvideOrganizationalNPI">Organizational NPI</Select.Option>
                                    <Select.Option value="billingProvideSpecialty">Specialty</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="searchwith" style={{ marginTop: '22px' }} >
                                <Select size="small" name="searchwith" style={{ width: '100%' }} >
                                    <Select.Option value="billingProvideStart">Start with</Select.Option>
                                    <Select.Option value="billingProvideEnd">Ends with</Select.Option>
                                    <Select.Option value="billingProvideContian">Contains</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="searchValue" label="Search value" >
                                <Input size="small" name="searchValue" />
                            </Form.Item>
                        </Col>
                        <Col span={6} justify="end" style={{ marginTop: '30px', color: '#fff' }} >
                            <Button htmlType="submit" className="btnPrimary">
                                Filter
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className="providerTable">
                <Row gutter={8}>
                    <Col span={24}>
                        <Table
                            rowKey="_id"
                            dataSource={dataList}
                            columns={columns} />
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default BillingProviderlist;