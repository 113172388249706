
import React, { useEffect, useState } from 'react';
import { Divider, Radio, Table, Modal, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const CPTModal = ({ iscptModal, handleCpt, fdsname, handlecptCancel, icdCodeList, updateCpt }) => {
    const [dataList, setDataList] = useState(icdCodeList);

    const onselect = (e, i) => {
        console.log(e, i);
        updateCpt(fdsname, i, e);
    }

    const searchCPT = (e, type) => {
        let filtData = [];
        for (var i = 0; i < icdCodeList.length; i++) {
            if (icdCodeList[i][type].toString().includes(e.target.value)) {
                filtData.push(icdCodeList[i]);
            }
        }
        setDataList(filtData);
    }

    useEffect(() => {
        setDataList(icdCodeList);
    }, [icdCodeList])

    const columns = [
        {
            title: 'Code',
            dataIndex: 'code',
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            render: (text, record) => <a onClick={() => onselect(text, record)}>{text}</a>,
        },
        {
            title: 'Description',
            dataIndex: 'desp',
        }
    ];

    return (
        <Modal open={iscptModal} width={750} onOk={handleCpt} onCancel={handlecptCancel}>
            <div style={{ marginTop: '30px', display: 'inline-block', marginBottom: '20px', width: '300px' }}>
                <Input placeholder='code' suffix={<SearchOutlined />} onChange={(e) => searchCPT(e, 'code')} />
            </div>
            <div style={{ display: 'inline-block', margin: '30px 0px 20px 10px', width: '300px' }}>
                <Input placeholder='Description' suffix={<SearchOutlined />} onChange={(e) => searchCPT(e, 'desp')} />
            </div>
            <Table
                // rowSelection={{
                //     ...rowSelection,
                // }}
                // onRow={(record, rowIndex) => onselect(record, rowIndex)}
                rowKey={(record) => record.code}
                columns={columns}
                dataSource={dataList}
                pagination={{
                    pageSize: 8
                }}
            />

        </Modal>
    )
};

export default CPTModal;

