import { createSlice } from "@reduxjs/toolkit";

const pageNameSlice = createSlice({
  name: "pageName",
  initialState: "",
  reducers: {
    headerName: (state, action) => {
        return (state = action.payload);
    },
  },
});

export const { headerName } = pageNameSlice.actions;
export const pageName = (state) => state.page;

export default pageNameSlice.reducer;
