import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: 'accesstoken',
    initialState: {
        token: ''
    },
    reducers: {
      setAccesstoken: (state, action) => {
        state = action.payload;
        return state;
      },
    },
  });
  
  export const { setAccesstoken } = authSlice.actions;
  export const getAccesstoken = (state) => state.accesstoken;
  
  export default authSlice.reducer;