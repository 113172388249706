import { Button, Card, Col, Form, Row, Select, Typography } from "antd";
const { Title } = Typography;

const OfficeDaily = () => {

    return (
        <>
            <div>
                <Row justify="center">
                    <Col span={22}>
                        <Title level={5} className="patientViewheader" >Office Daily Message - [Last 100 Entries]</Title>
                    </Col>
                    <Col span={22} style={{ marginTop: '10px' }}>
                        <Row>

                        
                        <Title level={5} > Display filter</Title>
                        <Card className="shadowCard">
                            <Form layout="vertical">
                                <Col span={6}>
                                    <Form.Item name="filterDaily" label="Office">
                                        <Select>
                                            <Select.Option value="All">All</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    Use this option to enter office daily short messages for inter-office communication. The message will be displayed at top of the Daily or WeeklyViewAppointment pages if
                                    user selects the option "Show Office Daily Message" in Appointment Page Settings.
                                </Col>
                                <Col span={24} justify='end' style={{marginTop:'30px', textAlign:'right'}}>
                                    <Button type="primary" className="btn-primary">
                                        Add Message
                                    </Button>
                                </Col>
                            </Form>
                        </Card>
                        </Row>
                    </Col>

                </Row>
            </div>
        </>
    )
}

export default OfficeDaily;