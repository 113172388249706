import { Row, Col, Typography, Card, Divider, Radio, Form, Select, DatePicker, Tabs, Input, Button } from "antd";
import { useState } from "react";
const { Title } = Typography;

const PatientLookUp = () => {
    const [value, setValue] = useState(1);
    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    
    const getFormbyName = () => {
        return (<>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name='byNamelastName' label="Last Name">
                        <Select>
                            <Select.Option value="startWith">Start With</Select.Option>
                            <Select.Option value="endWith">End With</Select.Option>
                            <Select.Option value="containWith">Contian With</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12} >
                    <Form.Item name='byNamelastNameText'>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name='byNamefirstName' label="First Name">
                        <Select>
                            <Select.Option value="startWith">Start With</Select.Option>
                            <Select.Option value="endWith">End With</Select.Option>
                            <Select.Option value="containWith">Contian With</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12} >
                    <Form.Item name='byNamefirstNameText'>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <Row gutter={16} justify='end'>
                <Col span={8}>
                    <Button type="primary" className="btnPrimary">Look up</Button>
                </Col>
                <Col span={8}>
                    <Button type="primary" className="btnDefault">Exit</Button>
                </Col>
            </Row>
        </>

        )
    }
    const itemTabs = [
        {
            label: `By Name`,
            key: 'byName',
            children: getFormbyName(),
        },
        {
            label: `By Insurance ID`,
            key: 'byInsuranceId',
            children: `Content of card tab `,
        },
        {
            label: `By Patient SSN`,
            key: 'byPatientSSN',
            children: `Content of card tab `,
        },
    ]
    return (
        <div>
            <Row justify='center'>
                <Col span={24}>
                    <Title level={5} className="patientViewheader">
                        Check Eligibility - Patient Look Up
                    </Title>
                </Col>
                <Col span={24}>
                    <Card className="shadowCard docAgree">
                        <Row gutter={16}>
                            <Col span={12}>
                                <Card className="shadowCard docAgree">
                                    <p>
                                        In the text below, the term 'Affiliate Organization'
                                        refers to the following organizations, for whom Office Ally®
                                        is providing services:
                                    </p>
                                    <p>
                                        Restriction of use of Confidential/Privilege Material:
                                        This site is owned and operated by Office Ally® and its
                                        Affiliate Organization (). The information contained,
                                        displayed, or transmitted via this site may be privileged
                                        and confidential and protected from disclosure. It is intended
                                        only for the person or entity to which it is addressed.
                                        Any dissemination, reproduction, retransmission,
                                        distribution, or copying of this information, other
                                        use of, or taking of any action in reliance upon, this information
                                        by persons or entities other than the intended recipient, or
                                        employee or agent of the intended recipient, is strictly prohibited.
                                    </p>
                                    <p>
                                        DISCLAIMER: UNLESS OTHERWISE EXPRESSED IN WRITING, THE INFORMATION AND
                                        MATERIAL DISPLAYED IN THIS SITE IS INFORMATIONAL AND INTENDED TO ASSIST
                                        THE AFFILIATED ORGANIZATIONS' () PROVIDER COMMUNITY IN VERIFYING MEMBERS'
                                        LIMITED DEMOGRAPHIC AND ELIGIBILITY RECORDS WITH THOSE IN THE AFFILIATED
                                        ORGANIZATIONS' RECORDS. OFFICE ALLY® OR ITS AFFILIATED ORGANIZATIONS MAKE
                                        NO GUARANTEES, EXPRESSED OR IMPLIED, AS TO THE ACCURACY OF THE CONTENT OF
                                        THIS SITE AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY ERRORS OR
                                        OMISSIONS CONTAINED IN THIS SITE. OFFICE ALLY® AND ITS AFFILIATE
                                        ORGANIZATIONS DO NOT WARRANT OR MAKE REPRESENTATION OF TIMELINESS
                                        AND ACCURACY OF ANY CORRECTION OF INFORMATION MADE ON THIS SITE FROM
                                        TIME TO TIME. PROVIDERS ARE ENCOURAGED TO FOLLOW THEIR STANDARD PROCESSES
                                        FOR VERIFYING ELIGIBILITY, BENEFITS, COPAYMENT, ETC. THROUGH THE HEALTH PLANS'
                                        WEB SITES OR CUSTOMER SERVICES DEPARTMENTS. INFORMATION INCLUDING MEMBER
                                        ELIGIBILITY AND DEMOGRAPHIC CONTAINED IN THIS SITE DOES NOT GUARANTEE
                                        BENEFITS OR CLAIMS CONSIDERATION OR PAYMENT.
                                    </p>
                                    <p>
                                        Termination: Upon the termination of provider agreement with Office Ally®
                                        and/or its Affiliate Organization (), provider or its agent is prohibited
                                        from using this site. Affiliate Organization () may, at it's sole discretion,
                                        terminate the agreement to use this site if provider or its agent fails to
                                        comply with any term or provision of this agreement.
                                    </p>
                                    <p>
                                        Limitation of Liability: Office Ally® or its Affiliate Organization () shall not
                                        be liable for any damages, under any circumstances, that result or arise from
                                        the use of this site or lack thereof and information contained herein.
                                    </p>
                                    <Divider />
                                    <Form.Item>
                                        <Radio.Group onChange={onChange} value={value}>
                                            <Radio value='agree'>agree</Radio>
                                            <Radio value='Disagress'>Disagress</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card className="shadowCard docAgree">
                                    <Form>
                                        <Form.Item name="insurancePayer" label="Insurance Co./Payer">
                                            <Select>
                                                <Select.Option value="copay">copay</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="dateofservice" label="Date of Service">
                                            <DatePicker />
                                        </Form.Item>
                                        <Tabs
                                            defaultActiveKey="1"
                                            type="card"
                                            size="small"
                                            items={itemTabs}
                                        />
                                    </Form>
                                </Card>
                            </Col>
                        </Row>
                    </Card >
                </Col >
            </Row >
        </div >
    )
}

export default PatientLookUp;