import { SearchOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Radio, Row, Select, Typography } from "antd";
const {Title} =  Typography;

const ClaimAwaiting = () => {
    return (
        <>
            <Row justify="center">
                {/* <Col span={24}>
                    <Title level={5} className="patientViewheader" >
                        Claims Awaiting Batch - [Show All]</Title>
                </Col> */}
                <Col span={24}>
                    <Form layout="vertical" className="specialEventForm">
                        <Card className="shadowCard">
                            <Row gutter={8}>
                                <Col span={18}>
                                    <Row gutter={8}>
                                        <Col span={12}>
                                            <span> Display Filter</span>
                                            <Form.Item name='formType'>
                                                <Select>
                                                    <Select.Option value="All">Form Type</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                        <span> Display Filter</span>
                                            <Form.Item name='formImage'>
                                                <Checkbox /> Include Form Image 
                                            </Form.Item>
                                            <Button className="btnPrimary">Print Claim Form</Button>
                                        </Col>
                                    </Row>
                                    <Row gutter={8}>
                                        <Col span={24}>
                                            <span>
                                            Claims in this list will be picked up and processed by Office Ally periodically throughout the day. Once the claims are successfully processed, you can view them in Inventory Reporting. Any claims that we cannot process due to error will also be available in the Repairable Claims List for you to take further action.
                                            </span>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col span={6}>
                                    <Row>
                                        <Col span={24}>
                                            <Radio value="CMS 1500 Old" label="CMS 1500 08/05 (old)" >CMS 1500 08/05 (old)
                                            </Radio>
                                            <Radio value="CMS 1500 New" label="CMS 1500 02/12 (New)" >CMS 1500 02/12 (New)
                                            </Radio>
                                        </Col>
                                        <Col span={24}>
                                            <Button className="btnPrimary" >
                                                Set Default
                                            </Button>
                                            
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                        </Card>
                    </Form>
                </Col>
            </Row>
        </>
    )
};

export default ClaimAwaiting;